import { put, takeLatest } from "redux-saga/effects";
import {
  ADD_NEW_MEMBER_REQUEST,
  ADD_NEW_MEMBER_SUCCESS,
  ADD_NEW_MEMBER_FAILURE,
  USER_DETAILS_SUCCESS, USER_DETAILS_FAILURE, USER_DETAILS_ATTEMPT
} from "./constants";
import {asyncRequest} from "../../api";
import {push} from "connected-react-router";
import * as ROUTES from "../../components/Containers/Main/constants";


export function* fetchAddNewMemberSaga(action: any) {
  try {
    const addNewMemberResults = yield asyncRequest({
      config: {
        method: "POST",
        data: {
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          email: action.payload.emailAddress,
          image: action.payload.image,
          imageName: action.payload.imageName,
          startDate: action.payload.startDate,
          endDate: action.payload.terminationDate,
          authorities: action.payload.authorities,
          companyId: action.payload.companyId,
        }
      },
      endpoint: 'api/users'
    });

    yield put({ type: ADD_NEW_MEMBER_SUCCESS, payload: addNewMemberResults });
    yield put(push(ROUTES.TEAM));
  } catch (error) {
    console.log(error);
    yield put({ type: ADD_NEW_MEMBER_FAILURE, payload: error && error.response && error.response.data &&
      error.response.data.title ? error.response.data.title : 'Unknown error' });
  }
}


export function* fetchUserDetailsSaga(action: any) {
  try {
    const userDetails = yield asyncRequest({
      config: {
        method: "GET",
      },
      endpoint: 'api/users/connected'
    });

    yield put({ type: USER_DETAILS_SUCCESS, payload: userDetails });
  } catch (error) {
    console.log(error);
    yield put({ type: USER_DETAILS_FAILURE, payload: error ? error : 'Unknown error' });
  }
}

export default  [
  takeLatest(ADD_NEW_MEMBER_REQUEST, fetchAddNewMemberSaga),
  takeLatest(USER_DETAILS_ATTEMPT, fetchUserDetailsSaga)
]
