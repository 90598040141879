import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";



const StyledWhat3Words = styled.div `
  width: 100%;
  margin: 10px auto;
  flex-grow: 1;
 
  #autosuggest {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 9999;
  }
  
  #wrapper {
    position: relative;
    height: 100%;
    overflow-y: hidden;
  }

  #map {
    height: 100%;
    width: 100%;
    margin-top: ${props => props.theme.clicked ? '1.8rem' : paymeTheme.paddingRemMd};
    
    form {
      padding: 0;
      left: 0;
      right: 0;
      margin: auto;
      
      .glass {
        width: 95vw;
        margin: auto;
      }

      .results {
        max-width: 350px;
        width: fit-content;
        margin: auto;
      }

      .reset {
        display: none;
      }
    }
    
    .leaflet-bar-part.leaflet-bar-part-single {
      display: none;
    }

    .leaflet-control-zoom {
      display: none;
    }

    .leaflet-popup-content-wrapper {
      text-align: center;
    }
    
    input {
      height: 30px;
    }

    .geosearch {
      margin-top: 0;
      border-top: 0;

      .glass {
        margin-top: 0;
        margin-bottom: 0;
      }

      .results {
        margin-top: 0;
        width: 200px;
      }
    }
  } 

  .leaflet-control-layers {
    position: absolute;
    right: 2vw;
    top: 5vh;

    &.leaflet-control-layers-expanded {
      padding: 6px;
    }

    a {
      display: block!important;
      margin-right: 0;
    }

    margin-right: 0;
  }
  
  ${media.desktop} {
    .glass,
    .results {
      min-width: 350px;
    }
    
    #map {
      min-height: 50vh;
    }
  }

  ${media.desktopMedium} {
    #wrapper {
      padding-left: 5vw;
    }

    #map {
      form {
        .glass {
          width: 90vw;
        }
      }
    }
  }

  #wrapper {
    .MuiIconButton-root {
      position: absolute;
      right: 5vw;
      z-index: 10000;
      fill: ${paymeTheme.floteColor};
      width: ${paymeTheme.paddingRemLg};
      height: ${paymeTheme.paddingRemLg};
      top: ${props => props.theme.clicked ? '30px' : '15px'};
    }
  }

`;

export { StyledWhat3Words } ;
