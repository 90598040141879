import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";


const StyledUserProfileCard = styled.div `
  position: relative;
  padding:  ${paymeTheme.paddingRem} 0;
  border-bottom: 1px solid ${paymeTheme.borderGrey};
  margin: ${paymeTheme.paddingRemSm} 0;
  color: ${paymeTheme.colorDarkGray};
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: ${paymeTheme.paddingRemXs} 0;
    
  .user-picture {
    img, svg {
      margin-right: ${paymeTheme.paddingRemXs}; 
      width: 50px;
      height: 50px;
    }
  }
  
  .user-picture img {
    border-radius: 50%;  
  }
  .user-details {
    display: flex;
    max-width: 65%;
    width: 100%;
  }
  .user-name {
    font-weight: ${paymeTheme.fontBold};
  }
  .user-status {
    color: ${props => props.theme.status === "Active" ? paymeTheme.successColor : props.theme.status === "Unverified" ? paymeTheme.customColor : paymeTheme.errorColor};
  }
  .user-last-seen {
    font-size: ${paymeTheme.fontRemSizeSmall};
  }
  .user-details-content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .user-date {
    justify-self: flex-end;
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: ${paymeTheme.paddingRemXs};
  }
  .user-menu {
    position: absolute;
    right: ${paymeTheme.paddingRemXs};
    top: 0;
    bottom: 0;
    margin: auto;
    .MuiButtonBase-root {
      color: ${paymeTheme.floteColor};
    }
    svg {
      fill: ${paymeTheme.floteColor}; 
    }
    padding: ${paymeTheme.paddingRemXs} 0;
  }
  
  ${media.desktopMedium} {
  margin: 0;
  }
  `;

export { StyledUserProfileCard } ;
