import React, {FC, useEffect, useState} from 'react';
import {StyledTeamMemberForm, AntSwitch} from "./styles";
import {GeneralTextField} from "../../../components/Inputs";
import {OutlinedButton, PrimaryButton} from "../../../components/Buttons";
import {useHistory} from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import {FormGroup} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {fileToDataUri} from "../../../utils/extractUriFromFile";
import {validateEmail} from "../../../utils/validateEmail";
import {useDispatch, useSelector} from "react-redux";
import {AddNewMemberAttempt, addNewMemberFailure, displayUserDetails} from "../actions";
import {extractAuthoritiesIdsFromUserDetails} from "../utils/extractAuthoritiesIdsFromUserDetails";
import {getAddNewMemeberError, getIsLoadingAddnewMember} from "../selectors";
import {NotificationError} from "../../../components/NotificationMessages";
import LoadingComponent from "../../../components/Loading";
import {parseJwt} from "../../../utils/parseDetailsFromToken";
import {asyncRequest} from "../../../api";
import * as ROUTES from "../../../components/Containers/Main/constants";
import DatePicker from "react-datepicker";
import { extractDateFromDatePicker } from "../../../utils/extractDateFromDatePicker";
import {UploadImage} from "../../../components/UploadImageSection";

interface Props {
  actionType: string;
  initialUserDetailsState: any;
}


export const TeamMemberForm: FC<Props> = ({actionType, initialUserDetailsState}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const userToken = localStorage.getItem("token");
  const companyId = userToken && parseJwt(userToken).companyId;
  const addNewMemberLoading = useSelector(getIsLoadingAddnewMember);
  const saveNewMemberError = useSelector(getAddNewMemeberError);

  const [errorMessage, setErrorMessage] = useState(saveNewMemberError);
  const [isLoading, setIsLoading] = useState(addNewMemberLoading);
  const [userDetailsState, setUserDetailsState] = useState();
  const [dataUri, setDataUri] = useState('');
  const [imageName, setImageName] = useState();
  const [isFirstNameEmpty, setIsFirstNameEmpty] = useState(false);
  const [isLastNameEmpty, setIsLastNameEmpty] = useState(false);
  const [isEmailEmpty, setIsEmailEmpty] = useState(false);
  const [isStartDateEmpty, setIsStartDateEmpty] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isEmailValidated, setIsEmailValidated] = useState(true);
  const [helperTextEmail, setHelperTextEmail] = useState();
  const [displayHelperTextEmail, setDisplayHelperTextEmail] = useState();
  const [helperTextTerminationDate, setHelperTextTerminationDate] = useState(false);
  const [activeMember, setActiveMember] = useState(false);

  useEffect(() => {
    initialUserDetailsState && setUserDetailsState(initialUserDetailsState);
    initialUserDetailsState && initialUserDetailsState.status &&
    setActiveMember(initialUserDetailsState.status.name !== 'Access denied');
  },[initialUserDetailsState]);


  useEffect(() => {
    setErrorMessage(saveNewMemberError);
    setTimeout(() => {
      dispatch(addNewMemberFailure(null));
    }, 3000);
  }, [saveNewMemberError]);

  useEffect(() => {
    if (userDetailsState && userDetailsState.startDate && userDetailsState.terminationDate) {
      if (userDetailsState.startDate > userDetailsState.terminationDate) {
        setHelperTextTerminationDate(true);
      } else {
        setHelperTextTerminationDate(false)
      }
    }
  }, [userDetailsState]);

  useEffect(() => {
    if (userDetailsState) {
      if (!userDetailsState.firstName || !userDetailsState.lastName ||
        !userDetailsState.emailAddress || !userDetailsState.startDate || helperTextTerminationDate ||
        (userDetailsState.authorities && userDetailsState.authorities.length > 0 &&
          userDetailsState.authorities.every(item => item.checked === false) || !userHasChanged())
      ) {
        setIsButtonDisabled(true);
      }
      if (!!userDetailsState.firstName && !!userDetailsState.lastName &&
        !!userDetailsState.emailAddress && !!userDetailsState.startDate && !helperTextTerminationDate &&
        (userDetailsState.authorities && userDetailsState.authorities.length > 0 &&
          userDetailsState.authorities.some(item => item.checked === true) && userHasChanged())
      ) {
        setIsButtonDisabled(false);
      }
    }
  }, [userDetailsState, helperTextTerminationDate]);

  useEffect(() => {
    displayHelperTextEmailHandler();
  }, [isEmailValidated, isEmailEmpty]);

  useEffect(() => {
    if (userDetailsState) {
      if (userDetailsState.firstName) {
        setIsFirstNameEmpty(false);
      }

      if (userDetailsState.lastName) {
        setIsLastNameEmpty(false);
      }

      if (userDetailsState.emailAddress) {
        setIsEmailEmpty(false);
      }
    }
  }, [userDetailsState]);

  const userHasChanged = () => {
    if (actionType !== "update") return true;
    let hasChanged = false;

    if (userDetailsState) {
        Object.keys(userDetailsState).forEach((key) => {
        if (key === "status") {
          if (userDetailsState[key].id !== initialUserDetailsState[key].id) hasChanged = true;
        } else {
          if (userDetailsState[key] !== initialUserDetailsState[key]) hasChanged = true;
        }
      })
    }

    return hasChanged;
  }


  const onBlurInputValueHandler = (value: string, setIsValueEmpty: any) => {
    if (!!value) {
      setIsValueEmpty(false);
    } else {
      setIsValueEmpty(true);
    }
  };

  const onBlurValidateEmailHandler = () => {
    if (userDetailsState.emailAddress !== '') {
      if (validateEmail(userDetailsState.emailAddress)) {
        setIsEmailValidated(true);
      } else {
        setIsEmailValidated(false);
      }
    }
  };

  const displayHelperTextEmailHandler = () => {
    if (isEmailEmpty) {
      setHelperTextEmail("Fill the Email address");
      setDisplayHelperTextEmail(true);
    }
    if (!isEmailEmpty && !isEmailValidated) {
      setHelperTextEmail("Fill a valid email format");
      setDisplayHelperTextEmail(true);
    }
    if (!isEmailEmpty && isEmailValidated) {
      setHelperTextEmail('');
      setDisplayHelperTextEmail(false);
    }
  };

  const onChangeImageHandler = (file: any) => {
    if(!file) {
      setDataUri('');
      return;
    }

    setImageName(file.name);

    fileToDataUri(file)
      .then(dataUri => {
        setDataUri(dataUri);
        setUserDetailsState({...userDetailsState, image: dataUri});
      })
  };

  const handleChangeRolesCheckboxes = (e:React.ChangeEvent<HTMLInputElement>) => {
    const updatedUserRolesState = userDetailsState.authorities
      .map(item => {
        if (item.checkBoxName !== e.target.name) {
          return {
            ...item,
            checked: false
          }
        }
        return {
          ...item,
          checked: e.target.checked
        }
      });
    setUserDetailsState(prevState => ({
      ...prevState,
        authorities: updatedUserRolesState,
    }));
  };

  const onChangeFirstNameHandler: React.ChangeEventHandler<HTMLInputElement> =
    (event) => {
      setIsFirstNameEmpty(false);
      setUserDetailsState({
        ...userDetailsState,
        firstName: event.target.value
      });
    };

  const onChangeLastNameHandler: React.ChangeEventHandler<HTMLInputElement> =
    (event) => {
      setIsLastNameEmpty(false);
      setUserDetailsState({
        ...userDetailsState,
        lastName: event.target.value
      });
    };

  const onChangeEmailAddressHandler: React.ChangeEventHandler<HTMLInputElement> =
    (event) => {
      setIsEmailEmpty(false);
      setUserDetailsState({
        ...userDetailsState,
        emailAddress: event.target.value
      });
    };

  const onChangeStartDateHandler = (dateTime: Date) => {
      const date = extractDateFromDatePicker(dateTime.toLocaleString().toString());
      setUserDetailsState({
        ...userDetailsState,
        startDate: date
      });
    };

  const onChangeTerminationDateHandler = (dateTime: Date) => {
      const date = extractDateFromDatePicker(dateTime.toLocaleString().toString());
      setUserDetailsState({
        ...userDetailsState,
        terminationDate: date
      });
    };

  const addNewMemberHandler = () => {
    dispatch(displayUserDetails(userDetailsState));
    dispatch(AddNewMemberAttempt({
      ...userDetailsState,
      startDate: new Date(userDetailsState.startDate).toISOString(),
      terminationDate: userDetailsState.terminationDate ? new Date(userDetailsState.terminationDate).toISOString() : undefined,
      authorities: extractAuthoritiesIdsFromUserDetails(userDetailsState),
      companyId: companyId,
      imageName,
    }));
  };

  const editMemberDetailsHandler = () => {
    setIsLoading(true);
    asyncRequest({
      config: {
        method: "PUT",
        data: {
          id: userDetailsState.userId,
          firstName: userDetailsState.firstName,
          lastName: userDetailsState.lastName,
          email: userDetailsState.emailAddress,
          image: userDetailsState.image,
          imageName,
          startDate: new Date(userDetailsState.startDate).toISOString(),
          endDate: userDetailsState.terminationDate? new Date(userDetailsState.terminationDate).toISOString() : undefined,
          authorities: extractAuthoritiesIdsFromUserDetails(userDetailsState),
          companyId: userDetailsState.companyId,
          userStatusId: userDetailsState.status.id
        }
      },
      endpoint: 'api/users',
    })
      .then((response) => {
        history.push(ROUTES.TEAM);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(err.response.data.title);
        setIsLoading(false);
      });
  };

  const goToTeamPageHandler = () => {
    if (actionType === 'add') {
      addNewMemberHandler();
      return;
    }

    if (actionType === 'update') {
      editMemberDetailsHandler();
      return;
    }
  };

  const onToggleMemberStatus: React.ChangeEventHandler<HTMLInputElement> =
    (event) => {
      let changingState;
      let changingStateId;
      if (!activeMember) {
        if (initialUserDetailsState.status.name === 'Access denied') {
          changingState = 'Unverified';
          changingStateId = 1;
        } else {
          changingState = initialUserDetailsState.status.name;
          changingStateId = initialUserDetailsState.status.id;
        }
      } else {
        changingState = 'Access denied';
        changingStateId = 3;
      }

      setUserDetailsState({
        ...userDetailsState,
        status: {
          id: changingStateId,
          name: changingState
        }
      });

      setActiveMember(!activeMember);
    };

  return (
    <StyledTeamMemberForm theme={{status: userDetailsState && userDetailsState.status ? userDetailsState.status.name : undefined}}>
      {userDetailsState && <form className="new-team-member-form">
        <div>
            <div className="field">
                <label></label>
                <GeneralTextField id="first-name"
                      value={userDetailsState.firstName}
                      onChange={onChangeFirstNameHandler}
                      onBlur={() => onBlurInputValueHandler(userDetailsState.firstName, setIsFirstNameEmpty)}
                      variant="filled"
                      required={true}
                      helperText={isFirstNameEmpty && "Fill the First name"}
                      placeholder="First name"/>
            </div>
            <div className="field">
                <label></label>
                <GeneralTextField id="last-name"
                          value={userDetailsState.lastName}
                          onChange={onChangeLastNameHandler}
                          onBlur={() => onBlurInputValueHandler(userDetailsState.lastName, setIsLastNameEmpty)}
                          variant="filled"
                          required={true}
                          helperText={isLastNameEmpty && "Fill the Last name"}
                          placeholder="Last name"/>
            </div>
            <div className="field">
                <label></label>
                <GeneralTextField id="email-address"
                          type="email"
                          value={userDetailsState.emailAddress}
                          onChange={onChangeEmailAddressHandler}
                          onBlur={() => {onBlurInputValueHandler(userDetailsState.emailAddress, setIsEmailEmpty);
                            onBlurValidateEmailHandler();}}
                          variant="filled"
                          required={true}
                          helperText={displayHelperTextEmail && helperTextEmail}
                          placeholder="Email address"/>
            </div>
            <div className="field date-picker-field">
              <label className="label-active">Start</label>
              <DatePicker
                required={true}
                onBlur={() => onBlurInputValueHandler(userDetailsState.startDate, setIsStartDateEmpty)}
                selected={new Date(userDetailsState.startDate)}
                onChange={(date) => onChangeStartDateHandler(date)}
                dateFormat="MMMM d, yyyy"
                placeholderText="Start date"
              />
              {isStartDateEmpty && <p className="error-text-date-picker">Fill the start date</p>}
            </div>
            <div className="field date-picker-field">
              <label className="label-access-denied">End</label>
              <div className="date-error-wrapper">
                <DatePicker
                    selected={userDetailsState.terminationDate ? new Date(userDetailsState.terminationDate) : null}
                  onChange={(date) => onChangeTerminationDateHandler(date)}
                  dateFormat="MMMM d, yyyy"
                  placeholderText="Termination date"
                />
                {helperTextTerminationDate && <p className="error-text-date-picker">End date should be higher than start date</p>}
              </div>
            </div>
        </div>
        <div className="status-wrapper">
          {userDetailsState.status && <p>Status - <span className="status">{userDetailsState.status.name}</span></p>}
          {actionType === 'update' &&
            <AntSwitch checked={activeMember} onChange={onToggleMemberStatus} name="checkedC" />
          }
        </div>
        <div className="roles-wrapper">
          <div className="roles-title">Role</div>
          <div className="roles-list">
            <FormGroup column="true">
              {
                userDetailsState.authorities && userDetailsState.authorities.length > 1 &&
                userDetailsState.authorities.map((item: any, index: number) => item.id !== 5 && <FormControlLabel
                  key={`checkbox-${index}`}
                  control={
                    <Checkbox
                            checked={item.checked}
                      onChange={handleChangeRolesCheckboxes}
                      color="primary"
                      name= {item.checkBoxName}/>}
                  label={item.label}
                />)
              }
            </FormGroup>
          </div>
        </div>
      </form>}
      <UploadImage onChangeImageHandler={onChangeImageHandler}
                   userDetails={userDetailsState}
                   dataUri={dataUri}
                   actionType={actionType}/>
      <div className="actions-wrapper">
        <OutlinedButton className="back-button" onClick={() => {history.goBack()}}>Back</OutlinedButton>
        <PrimaryButton className="continue-button"
                       onClick={goToTeamPageHandler}
                       disabled={isButtonDisabled}>Confirm</PrimaryButton>
      </div>
      {errorMessage && <NotificationError message={errorMessage} messageClass="error"/>}
      {isLoading && <LoadingComponent/>}
    </StyledTeamMemberForm>
  );
};
