import React, {FC, useEffect, useState} from "react";
import {StyledAllCollectionsList} from "./styles";
import {GeneralTextField} from "../../../components/Inputs";
import {useHistory, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import CollectionHistoryCard from "../CollectionHistoryCard";
import {OutlinedButton} from "../../../components/Buttons";
import {addSiteCollectionDetails, addTotalCashCollected} from "../../SiteCollection/actions";
import {getSiteDetailsData} from "../../AllClientsSitesPage/selectors";
import {displaySiteDetailsSuccess} from "../../AllClientsSitesPage/actions";
import * as ROUTES from "../../../components/Containers/Main/constants";


interface Props {
  isTechnician?: boolean;
  collectionHistoryList: any;
}
const AllCollectionsList: FC<Props> = ({isTechnician, collectionHistoryList}) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const siteDetails = useSelector(getSiteDetailsData);
  const [collectionHistoryListSorted, setCollectionHistoryListSorted] = useState([]);
  const prevStateLocation = history.location.state && history.location.state.initialUrl;
  let totalContactlessMoneyGross = 0;
  let totalEstimatedCashAmount = 0;
  let totalGrossMachineIncome = 0;
  let actualCashCollected = 0;

  const sortByName = (sites) => {
    const sorted = [...sites].sort((a, b) => {
      return a.siteName.localeCompare(b.siteName);
    });

    return sorted;
  };

  useEffect(() => {
    if (collectionHistoryList && !collectionHistoryListSorted.length) {
      setCollectionHistoryListSorted(sortByName(collectionHistoryList));
    }
  }, [collectionHistoryList]);

  const totalCashCollectedPerUnit = (item) => {
    if (item.collections.length > 0) {
      item.collections.forEach(collection => {
        actualCashCollected += collection.actualCashCollected;
        totalContactlessMoneyGross += collection.grossContactless;
        totalEstimatedCashAmount += collection.estimatedCashAmount;
        totalGrossMachineIncome += collection.grossCashCollected;
      });
      dispatch(addTotalCashCollected({
        actualCashCollected: actualCashCollected,
        totalContactlessMoneyGross: totalContactlessMoneyGross,
        totalEstimatedCashAmount: totalEstimatedCashAmount,
        totalGrossMachineIncome: totalGrossMachineIncome,
      }));
    }
  };

  const onSearchHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    console.log('on search handler');
  };

  const onClickCollectionHandler = (item) => {
    totalCashCollectedPerUnit(item);
    dispatch(addSiteCollectionDetails(item.collections));
    dispatch(displaySiteDetailsSuccess({
      ...siteDetails,
      name: item.siteName,
      town: item.town,
      postcode: '',
      groups: [...item.groups],
    }));
    history.push({
      pathname: ROUTES.COLLECTION_RECEIPT,
      state: {  prevUrl: location.pathname, initialUrl:  prevStateLocation, collectionGroupId: item.collectionGroupId},
    });
  };

  const collectionHistoryItem = collectionHistoryListSorted && collectionHistoryListSorted.map((item, index) => {
    return <div onClick={() => onClickCollectionHandler(item)} key={`history-${index}`}>
      {item.collections && item.collections.length > 0 && <CollectionHistoryCard collectionHistoryItem={item}/>}
    </div>
  });

  return (
    <StyledAllCollectionsList className="height-controller-wrapper">
      <form className="search-form search-sites-form">
        <GeneralTextField id="sites-search"
                          variant="filled"
                          placeholder="Search site"
                          onChange={onSearchHandler}
        />
      </form>
      <div className="header-list">
        <div>Client</div>
        <div>Site</div>
        <div>Town</div>
      </div>
      {collectionHistoryItem}
      <div className="actions-wrapper">
        <OutlinedButton onClick={() => {history.goBack()}}>Back</OutlinedButton>
      </div>
    </StyledAllCollectionsList>
  );
};

export default AllCollectionsList;
