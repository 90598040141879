import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";
import {makeStyles} from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";


const StyledAddNewStock = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: "Exo 2"!important;
  
 .machine-details-header {
    display: flex;
    margin: ${paymeTheme.paddingRemLg} 0;
    width: 100%;
    
    .machine-name {
      color: ${paymeTheme.floteColor};
      font-size: ${paymeTheme.fontSizeH6};
      padding-right: 50px;
      padding-left: ${paymeTheme.paddingRem};
    }
  } 

  .selection-wrapper {
    color: ${paymeTheme.colorWhite};
    text-align: center;
    padding: ${paymeTheme.paddingRemXs};
    margin: ${paymeTheme.paddingRemXs} auto;
    border-radius: ${paymeTheme.paddingRem};
    width: 80%;
    
    .selection-name {
      font-size: ${paymeTheme.fontSizeH1};
      padding-bottom: ${paymeTheme.paddingRemXs};
    }
    
    .selection-price,
    .increase-stock-wrapper{
      font-size: ${paymeTheme.fontSizeH3};
    }
    
    .increase-stock-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 80%;
      background-color: black;
      border-radius: 4px;
      margin: auto;
      padding: ${paymeTheme.paddingRemSm} 0;
      margin-top: ${paymeTheme.paddingRemXs};
  
      svg {
        width: 40px;
        height: 40px;
      }
    }
  }
  
  .stock-range {
    width: 80%;
  }
  
   ${media.desktop} {
      .selection-wrapper {
        width: 350px;
      }
    }
  
`;

export { StyledAddNewStock } ;

export const StyledSlider = styled<any>(Slider)`

  .MuiSlider-rail {
    height: 15px;
    opacity: 1;
    background: linear-gradient(to right, #E50000, #E50000 ${(props) => props.rangeValue[0] * 100 / 20}%, 
    #178900 ${(props) => (20 - props.rangeValue[1] ) * 100 / 20}%, #178900);
  }
 
`

export const useStyles = makeStyles(theme => ({
  root: {
    width: 300,
    textAlign: "center",
    height: "15px",
  },
  margin: {
    height: theme.spacing(3)
  },
  thumb: {
    background: paymeTheme.colorBlack,
    width: "16px",
    height: "16px",
    marginLeft: "-3px",
    marginTop: "-1px",
  },
  mark: {
    background: paymeTheme.colorBlack,
    height: "15px"
  },
  rail: {
    background: `linear-gradient(to right, #E50000, #E50000 50%, #178900 50%, #178900);`,
    height: "15px",
  },
  track: {
    background: paymeTheme.floteColor,
    height: "15px",
  },
  valueLabel: {
    "&>*": {
      background: paymeTheme.colorBlack
    }
  }
}));

