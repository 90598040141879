import React, {FC, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getAddClientDetailsError, getClientData, getClientSitesDetails, getSiteTypeList} from "../selectors";
import {GeneralTextField} from "../../../components/Inputs";
import {OutlinedButton, PrimaryButton} from "../../../components/Buttons";
import {useHistory, useParams} from "react-router-dom";
import { FormControl } from "@material-ui/core";
import {
  addClientDetails,
  addSiteToClientAttempt,
  displaySiteTypeAttempt,
  editClientNameAttempt,
  editSiteDetailsAttempt
} from "../actions";
import What3Words from "../../../components/What3Words";
import {parseJwt} from "../../../utils/parseDetailsFromToken";
import CustomSelect from "../../../components/Selects";
import * as ROUTES from "../../../components/Containers/Main/constants";
import {capitalizeTheFirstLetterOfEachWord} from "../../../utils/capitalizeEachLetterOfWord";
import { getFlowType } from "../../../components/Containers/Main/selectors";
import {getClientsListData, getSiteDetailsData} from "../../AllClientsSitesPage/selectors";
import { setFlowType } from "../../../components/Containers/Main/actions";
import {StyledClientForm} from "./styles";
import {NotificationError} from "../../../components/NotificationMessages";

interface Props {
  actionType: string;
}

const ClientForm: FC<Props> = ({actionType}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userLatLng = JSON.parse(localStorage.getItem('userLatLng'));
  const userToken = localStorage.getItem('token');
  const userCompanyType = userToken && parseJwt(userToken).companyType;
  const userCompanyName = userToken && parseJwt(userToken).companyName;
  const flowType = useSelector(getFlowType);
  const clientIdFlowType = flowType.clientIdFromAddSiteToClientFlow;
  const clientsList = useSelector(getClientsListData);
  const hasMultipleSites = useSelector(getClientData).hasMultipleSites;
  const clientNameFromFirstStep = useSelector(getClientSitesDetails).clientName;
  const siteDetailsFromStore = useSelector(getSiteDetailsData);
  const typeOptionsArr = useSelector(getSiteTypeList);
  const deleteClientOrSiteError = useSelector(getAddClientDetailsError);
  const { id } = useParams();

  const [clientName, setClientName] = useState(clientNameFromFirstStep);
  const [isClientNameEmpty, setIsClientNameEmpty] = useState(false);
  const [siteName, setSiteName] = useState(siteDetailsFromStore.name);
  const [isSiteNameEmpty, setIsSiteNameEmpty] = useState(false);
  const [town, setTown] = useState(siteDetailsFromStore.town);
  const [isTownEmpty, setIsTownEmpty] = useState(false);
  const [postCode, setPostCode] = useState(siteDetailsFromStore.postcode);
  const [siteType, setType] = React.useState(siteDetailsFromStore.siteType);
  const [what3Words, setWhat3Words] = React.useState(siteDetailsFromStore.what3Words);
  const [siteDetails, setSiteDetails] = useState({name: '', town: '', postCode: '', siteType: {id: -1, code: '', name: ''}, what3Words: ''});

  useEffect(() => {
    if (actionType === "add") {
      setSiteName("");
      setTown("");
      setPostCode("");
      setType({id: -1, code: '', name: ''});
    }
  }, []);

  useEffect(() => {
    if (clientIdFlowType !== -1 && clientsList && clientsList.items) {
      const client = clientsList.items.filter((item) => item.id == clientIdFlowType)[0];
      setClientName(client.name);
    }
  }, [clientIdFlowType, clientsList]);

  useEffect(() => {
    if (userCompanyType === 'SOLO') {
      setClientName(userCompanyName);
    }
    dispatch(displaySiteTypeAttempt());
  }, []);

  const sitesArray = [];

  const confirmClientNameHandler = () => {
    if (clientIdFlowType === -1) {
      if (hasMultipleSites && !siteName) {
        setIsSiteNameEmpty(true);
        return;
      }
      if (!town) {
        setIsTownEmpty(true);
        return;
      }
      setSiteDetails({name: siteName, town, postCode, siteType, what3Words});
      sitesArray.push({
        name: capitalizeTheFirstLetterOfEachWord(siteName),
        town: capitalizeTheFirstLetterOfEachWord(town),
        postCode,
        siteType,
        what3Words
      });
      dispatch(addClientDetails(capitalizeTheFirstLetterOfEachWord(clientName), sitesArray));
      history.push(ROUTES.ADD_CLIENT_SUMMARY);
    } else {
      dispatch(addSiteToClientAttempt({name: siteName, town, postCode, siteType, what3Words}, clientIdFlowType, clientName, flowType));
    }
  };

  const handleClientNameChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setClientName(event.target.value);
  };

  const onBlurClientHandler = () => {
    if (clientName) {
      setIsClientNameEmpty(false);
    } else {
      setIsClientNameEmpty(true);
    }
  };

  const onBlurSiteHandler = () => {
    if (siteName) {
      setIsSiteNameEmpty(false);
    } else {
      setIsSiteNameEmpty(true);
    }
  };

  const onBlurTownHandler = () => {
    if (town) {
      setIsTownEmpty(false);
    } else {
      setIsTownEmpty(true);
    }
  };

  const handleSiteNameChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setSiteName(event.target.value);
    setIsSiteNameEmpty(false);
  };

  const handleTownChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setTown(event.target.value);
    setIsTownEmpty(false);
  };


  const handleSelectTypeChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const selectedSiteType = typeOptionsArr.filter((type) => type.name == event.target.value)[0];
    setType(selectedSiteType);
  };

  const handlePostCodeChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setPostCode(event.target.value);
  };

  const handleWhat3WordsCallback = (data: string) =>{
    setWhat3Words(data);
  };

  const updateClientNameHandler = () => {
    dispatch(editClientNameAttempt(id, clientName));
  };

  const updateSiteDetailsHandler = () => {
    dispatch(editSiteDetailsAttempt(
      id,
      {
        name: siteName,
        town,
        postCode,
        siteType,
        what3Words
      },
      clientIdFlowType,
      flowType
    ));
  };

  const confirmEditAction = () => {
    actionType === 'edit-client' && updateClientNameHandler();
    actionType === 'edit-site' && updateSiteDetailsHandler();
  };

  return (
      <StyledClientForm>
        {clientNameFromFirstStep && userCompanyType !== 'SOLO' && clientIdFlowType === -1 && actionType !== 'edit-client' &&
        <div className="client-name">{clientNameFromFirstStep}</div>}
        {clientName && clientIdFlowType !== -1 && actionType !== 'edit-client' &&
        <div className="client-name">{clientName}</div>}
        {userCompanyType === 'SOLO' && clientIdFlowType === -1 && actionType !== 'edit-client' &&
        <div className="client-name">{userCompanyName}</div>}
        <form>
          {((!clientNameFromFirstStep && userCompanyType !== 'SOLO' && clientIdFlowType === -1) ||
          actionType === 'edit-client') &&
          <GeneralTextField id="client-name"
                            required={true}
                            variant="filled"
                            placeholder="Client name"
                            value={clientName}
                            error={isClientNameEmpty}
                            helperText={isClientNameEmpty && "Please fill the client name"}
                            onBlur={onBlurClientHandler}
                            onChange={handleClientNameChange}/>
          }
          {actionType !== 'edit-client' && <GeneralTextField id="site-name"
                                                             required={true}
                                                             variant="filled"
                                                             placeholder="Site name"
                                                             value={siteName}
                                                             error={isSiteNameEmpty}
                                                             helperText={isSiteNameEmpty && "Please fill the site name"}
                                                             onBlur={onBlurSiteHandler}
                                                             onChange={handleSiteNameChange}/>}
          {actionType !== 'edit-client' && <GeneralTextField id="town"
                            required={true}
                            variant="filled"
                            placeholder="Town"
                            value={town}
                            error={isTownEmpty}
                            helperText={isTownEmpty && "Please fill the town name"}
                            onBlur={onBlurTownHandler}
                            onChange={handleTownChange}
                            />
          }
          {actionType !== 'edit-client' &&
          <GeneralTextField id="postcode"
                            variant="filled"
                            value={postCode}
                            placeholder="Post code - optional"
                            onChange={handlePostCodeChange}
                            />
          }
          {actionType !== 'edit-client' && <FormControl>
            <CustomSelect id="type"
                          value={siteType.name}
                          onChangeHandler={handleSelectTypeChange}
                          defaultValue="Please select type"
                          optionsArray={typeOptionsArr.map((type) => type.name)}/>
          </FormControl>}
          {actionType !== 'edit-client' && <What3Words handleWhat3Words={handleWhat3WordsCallback} userLatLng={userLatLng}/>}
        </form>
        <div className="actions-wrapper">
          <OutlinedButton className="back-button" onClick={() => {history.goBack()}}>Back</OutlinedButton>
          {
            (actionType !== 'edit-client' && actionType !== 'edit-site') &&
            <PrimaryButton className="continue-button"
                           onClick={confirmClientNameHandler} disabled={!siteType.code || !siteName || !clientName || !town}>
              Continue
            </PrimaryButton>
          }
          {(actionType === 'edit-client' || actionType === 'edit-site') &&
          <PrimaryButton className="continue-button"
                         onClick={confirmEditAction}
                         disabled={(actionType === 'edit-client' && !clientName) ||
                         (actionType !== 'edit-client' && (!siteType.code || !siteName || !town))}>
            Confirm
          </PrimaryButton>
          }
        </div>
        {deleteClientOrSiteError && <NotificationError messageClass="error" message={deleteClientOrSiteError}/>}
      </StyledClientForm>
  );
};

export default ClientForm;
