import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";




const StyledCollectorMachineOptions = styled.div `
  .machine-summary-wrapper {
    font-size: ${paymeTheme.fontRemSizeSmall};
  }
  
  .actions-wrapper .back-button {
    margin-right: auto;
  }
  .machine-type {
    color: ${paymeTheme.floteColor};
    font-size: ${paymeTheme.fontRemSizeLg};
    padding: ${paymeTheme.paddingRemSm} 0;
  }
  
  .credit-value {
    font-weight: ${paymeTheme.fontBold};
  }
  
  .site-header {
    border-bottom: none;
    .MuiSvgIcon-root {
      display: none;
    }
  }
  .content-wrapper {
    padding: ${paymeTheme.paddingRemLg} 0;
  }
  .machine-type-card {
    border-bottom: none;
    margin-bottom: ${paymeTheme.paddingRemMd};
  }
  .helper-screen-description {
    font-weight: ${paymeTheme.fontSemiBold};
    padding: 0;
  }
  ${media.desktop} {
    .content-wrapper {
       padding: ${paymeTheme.listItemPadding} 0;
    }
  }

`;

export { StyledCollectorMachineOptions } ;
