import React, {FC} from "react";
import {StyledDisplayClientDetailsStep} from "./styles";
import Header from "../../../components/Header";
import {OutlinedButton, PrimaryButton} from "../../../components/Buttons";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  getAddClientDetailsError,
  getClientData,
  getClientSitesDetails,
  getIsAddClientDetailsLoading
} from "../selectors";
import RoomIcon from '@material-ui/icons/Room';
import LayersIcon from '@material-ui/icons/Layers';
import {StyledAddNewClientPage} from "../styles";
import {addClientAttempt} from "../actions";
import {NotificationError} from "../../../components/NotificationMessages";
import {parseJwt} from "../../../utils/parseDetailsFromToken";
import LoadingComponent from "../../../components/Loading";
import * as ROUTES from "../../../components/Containers/Main/constants";

const DisplayClientDetailsStep: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const userToken = localStorage.getItem('token');
  const userCompanyType = userToken && parseJwt(userToken).companyType;
  const userCompanyName = userToken && parseJwt(userToken).companyName;
  const clientDetails = useSelector(getClientSitesDetails);
  const hasMultipleSites = useSelector(getClientData).hasMultipleSites;
  const isLoading = useSelector(getIsAddClientDetailsLoading);
  const error = useSelector(getAddClientDetailsError);

  const sitesDetailsArray = clientDetails.sitesDetailsArray;

  const siteDetailsSection = sitesDetailsArray && sitesDetailsArray.map((item: any, index: number) =>
    <div key={index} className="item-details">
      <div className="site-name item-name">{item.name}</div>
      <div className="item-town">{item.town}</div>
      <div className="item-postcode">{item.postCode}</div>
      <div className="item-type">{item.siteType.name}</div>
      {item.name && <div className="item-group"><LayersIcon/>Groups</div>}
      {item.what3Words && <div className="item-location"><RoomIcon/>{item.what3Words}</div>}
    </div>);

  const confirmAddNewClientHandler = () => {
    dispatch(addClientAttempt(clientDetails.clientName, sitesDetailsArray));
  };

  const tryAgainHandler = () => {
    history.push(ROUTES.ADD_CLIENT_DETAILS);
  };

  return (
    <StyledAddNewClientPage className="height-controller-wrapper">
      <StyledDisplayClientDetailsStep>
        <Header title="Summary"/>

        {!error ? (<div className="summary-details-wrapper">
          {userCompanyType !== 'SOLO' && <div className="client-name">{clientDetails.clientName}</div>}
          {userCompanyType === 'SOLO' && <div className="client-name">{userCompanyName}</div>}
          <div className="items-wrapper">
            {siteDetailsSection}
            {hasMultipleSites &&
            <PrimaryButton className="add-more-sites" onClick={() => {history.goBack()}}>Add more Sites</PrimaryButton>}
          </div>
          <div className="actions-wrapper">
            <OutlinedButton className="back-button" onClick={() => {history.goBack()}}>Back</OutlinedButton>
            <PrimaryButton className="continue-button" onClick={confirmAddNewClientHandler}>Confirm</PrimaryButton>
          </div>
        </div>) : (
          <div>
            <NotificationError message={error} messageClass="error"/>
            <OutlinedButton className="back-button try-again" onClick={tryAgainHandler}>Try Again</OutlinedButton>
          </div>
        )}
      </StyledDisplayClientDetailsStep>
      {isLoading && <LoadingComponent/>}
    </StyledAddNewClientPage>
  );
};

export default DisplayClientDetailsStep;
