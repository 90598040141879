import React, {FC} from "react";
import { StyledUserProfileCard } from "./styles";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {displayUserDetails} from "../../pages/TeamMemberPages/actions";
import {convertDateFullYear} from "../../utils/getCurrentDate";
import * as ROUTES from "../Containers/Main/constants";

interface Props {
  item: any;
  hasDate: boolean;
}

const UserProfileCard: FC <Props>= ({item, hasDate}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onClickUserProfileCard = () => {
    history.push(ROUTES.EDIT_TEAM_MEMBER);
    dispatch(displayUserDetails({
      userId: item.userId,
      firstName: item.firstName,
      lastName: item.lastName,
      imageUrl: item.imageUrl,
      emailAddress: item.email,
      startDate: convertDateFullYear('-', new Date(item.startDate)),
      terminationDate: item.endDate ? convertDateFullYear('-', new Date(item.endDate)) : '',
      authorities: item.authorities,
      status: {
        id: item.status.id,
        name: item.status.name
      }
    }));
  }

  return (
    <StyledUserProfileCard theme={{status: item ? item.status.name : undefined}} onClick={onClickUserProfileCard}>
      <div className="user-details">
        <div className="user-picture">
          {item && item.imageUrl && item.imageUrl !== '' ? <img src={item.imageUrl} alt="user-picture"/> : <AccountCircleIcon/>}
        </div>
        <div className="user-details-content">
          {item && item.firstName && item.lastName &&
          <div className="user-name">{item.firstName}{' '}{item.lastName}</div>}
          {item && item.status.name && <div className="user-status">{item.status.name}</div>}
          {item && item.status.name && item.status.name !== "Unverified" && <div className="user-last-seen"></div>}
        </div>
      </div>
      {item && item.authorities && item.authorities.length === 1 ?
        <div className="user-role">{item.authorities[0].description}</div> :
        <div className="user-role">Multiple</div>
      }
      {hasDate && <div className="user-date">22.6.21</div>}
      {!hasDate &&
      <div className="user-menu">
        <ArrowForwardIosIcon />
      </div>
      }
    </StyledUserProfileCard>
  );
};

export default UserProfileCard;
