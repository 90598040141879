import React, { FC } from "react";
import { StyledSection } from "./styles";
import {HomeQRIcon, DisabledQRIcon} from "./QRIcon";
import {Tooltip} from "@material-ui/core";
import {WARNING_DEVICE_MESSAGE} from "../../pages/HomeLoggedUser/constants";

interface Props {
  isDesktop: boolean;
  onClickHandler: (data: string | null) => void;
}
const QRCodeSection: FC<Props> = ({ onClickHandler, isDesktop }) => {
  return (
    <StyledSection className="icon-wrapper">
      <div className="nfc-section-wrapper">
        {isDesktop ? (
          <Tooltip title={WARNING_DEVICE_MESSAGE}>
            <DisabledQRIcon onClick={onClickHandler} />
          </Tooltip>
        ) : (
          <HomeQRIcon onClick={onClickHandler} />
        )}
      </div>
    </StyledSection>
  );
};

export default QRCodeSection;
