import styled from 'styled-components';
import media from "../../../assets/styling/payme-media-queries";
import paymeTheme from "../../../assets/styling/payme-theme";

const StyledTeamActivityLogList = styled.div `
  .fixed-header-page {
    position: fixed;
    z-index: 2;
    right: 0; 
    left: 0;
    top: ${paymeTheme.topMd};
    background-color: #FAFAFA;
  }

  .user-cards-list {
    margin-top: ${paymeTheme.paddingRemLg};
  }

  .user-cards-list > div {
    display: flex;
    align-items: center;
  }


  ${media.desktop} {
    .user-cards-list {
      top: ${paymeTheme.topLg};
      margin-top: ${paymeTheme.paddingRemXXLg};
      max-width: ${paymeTheme.widthXLg};
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
    .fixed-header-page {
      top: ${paymeTheme.topLg};
      max-width: ${paymeTheme.widthXLg};
      margin: auto;
      
      .MuiInputBase-root {
          min-width: 30vw;
          width: 30vw;
      }
    }
  }

`;

export { StyledTeamActivityLogList } ;
