import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";


const StyledExternalNFCStep = styled.div `
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: "Exo 2"!important;
  
  .cards-wrapper {
    display: flex;
    justify-content: center;
    margin: ${paymeTheme.paddingXs} ${paymeTheme.paddingXs} ${paymeTheme.paddingLg} ${paymeTheme.paddingXs};
  }
  .safe-message-wrapper {
    position: relative;
    text-align: center;
    font-size: ${paymeTheme.fontRemSizeH5};
    padding: ${paymeTheme.paddingXs} ${paymeTheme.paddingLg};
    background-color: ${paymeTheme.colorWhite};
    border: 1px solid ${paymeTheme.colorBlack};
    border-radius: ${paymeTheme.padding};
    max-width: 90px;
    width: auto;
  }
  .vending-icons-wrapper {
    position: relative;
    display: flex;
    jusify-content: center;
    padding: ${paymeTheme.paddingXs} 0;
    border-radius: ${paymeTheme.padding};
    width: auto;
    svg {
      height: 40vh!important;
    }
  }
  .scanning-icons-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    jusify-content: center;
    padding: ${paymeTheme.paddingXs} ${paymeTheme.paddingLg};
    border-radius: ${paymeTheme.padding};
    width: auto;
    svg {
      height: ${paymeTheme.heightXLg};
    }
  }
  .nfc-title {
    display: none;
  }
  .nfc-description {
    margin: ${paymeTheme.paddingRemMd} auto;
  }
  .icon-wrapper {
    width: auto;
    &:first-child {
      margin-bottom: ${paymeTheme.paddingRemXs};
      background-color: ${paymeTheme.colorWhite};
    }
  }
  .dot-color{
    position: absolute;
    left: 5px;
    bottom: ${paymeTheme.paddingRemSm};
    width: ${paymeTheme.paddingRem};
    height: ${paymeTheme.paddingRem};
    border-radius: 50%;
    background-color: ${paymeTheme.colorBlack};
    &.green {
      background-color: ${paymeTheme.greenStatusColor};
    }
    &.red {
      background-color: ${paymeTheme.errorColor};
    }
    &.yellow {
      background-color: yellow;
    }
  }
    
  ${media.desktop} {
      .cards-wrapper {
        padding-top: 1.5rem;
      }
  }
  
`;

export { StyledExternalNFCStep } ;
