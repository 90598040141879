import React, {FC, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import {StyledSitesClientsWrapper} from "./styles";
import {displayClientsListAttempt, displayOffSiteDetailsSuccess, displaySitesListAttempt} from "./actions";
import ClientsList from "./ClientsList";
import {
  getClientsListData,
  getClientsListError,
  getClientsListLoading,
  getSitesListData, getSitesListError,
  getSitesListLoading
} from "./selectors";
import SitesList from "./SitesList";
import TownList from "./TownList";
import Header from "../../components/Header";
import LoadingComponent from "../../components/Loading";
import {NotificationError} from "../../components/NotificationMessages";
import { parseJwt } from '../../utils/parseDetailsFromToken';
import { useHistory } from 'react-router-dom';
import * as ROUTES from "../../components/Containers/Main/constants";
import {ITabPanelProps} from "../../@types";
import {addClientDetails} from "../AddNewClientPage/actions";
import { getFlowType } from '../../components/Containers/Main/selectors';
import { setFlowType } from '../../components/Containers/Main/actions';

function TabPanel(props: ITabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    maxWidth: '500px',
    width: '100%',
    margin: 'auto',
    ['@media (min-width: 992px)']: { 
      paddingTop: '5rem'
    },
    position: 'relative',
    paddingTop: '3.75rem'
  },
}));

interface Props {
  isTechnician?: boolean;
}

export const AllClientsSites: FC<Props> = ({ isTechnician }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [headerValue, setHeader] = useState();
  const flowType = useSelector(getFlowType);
  const isReplaceTagFlow = flowType.isReplaceTagFlow;
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setHeaderValue();
    dispatch(displayClientsListAttempt());
    dispatch(displaySitesListAttempt());
    dispatch(addClientDetails('', []));
    if (isReplaceTagFlow) {  
      dispatch(setFlowType({
        ...flowType,
        isReplaceTagFlow: false,
      }));
    }
  }, []);

  const userToken = localStorage.getItem('token');
  const userCompanyType = userToken && parseJwt(userToken).companyType;

  const handleAddNewEntity = () => {
    history.push(ROUTES.ADD_CLIENT_DETAILS);
  };

  const getClientsLoading = useSelector(getClientsListLoading);
  const getSitesLoading = useSelector(getSitesListLoading);
  const clientsError = useSelector(getClientsListError);
  const sitesError = useSelector(getSitesListError);
  const clientsData = useSelector(getClientsListData);
  const sitesData = useSelector(getSitesListData);


  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const offSiteItemDetails = sitesData && sitesData.items.length &&
    sitesData.items.filter((item: any) => item.offSite === true);

  useEffect(() => {
    offSiteItemDetails && offSiteItemDetails.length &&
    dispatch(displayOffSiteDetailsSuccess(offSiteItemDetails[0]));
  }, [sitesData]);

  const setHeaderValue = () => {
    if (!!isTechnician) {
      setHeader('Select site for new machine');
      return;
    }
    if (!isTechnician && value === 0) {
      setHeader('Clients list');
      return;
    }
    if (!isTechnician && value === 1) {
      setHeader('Sites list');
      return;
    }
    if (!isTechnician && value === 2) {
      setHeader('Town list');
    }
  };
  
  return (
    <StyledSitesClientsWrapper className="height-controller-wrapper" theme={{isTechnician}}>
      <Header title={headerValue}/>
      <div className={classes.root}>
        <AppBar position="sticky" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Clients" {...a11yProps(0)} />
            <Tab label="Sites" {...a11yProps(1)} />
            <Tab label="Town" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
          <TabPanel value={value} index={0} dir={theme.direction}>
            {getClientsLoading && <LoadingComponent/>}
            {clientsError && <NotificationError messageClass="error" message={clientsError}/>}
            {clientsData && <ClientsList clientsList={clientsData.items}
                                         isTechnician={isTechnician}
                                         addNewEntity={handleAddNewEntity}/>}
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            {getSitesLoading && <LoadingComponent/>}
            {sitesError && <NotificationError messageClass="error" message={sitesError}/>}
            {sitesData && <SitesList sitesList={sitesData.items}
                                     isTechnician={isTechnician}
                                     addNewEntity={handleAddNewEntity}/>}
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
          {getSitesLoading && <LoadingComponent/>}
          {sitesError && <NotificationError messageClass="error" message={sitesError}/>}
          {sitesData && <TownList sitesList={sitesData.items} 
                                  isTechnician={isTechnician} 
                                  addNewEntity={handleAddNewEntity}/>}
        </TabPanel>
      </div>
    </StyledSitesClientsWrapper>
  );
};
