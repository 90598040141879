import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";


const StyledScanQRCodeError = styled.div `
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: "Exo 2"!important;

  .scanning-icons-wrapper {
    position: relative;
    padding: ${paymeTheme.paddingRemXs} ${paymeTheme.paddingRemLg};
    background-color: ${paymeTheme.floteColor};
    border-radius: ${paymeTheme.paddingRem};
    margin: ${paymeTheme.paddingRemXs} ${paymeTheme.paddingRemXs} ${paymeTheme.paddingRemLg} ${paymeTheme.paddingRemXs};
    width: auto;
    svg {
      width: ${paymeTheme.widthXLg};
      height: ${paymeTheme.heightLg};
      max-width: 130px;
    }
  }
  .nfc-title {
    display: none;
  }
  .icon-wrapper {
    width: auto;
    &:first-child {
      margin-bottom: ${paymeTheme.paddingRemXs};
      background-color: ${paymeTheme.colorWhite};
    }
  }
  .dot-color{
    position: absolute;
    left: 5px;
    bottom: ${paymeTheme.paddingRemSm};
    width: ${paymeTheme.paddingRem};
    height: ${paymeTheme.paddingRem};
    border-radius: 50%;
    background-color: ${paymeTheme.colorBlack};
    &.green {
      background-color: ${paymeTheme.greenStatusColor};
    }
    &.red {
      background-color: ${paymeTheme.errorColor};
    }
    &.yellow {
      background-color: yellow;
    }
  }
    
  ${media.desktop} {
      
  }
  
`;

export { StyledScanQRCodeError } ;
