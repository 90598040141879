import styled from 'styled-components';
import media from "../../../assets/styling/payme-media-queries";
import paymeTheme from "../../../assets/styling/payme-theme";




const StyledSitesList = styled.div `
  padding-bottom: ${paymeTheme.paddingRemSm}!important;
  .actions-wrapper {
    position: fixed;
    bottom: 55px;
    left: 0;
    right: 0;
    padding-bottom: ${paymeTheme.paddingRem};
  }
  
  ${media.desktop} {
    
  }

`;

export { StyledSitesList } ;
