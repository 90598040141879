import {ADD_STOCK_DETAILS} from "./constants";
import {IStockSelectionDetails} from "./model";


export const stockSelectionDetails = (data: IStockSelectionDetails) => ({
  type: ADD_STOCK_DETAILS,
  payload: {...data}
});


