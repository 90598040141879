import { all } from "redux-saga/effects";
import accountsSaga from "./Accounts/sagas";
import authSagas from "./pages/LoginPage/sagas";
import addClientSagas from "./pages/AddNewClientPage/sagas";
import sitesAndClientsSagas from "./pages/AllClientsSitesPage/sagas";
import unitDetailsSagas from "./pages/MachineDetailsPage/sagas";
import teamMemberSagas from "./pages/TeamMemberPages/sagas"
import setKeyToLocalStorageSagas from "./store/sagas";
import resetUserPasswordSagas from "./pages/ResetPassword/sagas";
import changePlayPriceSagas from "./pages/MachineSummaryPage/sagas";
import navigationSagas from "./components/Containers/Main/sagas";
import companySagas from "./pages/CompanyProfile/sagas";
import historyCollectionsSaga from "./pages/CollectionReceipt/sagas";

export default function* indexSaga() {
  yield all([
    ...accountsSaga,
    ...authSagas,
    ...addClientSagas,
    ...sitesAndClientsSagas,
    ...unitDetailsSagas,
    ...teamMemberSagas,
    //...setKeyToLocalStorageSagas,
    ...resetUserPasswordSagas,
    ...changePlayPriceSagas,
    ...navigationSagas,
    ...companySagas,
    ...historyCollectionsSaga
  ]);
}
