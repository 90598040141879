import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";




const StyledMachineDetails = styled.div `
  display: flex;
  padding-top: ${paymeTheme.paddingRemSm};
  margin-bottom: ${paymeTheme.paddingRemSm};
  .machine-summary-wrapper {
    font-size: ${paymeTheme.fontRemSizeSmall};
  }
  .machine-type {
    color: ${paymeTheme.floteColor};
    font-size: ${paymeTheme.fontRemSizeLg};
    padding: 0 0 ${paymeTheme.paddingRemSm} 0;
  }
  
  .credit-value {
    font-weight: ${paymeTheme.fontBold};
  }
  
  ${media.tablet} {
    min-width: 40%;
    max-width: 80%;
    margin: auto;
  }

  ${media.desktopMedium} {
    min-width: 35%;
  }
`;

export { StyledMachineDetails } ;
