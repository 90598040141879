import {ISiteCollectionState, ITotalCashCollected} from "./model";
import {ADD_TOTAL_CASH_COLLECTED, GET_SITE_COLLECTION} from "./constants";


const siteCollectionInitialState: ISiteCollectionState = {
  data: [

  ],
  loading: false,
  error: null
};


export const siteCollectionDetailsReducer = (state = siteCollectionInitialState , action: any) => {
  switch (action.type) {
    case GET_SITE_COLLECTION:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

const totalCashCollectedInitialState: ITotalCashCollected = {
  data: {
      actualCashCollected: 0,
      totalContactlessMoneyGross: 0,
      totalEstimatedCashAmount: 0,
      totalGrossMachineIncome: 0,
    },
  loading: false,
  error: null
};

export const totalCashCollectedReducer = (state = totalCashCollectedInitialState , action: any) => {
  switch (action.type) {
    case ADD_TOTAL_CASH_COLLECTED:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    default:
      return state;
  }
};
