import styled from 'styled-components';
import paymeTheme from "../../assets/styling/payme-theme";
import media from "../../assets/styling/payme-media-queries";


const StyledCollectionCard = styled.div `
  padding: ${paymeTheme.paddingRemXs} ${paymeTheme.paddingRem};
  margin: 0;
  background-color: #bdd4db;
  opacity: 0.8;
  font-size: ${paymeTheme.fontRemSizeSmall};
  font-weight: ${paymeTheme.fontBold};
  
  .row-value {
    text-align: end;
  }

  .full-price-wrapper {
    display: flex;
  }

  .icon-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.pound-sign {
      align-items: start;
      justify-content: space-around;
    }

    svg {
      flex-basis: 25%;
    }
  }

  .collection-card-header {
    color: ${paymeTheme.colorPurple};
  }

  .collection-card-body {
    color: ${paymeTheme.colorBlack};
    display: flex;
    justify-content: space-between;

    svg {
      width: 0.25rem;
      height: unset;
      padding-right: 0.5rem;
      fill: ${paymeTheme.colorBlack};
    }
  }
  
  ${media.desktop} {
    min-width: 30%;
    max-width: 50%;
    width: fit-content;
    margin: 0 auto;
  }

`;

export { StyledCollectionCard } ;
