import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";


const StyledSiteCardComponent = styled.div `
  padding:  ${paymeTheme.paddingRem} 0;
  border-bottom: 1px solid ${paymeTheme.borderGrey};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  
  &.isSelected {
    color: ${paymeTheme.errorColor};
    .client-name,
    .site-name {
      color: ${paymeTheme.errorColor};
    }
  }
  
  .client-name {
    color: ${paymeTheme.colorBlack};
    font-weight: ${paymeTheme.fontBold};
  }
  
  .site-name {
    color: ${paymeTheme.colorPurple};
  }
    
  .item-name,
  .item-town,
  .client-name {
    width: 30%;
    flex-wrap: wrap;
  }
  
  .item-town {
    text-align: right;
  }
  
  ${media.desktopMedium} {
    margin: 0;
  }

  svg {
    align-self: center;
  }

  `;

export { StyledSiteCardComponent } ;
