import styled from 'styled-components';
import paymeTheme from "../../../assets/styling/payme-theme";
import media from "../../../assets/styling/payme-media-queries";



const GeneralStyles = styled.div `
  height: 100%;

  .home-header {
    z-index: ${props => props.theme.keyboard ? "1000" : "unset"};
  }

  .env {
    position: fixed;
    bottom: 5px;
    color: ${paymeTheme.colorYellow};
    z-index: 10000;
    text-transform: capitalize;
    font-size: ${paymeTheme.fontRemSizeNormal};

    &.env-right {
      right: ${paymeTheme.paddingRemMd};
    }

    &.env-left {
      left: ${paymeTheme.paddingRemMd};
    }
  }

  ${media.desktopMedium} {
    .env {
      &.env-right {
        bottom: 0;
        top: 5px;
      }
    }
  }

  .height-controller-wrapper {
    padding-top: 35px;
    padding-bottom: 130px;
    display: flex;
    flex-direction: column;
    // min-height: calc(100vh - 70px);
    overflow-y: auto;
    .height-controller-wrapper {
      margin-top: 0;
      padding-bottom: 0;
      padding-top: 0;
    }
  }
  
   .site-menu {
    .MuiSvgIcon-root {
      width: 30px;
      height: 30px;
    }
  }
  
  .machine-type-icon img {
    width: 2.5rem;
    height: auto;
  }
  
  .helper-screen-description {
    color: ${paymeTheme.errorColor};
    font-size: ${paymeTheme.fontRemSizeLg};
    text-align: center;
    padding: ${paymeTheme.paddingLg};
  }
  
  .MuiTextField-root,
  .MuiFormControl-root {
    margin-bottom: 3px;
  }
  
  .MuiFormLabel-root {
      font-weight: ${paymeTheme.fontRegular};
      font-family: "Exo 2"!important;
  }
  
  .MuiFilledInput-input {
    padding: ${paymeTheme.paddingXs};
  }

  .MuiInputBase-input::-webkit-input-placeholder {
    opacity: 0.8!important;
  }
  
  .MuiButtonBase-root.Mui-disabled {
    background-color: ${paymeTheme.lightGrey};
    color: ${paymeTheme.colorWhite};
  }
  
  form {
    display: flex;
    flex-direction: column;
    justifyContent: center;
    align-items: center;
  }
  
  .actions-wrapper {
    padding: ${paymeTheme.paddingRemSm} 0;
    position: ${props => props.theme.keyboard === true ? 'absolute' : 'fixed'};
    bottom: 55px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-grow: 1;
    z-index: 1000;
    
    .back-button {
      margin-right: ${paymeTheme.paddingRemSm};
    }
    .continue-button {
      margin-left: ${paymeTheme.paddingRemSm};
    }
  }
  
  .MuiFormHelperText-root.Mui-required {
    color: ${paymeTheme.errorColor};
  }
  
  .unidentified-unit-wrapper {
    color: ${paymeTheme.colorBlack};
    max-width: 85%;
    margin: auto;
    text-align: center;
    font-size: ${paymeTheme.fontRemSizeH3};
    padding-top: 30%;
  }
  .unidentified-unit-title {
    font-weight: ${paymeTheme.fontSemiBold};
    padding-bottom: ${paymeTheme.paddingRemLg};
    color: ${paymeTheme.errorColor};
  }
  
  ${media.desktop} {
    max-height: 100%;
  }

  @media (min-width: 600px) {
    .actions-wrapper {
      bottom: 61px;
      min-width: 30%;
      max-width: 50%;
      margin: auto;
      
      .back-button {
        margin: auto;
      }
      .continue-button {
        margin: auto;
      }
    }
  }

  ${media.desktopMedium} {
    .actions-wrapper {
      bottom: 20px;
    }
  }
  
  div[class^='makeStyles-footer'] {
    .MuiAppBar-root {
      position: ${props => props.theme.keyboard === true ? 'absolute' : 'fixed'};
      display: ${props => props.theme.isFeedbackPressed ? "none" : "flex"};
    }
  }
`;

export { GeneralStyles } ;
