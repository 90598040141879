import React, {FC, useState} from "react";
import {StyledSitePage} from "./styles";
import MachineType from "./MachineTypeSection";
import Header from "../../components/Header";
import SummaryHeader from "../../components/SummaryHeader";
import {useDispatch, useSelector} from "react-redux";
import {getSiteDetailsData, getSiteDetailsError, getSiteDetailsLoading} from "../AllClientsSitesPage/selectors";
import LoadingComponent from "../../components/Loading";
import {NotificationError} from "../../components/NotificationMessages";
import {IUnitDetails} from "../AllClientsSitesPage/model";
import {OutlinedButton} from "../../components/Buttons";
import {useHistory, useParams} from "react-router-dom";
import { useEffect } from "react";
import {asyncRequest} from "../../api";
import {displaySiteDetailsFailure, displaySiteDetailsSuccess} from "../AllClientsSitesPage/actions";
import {addMachineDetails} from "../MachineDetailsPage/actions";
import {displayMachineDetails} from "../MachineDetailsPage/selectors";

interface Props {
  isTechnician?: boolean;
}
const SitePage:FC<Props> = ({isTechnician}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const siteDetails = useSelector(getSiteDetailsData);
  const errorSiteDetails = useSelector(getSiteDetailsError);
  const loadingSiteDetails = useSelector(getSiteDetailsLoading);
  const machineDetails = useSelector(displayMachineDetails);
  const units = !!siteDetails.units && !!siteDetails.units.length &&
    siteDetails.units.map((item: IUnitDetails, index: number) =>
    <MachineType key={index} itemDetails={item} hasMenu={!isTechnician}/>
    );

  const [isLoading, setIsLoading] = useState(false);
  const [error, setIsError] = useState('');

  useEffect(() => {
    if (!!id && siteDetails.id === -1) {
      setIsLoading(true);
      asyncRequest({
        config: {
          method: "GET",
        },
        endpoint: `api/site/${id}`,
      })
        .then((response) => {
          dispatch(displaySiteDetailsSuccess(response));
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          dispatch(displaySiteDetailsFailure(err));
          setIsLoading(false);
          setIsError(err.response.data.title);
        });

      dispatch(
        addMachineDetails({
          ...machineDetails,
          siteId: id,
        })
      );
    }
  }, [siteDetails]);

  return (
    <StyledSitePage className="height-controller-wrapper">
      {loadingSiteDetails && <LoadingComponent/>}
      {errorSiteDetails && <NotificationError message={errorSiteDetails} messageClass="error"/>}
      <Header title={isTechnician ? "Adding new machine to" : "Site summary"}/>
      <SummaryHeader isMenuDisplayed={!isTechnician && !(siteDetails.siteType.code === "OFF_SITE")} isArrowDisplayed={false}/>
      {units}
      {!isTechnician && (<div className="actions-wrapper">
        <OutlinedButton className="back-button" onClick={() => {history.goBack()}}>Back</OutlinedButton>
      </div>)}
      {isLoading && <LoadingComponent/>}
      {error && <NotificationError messageClass="error" message={error}/>}
    </StyledSitePage>
  );
};

export default SitePage;
