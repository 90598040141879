export const getCurrentDate = (separator='/') => {

  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear().toString().substr(2,2);

  return `${date}${separator}${month<10?`0${month}`:`${month}`}${separator}${year}`
};


export const getCurrentDateFullYear = (separator='-') => {

  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear().toString();

  return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date<10?`0${date}` : `${date}`}`
};


export const convertDateFullYear = (separator='-', newDate: any) => {

  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear().toString();

  return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date<10?`0${date}` : `${date}`}`
};

export const convertDateFullMonthLetters = (separator=' ', newDate: any, monthType: string) => {
  const formatDate = new Date(newDate);
  const monthFormatter = new Intl.DateTimeFormat('default', { month: monthType });
  const month = monthFormatter.format(formatDate);
  let date = formatDate.getDate();
  let year = formatDate.getFullYear().toString();

  return `${date}${separator}${month}${separator}${year}`
};
