import React, {FC, useEffect, useState} from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import {StyledCollectionHistory} from "./styles";
import Header from "../../components/Header";
import AllCollectionsList from "./AllCollections";
import {asyncRequest} from "../../api";
import {useHistory} from "react-router-dom";
import {getSiteDetailsData} from "../AllClientsSitesPage/selectors";
import {useDispatch, useSelector} from "react-redux";
import LoadingComponent from "../../components/Loading";
import {ITabPanelProps} from "../../@types";
import {historyCollectionListAttempt} from "../CollectionReceipt/actions";
import {
  getHistoryCollectionListData,
  getHistoryCollectionListError,
  getHistoryCollectionListLoading
} from "../CollectionReceipt/selectors";
import {NotificationError} from "../../components/NotificationMessages";

function TabPanel(props: ITabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    maxWidth: '500px',
    width: '100%',
    margin: 'auto',
    ['@media (min-width: 992px)']: { 
      paddingTop: '1.5rem'
    }
  },
}));

interface Props {
  isTechnician?: boolean;
}

export const CollectionHistory: FC<Props> = ({ isTechnician }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const history = useHistory();
  const collectionHistoryData = useSelector(getHistoryCollectionListData);
  const [collectionHistoryList, setCollectionHistoryList] = useState(collectionHistoryData);
  const collectionHistoryListLoading = useSelector(getHistoryCollectionListLoading);
  const collectionHistoryListError = useSelector(getHistoryCollectionListError);
  const [collectionHistorySiteError, setCollectionHistorySiteError] = useState();
  const prevStateLocation = history.location.state && history.location.state.initialUrl;
  const [loading, setIsLoading] = useState(false);
  const siteDetails = useSelector(getSiteDetailsData);
  const siteId = siteDetails && siteDetails.id;

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    setCollectionHistoryList(collectionHistoryData);
  }, [collectionHistoryData]);

  useEffect(() => {
    setIsLoading(true);
    if (prevStateLocation && siteId) {
      asyncRequest({
        config:{
          method: "GET",
        },
        endpoint: `api/collection/history/site/${siteId}`,
      }).then(response => {
        setCollectionHistoryList(response);
        setIsLoading(false);
      })
        .catch(err => {
          console.log(err);
          setCollectionHistorySiteError(err.response.data.title);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      dispatch(historyCollectionListAttempt());
    }
  }, []);

  return (
    (loading || collectionHistoryListLoading) ? <LoadingComponent/> :
      <StyledCollectionHistory className="height-controller-wrapper">
        <Header title={value === 0 ? 'My collections history' : 'All collections history'}/>
        <div className={classes.root}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="My collections" {...a11yProps(0)} />
              <Tab label="All collections" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0} dir={theme.direction}>
            <AllCollectionsList collectionHistoryList={collectionHistoryList}/>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <AllCollectionsList collectionHistoryList={collectionHistoryList}/>
          </TabPanel>
          {collectionHistoryListError && <NotificationError messageClass="error" message={collectionHistoryListError}/>}
          {collectionHistorySiteError && <NotificationError messageClass="error" message={collectionHistorySiteError}/>}
        </div>
      </StyledCollectionHistory>
    );
};
