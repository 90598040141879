import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";




const StyledSitePage = styled.div `
  .actions-wrapper .back-button {
    margin-right: auto;
  }

  .site-name {
    color: ${paymeTheme.colorPurple};
  }
 
  ${media.desktopMedium} {
    .actions-wrapper {
      padding-left: ${paymeTheme.sideBarWidth};
    }
  }

`;

export { StyledSitePage } ;
