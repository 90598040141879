import React, {FC} from "react";
import { PrimaryButton} from "../../../components/Buttons";
import {Tooltip} from "@material-ui/core";
import {WARNING_DEVICE_MESSAGE} from "../constants";
import {StyledHomepage} from "../styles";
import {StyledCollectorDetails} from "./styles";

interface Props {
  isDesktop: boolean;
}

const OperativeHome: FC<Props> = ({ isDesktop }) => {


  return (
    <StyledHomepage>
      <StyledCollectorDetails>
        <div className="collector-name collector-box">TradePay</div>
        <div className="collector-description collector-box">ABC Plumbing Services</div>
      </StyledCollectorDetails>

      <div>
        <div className="button-section-wrapper">
          <Tooltip title={isDesktop ? WARNING_DEVICE_MESSAGE : ''} aria-label='disabled button'>
            <div className="button-wrapper"><PrimaryButton disabled={isDesktop}>Setup NFC Tag</PrimaryButton></div>
          </Tooltip>
        </div>

        <div className="button-section-wrapper">
          <PrimaryButton>History</PrimaryButton>
        </div>

        <div className="button-section-wrapper">
          <PrimaryButton>Clients</PrimaryButton>
        </div>

        <div className="button-section-wrapper">
          <Tooltip title={isDesktop ? WARNING_DEVICE_MESSAGE : ''} aria-label='disabled button'>
            <div className="button-wrapper"><PrimaryButton disabled={isDesktop}>Take payments</PrimaryButton></div>
          </Tooltip>
        </div>
      </div>

    </StyledHomepage>
  );
};

export default OperativeHome;
