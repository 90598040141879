import React, {FC, useEffect, useState} from 'react';
import {StyledActivateMemberAccount} from "./styles";
import Header from "../../components/Header";
import {asyncRequest} from "../../api";
import {PrimaryButton} from "../../components/Buttons";
import {useHistory} from "react-router-dom";
import LoadingComponent from "../../components/Loading";
import {isIOS, isAndroid, isSafari} from "react-device-detect";
import {APPLE_STORE_URL, GOOGLE_PLAY_URL} from "../../constants";
import {GeneralTextField} from "../../components/Inputs";
import * as ROUTES from "../../components/Containers/Main/constants";
import * as HELPER_TEXTS from "./constants";
import {IS_END_USER} from "../../components/Containers/Main/constants";

export const ActivateMemberAccount: FC = () => {
  const history = useHistory();
  const userToken = localStorage.getItem('token');
  const params = new URLSearchParams(window.location.search.substring(1));
  const activationKeyValue = params.get('activationKey');

  const [successActivationMessage, setSuccessActivationMessage] = useState();
  const [errorActivationMessage, setErrorActivationMessage] = useState();
  const [isLoadingDisplayed, setIsLoadingDisplayed] = useState();
  const [userPassword, setUserPassword] = useState();
  const [isUserPasswordEmpty, setIsUserPasswordEmpty] = useState(false);
  const [isUserPasswordValidated, setIsUserPasswordValidated] = useState(true);
  const [helperTextPassword, setHelperTextPassword] = useState();
  const [displayHelperTextPassword, setDisplayHelperTextPassword] = useState();
  const [userConfirmPassword, setUserConfirmPassword] = useState();
  const [helperTextConfirmPassword, setHelperTextConfirmPassword] = useState();
  const [displayHelperTextConfirmPassword, setDisplayHelperTextConfirmPassword] = useState();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    if (userToken) {
      localStorage.removeItem('token');
    }
  }, []);

 const onSetPasswordHandler = () => {
   if (!userPassword) {
     setIsUserPasswordEmpty(true);
     return;
   }
   if (!userConfirmPassword) {
     setHelperTextConfirmPassword(HELPER_TEXTS.CONFIRM_PASSWORD);
     setDisplayHelperTextConfirmPassword(true);
     return;
   }
   if (!!userConfirmPassword && userConfirmPassword !== userPassword) {
     setHelperTextConfirmPassword(HELPER_TEXTS.PASSWORDS_NOT_MATCH);
     setDisplayHelperTextConfirmPassword(true);
     return;
   }
   if (!!userPassword && !!userConfirmPassword && userPassword === userConfirmPassword) {
     setDisplayHelperTextConfirmPassword(false);
     asyncRequest({
       config: {
         method: "POST",
         data: {
           key: activationKeyValue,
           password: userPassword,
           isEndUser: IS_END_USER,
         }
       },
       endpoint: 'api/account/activate',
     })
       .then((response) => {
         setSuccessActivationMessage(HELPER_TEXTS.SUCCESSFUL_ACTIVATION);
         setIsLoadingDisplayed(false);
       })
       .catch((err) => {
         console.log(err);
         setErrorActivationMessage(err.response.data.title);
         setIsLoadingDisplayed(false);
       });
   }
 };

  useEffect(() => {
    displayHelperTextPasswordHandler();
  }, [isUserPasswordValidated, isUserPasswordEmpty]);

  const onChangeUserPasswordHandler: React.ChangeEventHandler<HTMLInputElement> =
    (event) => {
      setUserPassword(event.target.value);
      if (event.target.value !== '') {
        setIsUserPasswordEmpty(false);
        if (event.target.value.length >= 8) {
          setIsUserPasswordValidated(true);
        } else {
          setIsUserPasswordValidated(false);
        }
      } else {
        setIsUserPasswordEmpty(true);
      }
    };

  const onChangeConfirmUserPasswordHandler: React.ChangeEventHandler<HTMLInputElement> =
    (event) => {
      setUserConfirmPassword(event.target.value);
      if (event.target.value !== '') {
        if (event.target.value === userPassword) {
          setHelperTextConfirmPassword('');
          setDisplayHelperTextConfirmPassword(false);
        } else {
          setHelperTextConfirmPassword(HELPER_TEXTS.PASSWORDS_NOT_MATCH);
          setDisplayHelperTextConfirmPassword(true);
        }
      } else {
        setDisplayHelperTextConfirmPassword(true);
        setHelperTextConfirmPassword(HELPER_TEXTS.FILL_PASSWORD);
      }
    };


  const displayHelperTextPasswordHandler = () => {
    if (isUserPasswordEmpty) {
      setHelperTextPassword(HELPER_TEXTS.FILL_PASSWORD);
      setDisplayHelperTextPassword(true);
    }
    if (!isUserPasswordEmpty && !isUserPasswordValidated) {
      setHelperTextPassword(HELPER_TEXTS.FILL_LONGER_PASSWORD);
      setDisplayHelperTextPassword(true);
    }
    if (!isUserPasswordEmpty && isUserPasswordValidated) {
      setHelperTextPassword('');
      setDisplayHelperTextPassword(false);
    }
  };

  const returnSpecificUrl = () => {
    if (isIOS || isSafari) {
      return APPLE_STORE_URL;
    } else {
      return GOOGLE_PLAY_URL;
    }
  };

  useEffect(() => {
    if (!!userPassword && !!userConfirmPassword) {
      if (!isUserPasswordEmpty && !!isUserPasswordValidated && !displayHelperTextConfirmPassword) {
        setIsButtonDisabled(false);
      }

      if (!!isUserPasswordEmpty || !isUserPasswordValidated || displayHelperTextConfirmPassword) {
        setIsButtonDisabled(true);
      }
    }
  }, [isUserPasswordEmpty, isUserPasswordValidated, userPassword, displayHelperTextConfirmPassword, userConfirmPassword]);


  return (
    <StyledActivateMemberAccount className="height-controller-wrapper">
      <Header title="Account activation"/>
      {successActivationMessage &&
      <div className="activation-wrapper">
        <div className="success-message">{successActivationMessage}</div>
        <div className="actions-wrapper">
          {(isIOS || isAndroid) ?
          <PrimaryButton><a href={returnSpecificUrl()}>Get the app</a></PrimaryButton> :
          <PrimaryButton onClick={() => history.push(ROUTES.HOME)}>Sign in via browser</PrimaryButton>
          }
        </div>
      </div>
      }
      {errorActivationMessage && <div className="error-message">{errorActivationMessage}</div>}
      {
        !successActivationMessage && !errorActivationMessage &&
        <form className="create-password-form">
          <GeneralTextField id="user-password"
                            type="password"
                            value={userPassword}
                            onChange={onChangeUserPasswordHandler}
                            variant="filled"
                            required={true}
                            helperText={displayHelperTextPassword && helperTextPassword}
                            placeholder="Fill password"/>
          <GeneralTextField id="user-confirmation-password"
                            type="password"
                            value={userConfirmPassword}
                            onChange={onChangeConfirmUserPasswordHandler}
                            variant="filled"
                            required={true}
                            helperText={displayHelperTextConfirmPassword && helperTextConfirmPassword}
                            placeholder="Confirm password"/>
          <div className="actions-wrapper">
            <PrimaryButton onClick={onSetPasswordHandler} disabled={isButtonDisabled}>Submit</PrimaryButton>
          </div>
        </form>
      }
      {isLoadingDisplayed && <LoadingComponent/>}
    </StyledActivateMemberAccount>
  );
};
