import styled from 'styled-components';
import paymeTheme from "../../../assets/styling/payme-theme";
import media from "../../../assets/styling/payme-media-queries";


const StyledChangeSiteDetailsSection = styled.div `

  display: flex;
  flex-direction: column;
  margin: 0 ${paymeTheme.paddingRem};
  
  .groups-wrapper {
    display: flex;
    justify-content: baseline;
  }
  
  .client-name,
  .site-name,
  .site-town {
    font-size: ${paymeTheme.fontRemSizeLg};
  }

  .site-header {
    font-weight: 600;
    display: flex;
    justify-content: space-between;
  }

  .what-3-words {
    display: flex;
  }

  .site-details {
    svg {
      fill: ${props => props.theme.isPreviousSite ? paymeTheme.customColor : paymeTheme.floteColor};
    }
  }

  .client-name {
    color: ${props => props.theme.isPreviousSite ? paymeTheme.customColor : paymeTheme.colorBlack};
  }
  
  .site-town {
    color: ${paymeTheme.customColor};
  }

  .client-name,
  .site-name {
    padding-right: ${paymeTheme.paddingRemSm};
  }
  

`;

export { StyledChangeSiteDetailsSection } ;
