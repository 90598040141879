import React, { FC } from "react";
import {
  StyledErrorMessage,
  StyledFilledBackgroundNotification,
  StyledImportantNotification,
  StyledLoginError
} from "./styles";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import RemoveIcon from '@material-ui/icons/Remove';

interface Props {
  message: string;
  messageClass?: string
}

export const NotificationError: FC<Props> = ({ messageClass, message}) => {
  return (
    <StyledErrorMessage>
      <div className={messageClass}>
        <ErrorOutlineIcon/>
        <div className="message-content">{message}</div>
      </div>
    </StyledErrorMessage>
  );
};

export const ImportantNotification: FC<Props> = ({message}) => {
  return (
    <StyledImportantNotification>
      <Accordion>
        <AccordionSummary
          expandIcon={<RemoveIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        ></AccordionSummary>
        <AccordionDetails>
          <div>{message}</div>
        </AccordionDetails>
      </Accordion>
    </StyledImportantNotification>
  )
};


interface BackgroundNotificationProps {
  message: string;
  backgroundColorClass: string;
}

export const FilledBackgroundNotification: FC<BackgroundNotificationProps> = ({message, backgroundColorClass}) => {
  return (
    <StyledFilledBackgroundNotification>
      <div className={backgroundColorClass}>
        <div className="message-content">{message}</div>
      </div>
    </StyledFilledBackgroundNotification>
  )
};

interface LoginErrorProps {
  message: string;
}

export const LoginErrorNotification: FC<LoginErrorProps> = ({message}) => {
  return (
    <StyledLoginError>
      {message}
    </StyledLoginError>
  )
};
