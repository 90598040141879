import {
  ADD_CASH_COLLECTED_VALUE,
  HISTORY_COLLECTION_LIST_FAILURE,
  HISTORY_COLLECTION_LIST_REQUEST,
  HISTORY_COLLECTION_LIST_SUCCESS
} from "./constants";
import {ICashCollectedState, IHistoryCollectionList} from "./model";


const cashCollectedInitialState: ICashCollectedState = {
  data: {cashCollectedValues: []},
  loading: false,
  error: null
};

export const cashCollectedValuesReducer = (state = cashCollectedInitialState, action: any) => {
  switch (action.type) {
    case ADD_CASH_COLLECTED_VALUE:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

const historyCollectionListInitialState: IHistoryCollectionList = {
  data: [],
  loading: false,
  error: null
};

export const getHistoryCollectionListReducer = (state = historyCollectionListInitialState, action: any) => {
  switch (action.type) {
    case HISTORY_COLLECTION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case HISTORY_COLLECTION_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case HISTORY_COLLECTION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};
