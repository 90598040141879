import {IFlowType} from "./model";
import {SET_FLOW_TYPE} from "./constants";



const FlowTypeInitialState: IFlowType = {
  data: {
    isSetupFlow: false,
    isReplaceTagFlow: false,
    isChangeSiteFlow: false,
    isOnlyTestFlow: false,
    clientIdFromAddSiteToClientFlow: -1,
    replaceExternalTagId: "",
    isChangePositionFlow: false,
    isChangePricePlayFlow: false,
    clientPageId: "",
    isUnitRegistered: false
  },
  loading: false,
  error: null
};


export const flowTypeReducer = (state = FlowTypeInitialState , action: any) => {
  switch (action.type) {
    case SET_FLOW_TYPE:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

