import React, {FC, useEffect, useState} from "react";
import {StyledWhat3Words} from "./styles";
import * as GeoSearch from 'leaflet-geosearch';
import L from 'leaflet';
import SearchIcon from '@material-ui/icons/Search';
import { IconButton } from "@material-ui/core";

interface Props {
  handleWhat3Words: (data: string) => void;
  userLatLng: any;
}

const What3Words: FC<Props> = ({ handleWhat3Words, userLatLng }) => {
  const oxfordCoords = {lat: 51.754609108002676, lng: -1.254925570059918};
  const [userLatitude, setUserLatitude] = useState(oxfordCoords.lat);
  const [userLongitude, setUserLongitude] = useState(oxfordCoords.lng);
  const mapId = document.getElementById('map');
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);
  const [convertedUserPosition, setConvertedUserPosition] = useState('');
  const [clicked, setClicked] = useState(false);

  var redIcon = new L.Icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  const search = new GeoSearch.GeoSearchControl({
    provider: new GeoSearch.OpenStreetMapProvider(),
    showPopup: true, // optional: true|false  - default false
    showMarker: true,
    marker: {
      icon: new L.Icon.Default(),
      draggable: false,
    },
    maxMarkers: 1, // optional: number      - default 1
    retainZoomLevel: false, // optional: true|false  - default false
    animateZoom: true, // optional: true|false  - default true
    autoClose: false, // optional: true|false  - default false
    searchLabel: 'Enter address to change What3words location', // optional: string      - default 'Enter address'
    keepResult: false, // optional: true|false  - default false
    updateMap: true, // optional: true|false  - default true
  });

  let w3wIcon = L.icon({
    iconUrl: 'https://what3words.com/map/images/marker-18.png',
    iconSize: [64, 64], // size of the icon
    iconAnchor: [25, 59] // point of the icon which will correspond to marker's location
  });

  useEffect(() => {
    if (userLatLng) {
      setUserLatitude(userLatLng.lat);
      setUserLongitude(userLatLng.lng);
    }

    navigator.geolocation.getCurrentPosition(
      function(position) {
        if (userLatLng) {
          if (position.coords.latitude !== userLatLng.lat || position.coords.longitude !== userLatLng.lng) {
            setUserLatitude(position.coords.latitude);
            setUserLongitude(position.coords.longitude);
          }
        } else {
          setUserLatitude(position.coords.latitude);
          setUserLongitude(position.coords.longitude);
        }
      },
      function(error) {
        console.error("Error Code = " + error.code + " - " + error.message);
        setUserLatitude(oxfordCoords.lat);
        setUserLongitude(oxfordCoords.lng);
        // localStorage.removeItem('userLatLng');
      });

  },[]);

  const createPopupContent = (content: string) => {
    let splitContent = content.split(',');
    let newContent = `${splitContent[0]}`;

    if (splitContent[1]) {
      newContent += `,${splitContent[1]}`;
    }
    
    if (splitContent[2]) {
      newContent += `,${splitContent[2]}`;
    }

    return newContent;
  }

  const closeLocationOptions = () => {
    const results = document.getElementsByClassName("results")[0];
    results.classList.remove("active");
    results.innerHTML = '';
  }

  const onClickSearchIcon = async (event) => {
    event.preventDefault();
    const input = document.getElementsByClassName("glass")[0];
    input.focus();
  }

  const createLayers = () => {
    var osmLink = '<a href=\"https://openstreetmap.org\">OpenStreetMap</a>';
    var osmUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        osmAttrib = '&copy; ' + osmLink + ' Contributors';

    var osmMap = L.tileLayer(osmUrl, {attribution: osmAttrib});
    var satellite = L.tileLayer('https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',{
      maxZoom: 20,
      subdomains:['mt0','mt1','mt2','mt3']
    });
    var baseMaps = {
      "Default": osmMap,
      "Satellite": satellite
    };

    return baseMaps;
  }

  useEffect(() => {
    if (mapId) {
      let m = L.map('map', {zoomControl: false});
      
      const baseMaps = createLayers();
      let layerControl = L.control.layers(baseMaps, {}, {collapsed: true, autoZIndex: true}).addTo(m);

      const layerControlRadios = document.getElementsByClassName("leaflet-control-layers-selector");

      if (!layerControlRadios[0].checked && !layerControlRadios[1].checked) {
        layerControlRadios[0].checked = true;
      }
      
      layerControlRadios.forEach((radio) => {
        radio.addEventListener("change", () => {
          layerControl.collapse();
        })
      })

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        maxNativeZoom: 19,
        maxZoom: 25
      }).addTo(m);

      new L.Control.Zoom({position: 'bottomright'}).addTo(m);
      m.addControl(search);

      const input = document.getElementsByClassName("glass")[0];
      input.addEventListener('keydown', (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.keyCode === 13) {
          closeLocationOptions();
        }
      });

      m.on('geosearch/showlocation', function (data: any) {
        setUserLatitude(data.location.y);
        setUserLongitude(data.location.x);
      });

      m.on('click', function (e: any) {
        if (!clicked) {
          setClicked(true);
        }
        setUserLatitude(e.latlng.lat);
        setUserLongitude(e.latlng.lng);
      });

      setMap(m);
    }
  }, [mapId]);

  useEffect(() => {
    if (map !== null) {
      map.setView([userLatitude, userLongitude], 12);

      if (marker) {
        map.removeLayer(marker);
      }

      setMarker(L.marker([userLatitude, userLongitude], {icon: redIcon}).addTo(map));
      const leaflet = document.getElementsByClassName("leaflet-popup-content");
      
      if (leaflet.item(leaflet.length - 1)) {
        leaflet.item(leaflet.length - 1).innerHTML = createPopupContent(leaflet.item(leaflet.length - 1).innerHTML);
      }
    }

  }, [map, userLatitude, userLongitude]);

  useEffect(() => {
    what3words.api.convertTo3wa({lat: userLatitude, lng: userLongitude})
      .then(function(response: any) {
        setConvertedUserPosition(response.words);
        handleWhat3Words(response.words);
      })
      .catch(function(err: any) {
        console.log("what3words convert to 3 words error, ", err);
      });

  }, [userLatitude, userLongitude]);

  return (
    <StyledWhat3Words theme={{clicked}}>
      <div className="what3words-content">What3Words location: {convertedUserPosition}</div>
      <div className="what3words-wrapper" id="wrapper">
        <div id="map"></div>
        <IconButton onClick={onClickSearchIcon}>
          <SearchIcon color={"primary"}/>
        </IconButton>
      </div>
    </StyledWhat3Words>
  );
};

export default What3Words;
