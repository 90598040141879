import React, {useEffect, useState} from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import MenuItem from '@material-ui/core/MenuItem';
import {AppBarStyles, StyledMenuWrapper, DrawerStyles, useStyles} from "./styles";
import {parseJwt} from "../../utils/parseDetailsFromToken";
import {useHistory, useLocation} from "react-router-dom";
import {ROLE_ADMIN, ROLE_COLLECTOR, ROLE_MASTER, ROLE_OPERATIVE, ROLE_TECHNICIAN} from "../../constants";
import * as ROUTES from "../Containers/Main/constants";
import {useDispatch, useSelector} from "react-redux";
import {getFlowType} from "../Containers/Main/selectors";
import AppMenuItem from "./AppMenuItem";
import {useWindowSize} from "../../utils/detectWindowSize";
import {loginSuccess} from "../../pages/LoginPage/actions";
import {socketReceivedMessage, socketSentMessage} from "../SocketCommunication/actions";

export const masterBurgerMenu = [
  {
    name: "Profile",
    path: "",
    onClickHandlerType: 'onClickHandlerType',
    isOnlyMobile: false,
    hasSubmenu: true,
    subMenuList: [
      {
        name: "Company profile",
        path: ROUTES.COMPANY_PROFILE,
        onClickHandlerType: 'onClickHandlerType',
        isOnlyMobile: false,
        hasSubmenu: false,
      },
      {
        name: "My profile",
        path: ROUTES.MEMBER_PROFILE,
        onClickHandlerType: 'onClickHandlerType',
        isOnlyMobile: false,
        hasSubmenu: false,
      }
    ],

  },
  {
    name: "Bank account",
    path: "",
    onClickHandlerType: 'onClickHandlerType',
    isOnlyMobile: false,
    hasSubmenu: false,
    subMenuList: [],
  },
  {
    name: "Team",
    path: ROUTES.TEAM,
    onClickHandlerType: 'onClickHandlerType',
    isOnlyMobile: false,
    hasSubmenu: false,
    subMenuList: [],
  },
  {
    name: "Client list",
    path: ROUTES.ALL_CLIENTS_SITES,
    onClickHandlerType: 'onClickHandlerType',
    isOnlyMobile: false,
    hasSubmenu: false,
    subMenuList: [],
  },
  {
    name: "Groups",
    path: ROUTES.GROUPS,
    onClickHandlerType: 'onClickHandlerType',
    hasSubmenu: false,
    subMenuList: [],
  },
  {
    name: "Revenue",
    path: "",
    onClickHandlerType: 'onClickHandlerType',
    isOnlyMobile: false,
    hasSubmenu: true,
    subMenuList: [
      {
        name: "Overview",
        path: "",
        onClickHandlerType: 'onClickHandlerType',
        isOnlyMobile: false,
        hasSubmenu: false,
      },
      {
        name: "Client",
        path: "",
        onClickHandlerType: 'onClickHandlerType',
        isOnlyMobile: false,
        hasSubmenu: false,
      },
      {
        name: "Group",
        path: "",
        onClickHandlerType: 'onClickHandlerType',
        isOnlyMobile: false,
        hasSubmenu: false,
      },
      {
        name: "Collection history",
        path: ROUTES.COLLECTION_HISTORY,
        onClickHandlerType: 'onClickHandlerType',
        isOnlyMobile: false,
        hasSubmenu: false,
      },
      {
        name: "Transaction log",
        path: "",
        onClickHandlerType: 'onClickHandlerType',
        isOnlyMobile: false,
        hasSubmenu: false,
      },
      {
        name: "Fee log page",
        path: "",
        onClickHandlerType: 'onClickHandlerType',
        isOnlyMobile: false,
        hasSubmenu: false,
      }
    ],
  },
  {
    name: "Machines",
    path: "",
    onClickHandlerType: 'onClickHandlerType',
    isOnlyMobile: false,
    hasSubmenu: false,
    subMenuList: [],
  },
  {
    name: "Notifications",
    path: "",
    onClickHandlerType: 'onClickHandlerType',
    isOnlyMobile: false,
    hasSubmenu: false,
    subMenuList: [],
  },
  {
    name: "Help",
    path: "",
    onClickHandlerType: 'onClickHandlerType',
    isOnlyMobile: false,
    hasSubmenu: false,
    subMenuList: [],
  },
  {
    name: "Extra services",
    path: "",
    onClickHandlerType: 'onClickHandlerType',
    isOnlyMobile: false,
    hasSubmenu: false,
    subMenuList: [],
  },
  {
    name: "Shop",
    path: "",
    onClickHandlerType: 'onClickHandlerType',
    isOnlyMobile: false,
    hasSubmenu: false,
    subMenuList: [],
  },
  {
    name: "Setup",
    path: ROUTES.UNIT_DETAILS_SETUP,
    onClickHandlerType: 'onClickHandlerTypeSetup',
    isOnlyMobile: true,
    hasSubmenu: false,
    subMenuList: [],
  }
];

export const adminBurgerMenu = [
  {
    name: "My Profile",
    path: "",
    onClickHandlerType: 'onClickHandlerType',
    isOnlyMobile: false,
    hasSubmenu: false,
    subMenuList: [],

  },
  {
    name: "Bank account",
    path: "",
    onClickHandlerType: 'onClickHandlerType',
    isOnlyMobile: false,
    hasSubmenu: false,
    subMenuList: [],
  },
  {
    name: "Team",
    path: ROUTES.TEAM,
    onClickHandlerType: 'onClickHandlerType',
    isOnlyMobile: false,
    hasSubmenu: false,
    subMenuList: [],
  },
  {
    name: "Client list",
    path: ROUTES.ALL_CLIENTS_SITES,
    onClickHandlerType: 'onClickHandlerType',
    isOnlyMobile: false,
    hasSubmenu: false,
    subMenuList: [],
  },
  {
    name: "Groups",
    path: ROUTES.GROUPS,
    onClickHandlerType: 'onClickHandlerType',
    hasSubmenu: false,
    subMenuList: [],
  },
  {
    name: "Revenue",
    path: "",
    onClickHandlerType: 'onClickHandlerType',
    isOnlyMobile: false,
    hasSubmenu: true,
    subMenuList: [
      {
        name: "Overview",
        path: "",
        onClickHandlerType: 'onClickHandlerType',
        isOnlyMobile: false,
        hasSubmenu: false,
      },
      {
        name: "Client",
        path: "",
        onClickHandlerType: 'onClickHandlerType',
        isOnlyMobile: false,
        hasSubmenu: false,
      },
      {
        name: "Group",
        path: "",
        onClickHandlerType: 'onClickHandlerType',
        isOnlyMobile: false,
        hasSubmenu: false,
      },
      {
        name: "Collection history",
        path: ROUTES.COLLECTION_HISTORY,
        onClickHandlerType: 'onClickHandlerType',
        isOnlyMobile: false,
        hasSubmenu: false,
      },
      {
        name: "Transaction log",
        path: "",
        onClickHandlerType: 'onClickHandlerType',
        isOnlyMobile: false,
        hasSubmenu: false,
      },
      {
        name: "Fee log page",
        path: "",
        onClickHandlerType: 'onClickHandlerType',
        isOnlyMobile: false,
        hasSubmenu: false,
      }
    ],
  },
  {
    name: "Machines",
    path: "",
    onClickHandlerType: 'onClickHandlerType',
    isOnlyMobile: false,
    hasSubmenu: false,
    subMenuList: [],
  },
  {
    name: "Notifications",
    path: "",
    onClickHandlerType: 'onClickHandlerType',
    isOnlyMobile: false,
    hasSubmenu: false,
    subMenuList: [],
  },
  {
    name: "Help",
    path: "",
    onClickHandlerType: 'onClickHandlerType',
    isOnlyMobile: false,
    hasSubmenu: false,
    subMenuList: [],
  },
  {
    name: "Extra services",
    path: "",
    onClickHandlerType: 'onClickHandlerType',
    isOnlyMobile: false,
    hasSubmenu: false,
    subMenuList: [],
  },
  {
    name: "Shop",
    path: "",
    onClickHandlerType: 'onClickHandlerType',
    isOnlyMobile: false,
    hasSubmenu: false,
    subMenuList: [],
  },
];

export const technicianBurgerMenu = [
  {
    name: "Client list",
    path: ROUTES.ALL_CLIENTS_SITES,
    onClickHandlerType: 'onClickHandlerType',
    isOnlyMobile: false,
    hasSubmenu: false,
    subMenuList: [],
  },
  {
    name: "Groups",
    path: ROUTES.GROUPS,
    onClickHandlerType: 'onClickHandlerType',
    hasSubmenu: false,
    subMenuList: [],
  },
  {
    name: "Revenue",
    path: "",
    onClickHandlerType: 'onClickHandlerType',
    isOnlyMobile: false,
    hasSubmenu: true,
    subMenuList: [
      {
        name: "Transaction log",
        path: "",
        onClickHandlerType: 'onClickHandlerType',
        isOnlyMobile: false,
        hasSubmenu: false,
      }
    ],
  },
  {
    name: "Machines",
    path: "",
    onClickHandlerType: 'onClickHandlerType',
    isOnlyMobile: false,
    hasSubmenu: false,
    subMenuList: [],
  },
  {
    name: "Help",
    path: "",
    onClickHandlerType: 'onClickHandlerType',
    isOnlyMobile: false,
    hasSubmenu: false,
    subMenuList: [],
  },
];

export const collectorBurgerMenu = [
  {
    name: "Client list",
    path: ROUTES.ALL_CLIENTS_SITES,
    onClickHandlerType: 'onClickHandlerType',
    isOnlyMobile: false,
    hasSubmenu: false,
    subMenuList: [],
  },
  {
    name: "Groups",
    path: ROUTES.GROUPS,
    onClickHandlerType: 'onClickHandlerType',
    hasSubmenu: false,
    subMenuList: [],
  },
  {
    name: "Revenue",
    path: "",
    onClickHandlerType: 'onClickHandlerType',
    isOnlyMobile: false,
    hasSubmenu: true,
    subMenuList: [
      {
        name: "Collection history",
        path: ROUTES.COLLECTION_HISTORY,
        onClickHandlerType: 'onClickHandlerType',
        isOnlyMobile: false,
        hasSubmenu: false,
      },
      {
        name: "Transaction log",
        path: "",
        onClickHandlerType: 'onClickHandlerType',
        isOnlyMobile: false,
        hasSubmenu: false,
      },
    ],
  },
  {
    name: "Machines",
    path: "",
    onClickHandlerType: 'onClickHandlerType',
    isOnlyMobile: false,
    hasSubmenu: false,
    subMenuList: [],
  },
  {
    name: "Help",
    path: "",
    onClickHandlerType: 'onClickHandlerType',
    isOnlyMobile: false,
    hasSubmenu: false,
    subMenuList: [],
  },
];



export const MenuAppBar = () => {
  const classes = useStyles();
  const flowType = useSelector(getFlowType);
  const isSetupFlow = flowType.isSetupFlow;
  const isReplaceTagFlow = flowType.isReplaceTagFlow;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [userAnchorEl, setUserAnchorEl] = useState<null | HTMLElement>(null);
  const [isHomepage, setIsHomepage] = useState(true);
  const [disableBurgerMenu, setDisableBurgerMenu] = useState(false);
  const open = Boolean(anchorEl);
  const openUserMenu = Boolean(userAnchorEl);
  const [isSetupFlowState, setIsSetupFlowState] = useState(false);
  const [isReplaceTagFlowState, setIsReplaceTagFlowState] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const userToken = localStorage.getItem("token");
  const userRole = userToken && parseJwt(userToken).auth.split(",")[0];
  const windowSize = useWindowSize();
  const isDesktop = windowSize.width && windowSize.width > 1170;
  const menuPosition = isDesktop ? 'left' : 'bottom';

  useEffect(() => {
    setIsSetupFlowState(isSetupFlow);
    setIsReplaceTagFlowState(isReplaceTagFlow)
  }, [isSetupFlow, isReplaceTagFlow]);

  useEffect(() => {
    if (isSetupFlowState || isReplaceTagFlowState) {
      setDisableBurgerMenu(true);
    } else {
      setDisableBurgerMenu(false);
    }
  }, [isSetupFlowState, isReplaceTagFlowState]);


  useEffect(() => {
    if (location.pathname === ROUTES.LOGIN) {
      setIsHomepage(true);
    } else {
      setIsHomepage(false);
    }
  }, [location]);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    handleClose();
    if (!disableBurgerMenu && !open) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl('');
    }
  };

  const handleUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    handleClose();
    !openUserMenu && setUserAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setUserAnchorEl(null);
  };

  const redirectToHomepage = () => {
    dispatch(socketReceivedMessage(null));
    dispatch(socketSentMessage(null));
    history.push(ROUTES.HOME);
    handleClose();
  };

  const handleUserLogOut = () => {
    localStorage.clear();
    dispatch(loginSuccess(null));
    handleClose();
    history.push(ROUTES.LOGIN);
  };

  const myAccountHandler = () => {
    history.push(ROUTES.MEMBER_PROFILE);
    dispatch(socketReceivedMessage(null));
    dispatch(socketSentMessage(null));
    handleClose();
  };

  const specificMenuListByRole = () => {
    switch (userRole) {
      case ROLE_MASTER:
        return masterBurgerMenu;
      case ROLE_ADMIN:
        return adminBurgerMenu;
      case ROLE_TECHNICIAN:
        return technicianBurgerMenu;
      case ROLE_OPERATIVE:
      case ROLE_COLLECTOR:
        return collectorBurgerMenu;
      default:
        return collectorBurgerMenu;
    }
  };

  const menuItemComponent = specificMenuListByRole().map((elem: any, idx: number) => {
    return <AppMenuItem {...elem} key={`menu-item-${idx}`} handleClose={handleClose}/>
  });

  return (
    <div className={classes.footer}>
      {!isHomepage && (<AppBarStyles>
        <Toolbar>
          <IconButton
            aria-label="home"
            aria-controls="menu-home"
            aria-haspopup="true"
            color="inherit"
            onClick={redirectToHomepage}
          >
            <HomeIcon/>
          </IconButton>

          <React.Fragment key={`user-menu-${menuPosition}`}>
            <IconButton
              edge="start"
              className={classes.menuButton}
              aria-label="account of current user"
              aria-controls="user-menu"
              aria-haspopup="true"
              color="inherit"
              onClick={handleUserMenu}
            >
              <PersonIcon />
            </IconButton>
            <DrawerStyles
              anchor={menuPosition}
              open={openUserMenu}
              onClose={handleClose}
            >
              <MenuItem onClick={myAccountHandler}>My profile</MenuItem>
              <MenuItem onClick={handleUserLogOut}>Log out</MenuItem>
            </DrawerStyles>
          </React.Fragment>

          <React.Fragment key={`burger-menu-${menuPosition}`}>
            <IconButton edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="menu"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
            >
              <StyledMenuWrapper theme={{disableBurgerMenu}}>
                <MenuIcon /></StyledMenuWrapper>
            </IconButton>
            <DrawerStyles
              anchor={menuPosition}
              open={open}
              onClose={handleClose}
            >
              {menuItemComponent}
            </DrawerStyles>
          </React.Fragment>
        </Toolbar>
      </AppBarStyles>)}
    </div>
  );
};
