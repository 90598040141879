import React, { FC } from "react";
import {StyledChangeSiteDetailsSection} from "./styles";
import RoomIcon from "@material-ui/icons/Room";
import LayersIcon from "@material-ui/icons/Layers";

interface Props {
  siteDetails: any;
  isPreviousSite: boolean;
}
const ChangeSiteDetailsSection: FC<Props> = ({siteDetails, isPreviousSite}) => {

  const mappedSiteGroups = siteDetails.siteGroups && siteDetails.siteGroups.length > 0 &&
    siteDetails.siteGroups.map((item, index) => <span key={`group=${index}`}>{item.name};{' '}</span>);
  
  return (
    <StyledChangeSiteDetailsSection theme={{isPreviousSite}}>
      <div className="site-header">
        {siteDetails.clientName && <span className="client-name">{siteDetails.clientName}</span>}
        {siteDetails.name && <span className="site-name">{siteDetails.name}</span>}
        {siteDetails.town && <span className="site-town"> {siteDetails.town}</span>}
      </div>
      <div className="site-details">
        {siteDetails.siteGroups && siteDetails.siteGroups.length > 0 &&
        <div className="groups-wrapper"><LayersIcon/>{mappedSiteGroups}</div>}
        {siteDetails.siteType && siteDetails.siteType.name && <div>{siteDetails.siteType.name}</div>}
        {siteDetails.what3Words && <div className="what-3-words"><RoomIcon/>{siteDetails.what3Words}</div>}
      </div>
    </StyledChangeSiteDetailsSection>
  );
};

export default ChangeSiteDetailsSection;
