import { put, takeLatest } from "redux-saga/effects";
import {
  USER_EMAIL_SUCCESS,
  USER_EMAIL_FAILURE,
  USER_EMAIL_REQUEST,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_SUCCESS, USER_RESET_PASSWORD_FAILURE
} from "./constants";
import {asyncRequest} from "../../api";
import {IS_END_USER} from "../../components/Containers/Main/constants";


export function* fetchUserEmailSaga(action: any) {
  try {
    const userEmailResults = yield asyncRequest({
      config: {
        method: "POST",
        data: {
          email: `${action.payload}`,
          isEndUser: IS_END_USER
        }
      },
      endpoint: 'api/account/reset-password',
    });
    yield put({ type: USER_EMAIL_SUCCESS, payload: "Go to your provided email account and access the received link" });
  } catch (error) {
    console.log(error);
    yield put({
      type: USER_EMAIL_FAILURE,
      payload: error && error.response && error.response.data && error.response.data.title &&
      error.response.data.title ? error.response.data.title : 'Unknown error'
    });
  }
}

export function* fetchUserNewPasswordSaga(action: any) {
  try {
    const userNewPasswordResults = yield asyncRequest({
      config: {
        method: "POST",
        data: {
          password: `${action.payload.userNewPassword}`,
          key: `${action.payload.resetKeyValue}`,
          isEndUser: IS_END_USER,
        }
      },
      endpoint: 'api/account/create-password',
    });
    yield put({ type: USER_RESET_PASSWORD_SUCCESS, payload: "Your password was successfully reset" });
  } catch (error) {
    console.log(error);
    yield put({
      type: USER_RESET_PASSWORD_FAILURE,
      payload: error && error.response && error.response.data && error.response.data.title
        ? error.response.data.title : 'Unknown error'
    });
  }
}

export default  [
  takeLatest(USER_EMAIL_REQUEST, fetchUserEmailSaga),
  takeLatest(USER_RESET_PASSWORD_REQUEST, fetchUserNewPasswordSaga),
]

