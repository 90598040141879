export const ADD_SITE_TO_CLIENT_REQUEST = "ADD_SITE_TO_CLIENT_REQUEST";
export const ADD_SITE_TO_CLIENT_SUCCESS = "ADD_SITE_TO_CLIENT_SUCCESS";
export const ADD_SITE_TO_CLIENT_FAILURE = "ADD_SITE_TO_CLIENT_FAILURE";

export const ADD_CLIENT_NAME = "ADD_CLIENT_NAME";
export const ADD_CLIENT_DETAILS = "ADD_CLIENT_DETAILS";

export const ADD_CLIENT_SUCCESS = "ADD_CLIENT_SUCCESS";
export const ADD_CLIENT_REQUEST = "ADD_CLIENT_REQUEST";
export const ADD_CLIENT_FAILURE = "ADD_CLIENT_FAILURE";

export const GET_SITE_TYPE_SUCCESS = "GET_SITE_TYPE_SUCCESS";
export const GET_SITE_TYPE_REQUEST = "GET_SITE_TYPE_REQUEST";
export const GET_SITE_TYPE_FAILURE = "GET_SITE_TYPE_FAILURE";


export const DELETE_CLIENT_REQUEST = "DELETE_CLIENT_REQUEST";
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";
export const DELETE_CLIENT_FAILURE = "DELETE_CLIENT_FAILURE";

export const DELETE_SITE_REQUEST = "DELETE_SITE_REQUEST";
export const DELETE_SITE_SUCCESS = "DELETE_SITE_SUCCESS";
export const DELETE_SITE_FAILURE = "DELETE_SITE_FAILURE";

export const EDIT_CLIENT_NAME_REQUEST = "EDIT_CLIENT_NAME_REQUEST";
export const EDIT_CLIENT_NAME_SUCCESS = "EDIT_CLIENT_NAME_SUCCESS";
export const EDIT_CLIENT_NAME_FAILURE = "EDIT_CLIENT_NAME_FAILURE";

export const EDIT_SITE_DETAILS_REQUEST = "EDIT_SITE_DETAILS_REQUEST";
export const EDIT_SITE_DETAILS_SUCCESS_ON_SITE = "EDIT_SITE_DETAILS_SUCCESS_ON_SITE";
export const EDIT_SITE_DETAILS_SUCCESS_ON_CLIENT = "EDIT_SITE_DETAILS_SUCCESS_ON_CLIENT";
export const EDIT_SITE_DETAILS_FAILURE = "EDIT_SITE_DETAILS_FAILURE";
