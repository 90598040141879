import React, {FC, useEffect, useState} from "react";
import { StyledMachineTestResultsPage } from "./styles";
import Header from "../../components/Header";
import {OutlinedButton, PrimaryButton} from "../../components/Buttons";
import { useHistory } from "react-router-dom";
import SummaryHeader from "../../components/SummaryHeader";
import MachineDetails from "../../components/MachineDetails";
import {
  FilledBackgroundNotification,
  NotificationError,
} from "../../components/NotificationMessages";
import { useDispatch, useSelector } from "react-redux";
import { addMachineDetailsAttempt } from "../MachineDetailsPage/actions";
import {
  displayMachineDetails,
  getMachineDetailsError, getMachineDetailsLoading, getMachineDetailsSuccessRequest,
} from "../MachineDetailsPage/selectors";
import LoadingComponent from "../../components/Loading";
import { asyncRequest } from "../../api";
import {socketReceivedMessage, socketSentMessage} from "../../components/SocketCommunication/actions";
import {getReceivedSocketMessageDetails} from "../../components/SocketCommunication/selectors";
import * as ROUTES from "../../components/Containers/Main/constants";
import ModalComponent from "../../components/Modal";
import {useModalStyles} from "../../components/Modal/styles";
import {getFlowType} from "../../components/Containers/Main/selectors";
import {setFlowType} from "../../components/Containers/Main/actions";

const MachineTestResultsPage: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useModalStyles();
  const machineDetails = useSelector(displayMachineDetails);
  const hubTagId = machineDetails.hubTagId;
  const errorSettingMachine = useSelector(getMachineDetailsError);
  const loadingSettingMachine = useSelector(getMachineDetailsLoading);
  const machineDetailsSuccessResponse = useSelector(getMachineDetailsSuccessRequest);
  const receivedSocketMessage = useSelector(getReceivedSocketMessageDetails);
  const flowType = useSelector(getFlowType);
  const testCreditValue = machineDetails.creditTestValue;
  const isReplaceTagFlow = flowType.isReplaceTagFlow;
  const replaceExternalTagId = flowType.replaceExternalTagId;
  const isOnlyTestFlow = flowType.isOnlyTestFlow;

  const [replacementError, setReplacementError] = useState();
  const [isSocketLoading, setIsSocketLoading] = useState(false);
  const [openModal, setOpenModal] = React.useState(false);

  const editMachineSettingsHandler = () => {
    history.push(ROUTES.MACHINE_DETAILS);
    dispatch(socketReceivedMessage(null));
    dispatch(socketSentMessage(null));
  };

  const finishConfiguringHandler = () => {
    handleModalClose();
    dispatch(socketReceivedMessage(null));
    dispatch(socketSentMessage(null));
    if (isReplaceTagFlow) {
      asyncRequest({
        config: {
          method: "PATCH",
          data: {
            //hostTagId: replaceExternalTagId,
            qrCodeId: machineDetails.QrCodeId
          },
        },
        endpoint: `api/unit/${machineDetails.hubTagId}/replace-tags`,
      })
        .then((response) => {
          history.push(ROUTES.HOME);
          dispatch(setFlowType({
            ...flowType,
            isReplaceTagFlow: false,
            replaceExternalTagId: ""
          }));
        })
        .catch((err) => {
          console.log(err);
          setReplacementError(err.message);
        });
      return;
    }
    if (isOnlyTestFlow) {
      history.push(ROUTES.HOME);
      return;
    }
    dispatch(socketSentMessage({
      MT: "22",
      unitId: hubTagId,
      topic: "/server/client/unitConfiguration",
    }));
    setIsSocketLoading(true);
  };

  useEffect(() => {
    if (
      receivedSocketMessage &&
      receivedSocketMessage.MT &&
      receivedSocketMessage.MT === 23
    ) {
      setIsSocketLoading(false);
      dispatch(addMachineDetailsAttempt(machineDetails));
      dispatch(socketReceivedMessage(null));
      dispatch(socketSentMessage(null));
    }
  }, [receivedSocketMessage]);

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const MODAL_CONTENT = isReplaceTagFlow ?
    'Finish will confirm replacement and update the unit to the database ready for use' :
    'Finish will confirm set up and add the unit to the database ready for use';

  const modalBody = (
    <div className="modal-wrapper">
      <div className={classes.body}>
        {MODAL_CONTENT}
      </div>
      <div className="modal-footer">
        <div className={classes.footer}>
          <OutlinedButton className="back-button" onClick={handleModalClose}>Back</OutlinedButton>
          <PrimaryButton
            className="continue-button finish-button centered-button"
            onClick={finishConfiguringHandler}
          >
            Finish
          </PrimaryButton>
        </div>
      </div>
    </div>
  );

  return (
    <StyledMachineTestResultsPage className="height-controller-wrapper">
      <Header title="Test results" />
      <SummaryHeader isMenuDisplayed={false} isArrowDisplayed={false} />
      <MachineDetails />
      <div className="background-notification-wrapper">
      <FilledBackgroundNotification
        message={`£${testCreditValue} sent`}
        backgroundColorClass="green"
      />
      </div>
      <FilledBackgroundNotification
        message={`Check the machine has been credited by £${testCreditValue}`}
        backgroundColorClass="red"
      />
      <div className="warning-section">
        If the machine has not been credited, edit details and complete set up
        again or contact support
      </div>
      <div className="actions-wrapper">
        {!isReplaceTagFlow && !isOnlyTestFlow && (
          <PrimaryButton
            className="back-button edit-settings centered-button"
            onClick={editMachineSettingsHandler}
          >
            Edit settings
          </PrimaryButton>
        )}
        <PrimaryButton
          className="continue-button finish-button centered-button"
          onClick={isOnlyTestFlow ? finishConfiguringHandler : handleModalOpen}
        >
          {isOnlyTestFlow ? 'Finish' : 'Continue'}
        </PrimaryButton>
      </div>
      <ModalComponent open={openModal} handleClose={handleModalClose} body={modalBody}/>
      {(loadingSettingMachine || isSocketLoading) && <LoadingComponent />}
      {errorSettingMachine && (
        <NotificationError message={errorSettingMachine} messageClass="error" />
      )}
      {replacementError && (
        <NotificationError message={replacementError} messageClass="error" />
      )}
    </StyledMachineTestResultsPage>
  );
};

export default MachineTestResultsPage;
