import React, { FC, useEffect, useState } from "react";
import { PrimaryButton } from "../../../components/Buttons";
import { StyledHomepage } from "../styles";
import { useHistory } from "react-router-dom";
import { addMachineDetails } from "../../MachineDetailsPage/actions";
import { useDispatch, useSelector } from "react-redux";
import { displayMachineDetails } from "../../MachineDetailsPage/selectors";
import { parseJwt } from "../../../utils/parseDetailsFromToken";
import { socketSentMessage } from "../../../components/SocketCommunication/actions";
import { isIOS, isAndroid } from "react-device-detect";
import { ROLE_COLLECTOR } from "../../../constants";
import * as ROUTES from "../../../components/Containers/Main/constants";
import QrNfcHomeSection from "../QrNfcHomeSection";

interface Props {
  isDesktop: boolean;
  userRole: string;
}

const CollectorHome: FC<Props> = ({ isDesktop, userRole }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const machineDetails = useSelector(displayMachineDetails);
  const userToken = localStorage.getItem("token");
  const userCompanyType = userToken && parseJwt(userToken).companyType;
  const buttonContent = userCompanyType === "SOLO" ? "Sites" : "Clients";
  const [hubTagId, setHubTagId] = useState("");
  const [qrCodeId, setQrCodeId] = useState();
  const urlPath = window.location.pathname;

  const handleNativeUnitId = (event: any) => {
    if(!isIOS && !isAndroid) {
      return;
    }
    try {
      const unitDetailsFromRN = JSON.parse(event.data);
      const unitId = unitDetailsFromRN.unitID;
      const qrCodeId = unitDetailsFromRN.qrCodeId;
      unitId && setHubTagId(unitId);
      qrCodeId && setQrCodeId(qrCodeId);
    } catch (e) {
      console.warn('Error parsing unitDetailsFromRN, just turn on native app!');
    }
  };

  useEffect(() => {
    if (hubTagId && hubTagId !== "" && hubTagId !== machineDetails.hubTagId) {
      const socketMessageToSend = {
        MT: "13",
        unitId: hubTagId,
        topic: "/server/client/unitStocksConfiguration",
      };
      dispatch(socketSentMessage(socketMessageToSend));
      dispatch(
        addMachineDetails({
          ...machineDetails,
          hubTagId,
        })
      );
      history.push(ROUTES.MACHINE_OPTIONS);
    }
    if (qrCodeId) {
      const socketMessageToSend = {
        MT: "13",
        unitId: qrCodeId,
        topic: "/server/client/unitStocksConfiguration",
      };
      dispatch(socketSentMessage(socketMessageToSend));
      dispatch(
        addMachineDetails({
          ...machineDetails,
          QrCodeId: qrCodeId,
        })
      );
      history.push(ROUTES.MACHINE_OPTIONS);
    }
  }, [hubTagId, qrCodeId]);

  useEffect(() => {
    if (
      urlPath === ROUTES.HOME &&
      !isDesktop &&
      userRole &&
      userRole === ROLE_COLLECTOR
    ) {
      if (isIOS) {
        window.addEventListener("message", handleNativeUnitId);
        return () => window.removeEventListener("message", handleNativeUnitId);
      }
      if (isAndroid) {
        document.addEventListener("message", handleNativeUnitId);
        return () =>
          document.removeEventListener("message", handleNativeUnitId);
      }
      window.addEventListener("message", handleNativeUnitId);
      return () => window.removeEventListener("message", handleNativeUnitId);
    }
  }, []);

  const clientsSitesListHandler = () => {
    history.push(ROUTES.ALL_CLIENTS_SITES);
  };

  const historyListHandler = () => {
    history.push(ROUTES.COLLECTION_HISTORY);
  };

  return (
    <StyledHomepage>
      <QrNfcHomeSection isDesktop={isDesktop} userRole={userRole}/>
      <div>
        <div className="button-section-wrapper">
          <PrimaryButton onClick={historyListHandler}>History</PrimaryButton>
          <div>Collection History</div>
        </div>

        <div className="button-section-wrapper">
          <PrimaryButton onClick={clientsSitesListHandler}>
            {buttonContent}
          </PrimaryButton>
          <div>Go to {buttonContent} list</div>
        </div>
      </div>
    </StyledHomepage>
  );
};

export default CollectorHome;
