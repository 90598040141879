import React, {FC, useEffect, useState} from "react";
import {StyledMachineDeactivation} from "./styles";
import Header from "../../components/Header";
import {OutlinedButton, PrimaryButton} from "../../components/Buttons";
import {useHistory} from "react-router-dom";
import SummaryHeader from "../../components/SummaryHeader";
import {asyncRequest} from "../../api";
import {useDispatch, useSelector} from "react-redux";
import {displayMachineDetails} from "../MachineDetailsPage/selectors";
import {displaySiteDetailsSuccess} from "../AllClientsSitesPage/actions";
import MachineType from "../SitePage/MachineTypeSection";
import LoadingComponent from "../../components/Loading";
import {addMachineDetails} from "../MachineDetailsPage/actions";
import {NotificationError} from "../../components/NotificationMessages";
import * as ROUTES from "../../components/Containers/Main/constants";

const MachineDeactivation: FC= () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const machineDetails = useSelector(displayMachineDetails);
  const unitId = machineDetails && machineDetails.id;
  const [isLoading, setIsLoading] = useState(false);
  const [requestError, setRequestError] = useState();
  const [machineItemDetails, setMachineItemDetails] = useState(machineDetails);

  useEffect(() => {
    setMachineItemDetails(machineDetails);
  },[machineDetails]);


  const confirmDeactivationHandler = () => {
    setIsLoading(true);
    asyncRequest({
      config:{
        method: "PATCH",
      },
      endpoint: `api/unit/${unitId}/deactivate`,
    }).then(response => {
      dispatch(addMachineDetails(response));
      response.siteDetailsDto && dispatch(displaySiteDetailsSuccess(response.siteDetailsDto));
      setIsLoading(false);
      history.push(ROUTES.MACHINE_DEACTIVATION_CONFIRMATION);
    })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
        setRequestError(err);
      });
  };

  return (
    <StyledMachineDeactivation className="height-controller-wrapper">
      <Header title="Deactivation"/>
      <SummaryHeader isMenuDisplayed={false} isArrowDisplayed={false}/>
      <MachineType itemDetails={machineItemDetails} hasMenu={false}/>
      <div className="helper-screen-description">
        <div>Do you want to deactivate this machine?</div>
        <div>This will remove machine from site, add it to your "Off site" list, stop payments and all unit charges</div>
      </div>
      <div className="actions-wrapper">
        <OutlinedButton onClick={() => {history.goBack()}}>Back</OutlinedButton>
        <PrimaryButton onClick={confirmDeactivationHandler}>Confirm</PrimaryButton>
      </div>
      {isLoading && <LoadingComponent/>}
      {requestError && <NotificationError messageClass="error" message={requestError['response'].data.title}/>}
    </StyledMachineDeactivation>
  );
};

export default MachineDeactivation;
