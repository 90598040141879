import React, {FC} from "react";
import {StyledCollectionSummaryHeader} from "./styles";
import LayersIcon from '@material-ui/icons/Layers';


interface Props {
  siteDetails: any;
  isSiteCollection: boolean;
}


const CollectionSummaryHeader: FC<Props> = ({siteDetails, isSiteCollection}) => {
  const groupName = siteDetails.siteGroups && siteDetails.siteGroups.length > 0 &&
    siteDetails.siteGroups.map((item, index) => <span key={`group-key-${index}`}>{item.name}; </span>)

  return (
    <StyledCollectionSummaryHeader theme={{isSiteCollection}}>
      <div className="title">
        {siteDetails && siteDetails.clientName && <span className="client-name">{siteDetails.clientName}</span>}
        <span className="site-name">{siteDetails.name}</span>
        {siteDetails && siteDetails.town && <span className="site-town">{siteDetails.town}</span>}
      </div>
      {siteDetails && siteDetails.siteGroups && siteDetails.siteGroups.length > 0 && <div className="site-group"><LayersIcon/>{groupName}</div>}
    </StyledCollectionSummaryHeader>
  );
};

export default CollectionSummaryHeader;
