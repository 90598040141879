import { put, takeLatest } from "redux-saga/effects";

import { asyncRequest } from "../api";
import { addAccountSuccessAction, addAccountFailureAction, deleteAccountSuccessAction, deleteAccountFailureAction } from './actions';
import {
  ACCOUNTS_ENDPOINT,
  ACCOUNTS_REQUEST,
  ACCOUNTS_SUCCESS,
  ACCOUNTS_FAILURE,
  ADD_ACCOUNT_REQUEST,
  DELETE_ACCOUNT_REQUEST,
} from "./constants";

export function* fetchAccountsSaga() {
  try {
    const accounts = yield asyncRequest({
      config: { method: "GET" },
      endpoint: ACCOUNTS_ENDPOINT
    });

    yield put({ type: ACCOUNTS_SUCCESS, accounts, loading: false });
  } catch (error) {
    console.log(error);
    yield put({ type: ACCOUNTS_FAILURE, loading: false, error });
  }
}

export function* addAccountSaga({ data }: any) {
  let account = { 
    iban: data.iban, 
    resource_id: data.resourceId, 
    currency: data.currency,
    product: data.product,
    name: data.name,
    cash_account_type: data.accountType 
  }
  try {
    const res = yield asyncRequest({
      config: {
        method: "POST", data: account,
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json"
        }
      },
      endpoint: ACCOUNTS_ENDPOINT
    });
    yield put(addAccountSuccessAction(data, res.id))
  } catch (error) {
    console.log(error);
    yield put(addAccountFailureAction(error))
  }
}

export function* deleteAccountSaga(action: any) {

  try {
    yield asyncRequest({
      config: {
        method: "DELETE"
      },
      endpoint: `${ACCOUNTS_ENDPOINT}${action.id}/`
    });

    yield put(deleteAccountSuccessAction(action.id));
  } catch (error) {
    console.log(error);
    yield put(deleteAccountFailureAction(error));
  }
}

export default  [
  takeLatest(ACCOUNTS_REQUEST, fetchAccountsSaga),
  takeLatest(ADD_ACCOUNT_REQUEST, addAccountSaga),
  takeLatest(DELETE_ACCOUNT_REQUEST, deleteAccountSaga)
]
