import React, {useEffect, useState} from "react";
import {StyledMachineCollectionDetails} from "./styles";
import Header from "../../components/Header";
import {OutlinedButton, PrimaryButton} from "../../components/Buttons";
import {useHistory, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {displayMachineDetails} from "../MachineDetailsPage/selectors";
import LoadingComponent from "../../components/Loading";
import {getSiteDetailsData} from "../AllClientsSitesPage/selectors";
import CollectionCard from "../../components/CollectionCard";
import MachineCollectionCardDetails from "../../components/MachineCollectionCardDetails";
import SummaryHeader from "../../components/SummaryHeader";
import {displaySiteDetailsFailure, displaySiteDetailsSuccess} from "../AllClientsSitesPage/actions";
import {asyncRequest} from "../../api";
import {addMachineDetails} from "../MachineDetailsPage/actions";
import {getCashCollectedValues} from "../CollectionReceipt/selectors";
import {addMachineCollectionDetails} from "./actions";
import {displayMachineCollectionDetails} from "./selectors";
import {displayTotalCashCollected} from "../SiteCollection/selectors";
import {addSiteCollectionDetails, addTotalCashCollected} from "../SiteCollection/actions";
import * as ROUTES from "../../components/Containers/Main/constants";

const MachineCollectionDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const machineDetails = useSelector(displayMachineDetails);
  const unitId = machineDetails && machineDetails.id;
  const siteDetails = useSelector(getSiteDetailsData);
  const machineCollectionDetails = useSelector(displayMachineCollectionDetails);
  const totalCashCollected = useSelector(displayTotalCashCollected);
  const cashCollectedValuesData = useSelector(getCashCollectedValues);
  const cashCollectedArray = cashCollectedValuesData && cashCollectedValuesData.cashCollectedValues;
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const prevStateLocation = history.location.state && history.location.state.initialUrl;
  const [inputCashValue, setInputCashValue] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [totalCashCollectedState, setTotalCashCollectedState] = useState(totalCashCollected);
  const [machineCollectionDetailsState, setMachineCollectionDetailsState] = useState();

  useEffect(() => {
    if (totalCashCollected) {
      setTotalCashCollectedState(totalCashCollected);
    }
    dispatch(addSiteCollectionDetails([]));
  }, []);

  useEffect(() => {
    machineCollectionDetails && setMachineCollectionDetailsState(machineCollectionDetails);
  }, [machineCollectionDetails]);

  useEffect(() => {
    setIsLoading(true);
    asyncRequest({
      config:{
        method: "GET",
      },
      endpoint: `api/collection/unit/${unitId}`,
    }).then(response => {
      dispatch(addMachineCollectionDetails(response));
    })
    .then(() => {
      setIsLoading(false);
    })
      .catch(err => {
        console.log(err);
      });

  }, []);

  useEffect(() => {
    machineCollectionDetailsState && setTotalCashCollectedState({
      actualCashCollected: machineCollectionDetailsState.actualCashCollected,
      totalContactlessMoneyGross: machineCollectionDetailsState.grossContactless,
      totalEstimatedCashAmount: machineCollectionDetailsState.estimatedCashAmount,
      totalGrossMachineIncome: machineCollectionDetailsState.grossCashCollected
    });
  }, [machineCollectionDetailsState]);

  useEffect(() => {
    if (cashCollectedArray && cashCollectedArray.length === 0 && inputCashValue === '') {
      setIsButtonDisabled(true);
    } else if (inputCashValue !== '') {
      setIsButtonDisabled(false);
    } else if (cashCollectedArray && cashCollectedArray.length !== 0 ) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  },[cashCollectedValuesData, inputCashValue]);

  const onChangeInputHandler = (value: string) => {
    if (!!value) {
      setInputCashValue(value);
      setMachineCollectionDetailsState(prevState => {
        return {
          ...machineCollectionDetailsState,
          actualCashCollected: parseFloat(value),
          grossCashCollected: parseFloat(value) + prevState.grossContactless
        }
      });
    }
  };

  const confirmCollectionHandler = () => {
    dispatch(addTotalCashCollected(totalCashCollectedState));
    dispatch(addMachineCollectionDetails(machineCollectionDetailsState));
    history.push({
      pathname: ROUTES.COLLECTION_RECEIPT,
      state: {prevUrl: location.pathname, initialUrl:  prevStateLocation},
    })
  };

  const onClickSiteDetailsHandler = () => {
    const siteId = siteDetails.id;
    history.push({
      pathname: ROUTES.SITE_COLLECTION,
      state: {prevUrl: location.pathname, initialUrl:  prevStateLocation},
    });
    asyncRequest({
      config:{
        method: "GET",
      },
      endpoint: `api/site/${siteId}`,
    }).then(response => {
      dispatch(displaySiteDetailsSuccess(response));
    })
      .catch(err => {
        console.log(err);
        dispatch(displaySiteDetailsFailure(err));
      });
    dispatch(addMachineDetails({
      ...machineDetails,
      siteId: siteId,
    }))
  };

  return (
    <StyledMachineCollectionDetails className="height-controller-wrapper">
      <Header title="Machine Collection"/>
      <div onClick={onClickSiteDetailsHandler}><SummaryHeader isMenuDisplayed={false}/></div>
      <CollectionCard totalCashCollected={totalCashCollectedState}
                      collectionCardStartDate={machineCollectionDetails.collectionPeriod.startTime}
                      isIdenticalStartData={true}/>
      {!isLoading && machineCollectionDetailsState && <MachineCollectionCardDetails machineCollectionDetails={machineCollectionDetailsState}
                                    isIdenticalStartData={true}
                                    onChangeInputHandler={onChangeInputHandler}/>}
      <div className="helper-screen-description">
        Tap and enter actual cash amount. Confirm collection to save values.
      </div>
      <div className="actions-wrapper">
        <OutlinedButton className="back-button" onClick={() => {history.push(`${prevStateLocation}`)}}>Back</OutlinedButton>
        <PrimaryButton onClick={confirmCollectionHandler} disabled={isButtonDisabled}>Confirm Collection</PrimaryButton>
      </div>
      {isLoading && <LoadingComponent/>}
    </StyledMachineCollectionDetails>
  );
};

export default MachineCollectionDetails;
