import { put, takeLatest } from "redux-saga/effects";
import {
  ADD_CLIENT_SUCCESS,
  ADD_CLIENT_FAILURE,
  ADD_CLIENT_REQUEST,
  GET_SITE_TYPE_SUCCESS,
  GET_SITE_TYPE_FAILURE,
  GET_SITE_TYPE_REQUEST,
  ADD_SITE_TO_CLIENT_REQUEST,
  ADD_SITE_TO_CLIENT_SUCCESS,
  ADD_SITE_TO_CLIENT_FAILURE,
  DELETE_SITE_SUCCESS,
  DELETE_SITE_FAILURE,
  DELETE_SITE_REQUEST,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAILURE,
  DELETE_CLIENT_REQUEST,
  EDIT_CLIENT_NAME_SUCCESS,
  EDIT_CLIENT_NAME_FAILURE,
  EDIT_CLIENT_NAME_REQUEST,
  EDIT_SITE_DETAILS_SUCCESS_ON_SITE,
  EDIT_SITE_DETAILS_SUCCESS_ON_CLIENT, EDIT_SITE_DETAILS_FAILURE, EDIT_SITE_DETAILS_REQUEST
} from "./constants";
import {push} from "connected-react-router";
import {asyncRequest} from "../../api";
import * as ROUTES from "../../components/Containers/Main/constants";
import { SET_FLOW_TYPE } from "../../components/Containers/Main/constants";

export function* fetchAddClientSaga(action: any) {
  try {
    const sitesDetailsArray = action.payload.sitesDetailsArray.map((site) => { return {...site, siteType: site.siteType.code}});
    const addNewClientResults = yield asyncRequest({
      config: {
        method: "POST",
        data: {
          name: action.payload.clientName === 'EMPTY' ? "" : action.payload.clientName,
          sites: sitesDetailsArray
        }
      },
      endpoint: 'api/client',
    });

    yield put({ type: ADD_CLIENT_SUCCESS, payload: addNewClientResults });
    yield put(push(ROUTES.ALL_CLIENTS_SITES));
  } catch (error) {
    console.log(error);
    yield put({ type: ADD_CLIENT_FAILURE, payload: error && error.message ? error.message : 'Unknown error' });
  }
}

export function* fetchSiteTypeListSaga(action: any) {
  try {
    const getSiteTypeList = yield asyncRequest({
      config: {
        method: "GET",
      },
      endpoint: 'api/site/types',
    });


    yield put({ type: GET_SITE_TYPE_SUCCESS, payload: getSiteTypeList });
  } catch (error) {
    console.log(error);
    yield put({ type: GET_SITE_TYPE_FAILURE, payload: error && error.message ? error.message : 'Unknown error' });
  }
}

export function* postNewSiteToClientSaga(action: any) {
  try {
    yield asyncRequest({
      config: {
        method: "PATCH",
        data: {
          name: action.payload.site.name,
          postCode: action.payload.site.postCode,
          town: action.payload.site.town,
          siteType: action.payload.site.siteType.code,
          what3Words: action.payload.site.what3Words
        }
      },
      endpoint: `api/client/${action.payload.clientId}/site`
    })
  
    yield put({ type: ADD_SITE_TO_CLIENT_SUCCESS, payload: action.payload });
    yield put(push(`${ROUTES.CLIENT_PAGE}/${action.payload.clientId}`));
    yield put({ type: SET_FLOW_TYPE, payload: {
      ...action.payload.flowType,
      clientIdFromAddSiteToClientFlow: -1
    }});
  } catch (error) {
    console.log(error);
    yield put({
      type: ADD_SITE_TO_CLIENT_FAILURE,
      payload: error && error.response && error.response.data && error.response.data.title ?
        error.response.data.title : 'Unknown error' });
  }
}

export function* fetchDeleteClientSaga(action: any) {
  try {
    yield asyncRequest({
      config: {
        method: "DELETE",
      },
      endpoint: `api/client/${action.payload.clientId}`
    })

    yield put({ type: DELETE_CLIENT_SUCCESS, payload: action.payload });
    yield put(push(ROUTES.ALL_CLIENTS_SITES));
  } catch (error) {
    console.log(error);
    yield put({
      type: DELETE_CLIENT_FAILURE,
      payload: error && error.response && error.response.data && error.response.data.title ?
        error.response.data.title : 'Unknown error' });
  }
}

export function* fetchDeleteSiteSaga(action: any) {
  try {
    yield asyncRequest({
      config: {
        method: "DELETE",
      },
      endpoint: `api/site/${action.payload.siteId}`
    })

    yield put({ type: DELETE_SITE_SUCCESS, payload: action.payload });
    yield put(push(ROUTES.ALL_CLIENTS_SITES));
  } catch (error) {
    console.log(error);
    yield put({
      type: DELETE_SITE_FAILURE,
      payload: error && error.response && error.response.data && error.response.data.title ?
        error.response.data.title : 'Unknown error' });
  }
}

export function* fetchEditClientNameSaga(action: any) {
  try {
    yield asyncRequest({
      config: {
        method: "PATCH",
        data: {
          clientName: action.payload.clientName
        },
      },
      endpoint: `api/client/${action.payload.clientId}`
    })

    yield put({ type: EDIT_CLIENT_NAME_SUCCESS, payload: action.payload });
    yield put(push(ROUTES.ALL_CLIENTS_SITES));
  } catch (error) {
    console.log(error);
    yield put({
      type: EDIT_CLIENT_NAME_FAILURE,
      payload: error && error.response && error.response.data && error.response.data.title ?
        error.response.data.title : 'Unknown error' });
  }
}

export function* fetchEditSiteDetails(action: any) {
  try {
    const siteDetails = {
      siteId: action.payload.siteId,
      name: action.payload.site.name,
      postCode: action.payload.site.postCode,
      town: action.payload.site.town,
      siteType: action.payload.site.siteType,
      what3Words: action.payload.site.what3Words
    }

    yield asyncRequest({
      config: {
        method: "PUT",
        data: {...siteDetails, siteType: action.payload.site.siteType.code}
      },
      endpoint: 'api/site'
    })

    if (action.payload.clientId !== -1) {
      yield put({ type: EDIT_SITE_DETAILS_SUCCESS_ON_CLIENT, payload: {...siteDetails, clientId: action.payload.clientId} });
      yield put(push(`${ROUTES.CLIENT_PAGE}/${action.payload.clientId}`));
    } else {
      yield put({ type: EDIT_SITE_DETAILS_SUCCESS_ON_SITE, payload: {...siteDetails, clientId: action.payload.clientId} });
      yield put(push(`${ROUTES.SITE_PAGE}/${action.payload.siteId}`));
    }
    yield put({ type: SET_FLOW_TYPE, payload: {
      ...action.payload.flowType,
      clientIdFromAddSiteToClientFlow: -1
    }});
  } catch (error) {
    console.log(error);
    yield put({
      type: EDIT_SITE_DETAILS_FAILURE,
      payload: error && error.response && error.response.data && error.response.data.title ?
        error.response.data.title : 'Unknown error' });
  }
}


export default  [
  takeLatest(ADD_CLIENT_REQUEST, fetchAddClientSaga),
  takeLatest(GET_SITE_TYPE_REQUEST, fetchSiteTypeListSaga),
  takeLatest(ADD_SITE_TO_CLIENT_REQUEST, postNewSiteToClientSaga),
  takeLatest(DELETE_CLIENT_REQUEST, fetchDeleteClientSaga),
  takeLatest(DELETE_SITE_REQUEST, fetchDeleteSiteSaga),
  takeLatest(EDIT_CLIENT_NAME_REQUEST, fetchEditClientNameSaga),
  takeLatest(EDIT_SITE_DETAILS_REQUEST, fetchEditSiteDetails)
]
