import React, {FC, useState} from "react";
import {StyledPricePlayInput} from "./styles";
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import {useDispatch, useSelector} from "react-redux";
import {getMachineDetailsError} from "../../pages/MachineDetailsPage/selectors";
import {NotificationError} from "../NotificationMessages";
import { IconButton } from "@material-ui/core";
import ModalComponent from "../Modal";
import {useModalStyles} from "../Modal/styles";
import {MODAL_CONTENT} from "./constants";
import { putPricePlayAttempt } from "../../pages/MachineSummaryPage/actions";
import { IMachineDetailsState } from "../../pages/MachineDetailsPage/model";
import { ISiteDetailsData } from "../../pages/AllClientsSitesPage/model";
import { displaySiteDetailsSuccess } from "../../pages/AllClientsSitesPage/actions";
import { OutlinedButton, PrimaryButton } from "../Buttons";
import { useEffect } from "react";
import { getFlowType } from "../Containers/Main/selectors";

interface Props {
    machineDetails: IMachineDetailsState;
    siteDetails: ISiteDetailsData;
    openModal: boolean;
    setOpenModal: (x: boolean) => void;
}

const PricePlayInput: FC<Props> = ({ machineDetails, siteDetails, openModal, setOpenModal }) => {
    const classes = useModalStyles();
    const dispatch = useDispatch();
    const isHostSingleValue = machineDetails.creditType.code === "HOST_SING_VAL";
    const addPricePlayError = useSelector(getMachineDetailsError);
    const siteId = siteDetails.id;
    const flowType = useSelector(getFlowType);
    const [priceValue, setPriceValue] = useState(machineDetails.defaultTransactionAmount);
    const [maxPriceValue, setMaxPriceValue] = useState(machineDetails.maxTransactionAmount ? machineDetails.maxTransactionAmount : machineDetails.hostType.maxTransactionAmount);
    
    const addDecimals = (value: string) => {
        const priceValueTwoDecimals = `${value}`.split('.');
        if (priceValueTwoDecimals[1]) {
            if (priceValueTwoDecimals[1].length === 1) {
                return priceValueTwoDecimals[0] + '.' + priceValueTwoDecimals[1] + '0';
            } else {
                return value;
            }
        } else {
            return priceValueTwoDecimals[0] + '.00';
        }
    }

    useEffect(() => {
        if (isHostSingleValue) {
            if (priceValue) {
                setPriceValue(addDecimals(priceValue));
            }
        } else {
            if (maxPriceValue) {
                setMaxPriceValue(addDecimals(`${maxPriceValue}`));
            }
        }
    }, [maxPriceValue, priceValue]);

    const changeValue = (value: number) => {
        const currentValue = parseFloat(priceValue) + value;     
        if (currentValue >= 0.5) {
            setPriceValue(currentValue.toFixed(2).toString());
        }
    }

    const onConfirmChangePrice = () => {
        dispatch(putPricePlayAttempt(priceValue, machineDetails.hubTagId, siteId, flowType));
        siteDetails.units.map((unit) => unit.defaultTransactionAmount = unit.id === machineDetails.id ? priceValue : unit.defaultTransactionAmount);
        dispatch(displaySiteDetailsSuccess(siteDetails));
    }
    
    const handleModalClose = () => {
        setOpenModal(false);
    };
    
    const modalBody = (
    <div className="modal-wrapper">
        <div className={classes.body}>
        {MODAL_CONTENT}
        {addPricePlayError && <NotificationError message="Saving new price play failed" messageClass="error"/>}
        </div>
        <div className="modal-footer">
        <div className={classes.footer}>
            <OutlinedButton className="back-button" onClick={handleModalClose}>Back</OutlinedButton>
            <PrimaryButton
                className="continue-button finish-button centered-button"
                onClick={onConfirmChangePrice}
            >
                Finish
            </PrimaryButton>
        </div>
        </div>
    </div>
    );

    return (
        <StyledPricePlayInput theme={{isHostSingleValue}}>
            <div className="change-price">
                {isHostSingleValue ? <p className="change-price-description">Change price of play</p> : 
                <p className="change-price-description not-single">Maximum credit value</p>}
                <div className="change-price-functionality">
                    {isHostSingleValue && <IconButton onClick={() => changeValue(-0.1)}>
                        <RemoveRoundedIcon htmlColor={'#0090DF'}/>
                    </IconButton>}
                    <div>
                        <span>£</span>
                        <span>{isHostSingleValue ? priceValue : maxPriceValue}</span>
                    </div>
                    {isHostSingleValue && <IconButton onClick={() => changeValue(0.1)}>
                        <AddRoundedIcon htmlColor={'#0090DF'}/>
                    </IconButton>} 
                </div>
            </div>
            <ModalComponent open={openModal} handleClose={handleModalClose} body={modalBody}/>
        </StyledPricePlayInput>
    )
}

export default PricePlayInput;