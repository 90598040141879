import React, { FC, useEffect, useState } from "react";
import { StyledVendingMachineButtons } from "./styles";
import { OutlinedButton, PrimaryButton } from "../../components/Buttons";
import Header from "../../components/Header";
import { useHistory } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import { ALPHABET_HELPER_ARRAY, DIGITS_HELPER_ARRAY } from "./constants";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { GeneralTextField } from "../../components/Inputs";
import { Accordion } from "@material-ui/core";
import { useLongPress, LongPressDetectEvents } from "use-long-press";
import { useDispatch, useSelector } from "react-redux";
import { vendingConfiguredButtons } from "./actions";
import { StyledVendingKeyboard } from "../VendingMachineButtonsConfirmation/styles";
import { getVendingButtons } from "./selectors";
import { socketSentMessage } from "../../components/SocketCommunication/actions";
import { displayMachineDetails } from "../MachineDetailsPage/selectors";
import { getReceivedSocketMessageDetails } from "../../components/SocketCommunication/selectors";
import LoadingComponent from "../../components/Loading";
import { addMachineDetails } from "../MachineDetailsPage/actions";
import { isIOS, isAndroid } from "react-device-detect";
import * as ROUTES from "../../components/Containers/Main/constants";

const VendingMachineButtons: FC = () => {
  const digits = useSelector(getVendingButtons).vendingButtons.digits;
  const letters = useSelector(getVendingButtons).vendingButtons.letters;
  const extraButtons =
    useSelector(getVendingButtons).vendingButtons.extraButtons;
  const machineDetails = useSelector(displayMachineDetails);
  const receivedSocketMessage = useSelector(getReceivedSocketMessageDetails);
  const CoinMecAdptProg = useSelector(displayMachineDetails).CoinMecAdptProg;

  const [hubTagId, setHubTagId] = useState(machineDetails.hubTagId);
  const [externalTagId, setExternalTagId] = useState(
    machineDetails.externalTagId
  );
  const [hubTagPassword, setHubTagPassword] = useState(
    Math.random().toString(36).substring(5)
  );
  const [btnInputValue, setBtnInputValue] = useState("");
  const [customButtonsArray, setCustomButtonsArray] = useState([
    ...extraButtons,
  ]);
  const [lettersArr, setLettersArr] = useState([...letters]);
  const [digitsArr, setDigitsArr] = useState(
    digits.length > 0 ? [...digits] : [...DIGITS_HELPER_ARRAY]
  );
  const [clickCounter, setClickCounter] = useState(
    letters.length > 0
      ? ALPHABET_HELPER_ARRAY.indexOf(lettersArr[lettersArr.length - 1]) + 1
      : 0
  );

  const [enabled, setEnabled] = useState(true);

  const callback = React.useCallback(
    (event) => {
      const targetValue = event.target.innerHTML;

      let myLettersArray = [...lettersArr];
      let myDigitsArray = [...digitsArr];
      let myCustomBtnsArray = [...customButtonsArray];

      let lettersIndex = myLettersArray.indexOf(targetValue);
      let digitsIndex = myDigitsArray.indexOf(targetValue);
      let customBtnsIndex = myCustomBtnsArray.indexOf(targetValue);

      if (lettersIndex !== -1) {
        myLettersArray.splice(lettersIndex, 1);
        setLettersArr(myLettersArray);
        setClickCounter(
          ALPHABET_HELPER_ARRAY.indexOf(
            myLettersArray[myLettersArray.length - 1]
          ) + 1
        );
        return;
      }

      if (digitsIndex !== -1) {
        myDigitsArray.splice(digitsIndex, 1);
        setDigitsArr(myDigitsArray);
        return;
      }

      if (customBtnsIndex !== -1) {
        myCustomBtnsArray.splice(customBtnsIndex, 1);
        setCustomButtonsArray(myCustomBtnsArray);
        return;
      }
    },
    [lettersArr, digitsArr, customButtonsArray]
  );

  const bind = useLongPress(enabled ? callback : null, {
    threshold: 300,
    captureEvent: true,
    detect: LongPressDetectEvents.BOTH,
  });

  const history = useHistory();
  const dispatch = useDispatch();

  const onChangeVtnInputValue: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setBtnInputValue(event.target.value);
  };

  const addNewButtonHandler = () => {
    setCustomButtonsArray((prevState) => [...prevState, btnInputValue]);
    setBtnInputValue("");
  };

  const addLettersHandler = () => {
    setLettersArr((prevState) => [
      ...prevState,
      ALPHABET_HELPER_ARRAY[clickCounter],
    ]);
    setClickCounter((prevState) => prevState + 1);
  };

  const confirmButtons = () => {
    dispatch(
      vendingConfiguredButtons({
        digits: [...digitsArr],
        letters: [...lettersArr],
        extraButtons: [...customButtonsArray],
      })
    );
    history.push(ROUTES.MACHINE_BUTTONS_CONFIRMATION);
  };

  const handleNativeUnitId = (event: any) => {

    if(!isIOS && !isAndroid) {
      return;
    }
    try {
      const unitDetailsFromRN = JSON.parse(event.data);
      const unitPassword = unitDetailsFromRN.unitPassword;
      const nativeExternalTagId = unitDetailsFromRN.externalTagId;
      nativeExternalTagId && setExternalTagId(nativeExternalTagId);
      unitPassword && setHubTagPassword(unitPassword);
    } catch (e) {
      console.warn('Error parsing unitDetailsFromRN, just turn on native app!');
    }
  };

  useEffect(() => {
    if (isIOS) {
      window.addEventListener("message", handleNativeUnitId);
      return () => window.removeEventListener("message", handleNativeUnitId);
    }
    if (isAndroid) {
      document.addEventListener("message", handleNativeUnitId);
      return () => document.removeEventListener("message", handleNativeUnitId);
    }
    window.addEventListener("message", handleNativeUnitId);
    return () => window.removeEventListener("message", handleNativeUnitId);
  }, []);

  useEffect(() => {
    if (externalTagId && externalTagId !== "") {
      dispatch(
        addMachineDetails({
          ...machineDetails,
          externalTagId,
          hubTagPassword,
        })
      );
    }
  }, [hubTagId, externalTagId]);

  const createDigitsKeyboard = digitsArr.map((item, index) => (
    <PrimaryButton key={index} id={item} {...bind}>
      {item}
    </PrimaryButton>
  ));

  const createCustomButtonsKeyboard =
    customButtonsArray.length &&
    customButtonsArray.map((item, index) => (
      <PrimaryButton
        key={index}
        id={item}
        {...bind}
        className={`${item && item.length > 2 && "smaller-font"}`}
      >
        {item}
      </PrimaryButton>
    ));

  const createLettersKeyboard =
    lettersArr.length &&
    lettersArr.map((item, index) => (
      <PrimaryButton key={index} id={item} {...bind}>
        {item}
      </PrimaryButton>
    ));

  return (
    <StyledVendingMachineButtons className="height-controller-wrapper">
      <Header title="Set machine buttons" />
      <div className="helper-screen-description">
        Match the machines selection buttons
      </div>
      <div className="add-section add-letters">
        <AddIcon onClick={addLettersHandler} />
        <span>Add letters</span>
      </div>
      {lettersArr.length > 0 && (
        <StyledVendingKeyboard className="letters-keyboard">
          {createLettersKeyboard}
        </StyledVendingKeyboard>
      )}
      {digitsArr.length > 0 && (
        <StyledVendingKeyboard className="digits-keyboard">
          {createDigitsKeyboard}
        </StyledVendingKeyboard>
      )}
      {customButtonsArray.length > 0 && (
        <StyledVendingKeyboard className="custom-btns-keyboard">
          {createCustomButtonsKeyboard}
        </StyledVendingKeyboard>
      )}
      <Accordion>
        <AccordionSummary
          expandIcon={<AddIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <span className="extra-btns-label">
            Add extra feature buttons and edit description
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <form className="add-new-button-form">
            <GeneralTextField
              value={btnInputValue}
              placeholder="Set text button"
              onChange={onChangeVtnInputValue}
            />
            <PrimaryButton
              onClick={addNewButtonHandler}
              disabled={!btnInputValue ? true : false}
            >
              Add button
            </PrimaryButton>
          </form>
        </AccordionDetails>
      </Accordion>

      <div className="actions-wrapper">
        <OutlinedButton
          className="back-button"
          onClick={() => {
            history.goBack();
          }}
        >
          Back
        </OutlinedButton>
        <PrimaryButton className="continue-button" onClick={confirmButtons}>
          Continue
        </PrimaryButton>
      </div>
    </StyledVendingMachineButtons>
  );
};

export default VendingMachineButtons;
