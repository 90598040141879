import React, { FC } from "react";
import {StyledClientPage} from "../ClientPage/styles";
import MachineType from "../SitePage/MachineTypeSection";
import Header from "../../components/Header";
import SummaryHeader from "../../components/SummaryHeader";
import {useDispatch, useSelector} from "react-redux";
import {getClientsListData, getClientsListError, getClientsListLoading} from "../AllClientsSitesPage/selectors";
import LoadingComponent from "../../components/Loading";
import {NotificationError} from "../../components/NotificationMessages";
import {IClientItem, ISiteDetailsData, IUnitDetails} from "../AllClientsSitesPage/model";
import {OutlinedButton} from "../../components/Buttons";
import {useHistory, useParams} from "react-router-dom";
import * as ROUTES from "../../components/Containers/Main/constants";
import { displayMachineDetails } from "../MachineDetailsPage/selectors";
import { displaySiteDetailsFailure, displaySiteDetailsSuccess } from "../AllClientsSitesPage/actions";
import {addMachineDetails} from "../MachineDetailsPage/actions";
import {asyncRequest} from "../../api";


const TehnicianClientPage = () => {
  const clientDetails = useSelector(getClientsListData);
  const errorClientDetails = useSelector(getClientsListError);
  const loadingClientDetails = useSelector(getClientsListLoading);
    
  const dispatch = useDispatch();
  const machineDetails = useSelector(displayMachineDetails);

  const { id } = useParams();
  const client = clientDetails.items.filter((client: IClientItem) => client.id == id)[0];
  const history = useHistory();
  const sites = !!client && !!client.sites && !!client.sites.length && 
    client.sites.map((item: ISiteDetailsData | undefined, index: number) => (
        <div key={`${index}-site`}>
          <div onClick={() => onClcikItemHandler(item.id)}>
            <SummaryHeader isMenuDisplayed={false} site={item} isClientPage={true} isArrowDisplayed={false}/>
          </div>
          {!!item && !!item.units && !!item.units.length &&
          item.units.map((machine: IUnitDetails, index: number) =>
            <MachineType key={index} itemDetails={machine} hasMenu={false} site={item} isClientPage={true}/>
          )};
        </div>
    ));

  const onClcikItemHandler = (id: number) => {
    asyncRequest({
        config:{
          method: "GET",
        },
        endpoint: `api/site/${id}`,
      }).then(response => {
        dispatch(displaySiteDetailsSuccess(response));
      })
      .then(() => {
        dispatch(addMachineDetails({
          ...machineDetails,
          siteId: id,
        }));
      })
      .then(() => {
        history.push(`${ROUTES.TEHNICIAN_SITE_PAGE}/${id}`);
      })
        .catch(err => {
          console.log(err);
          dispatch(displaySiteDetailsFailure(err));
        });
  }

  return (
    <StyledClientPage className="height-controller-wrapper">
      {loadingClientDetails && <LoadingComponent/>}
      {errorClientDetails && <NotificationError message={errorClientDetails} messageClass="error"/>}
      <Header title="Select site for new machine"/>
      {sites}
      <div className="actions-wrapper">
        <OutlinedButton className="back-button" onClick={() => {history.goBack()}}>Back</OutlinedButton>
      </div>
    </StyledClientPage>
  );
};

export default TehnicianClientPage;
