import styled from 'styled-components';
import paymeTheme from "../../assets/styling/payme-theme";
import media from "../../assets/styling/payme-media-queries";

const StyledSummaryHeader = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: ${paymeTheme.paddingRem};
  padding-right: 0.625rem;
  border-bottom: 1px solid;
  position: relative;
  
  .MuiSvgIcon-root {
    fill: ${paymeTheme.floteColor};
  }
  
  .site-menu {
    position: absolute;
    right: 0.5rem;
    .MuiSvgIcon-root {
      width: 30px;
      height: 30px;
    }
  }
  
  .site-header {
    display: flex;
    padding-bottom: ${paymeTheme.paddingRem};
    width: 100%;
  }
  
  .site-name,
  .client-name {
    font-size: ${paymeTheme.fontRemSizeH5};
    word-break: break-word;
  }
  
  .client-name,
  .site-and-client-name {
    max-width: 48%;
  }
  
  .site-name {
    color: ${paymeTheme.colorPurple};
  }

  .off-site-name {
    color: ${paymeTheme.colorPurple};
    font-size: ${paymeTheme.fontRemSizeH4};
    position: relative;
    right: 0;
    left: 0;
    margin: auto;
  }

  .off-site-details-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &.off-site-header {
    padding: ${paymeTheme.paddingRemXs} ${paymeTheme.paddingRem};

    .site-header {
      padding: ${paymeTheme.paddingRemXs} 0;
    }
  }

  ${media.desktop} {
    &.off-site-header {
      padding-top: 1.75rem;
    }  
  }
  
  .client-name {
    color: ${paymeTheme.colorBlack};
    font-weight: ${paymeTheme.fontBold};
    padding-right: ${paymeTheme.paddingRem};
  }
  
  .site-details {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    .site-detail {
      display: flex;
      padding-right: ${paymeTheme.paddingRemSm};
      flex-wrap: wrap;
    }
  }

  .row-details{
    display: flex;
  }

  .site-details-wrapper {
    width: 100%;
  }
  
  ${media.tablet} {
    justify-content: center;
    width: 30%;
    margin: 0 auto;
    padding-top: 2.5rem;
  }

`;

export { StyledSummaryHeader } ;
