import React, {FC, useEffect, useState} from "react";
import {StyledClientsList} from "./styles";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import SitesAccordion from "./ExpandSites";
import {GeneralTextField} from "../../../components/Inputs";
import {asyncRequest} from "../../../api";
import {IClientItem, ISitesItem} from "../model";
import {NotificationError} from "../../../components/NotificationMessages";
import { useHistory } from "react-router-dom";
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import * as ROUTES from "../../../components/Containers/Main/constants";
import { getFlowType } from "../../../components/Containers/Main/selectors";
import { useSelector } from "react-redux";

interface Props {
  clientsList: IClientItem[];
  addNewEntity: () => void;
  isTechnician: boolean;
}

const ClientsList: FC<Props> = ({clientsList, addNewEntity, isTechnician}) => {
  const history = useHistory();
  const [clientsListSearch, setClientsListSearch] = useState([]);
  const [totalResults, setTotalResults] = useState(-1);
  const [searchClientsError, setSearchClientsError] = useState('');
  const flowType = useSelector(getFlowType);
  const isChangeSiteFlow = flowType.isChangeSiteFlow;

  const onClickListItemHandler = (id: number) => {
    if (isChangeSiteFlow) {
      history.push(`${ROUTES.CLIENT_SITES_LIST_PAGE}/${id}`);
    } else {
      if (isTechnician) { 
        history.push(`${ROUTES.TEHNICIAN_CLIENT_PAGE}/${id}`);
      } else {
        history.push(`${ROUTES.CLIENT_PAGE}/${id}`);
      }
    }
  };

  const onClickListItemHandlerItems = (id: number, sites: ISitesItem[]) => {
    if (sites.length === 1) {
      onClickListItemHandler(id);
    }
  }

  const [sortClientsList, setSortClientsList] = useState(clientsList);

  const sortByName = (clients) => {
    const sorted = [...clients].sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    return sorted;
  };

  useEffect (() => {
    if (clientsList) {
      setSortClientsList(sortByName(clientsList));
    }
  }, [clientsList]);

  const buildClientItem = (item: IClientItem, index: number) => {
    return <div key={index} className="item-wrapper">
      <div className="client-name item-name" onClick={() => onClickListItemHandler(item.id)}>{item.name}</div>
      <div className="sites-town-wrapper" onClick={() => onClickListItemHandlerItems(item.id, item.sites)}>
        <div className={item.sites && item.sites.length > 1 ?
          "sites-wrapper item-sites item-sites-multiple" :
          "sites-wrapper item-sites"}>
          <SitesAccordion sites={item.sites}/>
        </div>
      </div>
      {item.sites && item.sites.length === 1 && <div className="item-town" onClick={() => onClickListItemHandler(item.id)}>{item.sites[0].town}</div>}
      <ArrowForwardIosIcon onClick={() => onClickListItemHandler(item.id)}/>
    </div>
  };

  const onSearchHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    asyncRequest({
      config:{
        method: "GET",
        params: {
          search: event.target.value
        }
      },
      endpoint: `api/client`,
    }).then(response => {
      setClientsListSearch(sortByName(response.items));
      setTotalResults(response.total);
    })
      .catch(err => {
        setSearchClientsError(err.message);
        console.log(err);
      });
  };

  return (
    <StyledClientsList className="height-controller-wrapper">
      <div className="add-search-wrapper">
        {!isTechnician && <div className="add-new-client" onClick={addNewEntity}><AddIcon/><span>Add new</span></div>}
        <div className="search-section">
          <GeneralTextField id="clients-search"
                              placeholder="Search client"
                              onChange={onSearchHandler}
          />
          <SearchIcon />
        </div>
      </div>
      {totalResults === -1 && clientsList.length &&
      sortClientsList.map((item: IClientItem, index: number) => (buildClientItem(item, index)))}
      {totalResults > 0 && clientsListSearch.length &&
      clientsListSearch.map((item: IClientItem, index: number) => (buildClientItem(item, index)))}
      {totalResults === 0 && <div>No results found</div>}
      {searchClientsError && <NotificationError message={searchClientsError} messageClass="error"/>}
    </StyledClientsList>
  );
};

export default ClientsList;
