export const ACCOUNTS_ENDPOINT = "accounts/";
export const ACCOUNTS_REQUEST = "ACCOUNTS_REQUEST";
export const ACCOUNTS_SUCCESS = "ACCOUNTS_SUCCESS";
export const ACCOUNTS_FAILURE = "ACCOUNTS_FAILURE";
export const ADD_ACCOUNT_REQUEST = "ADD_ACCOUNT_REQUEST";
export const ADD_ACCOUNT_SUCCESS = "ADD_ACCOUNT_SUCCESS";
export const ADD_ACCOUNT_FAILURE = "ADD_ACCOUNT_FAILURE";
export const DELETE_ACCOUNT_REQUEST = "DELETE_ACCOUNT_REQUEST";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILURE = "DELETE_ACCOUNT_FAILURE";
