import React, { FC, useEffect, useState } from "react";
import { StyledCollectorMachineOptions } from "./styles";
import Header from "../../components/Header";
import { OutlinedButton, PrimaryButton } from "../../components/Buttons";
import { useHistory } from "react-router-dom";
import SummaryHeader from "../../components/SummaryHeader";
import { asyncRequest } from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { displayMachineDetails } from "../MachineDetailsPage/selectors";
import {
  displaySiteDetailsFailure,
  displaySiteDetailsSuccess,
} from "../AllClientsSitesPage/actions";
import MachineType from "../SitePage/MachineTypeSection";
import LoadingComponent from "../../components/Loading";
import { addMachineDetails } from "../MachineDetailsPage/actions";
import {isAndroid, isIOS} from "react-device-detect";
import * as ROUTES from "../../components/Containers/Main/constants";
import { setFlowType } from "../../components/Containers/Main/actions";
import { getFlowType } from "../../components/Containers/Main/selectors";

const UnitSummarySetup: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const machineDetails = useSelector(displayMachineDetails);
  const flowType = useSelector(getFlowType);
  const [unitId, setUnitId] = useState(machineDetails.hubTagId);
  const [isLoading, setIsLoading] = useState(true);
  const [isUnitRegistered, setIsUnitRegistered] = useState(true);
  const [redirectTo, setRedirectTo] = useState('');
  const [machineItemDetails, setMachineItemDetails] = useState({
    id: -1,
    hostType: {
      hostTypeId: -1,
      name: "",
      iconUrl: "",
      hostCategory: {
        id: -1,
        name: "",
        code: "",
      },
    },
    QrCodeId: "",
    hubTagId: "",
    lastSeen: "",
    position: "",
    manufacturer: { id: -1, name: "" },
    unitPowerSource: { id: -1, code: "", name: "" },
    unitStatus: { id: -1, code: "", name: "" },
  });

  const handleNativeUnitId = (event: any) => {
    if (!isIOS && !isAndroid) {
      return;
    }
    try {
      const unitDetailsFromRN = JSON.parse(event.data);
      const unitId = unitDetailsFromRN.unitID;
      const redirectTo = unitDetailsFromRN.redirectTo;
      unitId && setUnitId(unitId);
      redirectTo && setRedirectTo(redirectTo);
    } catch (e) {
      console.warn('Error parsing unitDetailsFromRN, just turn on native app!');
    }
  };

  useEffect(() => {
    redirectTo === 'HOME' && history.push(ROUTES.HOME);
  }, [redirectTo]);

  useEffect(() => {
    if (isIOS) {
      window.addEventListener("message", handleNativeUnitId);
      return () => window.removeEventListener("message", handleNativeUnitId);
    }
    if (isAndroid) {
      document.addEventListener("message", handleNativeUnitId);
      return () =>
        document.removeEventListener("message", handleNativeUnitId);
    }
    window.addEventListener("message", handleNativeUnitId);
    return () => window.removeEventListener("message", handleNativeUnitId);
  }, []);

  useEffect(() => {
    if (unitId && unitId !== "") {
      asyncRequest({
        config: {
          method: "GET",
        },
        endpoint: `api/unit/${unitId}`,
      })
        .then((response) => {
          setIsLoading(false);
          dispatch(displaySiteDetailsSuccess({ ...response.siteDetailsDto }));
          setMachineItemDetails({
            ...machineDetails,
            id: response.id,
            QrCodeId: response.qrCodeId,
            hostType: {...response.hostType},
            hubTagId: response.hubTagId,
            lastSeen: response.lastSeen,
            position: response.position,
            manufacturer: {...response.manufacturer},
            unitPowerSource: {...response.unitPowerSource},
            unitStatus: {...response.unitStatus},
          });
          dispatch(setFlowType({
            ...flowType,
            isUnitRegistered: true
          }))
        })
        .catch((err) => {
          console.log(err);
          err && err.response && err.response.status === 404 && setIsUnitRegistered(false);
          setMachineItemDetails(prevState => ({
            ...prevState,
            hubTagId: unitId
          }));
          dispatch(setFlowType({
            ...flowType,
            isUnitRegistered: false
          }));
          //dispatch(displaySiteDetailsFailure(err));
          setIsLoading(false);
        });
    }
  }, [unitId]);

  const continueToSelectSiteHandler = () => {
    dispatch(addMachineDetails({...machineItemDetails}));
    history.push(ROUTES.SETUP_ALL_CLIENTS_SITES);
  };
  return (
    <StyledCollectorMachineOptions className="height-controller-wrapper">
      <Header title="Unit details" />
      {
        !!isUnitRegistered && !isLoading &&
          <div className="content-wrapper">
            <div className="helper-screen-description">Current unit machine set up</div>
            <SummaryHeader isMenuDisplayed={false}/>
            <MachineType itemDetails={machineItemDetails} hasMenu={false}/>
            <div className="helper-screen-description">Continue to change set up</div>
          </div> }
      { !isUnitRegistered && !isLoading && <div className="unidentified-unit-wrapper">
          <div className="unidentified-unit-title">Unregistered unit</div>
          <div>Continue for setup</div>
        </div>
      }
      <div className="actions-wrapper">
        <OutlinedButton
          className="back-button"
          onClick={() => {
            history.push(ROUTES.HOME);
          }}
        >
          Back
        </OutlinedButton>
          <PrimaryButton onClick={continueToSelectSiteHandler}>
            Continue
          </PrimaryButton>
      </div>
      {isLoading && <LoadingComponent />}
    </StyledCollectorMachineOptions>
  );
};

export default UnitSummarySetup;
