import styled from "styled-components";
import media from "../../../assets/styling/payme-media-queries";

const StyledTownList = styled.div`
 .sites-town-wrapper {
   display: flex;
   flex-grow: 1;
   justify-content: space-between;
   padding: 0 16px;
   .sites-wrapper,
   .item-town {
     max-width: 50%;
     width: auto;
   }

   .sites-wrapper {
     &.item-sites-multiple {
       max-width: 100%;
       flex-grow: 1;
     }
   }
 }

 ${media.desktop} {
 }
`;

export { StyledTownList };