import {getCurrentDateFullYear} from "../../utils/getCurrentDate";

export const ADD_NEW_MEMBER_REQUEST = "ADD_NEW_MEMBER_REQUEST";
export const ADD_NEW_MEMBER_SUCCESS = "ADD_NEW_MEMBER_SUCCESS";
export const ADD_NEW_MEMBER_FAILURE = "ADD_NEW_MEMBER_FAILURE";

export const DISPLAY_USER_DETAILS = "DISPLAY_USER_DETAILS";
export const INITIAL_MEMBER_DETAILS = {
  userId: '',
  firstName: '',
  lastName: '',
  emailAddress: '',
  imageUrl: null,
  image: null,
  imageName: null,
  startDate: getCurrentDateFullYear(),
  terminationDate: null,
  authorities: []
};

export const USER_DETAILS_ATTEMPT = 'USER_DETAILS_ATTEMPT';
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const USER_DETAILS_FAILURE = 'USER_DETAILS_FAILURE';
