import {
  ADD_MACHINE_DETAILS,
  GET_HOST_TYPE_FAILURE,
  GET_HOST_TYPE_REQUEST,
  GET_HOST_TYPE_SUCCESS,
  GET_UNIT_BATTERY_POWERED_FAILURE,
  GET_UNIT_BATTERY_POWERED_REQUEST,
  GET_UNIT_BATTERY_POWERED_SUCCESS,
  GET_UNIT_MANUFACTURER_FAILURE,
  GET_UNIT_MANUFACTURER_REQUEST,
  GET_UNIT_MANUFACTURER_SUCCESS, MACHINE_DETAILS_FAILURE, MACHINE_DETAILS_REQUEST, MACHINE_DETAILS_SUCCESS
} from "./constants";
import {IMachineState} from "./model";


// add machine details actions

export const addMachineDetails = (data: IMachineState) => ({
  type: ADD_MACHINE_DETAILS,
  payload: {...data}
});

export const addMachineDetailsAttempt = (data: any) => ({
  type: MACHINE_DETAILS_REQUEST,
  payload: {...data}
});

export const addMachineDetailsSuccess = (data: any) => ({
  type: MACHINE_DETAILS_SUCCESS,
  payload: { ...data},
});

export const addMachineDetailsFailure = (error: any) => ({
  type: MACHINE_DETAILS_FAILURE,
  payload: error
});

// unit host type actions

export const displayUnitHostTypeAttempt = () => ({
  type: GET_HOST_TYPE_REQUEST,
});

export const displayUnitHostTypeSuccess = (data: any) => ({
  type: GET_HOST_TYPE_SUCCESS,
  payload: { ...data},
});

export const displayUnitHostTypeFailure = (error: any) => ({
  type: GET_HOST_TYPE_FAILURE,
  payload: error
});


// unit manufacturer actions

export const displayUnitManufacturerAttempt = (data: any) => ({
  type: GET_UNIT_MANUFACTURER_REQUEST,
  payload: data
});

export const displayUnitManufacturerSuccess = (data: any) => ({
  type: GET_UNIT_MANUFACTURER_SUCCESS,
  payload: { ...data},
});

export const displayUnitManufacturerFailure = (error: any) => ({
  type: GET_UNIT_MANUFACTURER_FAILURE,
  payload: error
});

// unit battery powered actions

export const displayUnitBatteryPoweredAttempt = () => ({
  type: GET_UNIT_BATTERY_POWERED_REQUEST,
});

export const displayUnitBatteryPoweredSuccess = (data: any) => ({
  type: GET_UNIT_BATTERY_POWERED_SUCCESS,
  payload: { ...data},
});

export const displayUnitBatteryPoweredFailure = (error: any) => ({
  type: GET_UNIT_BATTERY_POWERED_FAILURE,
  payload: error
});

// unit credit type actions

export const displayUnitCreditTypeAttempt = () => ({
  type: GET_UNIT_BATTERY_POWERED_REQUEST,
});

export const displayUnitCreditTypeSuccess = (data: any) => ({
  type: GET_UNIT_BATTERY_POWERED_SUCCESS,
  payload: { ...data},
});

export const displayUnitCreditTypeFailure = (error: any) => ({
  type: GET_UNIT_BATTERY_POWERED_FAILURE,
  payload: error
});

