import React, {FC, useEffect, useState} from "react";
import {StyledCollectionHistoryCard} from "./styles";
import LayersIcon from '@material-ui/icons/Layers';
import {convertDateFullMonthLetters, getCurrentDate} from "../../../utils/getCurrentDate";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {useDispatch, useSelector} from "react-redux";
import {displayTotalCashCollected} from "../../SiteCollection/selectors";
import {addTotalCashCollected} from "../../SiteCollection/actions";
import {startDateArrayReturned} from "../../../utils/extractStartDateArr";
import { FiberManualRecord } from "@material-ui/icons";

interface Props {
  collectionHistoryItem: any;
}


const CollectionHistoryCard:FC<Props> = ({collectionHistoryItem}) => {
  const dispatch = useDispatch();
  const totalCashCollected = useSelector(displayTotalCashCollected);
  const [collectionCardStartDate, setCollectionCardStartDate] = useState('');
  const [startDateArrState, setStartDateArrState] = useState([]);
  let totalContactlessMoneyGross = 0;
  let totalEstimatedCashAmount = 0;
  let totalGrossMachineIncome = 0;
  let actualCashCollected = 0;
  const [totalCashCollectedPerItem, setTotalCashCollectedPerItem] = useState();

  useEffect(() => {
    if (collectionHistoryItem.collections.length > 0) {
      collectionHistoryItem.collections.forEach(collection => {
        actualCashCollected += collection.actualCashCollected;
        totalContactlessMoneyGross += collection.grossContactless;
        totalEstimatedCashAmount += collection.estimatedCashAmount;
        totalGrossMachineIncome += collection.grossCashCollected;
      });
      setTotalCashCollectedPerItem({
        actualCashCollected: actualCashCollected,
        totalContactlessMoneyGross: totalContactlessMoneyGross,
        totalEstimatedCashAmount: totalEstimatedCashAmount,
        totalGrossMachineIncome: totalGrossMachineIncome,
      });
    }
  },[]);

  useEffect(() => {
    if (collectionHistoryItem.collections) {
      setStartDateArrState(startDateArrayReturned(collectionHistoryItem.collections));
    }
  }, [collectionHistoryItem.collections]);

  useEffect(() => {
    if (startDateArrState && startDateArrState.length) {
      setCollectionCardStartDate(convertDateFullMonthLetters('.', new Date(Math.min.apply(null, startDateArrState)), 'short'));
    }
  }, [startDateArrState]);

  const mappedGroups = collectionHistoryItem && collectionHistoryItem.groups && collectionHistoryItem.groups > 0 &&
    collectionHistoryItem.groups.map((group, index) => {
      return <span key={`group-${index}`}>{group};</span>
    });

  return (
    <StyledCollectionHistoryCard>
      <div className="card-wrapper">
        <div className="collection-header">
          <div className="header-content">
            <div className="title">
              <span className="client-name">{collectionHistoryItem && collectionHistoryItem.clientName  ? collectionHistoryItem.clientName : ""}</span>
              <span className="site-name">{collectionHistoryItem.siteName}</span>
              {collectionHistoryItem && collectionHistoryItem.town &&
              <span className="site-town">{collectionHistoryItem.town}</span>  }
            </div>
            {collectionHistoryItem.groups && collectionHistoryItem.groups > 0 &&
            <div className="site-group"><LayersIcon/>{mappedGroups}</div>}
          </div>
        </div>
        <div className="collection-card-header">
          <div className="period">Collection receipt - {collectionCardStartDate} - {convertDateFullMonthLetters('.', new Date(), 'short')}</div>
        </div>
        <div className="collection-card-body">
          <div className="body-content">
            <div className="column-label-wrapper">
              <div className="row-label">Estimate cash amount</div>
              <div className="row-label">Actual cash collected</div>
              <div className="row-label">Contactless money gross</div>
              <div className="row-label">Gross machine Income</div>
            </div>
            <div>
              {totalCashCollectedPerItem && 
                <div className="price-wrapper">
                  <FiberManualRecord />
                  <div className="row-value">£({totalCashCollectedPerItem.totalEstimatedCashAmount ? totalCashCollectedPerItem.totalEstimatedCashAmount : 0})</div>
                </div>
              }
              {totalCashCollectedPerItem && 
                <div className="price-wrapper">
                  <FiberManualRecord />
                  <div className="row-value total-cash-collected">
                    £
                  <span className="align-row-wrappers">(</span>
                  {totalCashCollected.actualCashCollected}
                  </div>
                </div>
              }
              {totalCashCollectedPerItem && 
                <div className="price-wrapper">
                  <FiberManualRecord />
                  <div className="row-value">
                    £
                  <span className="align-row-wrappers">(</span>
                  {totalCashCollected.totalContactlessMoneyGross}
                  </div>
                </div>
              }
              {totalCashCollectedPerItem && 
                <div className="price-wrapper">
                  <FiberManualRecord />
                  <div className="row-value">
                    £
                  <span className="align-row-wrappers">(</span>
                  {totalCashCollected.totalGrossMachineIncome}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="svg-wrapper">
        <ArrowForwardIosIcon/>
      </div>
    </StyledCollectionHistoryCard>
  );
};

export default CollectionHistoryCard;
