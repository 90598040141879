import styled from 'styled-components';
import media from "../../../assets/styling/payme-media-queries";
import paymeTheme from "../../../assets/styling/payme-theme";

const StyledMachineType = styled.div `
  display: flex;
  justify-content: center;
  margin: auto;
  padding: ${paymeTheme.paddingRem} 0;
  margin: 0 ${paymeTheme.paddingRem};
  margin-right: 0.625rem;
  border-bottom: 1px solid ${paymeTheme.borderGrey};
  
  .MuiSvgIcon-root {
    fill: ${paymeTheme.floteColor};
  }
  
  .site-menu {
    .MuiSvgIcon-root {
      width: 30px;
      height: 30px;
    }
  }
  
  .machine-type-header {
    display: flex;
    align-items: baseline;
    padding-bottom: ${paymeTheme.paddingRemSm};
  }
  
  .machine-type-wrapper {
    padding-left: ${paymeTheme.paddingRemSm};
  }
  
  .machine-type-name {
    color: ${paymeTheme.floteColor};
    padding-right: ${paymeTheme.paddingRem};
    font-size: ${paymeTheme.fontRemSizeLg};
  }
  
  .machine-type-details {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    font-size: ${paymeTheme.fontRemSizeSmall};
    .machine-type-detail {
      display: flex;
      padding-right: ${paymeTheme.paddingRemSm};
      
      &.machine-type-battery {
        color: ${paymeTheme.errorColor};
        padding: ${paymeTheme.paddingRemXs} 0;
      }
    }
  }
  
  .machine-type-status {
    font-size: ${paymeTheme.fontRemSizeLg};
    color: #178900;
    &.deactivated {
      color: ${paymeTheme.errorColor};
    }
  }
  
  ${media.desktop} {
    max-width: 50%;
    width: fit-content;
    margin: 0 auto;
  }

`;

export { StyledMachineType } ;
