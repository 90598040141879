import React, {FC} from "react";
import {ImportantNotification} from "../../../components/NotificationMessages";
import TotalCard from "./TotalCard";
import {StyledMerchantHome} from "./styles";
import MachineTypesCard from "./MachineTypesCard";
import QrNfcHomeSection from "../QrNfcHomeSection";
import {ROLE_MASTER} from "../../../constants";

interface Props {
  isDesktop: boolean;
  userRole: string;
}
const MerchantHome: FC<Props> = ({isDesktop, userRole}) => {

  return (
    <StyledMerchantHome>
      <ImportantNotification message='Hilighted important information'/>
      {userRole === ROLE_MASTER && <QrNfcHomeSection isDesktop={isDesktop} userRole={userRole}/>}
    </StyledMerchantHome>
  );
};

export default MerchantHome;
