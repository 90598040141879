import withStyles from "@material-ui/core/styles/withStyles";
import TextField, {TextFieldProps} from "@material-ui/core/TextField/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {createStyles, OutlinedInputProps, Theme} from "@material-ui/core";
import React from "react";
import paymeTheme from "../../assets/styling/payme-theme";

export const LoginInput = withStyles({
  root: {
    backgroundColor: paymeTheme.colorWhite,
    marginBottom: '10px',
    borderRadius: '5px',
    width: '250px',

    '& .MuiInputBase-root': {
      padding: '5px 10px',
      fontSize: paymeTheme.fontRemSizeBase
    },

    '& .MuiFilledInput-root': {
      borderBottom: 'none',
      backgroundColor: paymeTheme.colorWhite,
    },

    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: paymeTheme.floteColor,
    },
    '& .MuiInput-underline.Mui-error:after': {
      borderBottomColor: '#f44336',
    }
  },
})(TextField);

// default input

const useStylesGeneralInput = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: paymeTheme.colorBlack,
      height: '5vh',
      maxHeight: '40px',
      minHeight: '25px',
      border: `1px solid ${paymeTheme.borderGrey}`,
      overflow: 'hidden',
      borderRadius: 4,
      backgroundColor: paymeTheme.colorWhite,
      fontFamily: `"Exo 2"!important`,
      fontSize: `${paymeTheme.fontRemSizeNormal}!important`,
      minWidth: '250px',
      // '&:hover': {
      //   backgroundColor: paymeTheme.colorWhite,
      // },
      '&$focused': {
        backgroundColor: paymeTheme.colorWhite,
        borderColor: paymeTheme.floteColor,
      },
      '&.Mui-error': {
        borderColor: paymeTheme.errorColor,
      },
    },
    focused: {},
  }),
);

export const GeneralTextField = (props: TextFieldProps) => {
  const classes = useStylesGeneralInput();

  return (
    <TextField
      InputProps={{ classes, disableUnderline: true } as Partial<OutlinedInputProps>}
      {...props}
    />
  );
};
