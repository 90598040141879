const paymeTheme = {
    //px Paddings
    padding: '20px',
    paddingXLg: '40px',
    paddingLg: '30px',
    paddingMd: '15px',
    paddingSm: '10px',
    paddingXs: '8px',
    //rem Paddings
    paddingRem: '1.25rem',
    paddingRemXXLg: '4rem',
    paddingRemXLg: '2.5rem',
    paddingRemLg: '1.875rem',
    paddingRemMd: '0.9rem',
    paddingRemSm: '0.6rem',
    paddingRemXs: '0.5rem',
    //Brand Colors
    warningColor: '#FF8A02',
    colorBlack: '#000000',
    colorWhite: '#fff',
    errorColor: '#E50000',
    successColor: '#6FBC14',
    greenStatusColor: '#178900',
    colorDarkGray: 'grey',
    footerBackground: '#0B2D5A',
    floteColor: '#0090DF',
    headerColor: '#0B2D5A',
    customColor: '#7F7F7F',
    lightGrey: '#D3D3D3',
    borderGrey: '#D5D5D5',
    colorPurple: '#e200e2',
    colorYellow: 'yellow',
    //vh height
    heightXLg: '50vh',
    heightLg: '20vh',
    heightMd: '13vh',
    heightSm: '7vh',
    heightXs: '5vh',
    //vw width
    widthXLg: '75vw',
    widthLg: '50vw',
    widthMd: '40vw',
    widthSm: '20vw',
    widthXs: '10vw',
    //px Font
    fontSizeBase: '14px',
    fontSizeLg: '18px',
    fontSizeSmall: '13px',
    fontSizeXs: '10px',
    fontSizeNormal: '17px',
    fontSizeH1: '55px',
    fontSizeH2: '36px',
    fontSizeH3: '32px',
    fontSizeH4: '28px',
    fontSizeH5: '22px',
    fontSizeH6: '20px',
    fontLight: '300',
    fontRegular: '400',
    fontRegularItalic: 'italic',
    fontSemiBold: '600',
    fontBold: '700',
    fontExtraBold: '800',
    //rem Font
    fontRemSizeXLg: '1.5rem',
    fontRemSizeLg: '1.125rem',
    fontRemSizeBase: '0.875rem',
    fontRemSizeSmall: '0.813rem',
    fontRemSizeXs: '0.625rem',
    fontRemSizeNormal: '1rem',
    fontRemSizeH1: '3.4rem',
    fontRemSizeH2: '2.25rem',
    fontRemSizeH3: '2rem',
    fontRemSizeH4: '1.75rem',
    fontRemSizeH5: '1.375rem',
    fontRemSizeH6: '1.25rem',
    //Line height:
    lineHeightRemNormal: '1rem',
    //Input
    inputHeight: '39px',
    //Desktop Medium 
    sideBarWidth: '4rem',
    listItemPadding: '5.25rem',
    //top positioning
    topMd: '35px',
    topLg: '70px'
};

export default paymeTheme;
