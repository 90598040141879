import React, {useEffect, useState} from "react";
import {StyledListingCompanyGroups} from "./styles";
import Header from "../../components/Header";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {OutlinedButton, PrimaryButton} from "../../components/Buttons";
import {GeneralTextField} from "../../components/Inputs";
import {asyncRequest} from "../../api";
import GroupCardComponent from "./GroupCard";
import {addSelectedGroupDetails} from "./actions";
import {getSelectedGroupData} from "./selectors";
import * as ROUTES from "../../components/Containers/Main/constants";

const ListingCompanyGroups = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [newGroupName, setNewGroupName] = useState('');
  const [groupsList, setGroupsList] = useState([]);
  const [isEmptyField, setIsEmptyField] = useState(false);
  const selectedGroupData = useSelector(getSelectedGroupData);

  const sortByName = (groups) => {
    const sorted = [...groups].sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    return sorted;
  };

  const onClickListItemHandler = (id: number) => {
    asyncRequest({
      config:{
        method: "GET",
      },
      endpoint: `api/group/${id}/sites`,
    }).then(response => {
      history.push(`${ROUTES.GROUP_DETAILS}/${id}`);
      const selectedGroup = groupsList.filter(item => item.id === id)[0];
      groupsList.map(item => {
        if (item.id === id) {
          dispatch(addSelectedGroupDetails({
            ...selectedGroupData,
            id: selectedGroup.id,
            name: selectedGroup.name,
            groupType: selectedGroup.groupType,
            partyId: selectedGroup.partyId,
            sites: response,
          }));
        }
      })
    })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    asyncRequest({
      config:{
        method: "GET",
      },
      endpoint: 'api/group/company',
    }).then(response => {
      const groupsSorted = sortByName(response);
      setGroupsList(groupsSorted);
    })
      .catch(err => {
        console.log(err);
      });
  },[]);

  const addNewGroupHandler = () => {
    if (newGroupName !== '') {
      setIsEmptyField(false);
      asyncRequest({
        config:{
          method: "POST",
          data: {
            name: newGroupName,
            groupType: 'SITE'
          }
        },
        endpoint: 'api/group',
      }).then(response => {
        setNewGroupName('');
        const groupsSorted = sortByName([...groupsList, response]);
        setGroupsList(groupsSorted);
      })
        .catch(err => {
          console.log(err);
        });
    } else {
      setIsEmptyField(true);
    }
  };

  const groupsMappedList = groupsList && groupsList.map((item, index) =>
    <GroupCardComponent key={index}
                        item={item}
                        onClickHandler={() => onClickListItemHandler(item.id)}/>);

  const onChangeGroupNameHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setNewGroupName(event.target.value);
    setIsEmptyField(false);
  };

  return (
    <StyledListingCompanyGroups className="height-controller-wrapper">
      <Header title="Groups"/>
      <form className="add-new-group-form">
        <GeneralTextField id="new-group-name"
                          value={newGroupName}
                          variant="filled"
                          placeholder="New group name"
                          error={isEmptyField}
                          helperText={ isEmptyField && "Please fill the group name"}
                          onChange={onChangeGroupNameHandler}
        />
        <PrimaryButton onClick={addNewGroupHandler}>Add</PrimaryButton>
      </form>
      {groupsMappedList}
      <div className="actions-wrapper">
        <OutlinedButton onClick={() => {history.goBack()}}>Back</OutlinedButton>
      </div>
    </StyledListingCompanyGroups>
  );
};

export default ListingCompanyGroups;
