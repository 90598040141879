import styled from 'styled-components';
import paymeTheme from '../../assets/styling/payme-theme';


const StyledNfcSection = styled.div `
  text-align: center;
  width:50%;
  svg {
    width: ${paymeTheme.widthXLg};
    height: ${paymeTheme.heightLg};
    max-width: 130px;
  }

`;

export { StyledNfcSection } ;

