import styled from 'styled-components';
import media from "../../../assets/styling/payme-media-queries";
import paymeTheme from "../../../assets/styling/payme-theme";




const StyledEditTeamMember = styled.div `
  
 
  ${media.desktop} {
    .new-team-member-form {
      padding-top: 2.5rem;
    }
  }

`;

export { StyledEditTeamMember } ;
