import React, {FC, useEffect, useState} from "react";
import {StyledMachineCollectionCardDetails} from "./styles";
import SportsBaseballIcon from '@material-ui/icons/SportsBaseball';
import {GeneralTextField} from "../Inputs";
import {useDispatch, useSelector} from "react-redux";
import {getCashCollectedValues} from "../../pages/CollectionReceipt/selectors";
import {addCashCollectedValue} from "../../pages/CollectionReceipt/actions";
import {IUnitCollectionDetailsState} from "../../pages/MachineCollectionDetails/model";
import {addMachineCollectionDetails} from "../../pages/MachineCollectionDetails/actions";
import {displaySiteCollectionDetails} from "../../pages/SiteCollection/selectors";
import {addSiteCollectionDetails} from "../../pages/SiteCollection/actions";
import {displayMachineCollectionDetails} from "../../pages/MachineCollectionDetails/selectors";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";


interface Props {
  isCollectionReceipt?: boolean;
  machineCollectionDetails: IUnitCollectionDetailsState;
  position?: number;
  isCollectionHistory?: boolean;
  onChangeInputHandler: (value: string, id: number) => void;
  isIdenticalStartData: boolean;
}


const MachineCollectionCardDetails: FC<Props> = ({
   isCollectionReceipt,
   isCollectionHistory=false,
   onChangeInputHandler,
   machineCollectionDetails,
   position = 0,
   isIdenticalStartData
  }) => {
  const [inputValue, setInputValue] = useState('');
  const siteCollectionDetails = useSelector(displaySiteCollectionDetails);

  useEffect(() => {
    if(!siteCollectionDetails || !siteCollectionDetails.length) {
      return
    }
    siteCollectionDetails
      .map((item, index) => {
        if (item.unitId === machineCollectionDetails.unitId) {
          setInputValue(item.actualCashCollected.toString());
        }
      });
  }, [siteCollectionDetails]);

  const onChangeCashCollectedHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setInputValue(event.target.value);
    onChangeInputHandler(event.target.value, machineCollectionDetails.unitId);
  };

  return (
    <StyledMachineCollectionCardDetails theme={{isCollectionReceipt}}>
      <div className="machine-type-icon"><img src={machineCollectionDetails.iconUrl} alt="unit-icon"/></div>
      <div className="machine-details-wrapper">
        <div className="machine-collection-card-header">
          <div className="machine-title">
            <div><span className="collection-machine-type">{machineCollectionDetails.title}</span></div>
            {machineCollectionDetails && !isIdenticalStartData && <div className="machine-collection-date">
              <span className="start-date">{machineCollectionDetails.collectionPeriod.startTime.split(" ")[0]}</span>-
              <span className="end-date">{machineCollectionDetails.collectionPeriod.endTime.split(" ")[0]}</span>
            </div>}
          </div>
          <div className="manufacturer-code">{machineCollectionDetails.manufacturerCode}</div>
        </div>
        <div className="collection-card-body">
          <div className="column-label-wrapper">
            {!isCollectionHistory && 
              <div className="row-label">Estimate cash amount</div>
            }
            <div className="row-label">Actual cash collected </div>
            <div className="row-label">Contactless money gross</div>
            <div className="row-label">Gross machine Income</div>
          </div>
          <div className="full-price-wrapper">
            <div className="icon-wrapper">
              <FiberManualRecord />
              <FiberManualRecord />
              <FiberManualRecord />
              <FiberManualRecord />
            </div>
            <div className="icon-wrapper pound-sign">
              <span>(£</span>
              <span>£</span>
              <span>£</span>
              <span>£</span>
            </div>
            <div>
              {machineCollectionDetails &&  <div className="row-value">{machineCollectionDetails.estimatedCashAmount ? machineCollectionDetails.estimatedCashAmount.toFixed(2) : 0.00})</div>}
              <div className="row-value">
                {isCollectionReceipt &&
                <div className="total-cash-collected">
                  
                    {machineCollectionDetails.actualCashCollected.toFixed(2)}
                </div>}
                {!isCollectionReceipt && <GeneralTextField required
                                                        value={inputValue}
                                                        onChange={onChangeCashCollectedHandler}/>}
              </div>
                {machineCollectionDetails && <div className="row-value">
                  
                  {machineCollectionDetails.grossContactless.toFixed(2)}
                </div>}
                {machineCollectionDetails && <div className="row-value">
                  
                  {machineCollectionDetails.grossCashCollected.toFixed(2)}
                </div>}
            </div>
          </div>
        </div>
      </div>
    </StyledMachineCollectionCardDetails>
  );
};

export default MachineCollectionCardDetails;
