import {RECEIVE_SOCKET_MESSAGE, SEND_SOCKET_MESSAGE} from "./constants";
import {ISocketMessageState} from "./model";


const socketMessageState: ISocketMessageState = {
  data: null,
  loading: false,
  error: null
};


export const sentSocketMessagesReducer = (state = socketMessageState, action: any) => {
  switch (action.type) {
    case SEND_SOCKET_MESSAGE:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};


export const receivedSocketMessagesReducer = (state = socketMessageState, action: any) => {
  switch (action.type) {
    case RECEIVE_SOCKET_MESSAGE:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

