import {IMachineState, IUnitDetailsState} from "./model";
import {
  ADD_MACHINE_DETAILS,
  GET_HOST_TYPE_FAILURE,
  GET_HOST_TYPE_REQUEST,
  GET_HOST_TYPE_SUCCESS,
  GET_UNIT_BATTERY_POWERED_FAILURE,
  GET_UNIT_BATTERY_POWERED_REQUEST,
  GET_UNIT_BATTERY_POWERED_SUCCESS,
  GET_UNIT_CREDIT_TYPE_FAILURE,
  GET_UNIT_CREDIT_TYPE_REQUEST,
  GET_UNIT_CREDIT_TYPE_SUCCESS,
  GET_UNIT_MANUFACTURER_FAILURE,
  GET_UNIT_MANUFACTURER_REQUEST,
  GET_UNIT_MANUFACTURER_SUCCESS, MACHINE_DETAILS_FAILURE, MACHINE_DETAILS_REQUEST, MACHINE_DETAILS_SUCCESS
} from "./constants";


const unitDetailsInitialState: IUnitDetailsState = {
  data: null,
  loading: false,
  error: null
};

export const getUnitHostTypeReducer = (state = unitDetailsInitialState, action: any) => {
  switch (action.type) {
    case GET_HOST_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_HOST_TYPE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case GET_HOST_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export const getUnitManufacturerDetailsReducer = (state = unitDetailsInitialState, action: any) => {
  switch (action.type) {
    case GET_UNIT_MANUFACTURER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_UNIT_MANUFACTURER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case GET_UNIT_MANUFACTURER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export const getUnitBatteryPoweredDetailsReducer = (state = unitDetailsInitialState, action: any) => {
  switch (action.type) {
    case GET_UNIT_BATTERY_POWERED_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_UNIT_BATTERY_POWERED_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case GET_UNIT_BATTERY_POWERED_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export const getUnitCreditTypeDetailsReducer = (state = unitDetailsInitialState, action: any) => {
  switch (action.type) {
    case GET_UNIT_CREDIT_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_UNIT_CREDIT_TYPE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case GET_UNIT_CREDIT_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};


const machineDetailsInitialState: IMachineState = {
  data: {
    siteId: 0,
    QrCodeId: '',
    externalTagId: '',
    hubTagId: '',
    hubTagPassword: '',
    position: '',
    hostType: {
      name: '',
      hostTypeId: 0,
      code: '',
      iconUrl: '',
      maxTransactionAmount: -1,
      hostCategory: {id: 0, name: '', code: ''}
    },
    manufacturer: {name: '', id: 0},
    unitPowerSource: {name: '', code: '', id: 0},
    metalCabinetOption: false,
    creditType: {name: '', code: '', id: 0},
    defaultTransactionAmount: '0.00',
    creditTestValue: '',
    CoinMecAdptProg: null,
    VRow: [],
    VCol: [],
    VendingRefs: [],
    MT8MessageContent: null,
  },
  loading: false,
  error: null
};


export const machineDetails = (state = machineDetailsInitialState , action: any) => {
  switch (action.type) {
    case ADD_MACHINE_DETAILS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

const addMachineDetailsInitialState: IUnitDetailsState = {
  data: null,
  loading: false,
  error: null
};

export const addMachineDetailsReducer = (state = addMachineDetailsInitialState , action: any) => {
  switch (action.type) {
    case MACHINE_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case MACHINE_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case MACHINE_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};
