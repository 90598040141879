import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";


const StyledAddNewClientPage = styled.div ` 
  padding-bottom: 0!important;

  .items-wrapper {
    margin: 0 0 ${paymeTheme.paddingRemLg} 0;
  }
  
   #site-name,
   #client-name,
   #town {
     text-transform: capitalize;
   }

  .client-name {
    font-weight: ${paymeTheme.fontBold};
    color: ${paymeTheme.colorBlack};
    font-size: ${paymeTheme.fontRemSizeH5};
  }
  
  .site-name {
    padding-bottom: ${paymeTheme.paddingRemSm};
    color: ${paymeTheme.colorPurple};
    font-size: ${paymeTheme.fontRemSizeH5};
    font-weight: ${paymeTheme.fontBold};
  }
  
  .item-town {
    font-size: ${paymeTheme.fontRemSizeLg};
  }
  
  .item-group,
  .item-location {
    display: flex;
    align-items: center;
    justify-content: center;
    
    .MuiSvgIcon-root {
      fill: #415bca;
      margin-right: 5px;
      width: 25px;
      height: 25px;
    }
  }
  
  .item-details {
    padding: ${paymeTheme.paddingRemMd};
    &:first-child {
      padding-top: 0;
    }
  }
  
  form {
    padding: ${paymeTheme.paddingRemSm} 0;
    height: calc(100vh - 35px - 64px);
  }
  
  .try-again {
    margin-top: 50px;
  }
  
  ${media.desktop} {
      form {
        margin-top: 2.5rem;
      }
  }

  ${media.desktopMedium} {
    form {
      height: calc(100vh - 57px - 2.5rem);
    }
  }

`;

export { StyledAddNewClientPage } ;
