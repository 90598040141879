import React, {FC} from "react";
import {createStyles, Theme} from "@material-ui/core";
import NativeSelect from "@material-ui/core/NativeSelect";
import withStyles from "@material-ui/core/styles/withStyles";
import InputBase from "@material-ui/core/InputBase/InputBase";
import paymeTheme from "../../assets/styling/payme-theme";

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: '0px',
      },

      width: '250px',
    },
    input: {
      height: '5vh',
      maxHeight: '40px',
      minHeight: '25px',
      padding: '0 24px 0 8px',
      color: paymeTheme.colorBlack,
      border: `1px solid ${paymeTheme.borderGrey}`,
      overflow: 'hidden',
      borderRadius: 4,
      backgroundColor: paymeTheme.colorWhite,
      fontFamily: `"Exo 2"`,
      fontSize: paymeTheme.fontSizeNormal,
      '&:hover': {
        backgroundColor: paymeTheme.colorWhite,
      },
      '&:focus': {
        backgroundColor: paymeTheme.colorWhite,
        borderColor: paymeTheme.floteColor,
      },
      '&.Mui-error': {
        borderColor: 'red',
      }
    },

  }),
)(InputBase);

interface Props {
  value: string;
  id: string;
  onChangeHandler: (evt: any) => void;
  optionsArray: string[];
  defaultValue: string;
}

const CustomSelect: FC<Props> = ({ value, id, onChangeHandler, optionsArray, defaultValue }) => {

  const createTypeOption = optionsArray.length > 0 &&
    optionsArray.map((item, index) =>
      <option key={index} value={item}>{item.replace('_', ' ')}</option>
    );

  return (
        <NativeSelect
          id={id}
          value={value}
          onChange={onChangeHandler}
          input={<BootstrapInput/>}
        >
          <option value='' disabled>{defaultValue}</option>
          {createTypeOption}
        </NativeSelect>
  );
};

export default CustomSelect;
