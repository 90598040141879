import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";



const StyledCollectionHistory = styled.div `
  .header-list {
      border-bottom: 1px solid;
   }
   
 .MuiTab-textColorPrimary.Mui-selected {
  color: ${paymeTheme.floteColor};
 }
 .PrivateTabIndicator-colorPrimary-12 {
    background-color: ${paymeTheme.floteColor};
 }
   
 .header-list,
 .item-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: ${paymeTheme.paddingRemXs} 0;
     .item-name,
     .item-town {
        width: 30%;
        flex-wrap: wrap;
      }
      
    .item-sites {
      max-width: 50%;
      width: 100%;
      flex-wrap: wrap;
    }
 }
 .header-list {
    justify-content: flex-start;
    div {
      width: 30%;
    }
 }
  
  .item-wrapper {
    padding:  ${paymeTheme.paddingRem} 0;
    border-bottom: 1px solid ${paymeTheme.borderGrey};
    margin: ${paymeTheme.paddingRemSm} 0;
  }
  
  .item-town {
    text-align: right;
  }
  
 .search-form {
  flex-direction: row;
  justify-content: center;
  align-content: center;
  margin: ${paymeTheme.paddingRemSm} auto;
  
  .MuiTextField-root {
    margin: 0 ${paymeTheme.paddingRemSm} 0;
  }
 }
  
  .client-name {
    color: ${paymeTheme.colorBlack};
    font-weight: ${paymeTheme.fontBold};
  }
  
  .site-name,
  .sites-wrapper {
    color: ${paymeTheme.colorPurple};
  }
  
  .MuiSvgIcon-root {
    fill: ${paymeTheme.floteColor};
  }
  
  ${media.desktop} {
    
  }

`;

export { StyledCollectionHistory } ;
