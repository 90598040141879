//state type

export type LoginState = {
  email: string
  password:  string
  isButtonDisabled: boolean
  helperText: string
  isError: boolean
  isEmptyPassError: boolean
};

type Action = { type: 'setUsername', payload: string }
  | { type: 'setPassword', payload: string }
  | { type: 'setIsButtonDisabled', payload: boolean }
  | { type: 'loginSuccess', payload: string }
  | { type: 'loginFailed', payload: string }
  | { type: 'setEmptyPassError', payload: boolean }
  | { type: 'setIsError', payload: boolean };

export const loginReducer = (state: LoginState, action: Action): LoginState => {
  switch (action.type) {
    case 'setUsername':
      return {
        ...state,
        email: action.payload
      };
    case 'setPassword':
      return {
        ...state,
        password: action.payload
      };
    case 'setIsButtonDisabled':
      return {
        ...state,
        isButtonDisabled: action.payload
      };
    case 'loginSuccess':
      return {
        ...state,
        helperText: action.payload,
        isError: false
      };
    case 'loginFailed':
      return {
        ...state,
        helperText: action.payload,
        isError: true
      };
    case 'setIsError':
      return {
        ...state,
        isError: action.payload
      };
    case 'setEmptyPassError':
      return {
        ...state,
        isEmptyPassError: action.payload
      };
  }
}
