import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";




const StyledChangeMachinePosition = styled.div `
  form {
    padding: ${paymeTheme.paddingRemLg};
  }
  .credit-box-section {
    max-width: 200px;
    text-align: center;
    padding: ${paymeTheme.paddingRemSm};
    color: ${paymeTheme.colorWhite};
    background-color: ${paymeTheme.floteColor};
    .MuiInputBase-root {
      min-width: 150px;
      max-width: 180px;
      margin-top: ${paymeTheme.paddingRemXs};
    }
  }
  ${media.desktop} {
    form {
      padding-top: 2.5rem;
    }
  }

`;

export { StyledChangeMachinePosition } ;
