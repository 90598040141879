import React, {FC, useEffect, useState} from 'react';
import {StyledEditTeamMember} from "./styles";
import Header from "../../../components/Header";
import {TeamMemberForm} from "../TeamMemberForm";
import {asyncRequest} from "../../../api";
import {useSelector} from "react-redux";
import {displayUserDetails} from "../selectors";


export const EditTeamMember: FC = () => {
  const userDetailsRedux = useSelector(displayUserDetails);
  const existingUserAuthorities = userDetailsRedux && userDetailsRedux.authorities;
  const [allAuthorities, setAllAuthorities] = useState();
  const [initialUserDetailsState, setInitialUserDetailsState] = useState(userDetailsRedux);

  useEffect(() => {
    asyncRequest({
      config: {
        method: "GET",
      },
      endpoint: 'api/users/authorities',
    })
      .then((response) => {
        setAllAuthorities(response);
      })
      .catch((err) => {
        console.log(err);
      });

  }, []);

  const extendedAuthorities = allAuthorities && allAuthorities.length > 0 && allAuthorities.map(item => {
    const matchedAuthorities = existingUserAuthorities.filter(
      (authority: any) => authority.name === item.name)[0];

    if (matchedAuthorities) {
      return {
        label: item.description,
        value: item.name,
        id: item.authority_id,
        checked: true,
        checkBoxName: item.description,
      };
    }

    return {
      label: item.description,
      value: item.name,
      id: item.authority_id,
      checked: false,
      checkBoxName: item.description,
    };
  });

  useEffect(() => {
    setInitialUserDetailsState({
      ...initialUserDetailsState,
      authorities: extendedAuthorities
    })
  }, [allAuthorities]);

  return (
    <StyledEditTeamMember className="height-controller-wrapper">
      <Header title="Member profile"/>
      {initialUserDetailsState && <TeamMemberForm actionType="update" initialUserDetailsState={initialUserDetailsState}/>}
    </StyledEditTeamMember>
  );
};
