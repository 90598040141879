import React, {FC, useEffect, useState} from 'react';
import {StyledMemberProfile} from "./styles";
import Header from "../../../components/Header";
import {OutlinedButton, PrimaryButton} from "../../../components/Buttons";
import {useHistory} from "react-router-dom";
import * as ROUTES from "../../../components/Containers/Main/constants";
import {useDispatch, useSelector} from "react-redux";
import {memberDetailsAttempt} from "../actions";
import {displayUserDetails} from "../selectors";
import {convertDateFullMonthLetters} from "../../../utils/getCurrentDate";
import {UploadImage} from "../../../components/UploadImageSection";
import {fileToDataUri} from "../../../utils/extractUriFromFile";
import { asyncRequest } from '../../../api';
import { NotificationError } from '../../../components/NotificationMessages';
import LoadingComponent from '../../../components/Loading';

export const MemberProfile: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userDetails = useSelector(displayUserDetails);

  const [dataUri, setDataUri] = useState('');
  const [imageName, setImageName] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const userRoles = userDetails && userDetails.authorities && userDetails.authorities.length > 0 &&
    userDetails.authorities.map((role, index) => {
      return <div key={`${index}-role`}>{role.description}</div>
    });

  useEffect(() => {
    dispatch(memberDetailsAttempt());
  }, []);

  const confirmUserProfileHandler = () => {
    setIsLoading(true);
    asyncRequest({
      config: {
        method: "POST",
        data: {
          image: dataUri,
          imageName,
        }
      },
      endpoint: 'api/users/connected/upload',
    })
      .then((response) => {
        history.push(ROUTES.HOME);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(err.response.data.title);
        setIsLoading(false);
      });
  };


  const onChangeImageHandler = (file: any) => {
    if(!file) {
      setDataUri('');
      return;
    }

    setImageName(file.name);

    fileToDataUri(file)
      .then(dataUri => {
        setDataUri(dataUri);
      })
  };

  return (
    <StyledMemberProfile className="height-controller-wrapper">
      <Header title="My profile"/>
      <div className="user-details-wrapper">
        <div className="name-details detail">
          <span>{userDetails && userDetails.firstName}</span>
          <span>{userDetails && userDetails.lastName}</span>
        </div>
        <div className="detail">{userDetails && userDetails.email}</div>
        <div className="detail">Start -
          <span>{userDetails && convertDateFullMonthLetters(' ', userDetails.startDate, 'long')}</span>
        </div>
        <div className="detail">End -
          <span>
            {userDetails && userDetails.endDate ?
            convertDateFullMonthLetters(' ', userDetails.endDate, 'long') :
            'no end date'}
          </span>
        </div>
        <div className="detail">{userRoles}</div>
      </div>
      <UploadImage actionType="display-details"
                   userDetails={userDetails}
                   dataUri={dataUri}
                   onChangeImageHandler={onChangeImageHandler}/>
      <div className="actions-wrapper">
        <OutlinedButton className="back-button" onClick={() => {history.goBack()}}>Back</OutlinedButton>
        <PrimaryButton className="continue-button" onClick={confirmUserProfileHandler} disabled={dataUri === ""}>Confirm</PrimaryButton>
      </div>
      {errorMessage && <NotificationError message={errorMessage} messageClass="error"/>}
      {isLoading && <LoadingComponent/>}
    </StyledMemberProfile>
  );
};
