import React, {FC, useEffect, useState} from 'react';
import Header from "../../../components/Header";
import AddIcon from '@material-ui/icons/Add';
import {GeneralTextField} from "../../../components/Inputs";
import SearchIcon from '@material-ui/icons/Search';
import UserProfileCard from "../../../components/UserProfileCard";
import {OutlinedButton, PrimaryButton} from "../../../components/Buttons";
import {useHistory} from "react-router-dom";
import {StyledTeamLog} from "../styles";
import {asyncRequest} from "../../../api";
import LoadingComponent from "../../../components/Loading";
import * as ROUTES from "../../../components/Containers/Main/constants";
import { AppBar, Box, makeStyles, Tab, Tabs, Theme, useTheme } from '@material-ui/core';
import {ITabPanelProps} from "../../../@types";


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    maxWidth: '500px',
    width: '100%',
    margin: 'auto',
    ['@media (min-width: 992px)']: { 
      paddingTop: '5rem'
    },
    position: 'relative',
    paddingTop: '3.75rem'
  },
}));

export const TeamList: FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [searchTeamValue, setSearchTeamValue] = useState("");
  const [teamList, setTeamList] = useState();
  const [searchTeamList, setSearchTeamList] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    asyncRequest({
      config: {
        method: "GET",
      },
      endpoint: 'api/users',
    })
      .then((response) => {
        const sorted = [...response].sort((a, b) => {
          const nameA = [a.firstName.toLowerCase(), a.lastName.toLowerCase()];
          const nameB = [b.firstName.toLowerCase(), b.lastName.toLowerCase()];

          return nameA[0].localeCompare(nameB[0]) === 0 ? nameA[1].localeCompare(nameB[1]) : nameA[0].localeCompare(nameB[0]);
        });
        setTeamList(sorted);
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);


  useEffect(() => {
    let sorted;
    if (teamList) {
      if (value === 0) {
        sorted = [...teamList].sort((a, b) => {
          const nameA = [a.firstName.toLowerCase(), a.lastName.toLowerCase()];
          const nameB = [b.firstName.toLowerCase(), b.lastName.toLowerCase()];

          return nameA[0].localeCompare(nameB[0]) === 0 ? nameA[1].localeCompare(nameB[1]) : nameA[0].localeCompare(nameB[0]);
        });
      } else {
        sorted = [...teamList].sort((a, b) => {
          const authoritiesA = a.authorities.length === 1 ? a.authorities[0].description : 'Multiple';
          const authoritiesB = b.authorities.length === 1 ? b.authorities[0].description : 'Multiple';

          return authoritiesA.localeCompare(authoritiesB);
        });
      }
      setTeamList(sorted);
    }
  }, [value]);

  const onChangeSearchValueHandler: React.ChangeEventHandler<HTMLInputElement> =
    (event) => {
      let usersSearch = [];
      const searchValue = event.target.value.toLocaleLowerCase();
      setSearchTeamValue(event.target.value.toLocaleLowerCase());
      if (value === 0) {
        usersSearch = teamList.filter((team) => {
          const firstName = team.firstName.toLowerCase();
          const lastName = team.lastName.toLowerCase();
          const firstLastName = firstName + ' ' + lastName;
          const lastFirstName = lastName + ' ' + firstName;

          return firstName.includes(searchValue) || lastName.includes(searchValue) || firstLastName.includes(searchValue) || lastFirstName.includes(searchValue);
        })
      } else {
        usersSearch = teamList.filter((team) => {
          const authorities = team.authorities.length === 1 ? team.authorities[0].description : 'Multiple';

          return authorities.toLowerCase().includes(searchValue)
        })
      }

      setSearchTeamList(usersSearch);
    };

  const onClickAddMemberHandler = () => {
    history.push(ROUTES.NEW_TEAM_MEMBER);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  function TabPanel(props: ITabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={2}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: any) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  const onClickActivityLogHandler = () => {
    history.push(ROUTES.TEAM_ACTIVITY_LOG);
  };

  return (
    <StyledTeamLog className="height-controller-wrapper">
      <Header title="Team"/>
      <div className={classes.root}>
        <AppBar position="sticky" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Name" {...a11yProps(0)} />
            <Tab label="Role" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <div className="add-search-wrapper">
          <div className="add-trigger" onClick={onClickAddMemberHandler}><AddIcon/><span>Add member</span></div>
            <div className="search-section">
              <GeneralTextField value={searchTeamValue}
                                placeholder={`Search ${value === 0 ? "name" : "role"}`}
                                onChange={onChangeSearchValueHandler}/>
              <SearchIcon/>
            </div>
        </div>
        <TabPanel value={value} index={0} dir={theme.direction}>
              <div className="user-cards-list">
                {
                  searchTeamValue === '' && teamList && teamList.length > 0 && teamList.map((item, index) => {
                    return <UserProfileCard key={`user-${index}`} item={item} hasDate={false}/>
                  })
                }
                {
                  searchTeamValue !== '' && searchTeamList && searchTeamList.length > 0 && searchTeamList.map((item, index) => {
                    return <UserProfileCard key={`user-${index}`} item={item} hasDate={false}/>
                  })
                }
              </div>  
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
              <div className="user-cards-list">
                {
                  searchTeamValue === '' && teamList && teamList.length > 0 && teamList.map((item, index) => {
                    return <UserProfileCard key={`user-${index}`} item={item} hasDate={false}/>
                  })
                }
                {
                  searchTeamValue !== '' && searchTeamList && searchTeamList.length > 0 && searchTeamList.map((item, index) => {
                    return <UserProfileCard key={`user-${index}`} item={item} hasDate={false}/>
                  })
                }
              </div>  
          </TabPanel>
      </div>
      <div className="actions-wrapper">
        <OutlinedButton onClick={() => {history.goBack()}}>Back</OutlinedButton>
        <PrimaryButton className="continue-button"
                       onClick={onClickActivityLogHandler}>Activity Log</PrimaryButton>
      </div>
      {isLoading && <LoadingComponent/>}
    </StyledTeamLog>
  );
};
