import React, {FC, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import Header from "../../../components/Header";
import {PrimaryButton} from "../../../components/Buttons";
import LoadingComponent from "../../../components/Loading";
import {GeneralTextField} from "../../../components/Inputs";
import {StyledResetPassword} from "../styles";
import {useDispatch, useSelector} from "react-redux";
import {getIsResetPasswordLoading, getResetPasswordData, getResetPasswordError} from "../selectors";
import {
  userResetPasswordAttempt,
  userResetPasswordFailure,
  userResetPasswordSuccess
} from "../actions";
import * as ROUTES from "../../../components/Containers/Main/constants";
import * as HELPER_TEXT from "../../ActivateMemberAccount/constants";
import {FILL_NEW_PASSWORD, CONFIRM_NEW_PASSWORD} from "./constants";

export const CreateNewPassword: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userToken = localStorage.getItem('token');
  const params = new URLSearchParams(window.location.search.substring(1));
  const resetKeyValue = params.get('resetKey');

  const errorRequest = useSelector(getResetPasswordError);
  const successRequest = useSelector(getResetPasswordData);
  const isLoadingDisplayed = useSelector(getIsResetPasswordLoading);

  const [userNewPassword, setUserNewPassword] = useState();
  const [isUserNewPasswordEmpty, setIsUserNewPasswordEmpty] = useState(false);
  const [isUserNewPasswordEmptyValidated, setIsUserNewPasswordValidated] = useState(true);
  const [helperTextNewPassword, setHelperTextNewPassword] = useState();
  const [displayHelperTextNewPassword, setDisplayHelperTextNewPassword] = useState();
  const [userConfirmNewPassword, setUserConfirmNewPassword] = useState();
  const [isUserConfirmNewPasswordEmpty, setIsUserConfirmNewPasswordEmpty] = useState(false);
  const [isUserConfirmNewPasswordEmptyValidated, setIsUserConfirmNewPasswordValidated] = useState(true);
  const [helperTextConfirmNewPassword, setHelperTextConfirmNewPassword] = useState();
  const [displayHelperTextConfirmNewPassword, setDisplayHelperTextConfirmNewPassword] = useState();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [successRequestMessage, setRequestSuccessMessage] = useState();
  const [errorRequestMessage, setErrorRequestMessage] = useState();

  useEffect(() => {
    if (userToken) {
      localStorage.removeItem('token');
    }
  }, []);

  useEffect(() => {
    if (!!successRequest) {
      setRequestSuccessMessage(successRequest);
    }
  }, [successRequest]);

  useEffect(() => {
    if (!!errorRequest) {
      setErrorRequestMessage(errorRequest);
    }
  }, [errorRequest]);

  useEffect(() => {
    if (!!userNewPassword && !!userConfirmNewPassword) {
      if (!isUserNewPasswordEmpty && !!isUserNewPasswordEmptyValidated && !isUserConfirmNewPasswordEmpty && !!isUserConfirmNewPasswordEmptyValidated) {
        setIsButtonDisabled(false);
      }

      if (!!isUserNewPasswordEmpty || !isUserNewPasswordEmptyValidated || !!isUserConfirmNewPasswordEmpty || !isUserConfirmNewPasswordEmptyValidated) {
        setIsButtonDisabled(true);
      }
    }
  }, [isUserNewPasswordEmpty, isUserNewPasswordEmptyValidated, userNewPassword, isUserConfirmNewPasswordEmpty, isUserConfirmNewPasswordEmptyValidated, userConfirmNewPassword]);

  useEffect(() => {
    displayHelperTextNewPasswordHandler();
  }, [isUserNewPasswordEmptyValidated, isUserNewPasswordEmpty]);

  useEffect(() => {
    displayHelperTextConfirmNewPasswordHandler();
  }, [isUserConfirmNewPasswordEmptyValidated, isUserConfirmNewPasswordEmpty]);


  const onChangeConfirmUserPasswordHandler: React.ChangeEventHandler<HTMLInputElement> =
    (event) => {
      setUserConfirmNewPassword(event.target.value);
      if (event.target.value !== '') {
        setIsUserConfirmNewPasswordEmpty(false);
        if (event.target.value === userNewPassword) {
          setIsUserConfirmNewPasswordValidated(true);
        } else {
          setIsUserConfirmNewPasswordValidated(false);
        }
      } else {
        setIsUserConfirmNewPasswordEmpty(true);
      }
    };

  const onClickSendNewPasswordHandler = () => {
    setRequestSuccessMessage('');
    setErrorRequestMessage('');
    dispatch(userResetPasswordAttempt({userNewPassword, resetKeyValue}));
  };

  const onChangeUserPasswordHandler: React.ChangeEventHandler<HTMLInputElement> =
    (event) => {
      setUserNewPassword(event.target.value);
      if (event.target.value !== '') {
        setIsUserNewPasswordEmpty(false);
        if (event.target.value.length >= 8) {
          setIsUserNewPasswordValidated(true);
        } else {
          setIsUserNewPasswordValidated(false);
        }
      } else {
        setIsUserNewPasswordEmpty(true);
      }
    };

  const displayHelperTextNewPasswordHandler = () => {
    if (isUserNewPasswordEmpty) {
      setHelperTextNewPassword(FILL_NEW_PASSWORD);
      setDisplayHelperTextNewPassword(true);
    }
    if (!isUserNewPasswordEmpty && !isUserNewPasswordEmptyValidated) {
      setHelperTextNewPassword(HELPER_TEXT.FILL_LONGER_PASSWORD);
      setDisplayHelperTextNewPassword(true);
    }
    if (!isUserNewPasswordEmpty && isUserNewPasswordEmptyValidated) {
      setHelperTextNewPassword('');
      setDisplayHelperTextNewPassword(false);
    }
  };

  const displayHelperTextConfirmNewPasswordHandler = () => {
    if (isUserConfirmNewPasswordEmpty) {
      setHelperTextConfirmNewPassword(CONFIRM_NEW_PASSWORD);
      setDisplayHelperTextConfirmNewPassword(true);
    }
    if (!isUserConfirmNewPasswordEmpty && !isUserConfirmNewPasswordEmptyValidated) {
      setHelperTextConfirmNewPassword(HELPER_TEXT.PASSWORDS_NOT_MATCH);
      setDisplayHelperTextConfirmNewPassword(true);
    }
    if (!isUserConfirmNewPasswordEmpty && isUserConfirmNewPasswordEmptyValidated) {
      setHelperTextConfirmNewPassword('');
      setDisplayHelperTextConfirmNewPassword(false);
    }
  }

  return (
    <StyledResetPassword className="height-controller-wrapper">
      <Header title="Create Password"/>
      {
        !successRequestMessage &&
        <form className="existing-email-form">
          <GeneralTextField id="user-new-password"
                            type="password"
                            value={userNewPassword}
                            onChange={onChangeUserPasswordHandler}
                            variant="filled"
                            required={true}
                            helperText={displayHelperTextNewPassword && helperTextNewPassword}
                            placeholder="Fill new password"/>
          <GeneralTextField id="user-confirmation-new-password"
                            type="password"
                            value={userConfirmNewPassword}
                            onChange={onChangeConfirmUserPasswordHandler}
                            variant="filled"
                            required={true}
                            helperText={displayHelperTextConfirmNewPassword && helperTextConfirmNewPassword}
                            placeholder="Confirm new password"/>
        </form>
      }
      {
        !successRequestMessage ?
          <div className="actions-wrapper">
            <PrimaryButton onClick={onClickSendNewPasswordHandler} disabled={isButtonDisabled}>Confirm</PrimaryButton>
          </div> :
          <div className="activation-wrapper">
            <div className="success-message">{successRequestMessage}</div>
            <div className="actions-wrapper">
              <PrimaryButton
                onClick={() => {
                  history.push(ROUTES.LOGIN);
                  dispatch(userResetPasswordSuccess(undefined));
                  dispatch(userResetPasswordFailure(undefined));
                }}>
                Sign in
              </PrimaryButton>
            </div>
          </div>
      }
      {errorRequestMessage && <div className="error-message">{errorRequestMessage}</div>}
      {isLoadingDisplayed && <LoadingComponent/>}
    </StyledResetPassword>
  );
};
