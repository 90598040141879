import React, { FC, useEffect, useState } from "react";
import { StyledSummaryHeader } from "./styles";
import RoomIcon from "@material-ui/icons/Room";
import LayersIcon from "@material-ui/icons/Layers";
import { SiteMenuAppBar } from "../../pages/SitePage/SiteMenu";
import { useDispatch, useSelector } from "react-redux";
import { getClientsListData, getSiteDetailsData } from "../../pages/AllClientsSitesPage/selectors";
import { parseJwt } from "../../utils/parseDetailsFromToken";
import {
  ROLE_ADMIN,
  ROLE_COLLECTOR,
  ROLE_MASTER,
  ROLE_OPERATIVE,
  ROLE_TECHNICIAN,
} from "../../constants";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { asyncRequest } from "../../api";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { onClickMenuItemHandler } from "../../utils/menuItemsHandler";
import { displaySiteDetailsSuccess } from "../../pages/AllClientsSitesPage/actions";
import { addCashCollectedValue } from "../../pages/CollectionReceipt/actions";
import { getCashCollectedValues } from "../../pages/CollectionReceipt/selectors";
import * as ROUTES from "../Containers/Main/constants";
import { ISiteDetailsData } from "../../pages/AllClientsSitesPage/model";
import { setFlowType } from "../Containers/Main/actions";
import { getFlowType } from "../Containers/Main/selectors";
import {OutlinedButton, PrimaryButton} from "../Buttons";
import {useModalStyles} from "../Modal/styles";
import ModalComponent from "../Modal";
import {
  addClientFailure,
  deleteClientAttempt,
  deleteSiteAttempt
} from "../../pages/AddNewClientPage/actions";
import {getAddClientDetailsError} from "../../pages/AddNewClientPage/selectors";
import {NotificationError} from "../NotificationMessages";

interface Props {
  isMenuDisplayed?: boolean;
  isArrowDisplayed?: boolean;
  isClientPage?: boolean;
  site?: ISiteDetailsData;
}

const SummaryHeader: FC<Props> = ({
  isMenuDisplayed = false,
  isArrowDisplayed = true,
  isClientPage,
  site
}) => {
  const classes = useModalStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const siteDetails: ISiteDetailsData | undefined = useSelector(getSiteDetailsData);
  const deleteClientOrSiteError = useSelector(getAddClientDetailsError);
  const clientsList = useSelector(getClientsListData);
  const [siteDetailsState, setSiteDetailsState] = useState(siteDetails);
  const [isOffSite, setIsOffSite] = useState();
  const flowType = useSelector(getFlowType);
  const { id } = useParams();

  const cashCollectedValuesData = useSelector(getCashCollectedValues);
  const userToken = localStorage.getItem("token");
  const isReplaceTagFlow = flowType.isReplaceTagFlow;
  const location = useLocation();
  const userCompanyType = userToken && parseJwt(userToken).companyType;
  const userRole = userToken && parseJwt(userToken).auth.split(",")[0];
  const [groupList, setGroupList] = useState([]);
  const [isGroupedClicked, setIsGroupedClicked] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [deleteTypeHandler, setDeleteTypeHandler] = useState('');
  const [showConfirmDeleteClientSiteButton, setShowConfirmDeleteClientSiteButton] = useState(false);

  useEffect(() => {
    setSiteDetailsState(!!isClientPage ? site : siteDetails);
  }, [siteDetails]);

  useEffect(() => {
    siteDetailsState && siteDetailsState.siteType && siteDetailsState.siteType.code &&
    setIsOffSite(siteDetailsState.siteType.code === "OFF_SITE");
  }, [siteDetailsState]);

  useEffect(() => {
    dispatch(addClientFailure(null));
  }, []);

  useEffect(() => {
    asyncRequest({
      config: {
        method: "GET",
      },
      endpoint: "api/group/company",
    })
      .then((response) => {
        setGroupList(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const clientHasSite = (sites, siteId) => {
    const items = sites.filter((site) => site.id == siteId);
    return !!items.length;
  };

  const onClickGroupsHandler = () => {
    setIsGroupedClicked((prevState) => !prevState);
  };

  const onClickCollectionSiteHandler = () => {
    history.push({
      pathname: ROUTES.SITE_COLLECTION,
      state: { initialUrl: location.pathname },
    });
    dispatch(
      addCashCollectedValue({
        ...cashCollectedValuesData,
        cashCollectedValues: [],
      })
    );
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  let sitesLength = -1;
  let unitsLengthPerSiteForClient: number[] = [];
  const lengthOfSitesPerClient = () => {
    clientsList && clientsList.items && clientsList.items.forEach(client => {
     if (client.sites.filter(site => site.id === siteDetailsState.id).length > 0) {
       sitesLength = client.sites.length;
       client.sites.forEach(site => {
         unitsLengthPerSiteForClient.push(site.units ? site.units.length : 0);
       })
     }
    });
    return sitesLength;
  };

  const onClickCollectionHistorySiteHandler = () => {
    history.push({
      pathname: ROUTES.COLLECTION_HISTORY,
      state: { initialUrl: location.pathname },
    });
    dispatch(
      addCashCollectedValue({
        ...cashCollectedValuesData,
        cashCollectedValues: [],
      })
    );
  };

  const onClickWhat3WordsHandler = () => {
    if (siteDetailsState && siteDetailsState.what3Words) {
      window.location = `https://what3words.com/${siteDetailsState.what3Words}`;
    } else {
      window.location = "https://what3words.com";
    }
  };

  const onClickEditClientHandler = () => {
    const clientIdFlowType = isClientPage ? id : clientsList && clientsList.items &&
      clientsList.items.filter((item) => clientHasSite(item.sites, id))[0].id;
    history.push(`${ROUTES.EDIT_CLIENT_NAME}/${clientIdFlowType}`);
  };

  const onClickEditSiteHandler = () => {
    if (isClientPage) {
      const clientIdFlowType = isClientPage ? id : clientsList && clientsList.items &&
      clientsList.items.filter((item) => clientHasSite(item.sites, id))[0].id;
    
      dispatch(
        setFlowType({
          ...flowType,
          clientIdFromAddSiteToClientFlow: clientIdFlowType
        })
      );
    }

    history.push(`${ROUTES.EDIT_SITE_DETAILS}/${siteDetailsState.id}`);
  };

  const onClickDeleteSiteHandler = () => {
    setDeleteTypeHandler('site');
    setOpenModal(true);
    if ((siteDetailsState && siteDetailsState.units.length > 0) || lengthOfSitesPerClient() === 1) {
      return;
    }
    setShowConfirmDeleteClientSiteButton(true);

  };

  const onClickDeleteClientHandler = () => {
    setDeleteTypeHandler('client');
    setOpenModal(true);
    if (unitsLengthPerSiteForClient.some(value => value > 0)) {
      setShowConfirmDeleteClientSiteButton(false);
    } else {
      setShowConfirmDeleteClientSiteButton(true);
    }
  };

  const confirmDeleteSiteClientHandler = () => {
    const clientIdFlowType = isClientPage ? id : clientsList && clientsList.items &&
      clientsList.items.filter((item) => clientHasSite(item.sites, id))[0].id;
    showConfirmDeleteClientSiteButton && deleteTypeHandler === 'client' &&
    clientIdFlowType && dispatch(deleteClientAttempt(clientIdFlowType));

    showConfirmDeleteClientSiteButton && deleteTypeHandler === 'site' &&
    siteDetailsState && siteDetailsState.id && dispatch(deleteSiteAttempt(siteDetailsState.id));
    setOpenModal(false);
  };

  const modalBody = (
    <div className="modal-wrapper">
      <div className={classes.body}>
        {lengthOfSitesPerClient() === 1 ?
          'This is the only site attached to client so you can not delete it' :
          showConfirmDeleteClientSiteButton ? `Are you sure you want to delete this ${deleteTypeHandler}?` :
          `This ${deleteTypeHandler} can not be deleted as there is data attached to it`
        }
      </div>
      <div className="modal-footer">
        <div className={classes.footer}>
          <OutlinedButton className="back-button" onClick={handleModalClose}>Back</OutlinedButton>
          {showConfirmDeleteClientSiteButton &&
          <PrimaryButton className="continue-button" onClick={confirmDeleteSiteClientHandler}>Confirm</PrimaryButton>}
        </div>
      </div>
    </div>
  );

  const onClickAddSiteHandler = () => {
    history.push({
      pathname: ROUTES.ADD_CLIENT_DETAILS,
      state: { initialUrl: location.pathname },
    });
    const clientIdFlowType = isClientPage ? id : clientsList && clientsList.items &&
      clientsList.items.filter((item) => clientHasSite(item.sites, id))[0].id;
    dispatch(
      setFlowType({
        ...flowType,
        clientIdFromAddSiteToClientFlow: clientIdFlowType
      })
    );
  };

  const defaultMenuOptionsSummary = [
    {
      name: "Add site to client",
      path: ROUTES.ADD_CLIENT_DETAILS,
      onClickHandler: onClickAddSiteHandler,
      hasSubmenu: false
    },
    {
      name: "Edit site",
      path: ROUTES.EDIT_SITE_DETAILS,
      onClickHandler: onClickEditSiteHandler,
      hasSubmenu: false,
    },
    {
      name: "Delete site",
      path: "",
      onClickHandler: onClickDeleteSiteHandler,
      hasSubmenu: false,
    },
    {
      name: "Add to group",
      path: "",
      onClickHandler: onClickGroupsHandler,
      hasSubmenu: true,
      subMenuList: [],
    },
    {
      name: "What3words link",
      path: "",
      onClickHandler: onClickWhat3WordsHandler,
      hasSubmenu: false,
    },
    {
      name: "Navigate to site",
      path: "",
      onClickHandler: onClickWhat3WordsHandler,
      hasSubmenu: false,
    },
    {
      name: "Machines history",
      path: "",
      onClickHandler: onClickMenuItemHandler,
      hasSubmenu: false,
    },
    {
      name: "Collection history - site",
      path: "",
      onClickHandler: onClickCollectionHistorySiteHandler,
      hasSubmenu: false,
    },
    {
      name: "Collection - site",
      path: ROUTES.SITE_COLLECTION,
      onClickHandler: onClickCollectionSiteHandler,
      hasSubmenu: false,
    },
  ];

  const onGroupOptionsClickHandler = (
    groupId: number,
    partyIdArray: number[]
  ) => {
    if (partyIdArray.indexOf(siteDetailsState.id) !== -1) {
      asyncRequest({
        config: {
          method: "PATCH",
        },
        endpoint: `api/group/${groupId}/remove/site/${siteDetailsState.id}`,
      })
        .then((response) => {
          updateGroupListPerUserAction(response);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      asyncRequest({
        config: {
          method: "PATCH",
        },
        endpoint: `api/group/${groupId}/add/site/${siteDetailsState.id}`,
      })
        .then((response) => {
          updateGroupListPerUserAction(response);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const updateGroupListPerUserAction = (response: any) => {
    let newGroupList = [];
    setGroupList((prevState) => {
      newGroupList = prevState.map((item) => {
        if (item.id === response.id) {
          return { ...item, partyId: [...response.partyId] };
        } else {
          return item;
        }
      });
      return newGroupList;
    });
    const siteGroups = newGroupList.filter((item) => {
      return item.partyId.indexOf(siteDetailsState.id) !== -1;
    });
    dispatch(
      displaySiteDetailsSuccess({ ...siteDetailsState, siteGroups: siteGroups })
    );
  };

  const menuOptions = (roleType: string) => {
    switch (roleType) {
      case ROLE_ADMIN:
      case ROLE_MASTER:
      case ROLE_TECHNICIAN:
      case ROLE_OPERATIVE:
      case ROLE_COLLECTOR:
        if (userCompanyType !== 'SOLO') {
          let operatorMenuOptionsSummary = [...defaultMenuOptionsSummary];
          operatorMenuOptionsSummary.splice(3, 0,
            {
              name: "Edit client",
              path: ROUTES.EDIT_CLIENT_NAME,
              onClickHandler: onClickEditClientHandler,
              hasSubmenu: false,
            },
            {
              name: "Delete client",
              path: "",
              onClickHandler: onClickDeleteClientHandler,
              hasSubmenu: false,
            });
          return operatorMenuOptionsSummary;
        }
        return defaultMenuOptionsSummary;
      default:
        return defaultMenuOptionsSummary;
    }
  };
  
  const groupsAttended = 
  groupList &&
  groupList.length > 0 &&
  groupList.filter((item, index) => {
    return item.partyId.indexOf(siteDetailsState.id) !== -1;
  });

  const mappedGroupedList =
  groupList &&
  groupList.length > 0 &&
  groupList.map((item, index) => {
    return (
      <span key={`group-name-${index}`}>
        {item.partyId.indexOf(siteDetailsState.id) !== -1 && `${item.name};`}{" "}
      </span>
    );
  });

  const siteClass = siteDetailsState.siteType.code === "OFF_SITE" ? "off-site-name" : "site-name";
  const siteClassAccordingToClientName = userCompanyType !== "SOLO" &&
  siteDetailsState.clientName &&
  !isOffSite ? "site-and-client-name" : "";

  return (
    <StyledSummaryHeader className={siteDetailsState.siteType.code === "OFF_SITE" ? "site-header off-site-header" : "site-header"}>
      <div className={siteDetailsState.siteType.code === "OFF_SITE" ?
        "off-site-details-wrapper site-details-wrapper" :
        "site-details-wrapper"}>
        <div className="site-header">
        {userCompanyType !== "SOLO" &&
            siteDetailsState.clientName &&
            !isOffSite && (
              <div className="client-name">{siteDetailsState.clientName}</div>
            )}
          {siteDetailsState.name && (
            <div className={siteClass + " " + siteClassAccordingToClientName}>
              {siteDetailsState.name}
            </div>
          )}
          
        </div>
        {!isOffSite && (
          <div className="site-details">
            <div className="row-details">
              {siteDetailsState.town && (
                <span className="site-detail">{siteDetailsState.town}</span>
              )}
              {siteDetailsState.postcode && (
                <span className="site-detail">{siteDetailsState.postcode}</span>
              )}
              {siteDetailsState.siteType && siteDetailsState.siteType.name && (
                <span className="site-detail">{siteDetailsState.siteType.name}</span>
              )}
            </div>
            <div className="row-details">
              {groupsAttended && groupsAttended.length > 0 && 
                <span className="site-detail">
                  <LayersIcon />
                  {mappedGroupedList}
                </span>}
            </div>
            <div className="row-details">
              {siteDetailsState.what3Words && (
                <span className="site-detail">
                  <RoomIcon />
                  {siteDetailsState.what3Words}
                </span>
              )}
            </div>
          </div>
        )}
      </div>
      {isMenuDisplayed && !isReplaceTagFlow && (
        <div className="site-menu">
          <SiteMenuAppBar
            isClientPage={isClientPage}
            site={site}
            isMachine={false}
            isGroupedClicked={isGroupedClicked}
            onGroupOptionsClickHandler={onGroupOptionsClickHandler}
            groupList={groupList}
            menuList={menuOptions(userRole)}
          />
        </div>
      )}
      {!isMenuDisplayed && isArrowDisplayed && !isReplaceTagFlow && (
        <ArrowForwardIosIcon />
      )}
      {openModal && <ModalComponent open={openModal} handleClose={handleModalClose} body={modalBody}/>}
      {deleteClientOrSiteError && <NotificationError messageClass="error" message={deleteClientOrSiteError}/>}
    </StyledSummaryHeader>
  );
};

export default SummaryHeader;
