import React, {FC, useEffect, useState} from "react";
import {StyledVendingKeyboard, StyledVendingMachineButtonsConfirmation} from "./styles";
import {OutlinedButton, PrimaryButton} from "../../components/Buttons";
import Header from "../../components/Header";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getVendingButtons} from "../VendingMachineButtons/selectors";
import {FilledBackgroundNotification} from "../../components/NotificationMessages";
import {getReceivedSocketMessageDetails} from "../../components/SocketCommunication/selectors";
import {socketSentMessage} from "../../components/SocketCommunication/actions";
import {addMachineDetails} from "../MachineDetailsPage/actions";
import {displayMachineDetails} from "../MachineDetailsPage/selectors";
import * as ROUTES from "../../components/Containers/Main/constants";

const VendingMachineButtonsConfirmation: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [buttonColor, setButtonColor] = useState('');
  const [clickedTargetIndexArr, setClickedTargetIndexArr] = useState([]);
  const [isDisabledConfirmButton, setIsDisabledConfirmButton] = useState(true);

  const [btnRefs, setBtnRefs] = useState([]);
  const [btnVals, setBtnVals] = useState([]);
  const [sentMessagesCounter, setSentMessagesCounter] = useState(0);
  const [receivedMessagesCounter, setReceivedMessagesCounter] = useState(0);
  const receivedSocketMessage = useSelector(getReceivedSocketMessageDetails);
  const [saveReceivedBtnVal, setSaveReceivedBtnVal] = useState(false);
  const specialButtons = useSelector(getVendingButtons).vendingButtons.extraButtons;
  const vendingConfiguredButtons = [
    ...useSelector(getVendingButtons).vendingButtons.digits,
    ...useSelector(getVendingButtons).vendingButtons.letters,
    ...useSelector(getVendingButtons).vendingButtons.extraButtons,
  ];

  const SUCCESS_BUTTON_MESSAGE = "Press corresponding button bellow";
  const START_NOTIFICATION_MESSAGE = "Press a button on the machine";
  const SUCCESS_CONFIGURATION = "Configuration done";
  const [messageDetails, setMessageDetails] = useState({content: START_NOTIFICATION_MESSAGE, color: 'yellow'});

  const [MT8Content, setMT8Content] = useState(null);
  const machineDetails = useSelector(displayMachineDetails);

  useEffect(() => {
    if (receivedSocketMessage && receivedSocketMessage.MT && receivedSocketMessage.MT === 8) {
      setMT8Content(receivedSocketMessage);
      dispatch(addMachineDetails({
        ...machineDetails,
        MT8MessageContent: receivedSocketMessage,
      }));
    }

  }, [receivedSocketMessage]);

  useEffect(() => {
    if (MT8Content !== null) {
      dispatch(socketSentMessage({MT: '17', topic: '/server/client/unitConfiguration'}));
      setSaveReceivedBtnVal(true);
      setSentMessagesCounter(prevState => prevState + 1);
    }
  }, [MT8Content]);

  useEffect(() => {
    if (receivedSocketMessage && receivedSocketMessage.BtnRef &&
      btnRefs.indexOf(receivedSocketMessage.BtnRef) === -1 && saveReceivedBtnVal) {
      setReceivedMessagesCounter(prevState => prevState + 1);
      setMessageDetails({content: SUCCESS_BUTTON_MESSAGE, color: 'green'});
      setBtnRefs(prevState => [...prevState, receivedSocketMessage.BtnRef]);
      setSaveReceivedBtnVal(false);
    }

  }, [receivedSocketMessage, receivedMessagesCounter, sentMessagesCounter, MT8Content]);


  useEffect(() => {
    if (btnRefs.length === btnVals.length && vendingConfiguredButtons.length === btnRefs.length) {
      setIsDisabledConfirmButton(false);
      setMessageDetails({content: SUCCESS_CONFIGURATION, color: 'green'});
    }
  },[btnRefs, btnVals]);

  const onClickKeyboardButtonHandler = (index: number) => {
    setButtonColor('green');
    setMessageDetails({content: START_NOTIFICATION_MESSAGE, color: 'yellow'});
    setSentMessagesCounter(prevState => prevState + 1);
    setBtnVals(prevState => [...prevState, vendingConfiguredButtons[index]]);
    setClickedTargetIndexArr(prevState => [...prevState, index]);
    setSaveReceivedBtnVal(true);
    if (btnVals.length < vendingConfiguredButtons.length-1) {
      dispatch(socketSentMessage({MT: '17', topic: '/server/client/unitConfiguration'}));
    }

  };

  const confirmButtons = () => {
    let specBtnRefs: string[] = [];
    let specBtnVals: string[] = [];
    let btnRefsWithoutSpec: string[] = [];
    let btnValsWithoutSpec: string[] = [];
    btnVals.forEach((value, index) => {
      if (specialButtons.indexOf(value) !== -1) {
        if (value.toLowerCase() === 'ok') {
          btnValsWithoutSpec.push(value);
          btnRefsWithoutSpec.push(btnRefs[index]);
        } else {
          specBtnVals.push(value);
          specBtnRefs.push(btnRefs[index]);
        }
      } else {
        btnValsWithoutSpec.push(value);
        btnRefsWithoutSpec.push(btnRefs[index]);
      }
    });
    dispatch(socketSentMessage({
      MT: '10',
      BtnRefs: [...btnRefsWithoutSpec],
      BtnVals: [...btnValsWithoutSpec],
      SpecBtnRefs: [...specBtnRefs],
      SpecBtnVals: [...specBtnVals],
      topic: '/server/client/unitConfiguration',
    }));
    history.push(ROUTES.MACHINE_LAYOUT_SELECTION);
  };

  return (
      <StyledVendingMachineButtonsConfirmation className="height-controller-wrapper">
        <Header title="Button Confirmation" />
        <div className="helper-screen-description">
          All buttons must be learnt by the unit, follow the instruction in the tile below
        </div>
        <FilledBackgroundNotification message={messageDetails.content}
                                      backgroundColorClass={messageDetails.color}/>
        <StyledVendingKeyboard className="keyboard">
        {vendingConfiguredButtons && vendingConfiguredButtons.length > 0 &&
          vendingConfiguredButtons.map((item: string, index: number) => (
          <PrimaryButton key={index}
                         onClick={() => onClickKeyboardButtonHandler(index)}
                         className={`${item && item.length > 2 ? 'smaller-font': ''}
                         ${(btnVals.indexOf(item) !== -1 && item !== btnVals[btnVals.length - 1]) ? 'blue-color' : ''}
                          ${(btnVals.length === btnRefs.length && vendingConfiguredButtons.length === btnRefs.length) ? 'blue-color' : ''}
                         ${buttonColor === 'green' && (clickedTargetIndexArr.indexOf(index) !== -1) ? 'green-color' : ''}`}>
            {item}
          </PrimaryButton>
        ))}
        </StyledVendingKeyboard>
        <div className="actions-wrapper">
          <OutlinedButton className="back-button" onClick={() => {history.goBack()}}>Back</OutlinedButton>
          <PrimaryButton className="continue-button"
                         disabled={isDisabledConfirmButton}
                         onClick={confirmButtons}>
            Continue
          </PrimaryButton>
        </div>
      </StyledVendingMachineButtonsConfirmation>
  );
};

export default VendingMachineButtonsConfirmation;
