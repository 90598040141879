import React from 'react';
import {StyledTechnicianSiteSummary} from "./styles";
import {OutlinedButton, PrimaryButton} from "../../components/Buttons";
import {useHistory} from "react-router-dom";
import SitePage from "../SitePage";
import {useDispatch, useSelector} from "react-redux";
import {getSiteDetailsData} from "../AllClientsSitesPage/selectors";
import {displayMachineDetails} from "../MachineDetailsPage/selectors";
import {addMachineDetails} from "../MachineDetailsPage/actions";
import * as ROUTES from "../../components/Containers/Main/constants";

export const TechnicianSiteSummary = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const siteDetails = useSelector(getSiteDetailsData);
  const siteId = siteDetails && siteDetails.id;
  const machineDetails = useSelector(displayMachineDetails);

  const confirmAddingNewMachineHandler = () => {
    dispatch(addMachineDetails({...machineDetails, siteId}));
    history.push(ROUTES.MACHINE_DETAILS);
  };

  return (
    <StyledTechnicianSiteSummary className="height-controller-wrapper">
      <SitePage isTechnician={true}/>
      <div className="actions-wrapper">
        <OutlinedButton className="back-button" onClick={() => {history.goBack()}}>Back</OutlinedButton>
        <PrimaryButton className="continue-button" onClick={confirmAddingNewMachineHandler}>Continue</PrimaryButton>
      </div>
    </StyledTechnicianSiteSummary>
  );
}
