import React, {FC} from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {StyledModal} from "./styles";
import paymeTheme from '../../assets/styling/payme-theme';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: paymeTheme.colorWhite,
      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      height: 'fit-content',
      maxWidth: 400,
      width: '75%',
      borderRadius: '30px',
      padding: '30px',
      margin: 'auto',
      "&:focus": {
        outline: 'none'
      }
    },
  }),
);

interface Props {
  open: boolean;
  handleClose: () => void;
  body: any;
}

const ModalComponent: FC<Props> = ({open, handleClose, body}) => {
  const classes = useStyles();

  return (
    <StyledModal>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>{body}</div>
      </Modal>
    </StyledModal>
  );
};

export default ModalComponent;
