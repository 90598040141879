export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS";
export const GET_CLIENTS_REQUEST = "GET_CLIENTS_REQUEST";
export const GET_CLIENTS_FAILURE = "GET_CLIENTS_FAILURE";


export const GET_SITES_SUCCESS = "GET_SITES_SUCCESS";
export const GET_SITES_REQUEST = "GET_SITES_REQUEST";
export const GET_SITES_FAILURE = "GET_SITES_FAILURE";

export const GET_SITE_DETAILS_SUCCESS = "GET_SITE_DETAILS_SUCCESS";
export const GET_SITE_DETAILS_FAILURE = "GET_SITE_DETAILS_FAILURE";

export const GET_OFF_SITE_DETAILS_SUCCESS = "GET_OFF_SITE_DETAILS_SUCCESS";
export const GET_OFF_SITE_DETAILS_FAILURE = "GET_OFF_SITE_DETAILS_FAILURE";
