import styled from 'styled-components';
import media from "../../../assets/styling/payme-media-queries";
import paymeTheme from "../../../assets/styling/payme-theme";


const StyledClientForm = styled.div `
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
 
  .client-name {
    padding-top: 15px;
  }

  ${media.desktop} {
    .client-name {
      padding-top: 50px;
    }
  }
  
`;

export { StyledClientForm } ;
