import {
  ADD_NEW_MEMBER_FAILURE,
  ADD_NEW_MEMBER_REQUEST,
  ADD_NEW_MEMBER_SUCCESS,
  DISPLAY_USER_DETAILS, USER_DETAILS_ATTEMPT, USER_DETAILS_FAILURE, USER_DETAILS_SUCCESS
} from "./constants";


export const displayUserDetails = (data: any) => ({
  type: DISPLAY_USER_DETAILS,
  payload: data
});


export const AddNewMemberAttempt = (data: any) => ({
  type: ADD_NEW_MEMBER_REQUEST,
  payload: data
});

export const addNewMemberSuccess = (data: any) => ({
  type: ADD_NEW_MEMBER_SUCCESS,
  payload: { ...data},
});

export const addNewMemberFailure = (error: any) => ({
  type: ADD_NEW_MEMBER_FAILURE,
  payload: error
});

export const memberDetailsAttempt = () => ({
  type: USER_DETAILS_ATTEMPT,
});

export const memberDetailsSuccess = (data: any) => ({
  type: USER_DETAILS_SUCCESS,
  payload: { ...data},
});

export const memberDetailsFailure = (error: any) => ({
  type: USER_DETAILS_FAILURE,
  payload: error
});

