import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import PaymeTheme from "../../assets/styling/payme-theme";


const StyledHeader = styled.div `
  background-color: ${PaymeTheme.headerColor};
  padding-bottom: ${PaymeTheme.paddingRemXs};
  text-align: center;
  font-weight: ${PaymeTheme.fontBold};
  font-size: ${PaymeTheme.fontRemSizeXLg};
  color: ${PaymeTheme.colorWhite};
  width: 100%;
  position: fixed;
  z-index: 2;
  top: 0;
  
  ${media.desktop} {
    padding-top: ${PaymeTheme.paddingRem};
  }
`;

export { StyledHeader } ;
