export const HOME = "/";
export const LOGIN = "/login"; 
export const ADD_CLIENT_DETAILS = "/add-client-details";
export const EDIT_CLIENT_NAME = "/edit-client-name";
export const EDIT_SITE_DETAILS = '/edit-site-details';
export const ADD_CLIENT_SUMMARY = "/add-client-summary";
export const ALL_CLIENTS_SITES = "/all-clients-sites";
export const SETUP_ALL_CLIENTS_SITES = "/setup-all-clients-sites";
export const TEHNICIAN_SITE_PAGE = '/technician-site-page';
export const TEHNICIAN_CLIENT_PAGE = '/tehnician-client-page';
export const SITE_PAGE = '/site-page';
export const CLIENT_PAGE = '/client-page';
export const CLIENT_SITES_LIST_PAGE = '/client-sites-list-page';
export const MACHINE_DETAILS = "/machine-details";
export const MACHINE_SUMMARY = "/machine-summary";
export const EXTERNAL_NFC_TAG = "/external-nfc-tag";
export const MACHINE_TEST = "/machine-test";
export const MACHINE_TEST_RESULTS = "/machine-test-results";
export const MACHINE_CONNECTION_TYPE = "/machine-connection-type";
export const MACHINE_BUTTONS = "/machine-buttons";
export const MACHINE_BUTTONS_CONFIRMATION = "/machine-buttons-confirmation";
export const MACHINE_LAYOUT_SELECTION = "/machine-layout-selection";
export const MACHINE_OPTIONS = "/machine-options";
export const MACHINE_STOCK_DETAILS = "/machine-stock-details";
export const ADD_NEW_STOCK = '/add-new-stock';
export const GROUPS = '/groups';
export const GROUP_DETAILS = '/group-details';
export const SITE_COLLECTION = '/site-collection';
export const MACHINE_COLLECTION = '/machine-collection';
export const COLLECTION_RECEIPT = '/collection-receipt';
export const COLLECTION_HISTORY = '/collection-history';
export const MACHINE_DEACTIVATION = '/machine-deactivation';
export const MACHINE_DEACTIVATION_CONFIRMATION = '/machine-deactivation-confirmation';
export const SCAN_QR_CODE = '/scan-qr-code';
export const SCAN_QR_CODE_ERROR = '/scan-qr-code-error';
export const REPLACEMENT_TAG_INSTRUCTIONS = '/replacement-tag-instructions';
export const MACHINE_SITE_CHANGE = '/machine-site-change';
export const CHANGE_MACHINE_POSITION = '/change-machine-position';
export const TEAM = '/team';
export const TEAM_ACTIVITY_LOG = '/team-activity-log';
export const NEW_TEAM_MEMBER = '/new-team-member';
export const EDIT_TEAM_MEMBER = '/edit-team-member';
export const MEMBER_PROFILE = '/member-profile';
export const COMPANY_PROFILE = '/company-profile';
export const ACTIVATE_ACCOUNT = '/activate-account';
export const ADD_USER_EMAIL = '/add-user-email';
export const CREATE_PASSWORD = '/create-password';
export const UNIT_DETAILS_PAGE = '/unit-details';
export const UNIT_DETAILS_SETUP = '/unit-details-setup';

export const SET_FLOW_TYPE = 'SET_FLOW_TYPE';
export const IS_END_USER = false;
