import styled from 'styled-components';
import media from "../../../assets/styling/payme-media-queries";
import paymeTheme from "../../../assets/styling/payme-theme";


const StyledDisplayClientDetailsStep = styled.div `
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  
  .summary-details-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  
  .add-more-sites {
    margin-top: ${paymeTheme.paddingRemLg};
  }
  
  .client-name {
    margin: ${paymeTheme.paddingRem};
  }
  
  ${media.desktop} {
      
  }
  
`;

export { StyledDisplayClientDetailsStep } ;
