import React, {FC, useEffect, useState} from "react";
import {StyledCollectionReceipt} from "./styles";
import Header from "../../components/Header";
import {PrimaryButton} from "../../components/Buttons";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import LoadingComponent from "../../components/Loading";
import {getSiteDetailsData} from "../AllClientsSitesPage/selectors";
import CollectionCard from "../../components/CollectionCard";
import MachineCollectionCardDetails from "../../components/MachineCollectionCardDetails";
import CollectionSummaryHeader from "../../components/CollectionSummaryHeader";
import {SiteMenuAppBar} from "../SitePage/SiteMenu";
import ModalComponent from "../../components/Modal";
import {GeneralTextField} from "../../components/Inputs";
import {useModalStyles} from "../../components/Modal/styles";
import {getCashCollectedValues} from "./selectors";
import {displayMachineCollectionDetails} from "../MachineCollectionDetails/selectors";
import {displaySiteCollectionDetails, displayTotalCashCollected} from "../SiteCollection/selectors";
import {asyncRequest} from "../../api";
import {startDateArrayReturned} from "../../utils/extractStartDateArr";
import {addCashCollectedValue} from "./actions";
import {NotificationError} from "../../components/NotificationMessages";
import * as ROUTES from "../../components/Containers/Main/constants";

const CollectionReceipt: FC= () => {
  const classes = useModalStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const siteDetails = useSelector(getSiteDetailsData);
  const cashCollectedValuesData = useSelector(getCashCollectedValues);
  const cashCollectedValues = cashCollectedValuesData.cashCollectedValues;
  const totalCashCollected = useSelector(displayTotalCashCollected);
  const siteCollectionDetails = useSelector(displaySiteCollectionDetails);
  const machineCollectionDetails = useSelector(displayMachineCollectionDetails);
  const [isLoading, setIsLoading] = useState(false);
  const [requestError, setRequestError] = useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [emailValue, setEmailValue] = useState('');
  const [isMailFormatError, setIsMailFormatError] = useState(false);
  const prevLocation = history.location.state.prevUrl;
  const collectionGroupId = history && history.location && history.location.state
    && history.location.state.collectionGroupId;
  let re = /\S+@\S+\.\S+/;
  const isIdenticalStartData = siteCollectionDetails &&
    siteCollectionDetails.every( (val, i, arr) =>
      val.collectionPeriod.startTime.split(" ")[0] === arr[0].collectionPeriod.startTime.split(" ")[0]);
  const [collectionCardStartDate, setCollectionCardStartDate] = useState('');
  const [startDateArrState, setStartDateArrState] = useState([]);

  const saveCollectionHandler = () => {
    openModalHandler();
  };

  const onEditReceiptHandler = () => {
    if (prevLocation === ROUTES.SITE_COLLECTION) {
      history.push({
        pathname: ROUTES.SITE_COLLECTION,
        state: {prevUrl: prevLocation, initialUrl:  history.location.state.initialUrl},
      });
    } else {
      history.push({
        pathname: ROUTES.MACHINE_COLLECTION,
        state: {prevUrl: prevLocation, initialUrl:  history.location.state.initialUrl},
      });
    }
  };

  const [isCollectionHistory, setIsCollectionHistory] = useState(false);

  useEffect(() => {
    if (prevLocation === ROUTES.COLLECTION_HISTORY) {
      setIsCollectionHistory(true);
    } else {
      setIsCollectionHistory(false);
    }
  }, [history]);

  useEffect(() => {
    setStartDateArrState(startDateArrayReturned(siteCollectionDetails));
  }, [siteCollectionDetails]);

  useEffect(() => {
    setCollectionCardStartDate(new Date(Math.min.apply(null,startDateArrState)).toLocaleDateString());
  }, [startDateArrState]);

  const openModalHandler = () => {
    setOpenModal(true);
  };

  const closeModalHandler = () => {
    setIsLoading(true);
    if (prevLocation === ROUTES.COLLECTION_HISTORY && emailValue === '') {
      history.push(ROUTES.HOME);
      return;
    }
    if (prevLocation === ROUTES.COLLECTION_HISTORY && emailValue !== '' && re.test(emailValue)) {
      setIsMailFormatError(false);
      setOpenModal(false);
      asyncRequest({
        config:{
          method: "POST",
          data: {
            collectionGroupId,
            email: emailValue,
          },
        },
        endpoint: 'api/collection/history/report',
      }).then(response => {
        setIsLoading(false);
        history.push(ROUTES.HOME);
      })
        .catch(err => {
          console.log(err);
          setIsLoading(false);
          setRequestError(err.response.data.title);
        });
      return;
    }
    if (emailValue !== '' && !re.test(emailValue)) {
      setIsMailFormatError(true);
    } else {
      setIsMailFormatError(false);
      setOpenModal(false);
      asyncRequest({
        config:{
          method: "POST",
          data: {
            collections: prevLocation === ROUTES.SITE_COLLECTION ? siteCollectionDetails : [machineCollectionDetails],
            sendReport: emailValue !== '',
            email: emailValue,
          },
        },
        endpoint: 'api/collection',
      }).then(response => {
        history.push(ROUTES.HOME);
        setIsLoading(false);
        dispatch(addCashCollectedValue({...cashCollectedValuesData, cashCollectedValues: []}));
      })
        .catch(err => {
          console.log(err);
          setIsLoading(false);
          setRequestError(err.response.data.title);
        });
    }

  };

  const onChangeEmailValueHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setEmailValue(event.target.value);
    if (re.test(emailValue)) {
      setIsMailFormatError(false);
    }
  };

  const modalBody = (
    <div className="modal-wrapper">
      <div className={classes.body}>
        <div>If you don't want to send a report by email, just press the button</div>
        <GeneralTextField placeholder="Enter email to send report to"
                          id="email-value"
                          value={emailValue}
                          variant="filled"
                          error={isMailFormatError}
                          helperText={isMailFormatError && "Incorrect email format"}
                          type="email"
                          onChange={onChangeEmailValueHandler}
        />
      </div>
      <div className="modal-footer">
        <div className={classes.footer}>
          <PrimaryButton type="button" onClick={closeModalHandler}>Send/Finish</PrimaryButton>
        </div>
      </div>
    </div>
  );

  const menuList = [{
    name: 'Edit receipt',
    path: '',
    onClickHandler: onEditReceiptHandler,
    hasSubmenu: false,
  }];

  const mappedSiteCollectionUnits = siteCollectionDetails && siteCollectionDetails.length > 0 &&
    siteCollectionDetails.map((item: any, position: number) => {
      return <MachineCollectionCardDetails key={`machine-collection-receipt-${position}`}
                                           position={position}
                                           machineCollectionDetails={item}
                                           isCollectionReceipt={true}
                                           isCollectionHistory={isCollectionHistory}
                                           isIdenticalStartData={isIdenticalStartData}/>;
    });

  return (
    <StyledCollectionReceipt className="height-controller-wrapper">
      <Header title="Collection Receipt"/>
      <div className="collection-summary-wrapper">
        <CollectionSummaryHeader siteDetails={siteDetails} isSiteCollection={false}/>
        {!isCollectionHistory && <div className="site-menu"><SiteMenuAppBar menuList={menuList}/></div>}
      </div>
      <CollectionCard isCollectionHistory={isCollectionHistory}
                      totalCashCollected={totalCashCollected}
                      collectionCardStartDate={collectionCardStartDate}
                      isIdenticalStartData={prevLocation !== '/site-collection' ? true : isIdenticalStartData}/>
      {prevLocation === '/machine-collection' &&
      <MachineCollectionCardDetails isCollectionHistory={isCollectionHistory}
                                    machineCollectionDetails={machineCollectionDetails}
                                    collectionCardStartDate={machineCollectionDetails.collectionPeriod.startTime}
                                    isIdenticalStartData={true}
                                    isCollectionReceipt={true} />}
      {prevLocation !== '/machine-collection' && mappedSiteCollectionUnits}
      <div className="actions-wrapper">
        <PrimaryButton onClick={saveCollectionHandler}>Finish</PrimaryButton>
      </div>
      <ModalComponent open={openModal} handleClose={closeModalHandler} body={modalBody}/>
      {isLoading && <LoadingComponent/>}
      {requestError && <NotificationError messageClass="error" message={requestError}/>}
    </StyledCollectionReceipt>
  );
};

export default CollectionReceipt;
