import React, { FC } from "react";
import { StyledNfcSection } from "./styles";
import { Tooltip } from "@material-ui/core";
import { WARNING_DEVICE_MESSAGE } from "../../pages/HomeLoggedUser/constants";
import SvgTagIcon, { DisabledSvgTagIcon } from "./NFCIcon";

interface Props {
  isDesktop: boolean;
  onClickHandler?: () => void;
}

const NfcTagSection: FC<Props> = ({ isDesktop, onClickHandler }) => {
  return (
    <StyledNfcSection className="icon-wrapper" onClick={onClickHandler}>
      <div className="nfc-section-wrapper">
        {isDesktop ? (
          <Tooltip title={WARNING_DEVICE_MESSAGE}>
            <DisabledSvgTagIcon />
          </Tooltip>
        ) : (
          <SvgTagIcon />
        )}
      </div>
    </StyledNfcSection>
  );
};

export default NfcTagSection;
