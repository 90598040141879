import React, {FC, useEffect, useState} from "react";
import {StyledClientPage} from "./styles";
import MachineType from "../SitePage/MachineTypeSection";
import Header from "../../components/Header";
import SummaryHeader from "../../components/SummaryHeader";
import {useDispatch, useSelector} from "react-redux";
import {getClientsListData, getClientsListError, getClientsListLoading} from "../AllClientsSitesPage/selectors";
import LoadingComponent from "../../components/Loading";
import {NotificationError} from "../../components/NotificationMessages";
import {IClientItem, ISiteDetailsData, IUnitDetails} from "../AllClientsSitesPage/model";
import {OutlinedButton} from "../../components/Buttons";
import {useHistory, useParams} from "react-router-dom";
import {asyncRequest} from "../../api";
import {addClientDetails} from "../AddNewClientPage/actions";

interface Props {
  isTechnician?: boolean;
}
const ClientPage:FC<Props> = ({isTechnician}) => {
  const dispatch = useDispatch();
  const clientsList = useSelector(getClientsListData);
  const errorClientDetails = useSelector(getClientsListError);
  const loadingClientDetails = useSelector(getClientsListLoading);

  const [clientDetailsState, setClientDetailsState] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setIsError] = useState('');
    
  const { id } = useParams();
  const history = useHistory();
  const sites = !!clientDetailsState && !!clientDetailsState.sites && !!clientDetailsState.sites.length &&
    clientDetailsState.sites.map((item: ISiteDetailsData | undefined, index: number) => (
        <div key={`${index}-site`}>
          <SummaryHeader isMenuDisplayed={!isTechnician && !(item.siteType.code === "OFF_SITE")}
                         isArrowDisplayed={false}
                         site={item}
                         isClientPage={true}/>
          {!!item && !!item.units && !!item.units.length &&
          item.units.map((machine: IUnitDetails, index: number) =>
            <MachineType key={index}
                         itemDetails={machine}
                         hasMenu={!isTechnician}
                         site={item}
                         isClientPage={true}
                         clientId={clientDetailsState.id}/>
          )}
        </div>
    ));

  useEffect(() => {
    if (clientsList && clientsList.items && clientsList.items.length > 0 &&
      clientsList.items.filter((client: IClientItem) => client.id == id).length > 0) {
      setClientDetailsState(clientsList.items.filter((client: IClientItem) => client.id == id)[0]);
    }
  }, []);


  useEffect(() => {
    clientDetailsState && dispatch(addClientDetails(clientDetailsState.name, clientDetailsState.sites))
  }, [clientDetailsState]);

  useEffect(() => {
    if (!!id && !clientsList) {
      setIsLoading(true);
      asyncRequest({
        config: {
          method: "GET",
        },
        endpoint: `api/client/${id}`,
      })
        .then((response) => {
          dispatch(addClientDetails( response.name, response.sites));
          setClientDetailsState(response);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsError(err.response.data.title);
          setIsLoading(false);
        });
    }
  }, [clientsList]);

  return (
    <StyledClientPage className="height-controller-wrapper">
      {loadingClientDetails && <LoadingComponent/>}
      {errorClientDetails && <NotificationError message={errorClientDetails} messageClass="error"/>}
      <Header title={isTechnician ? "Adding new machine to" : "Client summary"}/>
      {sites}
      {!isTechnician && (<div className="actions-wrapper">
        <OutlinedButton className="back-button" onClick={() => {history.goBack()}}>Back</OutlinedButton>
      </div>)}
      {isLoading && <LoadingComponent/>}
      {error && <NotificationError messageClass="error" message={error}/>}
    </StyledClientPage>
  );
};

export default ClientPage;
