import styled from "styled-components";
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";

const StyledLoggedUserHome = styled.div`
  padding: 0;
  position: relative;
  text-align: center;
  font-weight: ${paymeTheme.fontBold};
  font-size: ${paymeTheme.fontRemSizeBase};
  padding-bottom: 56px!important;
  
  &.master,
  &.admin {
    padding-top: 50px!important;
    padding-bottom: 60px!important;
    .qr-nfc-wrapper {
      height: auto;
    }
  }

  .home-header {
    background-color: ${paymeTheme.headerColor};
    padding-bottom: ${paymeTheme.paddingRemXs};
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  .home-title {
    font-weight: ${paymeTheme.fontBold};
    font-size: ${paymeTheme.fontRemSizeXLg};
    color: ${paymeTheme.colorWhite};

    .flote-word {
      color: ${paymeTheme.floteColor};
    }
  }

  .qr-nfc-description {
    color: ${paymeTheme.colorBlack};
    padding: ${paymeTheme.paddingRem} ${paymeTheme.paddingRemMd} 0 ${paymeTheme.paddingRemMd};
    font-size: ${paymeTheme.fontRemSizeNormal};
  }

  .button-wrapper {
    width: fit-content;
    margin: auto;
  }

  .nfc-section-wrapper {
    display: flex;
    justify-content: center;
    align-content: center;
  }

  .nfc-title {
    color: ${paymeTheme.headerColor};
    padding: 0 ${paymeTheme.paddingRem} ${paymeTheme.paddingRem} ${paymeTheme.paddingRem};
    font-size: ${paymeTheme.fontRemSizeLg};
  }

  .button-section-wrapper {
    padding-bottom: ${paymeTheme.paddingRem};
    button {
      margin-bottom: ${paymeTheme.paddingRemXs};
    }
  }

  .qr-nfc-wrapper {
    display: flex;
    align-items: center;
  }
  
  .nfc-error-wrapper {
    color: ${paymeTheme.errorColor};
    text-align: center;
    position: absolute;
    z-index: 5;
    left: 0;
    right: 0;
    top: 35px;
    background-color: ${paymeTheme.colorWhite};
    
    .error-title {
      font-size: ${paymeTheme.fontRemSizeH2};
      padding-bottom: ${paymeTheme.paddingRemSm}
    }
    
    .error-body {
      padding-bottom: ${paymeTheme.paddingRemXs};
    }
  }
  
  
  .feedback-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: ${paymeTheme.paddingRemMd};
  }
  
  .feedback-form-wrapper {
    position: relative;
    max-width: 300px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: ${paymeTheme.colorBlack};
    margin: ${paymeTheme.paddingRemMd} auto;
    
    .MuiSvgIcon-root {
      fill: ${paymeTheme.successColor};
      width: ${paymeTheme.paddingXLg};
      height: ${paymeTheme.paddingXLg};
      margin-left: ${paymeTheme.paddingSm};
      margin-bottom: ${paymeTheme.paddingXs};
      margin-top: -${paymeTheme.paddingXs};
      transform: rotate(-50deg);
    }
    textarea {
      width: 80%;
      min-height: ${paymeTheme.paddingLg}!important;
      font-size: ${paymeTheme.fontRemSizeLg};
    }
  }
  
  .qr-nfc-section {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      .qr-nfc-wrapper {
        flex-grow: 1;
      }
  }

  ${media.desktop} {
    .home-header {
      padding-top: ${paymeTheme.paddingRem};
    }
    
    .qr-nfc-description {
      padding-top: 2.5rem;
    }
  }

  ${media.desktopMedium} {
     &.master,
     &.admin {
        padding-bottom: 60px!important;
        max-width: 500px;
        margin: auto;
        .qr-nfc-wrapper {
          height: auto;
        }
     }
  }
  }
`;

export { StyledLoggedUserHome };

export const StyledHomepage = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 250px);
  max-width: 500px;
  margin: auto;
  height: 70vh; //to be changed back to 80 when we'll remove feedback
`;
