import styled from 'styled-components';
import paymeTheme from "../../assets/styling/payme-theme";
import media from "../../assets/styling/payme-media-queries";





const StyledResetPassword= styled.div `
  .error-message,
  .success-message {
    font-size: ${paymeTheme.fontRemSizeH4};
    position: fixed;
    top: 30%;
    left: 0;
    right: 0;
    margin: auto ${paymeTheme.paddingRemLg};
    text-align: center;
  }
  .error-message {
    color: ${paymeTheme.errorColor};
  }
  .success-message {
    color: ${paymeTheme.successColor};
  }
  form {
    padding-top: 10vh;
  }
  .MuiFormHelperText-contained {
    margin-right: 0;
  }
  ${media.desktop} {
    .error-message,
    .success-message {
      margin: auto;
    }
  }

`;

export { StyledResetPassword } ;
