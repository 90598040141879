import {ICompanyState} from "./model";
import {GET_COMPANY_DETAILS_FAILURE, GET_COMPANY_DETAILS_REQUEST, GET_COMPANY_DETAILS_SUCCESS} from "./constants";


const companyDetailsInitialState: ICompanyState = {
  data: {
    companyName: '',
    firstName: '',
    lastName: '',
    email: '',
    country: '',
    currency: '',
    companyType: '',
    start: '',
    products: [],
  },
  loading: false,
  error: null
};

export const getCompanyDetailsReducer = (state = companyDetailsInitialState, action: any) => {
  switch (action.type) {
    case GET_COMPANY_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case GET_COMPANY_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};
