import { takeLatest } from "redux-saga/effects";

export function* navigationSaga(action: any) {
  window.ReactNativeWebView &&
  window.ReactNativeWebView.postMessage(
    JSON.stringify({ pageRoute: window.location.pathname })
  );
}

export default  [
 takeLatest('@@router/LOCATION_CHANGE', navigationSaga),
]
