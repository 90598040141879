import React, {FC, useEffect, useState} from "react";
import {StyledChangeMachinePosition} from "./styles";
import Header from "../../components/Header";
import {OutlinedButton, PrimaryButton} from "../../components/Buttons";
import {useHistory} from "react-router-dom";
import {GeneralTextField} from "../../components/Inputs";
import {useDispatch, useSelector} from "react-redux";
import {getSiteDetailsData} from "../AllClientsSitesPage/selectors";
import {displayMachineDetails} from "../MachineDetailsPage/selectors";
import {addMachineDetails} from "../MachineDetailsPage/actions";
import * as ROUTES from "../../components/Containers/Main/constants";


const ChangeMachinePosition = () => {

  const machineDetails = useSelector(displayMachineDetails);
  const siteDetails = useSelector(getSiteDetailsData);

  const dispatch = useDispatch();
  const history = useHistory();

  const [positionOnSite, setPositionOnSite] = useState(machineDetails.position);

  const onChangePositionHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setPositionOnSite(event.target.value);
  };

  const continueOnClickHandler = () => {
    dispatch(addMachineDetails({
      ...machineDetails,
      position: positionOnSite,
    }));
    history.push(ROUTES.MACHINE_SUMMARY);
  };


  return (
    <StyledChangeMachinePosition className="height-controller-wrapper">
      <Header title="Machine details"/>
      <form>
        <GeneralTextField id="site-position"
                          value={positionOnSite}
                          onChange={onChangePositionHandler}
                          variant="filled"
                          placeholder="Position on site"/>
      </form>
      <div className="actions-wrapper">
        <OutlinedButton className="back-button" onClick={() => {history.goBack()}}>Back</OutlinedButton>
        <PrimaryButton className="continue-button" onClick={continueOnClickHandler}>
          Continue
        </PrimaryButton>
      </div>
    </StyledChangeMachinePosition>
  );
};

export default ChangeMachinePosition;
