import {ADD_TOTAL_CASH_COLLECTED, GET_SITE_COLLECTION,} from "./constants";
import {ISiteCollectionState, ITotalCashCollected} from "./model";


// add site collection action

export const addSiteCollectionDetails = (data: ISiteCollectionState) => ({
  type: GET_SITE_COLLECTION,
  payload: data
});

export const addTotalCashCollected = (data: ITotalCashCollected) => ({
  type: ADD_TOTAL_CASH_COLLECTED,
  payload: data
});
