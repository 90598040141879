import styled from "styled-components";
import media from "../../../assets/styling/payme-media-queries";
import paymeTheme from "../../../assets/styling/payme-theme";

const StyledClientsList = styled.div`
  .sites-town-wrapper {
    text-align: center;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    .sites-wrapper,
    .item-town {
      width: auto;
    }

    .sites-wrapper {
      width: 100%;
      &.item-sites-multiple {
        text-align: center;
        max-width: 100%;
        flex-grow: 1;
      }
    }
  }

  svg {
    align-self: center;
  }

  ${media.desktop} {
  }
`;

export { StyledClientsList };
