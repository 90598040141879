import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";




const StyledSiteCollection = styled.div `
  .MuiInputBase-input,
  .total-cash-collected {
    color: ${paymeTheme.errorColor};
    font-weight: ${paymeTheme.fontSemiBold};
  }
  ${media.desktop} {
    
  }

`;

export { StyledSiteCollection } ;
