import React, { FC, useEffect, useState } from "react";
import {HELPER_QR_NFC_DESCRIPTION} from "../constants";
import NfcTagSection from "../../../components/NfcTagIcon";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addMachineDetails } from "../../MachineDetailsPage/actions";
import { displayMachineDetails } from "../../MachineDetailsPage/selectors";
import { isIOS, isAndroid } from "react-device-detect";
import {ROLE_COLLECTOR, ROLE_MASTER, ROLE_TECHNICIAN} from "../../../constants";
import QRCodeSection from "../../../components/QRCodeSection";
import * as ROUTES from "../../../components/Containers/Main/constants";

interface Props {
  isDesktop: boolean;
  userRole: string;
}

const QrNfcHomeSection: FC<Props> = ({ isDesktop, userRole }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const machineDetails = useSelector(displayMachineDetails);
  const [hubTagId, setHubTagId] = useState(machineDetails.hubTagId);
  const [qrCodeId, setQrCodeId] = useState();
  const [hasAndroidNfcEnabled, setHasAndroidNfcEnabled] = useState('true');
  const urlPath = window.location.pathname;

  const handleNativeUnitId = (event: any) => {
    if(!isIOS && !isAndroid) {
      return;
    }
    try {
      const unitDetailsFromRN = JSON.parse(event.data);
      const unitId = unitDetailsFromRN.unitID;
      const qrCodeId = unitDetailsFromRN.qrCodeId;
      const hasAndroidNfcEnabled = unitDetailsFromRN.hasAndroidNfcEnabled;
      setHasAndroidNfcEnabled(hasAndroidNfcEnabled);
      unitId && setHubTagId(unitId);
      qrCodeId && setQrCodeId(qrCodeId);
    } catch (e) {
      console.warn('Error parsing unitDetailsFromRN, just turn on native app!');
    }
  };

  useEffect(() => {
    if (hubTagId && hubTagId !== "" && hubTagId !== machineDetails.hubTagId) {
      dispatch(
        addMachineDetails({
          ...machineDetails,
          hubTagId,
        })
      );
      history.push(ROUTES.MACHINE_OPTIONS);
    }
    if (!!qrCodeId) {
      dispatch(
        addMachineDetails({
          ...machineDetails,
          QrCodeId: qrCodeId,
        })
      );
      history.push(ROUTES.MACHINE_OPTIONS);
    }
  }, [hubTagId, qrCodeId]);

  useEffect(() => {
    if (
      urlPath === ROUTES.HOME &&
      !isDesktop &&
      userRole &&
      userRole === ROLE_TECHNICIAN ||
      userRole === ROLE_MASTER ||
      userRole === ROLE_COLLECTOR
    ) {
      if (isIOS) {
        window.addEventListener("message", handleNativeUnitId);
        return () => window.removeEventListener("message", handleNativeUnitId);
      }
      if (isAndroid) {
        document.addEventListener("message", handleNativeUnitId);
        return () =>
          document.removeEventListener("message", handleNativeUnitId);
      }
      window.addEventListener("message", handleNativeUnitId);
      return () => window.removeEventListener("message", handleNativeUnitId);
    }
  }, []);

  const nfcTagHandler = () => {
    window.ReactNativeWebView &&
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ needHubTagId: "true" })
    );
  };

  const qRCodeHandler = () => {
    window.ReactNativeWebView &&
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ showQrCode: "true" })
    );
  };

  return (
    <div className="qr-nfc-section">
      {hasAndroidNfcEnabled === 'false' &&
      <div className="nfc-error-wrapper">
        <div className="error-title">TURN ON NFC</div>
        <div className="error-body">Go to device Settings and enable NFC</div>
      </div>
      }
      <div className="qr-nfc-description">{HELPER_QR_NFC_DESCRIPTION}</div>
      <div className="qr-nfc-wrapper">
        <QRCodeSection onClickHandler={qRCodeHandler} isDesktop={isDesktop}/>
        <NfcTagSection onClickHandler={nfcTagHandler} isDesktop={isDesktop} />
      </div>
    </div>
  );
};

export default QrNfcHomeSection;
