import React, {FC, useEffect, useState} from "react";
import {StyledSiteCollection} from "./styles";
import Header from "../../components/Header";
import {OutlinedButton, PrimaryButton} from "../../components/Buttons";
import {useHistory, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import LoadingComponent from "../../components/Loading";
import {getSiteDetailsData} from "../AllClientsSitesPage/selectors";
import CollectionCard from "../../components/CollectionCard";
import MachineCollectionCardDetails from "../../components/MachineCollectionCardDetails";
import CollectionSummaryHeader from "../../components/CollectionSummaryHeader";
import {displaySiteCollectionDetails, displayTotalCashCollected} from "./selectors";
import {asyncRequest} from "../../api";
import {addSiteCollectionDetails, addTotalCashCollected} from "./actions";
import {startDateArrayReturned} from "../../utils/extractStartDateArr";
import * as ROUTES from "../../components/Containers/Main/constants";

const SiteCollection: FC= () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const siteDetails = useSelector(getSiteDetailsData);
  const siteId = siteDetails && siteDetails.id;
  const siteCollectionDetails = useSelector(displaySiteCollectionDetails);
  const totalCashCollected = useSelector(displayTotalCashCollected);

  const prevStateLocation = history.location.state && history.location.state.initialUrl;
  const isIdenticalStartData = siteCollectionDetails &&
    siteCollectionDetails.every((val, i, arr) =>
      val.collectionPeriod.startTime.split(" ")[0] === arr[0].collectionPeriod.startTime.split(" ")[0]);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCashCollectedState, setTotalCashCollectedState] = useState(totalCashCollected);
  const [collectionCardStartDate, setCollectionCardStartDate] = useState('');
  const [startDateArrState, setStartDateArrState] = useState([]);
  const [siteCollectionDetailsState, setSiteCollectionDetailsState] = useState();
  const [loadingMachine, setLoadingMachine] = useState(false);

  useEffect(() => {
    if (totalCashCollected) {
      setTotalCashCollectedState(totalCashCollected);
    }
  }, []);

  useEffect(() => {
    if (siteCollectionDetails && siteCollectionDetails.length > 0) {
      setSiteCollectionDetailsState(siteCollectionDetails);
    }
  }, [siteCollectionDetails]);

  const confirmCollectionHandler = () => {
    dispatch(addSiteCollectionDetails(siteCollectionDetailsState));
    dispatch(addTotalCashCollected(totalCashCollectedState));
    history.push({
      pathname: ROUTES.COLLECTION_RECEIPT,
      state: {  prevUrl: location.pathname, initialUrl:  prevStateLocation},
    });
  };

  useEffect(() => {
    if (siteCollectionDetails) {
      setStartDateArrState(startDateArrayReturned(siteCollectionDetails));
    }
  }, [siteCollectionDetails]);

  useEffect(() => {
    setCollectionCardStartDate(new Date(Math.min.apply(null,startDateArrState)).toLocaleDateString());
  }, [startDateArrState]);

  const calculateTotalCollectionAmounts = () => {
    let totalContactlessMoneyGross = 0;
    let totalEstimatedCashAmount = 0;
    let totalGrossMachineIncome = 0;
    let actualCashCollected = 0;
    siteCollectionDetailsState.forEach((item) => {
      actualCashCollected+= item.actualCashCollected !== '' ? item.actualCashCollected : 0;
      totalContactlessMoneyGross += item.grossContactless;
      totalEstimatedCashAmount += item.estimatedCashAmount;
      totalGrossMachineIncome += item.grossCashCollected;

    });
    setTotalCashCollectedState({
      actualCashCollected: actualCashCollected,
      totalContactlessMoneyGross: totalContactlessMoneyGross,
      totalEstimatedCashAmount: totalEstimatedCashAmount,
      totalGrossMachineIncome: totalGrossMachineIncome,
    });
  };

  const onChangeInputHandler = ((value:any, id: number) => {
    if(!siteCollectionDetailsState || !siteCollectionDetailsState.length) {
      return
    }
    const updatedSiteCollectionState = siteCollectionDetailsState
      .map((item, index) => {
        if (item.unitId !== id) {
          return item;
        }
        return {
          ...item,
          actualCashCollected: value!== '' ? parseFloat(value) : '',
          grossCashCollected: value!== '' ? (parseFloat(value) + item.grossContactless) : item.grossContactless,
        }
      });
     setSiteCollectionDetailsState(updatedSiteCollectionState);
  });

  useEffect(() => {
    setLoadingMachine(true);
    asyncRequest({
      config: {
        method: "GET",
      },
      endpoint: `api/collection/site/${siteId}`,
    }).then(response => {
      dispatch(addSiteCollectionDetails(response));
    })
    .then(() => {
      setLoadingMachine(false);
    })
      .catch(err => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (siteCollectionDetailsState && siteCollectionDetailsState.length > 0) {
      calculateTotalCollectionAmounts();
      setIsButtonDisabled(siteCollectionDetailsState.some(item => {
        return item.actualCashCollected === '';
      }));
    }
  }, [siteCollectionDetailsState]);

  const mappedSiteCollectionUnits = siteCollectionDetailsState && siteCollectionDetailsState.length > 0 &&
    siteCollectionDetailsState.map((item: any, position: number) => {
      return <MachineCollectionCardDetails key={`machine-collection-${position}`}
                                           position={position}
                                           isIdenticalStartData={isIdenticalStartData}
                                           machineCollectionDetails={item}
                                           isCollectionReceipt={false}
                                           onChangeInputHandler={onChangeInputHandler}
      />;
    });

  return (
    <StyledSiteCollection className="height-controller-wrapper">
      <Header title="Site Collection"/>
      <CollectionSummaryHeader siteDetails={siteDetails} isSiteCollection={true}/>
      <CollectionCard totalCashCollected={totalCashCollectedState}
                      collectionCardStartDate={collectionCardStartDate}
                      isIdenticalStartData={isIdenticalStartData}/>
      {loadingMachine? <LoadingComponent/> : mappedSiteCollectionUnits}
      <div className="helper-screen-description">
        Tap and enter actual cash amount. Confirm collection to save values.
      </div>
      <div className="actions-wrapper">
        <OutlinedButton className="back-button"
                        onClick={() => {history.push(`${prevStateLocation}`)}}>Back</OutlinedButton>
        <PrimaryButton onClick={confirmCollectionHandler} disabled={isButtonDisabled}>Confirm Collection</PrimaryButton>
      </div>
      {isLoading && <LoadingComponent/>}
    </StyledSiteCollection>
  );
};

export default SiteCollection;
