import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";




const StyledMachineTestPage = styled.div ` 
  .warning-section {
    text-align: center;
    padding: ${paymeTheme.paddingRemLg} ${paymeTheme.paddingRemXs};
    color: ${paymeTheme.errorColor};
  }
  
  .warning-title {
    font-weight: ${paymeTheme.fontBold};
    font-size: ${paymeTheme.fontRemSizeLg};
  }
  .actions-wrapper button.continue-button {
    margin: 0 auto;
  }

  ${media.desktop} {
    
  }

`;

export { StyledMachineTestPage } ;


export const StyledBoxSection = styled.div ` 
   .credit-box-section {
    color: ${paymeTheme.colorBlack};
    text-align: center;
    padding: ${paymeTheme.paddingRemSm};
    display: flex;
    width: 250px;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    .MuiInputBase-root {
      min-width: 150px;
      max-width: 180px;
      margin-top: ${paymeTheme.paddingRemXs};
    }
  }
  
  .credit-type-label {
    font-size: ${paymeTheme.fontRemSizeLg};
    padding: ${paymeTheme.paddingRemLg} 0 ${paymeTheme.paddingRemMd} 0;
  }
  .credit-value-wrapper {
    display: flex;
    align-items: center;
    min-height: 55px;
    margin: auto;
    width: auto;
    .MuiSvgIcon-root {
       color: ${paymeTheme.floteColor};
       width: ${paymeTheme.fontRemSizeH1};
       height: ${paymeTheme.fontRemSizeH1};
    }
    
    .credit-value {
       background-color: ${paymeTheme.colorWhite};
       display: flex;
       justify-content: space-between;
       min-width: 115px;
       width 100%;
       height: 36px;
       border: 1px solid ${paymeTheme.floteColor};
       border-radius: 7px;
       font-size: ${paymeTheme.fontRemSizeH4};
       padding: 0 ${paymeTheme.paddingRemXs};
    }
  }
  ${media.desktop} {
    
  }

`;
