import React, {FC, useEffect, useState} from "react";
import {StyledVendingConnectionStep} from "./styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import Radio from '@material-ui/core/Radio';
import {OutlinedButton, PrimaryButton} from "../../components/Buttons";
import Header from "../../components/Header";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {displayMachineDetails} from "../MachineDetailsPage/selectors";
import {addMachineDetails} from "../MachineDetailsPage/actions";
import {socketReceivedMessage, socketSentMessage} from "../../components/SocketCommunication/actions";
import {getReceivedSocketMessageDetails} from "../../components/SocketCommunication/selectors";
import {NotificationError} from "../../components/NotificationMessages";
import LoadingComponent from "../../components/Loading";
import * as ROUTES from "../../components/Containers/Main/constants";
import {useModalStyles} from "../../components/Modal/styles";
import ModalComponent from "../../components/Modal";

const VendingConnectionStep: FC = () => {
  const [connectionType, setConnectionType] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [isHardwareConnected, setIsHardwareConnected] = useState(true);
  const [wasSentMT7, setWasSentMT7] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useModalStyles();
  const machineDetails = useSelector(displayMachineDetails);
  const receivedSocketMessage = useSelector(getReceivedSocketMessageDetails);

  const handleModalClose = () => {
    history.push(ROUTES.HOME);
    setOpenModal(false);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConnectionType(parseInt(event.target.value));
  };

  const confirmConnectionType = () => {
    setIsLoading(true);
    setOpenModal(false);
    setWasSentMT7(true);
    dispatch(addMachineDetails({
      ...machineDetails,
      CoinMecAdptProg: connectionType,
    }));
    dispatch(
      socketSentMessage({
        MT: "7",
        unitId: machineDetails.hubTagId,
        CoinMecAdptProg: connectionType,
        hostTypeId: machineDetails.hostType.hostTypeId,
        manufacturerId: machineDetails.manufacturer.id,
        topic: "/server/client/unitConfiguration",
        PwrSrc: machineDetails.unitPowerSource.code === "BATTERY" ? "B" : "M",
      })
    );
  };

  const retryModalBody = (
    <div className="modal-wrapper">
      <div className={classes.body}>
        There is no response from the hardware. Please try again or abort!
      </div>
      <div className="modal-footer">
        <div className={classes.footer}>
          <OutlinedButton className="back-button" onClick={handleModalClose}>Abort</OutlinedButton>
          <PrimaryButton className="continue-button" onClick={confirmConnectionType}>Try Again</PrimaryButton>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    if (receivedSocketMessage && receivedSocketMessage.MT) {
      setIsLoading(false);
    }
    if (
      receivedSocketMessage &&
      receivedSocketMessage.MT === 8 &&
      receivedSocketMessage.Sts === 1
    ) {
      history.push(ROUTES.MACHINE_SUMMARY);
    }
    if (receivedSocketMessage && receivedSocketMessage.MT === 24) {
      setIsHardwareConnected(false);
    }
  }, [receivedSocketMessage]);

  useEffect(() => {
    if (!!wasSentMT7) {
      setTimeout(() => {
        if (!receivedSocketMessage) {
          setIsLoading(false);
          setOpenModal(true);
          setWasSentMT7(false);
        }
      }, 10000)
    }
  }, [wasSentMT7, receivedSocketMessage]);

  return (
      <StyledVendingConnectionStep className="height-controller-wrapper">
        <Header title="Connection type" />
        <form className="connection-type-form" noValidate autoComplete="off">
          <div className="check-connection-type-wrapper">
            <FormControl component="fieldset">
              <FormLabel component="legend">How have you connected the unit to the machine?</FormLabel>
              <RadioGroup aria-label="connection-type"
                          name="connection-type"
                          value={connectionType}
                          onChange={handleRadioChange}>
                <FormControlLabel value={3} control={<Radio/>} label="Connected with the coin mec" />
                <FormControlLabel value={1} control={<Radio/>} label="Connected as card terminal 1" />
                <FormControlLabel value={2} control={<Radio/>} label="Connected as card terminal 2" />
              </RadioGroup>
            </FormControl>
          </div>
        </form>
        {!isHardwareConnected && <NotificationError messageClass="error" message="Hardware is not connected. Press Try again button"/>}
        <div className="actions-wrapper">
          { isHardwareConnected ?
            <OutlinedButton className="back-button"
                            onClick={() => {history.goBack(); dispatch(socketReceivedMessage(null));}}>
              Back
            </OutlinedButton> :
            <OutlinedButton className="back-button"
                            onClick={() => {history.push(ROUTES.HOME); dispatch(socketReceivedMessage(null));}}>
              Abort
            </OutlinedButton>
          }
          <PrimaryButton className="continue-button"
                         disabled={connectionType === -1 ? true : false}
                         onClick={confirmConnectionType}>
            {!isHardwareConnected ? "Try again" : "Continue"}
          </PrimaryButton>
        </div>
        {isLoading && <LoadingComponent/>}
        <ModalComponent open={openModal} handleClose={handleModalClose} body={retryModalBody}/>
      </StyledVendingConnectionStep>
  );
};

export default VendingConnectionStep;
