import styled from 'styled-components';
import paymeTheme from "../../assets/styling/payme-theme";
import media from "../../assets/styling/payme-media-queries";


const StyledMachineCollectionCardDetails = styled.div `
  background-color: white;
  color: ${paymeTheme.colorBlack};
  font-size: ${paymeTheme.fontRemSizeSmall};
  padding: ${paymeTheme.paddingRemXs} 0;
  margin: 0;
  display: flex;
  
  .row-value {
    text-align: end;
  }

  .full-price-wrapper {
    display: flex;  
  }

  .icon-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.pound-sign {
      align-items: start;
      justify-content: space-around;
    }

    svg {
      flex-basis: 25%;
    }
  }

  .machine-type-icon,
  .machine-details-wrapper {
    padding-bottom: ${paymeTheme.paddingRemXs};
    border-bottom: 2px solid ${paymeTheme.borderGrey};
  }
  
  .machine-details-wrapper {
    flex: 1;
    padding-right: ${paymeTheme.paddingRem};
  }
  
  .machine-title {
    display: flex;
    justify-content: space-between;
  }
  
  .machine-collection-card-header {
    .collection-machine-type {
       font-weight: ${paymeTheme.fontBold};
    }
  }
  
  .collection-card-body {
     font-weight: ${paymeTheme.fontBold};
  }
  
  .machine-collection-date {
    color: ${paymeTheme.colorPurple};
    font-weight: ${paymeTheme.fontLight};
  }
  
  .collection-card-body {
    color: ${paymeTheme.colorBlack};
    display: flex;
    justify-content: space-between;

    svg {
      width: 0.25rem;
      height: unset;
      padding-right: 0.5rem;
      fill: ${paymeTheme.colorBlack};
    }

    .MuiTextField-root {
      margin-bottom: 0;
    }
    .MuiInputBase-root {
      min-width: 60px;
      max-width: 75px;
      height: ${paymeTheme.padding};
      border-color: ${paymeTheme.colorBlack};
    }
  }

  ${media.desktop} {
    min-width: 30%;
    max-width: 50%;
    width: fit-content;
    margin: 0 auto;
  }

`;

export { StyledMachineCollectionCardDetails } ;
