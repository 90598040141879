import React, { FC } from "react";
import {StyledMachineSiteChange} from "./styles";
import Header from "../../components/Header";
import {OutlinedButton, PrimaryButton} from "../../components/Buttons";
import {useHistory} from "react-router-dom";
import MachineDetails from "../../components/MachineDetails";
import MachineType from "../SitePage/MachineTypeSection";
import {useSelector} from "react-redux";
import {displayMachineDetails} from "../MachineDetailsPage/selectors";
import ShuffleIcon from '@material-ui/icons/Shuffle';
import {getSiteDetailsData} from "../AllClientsSitesPage/selectors";
import RoomIcon from "@material-ui/icons/Room";
import LayersIcon from "@material-ui/icons/Layers";
import {getInitialSiteDetailsData} from "./selectors";
import ChangeSiteDetailsSection from "./ChangeSiteDetails";
import * as ROUTES from "../../components/Containers/Main/constants";
import {CONTINUE_BUTTON_INSTRUCTIONS_1, CONTINUE_BUTTON_INSTRUCTIONS_2} from "./constants";

interface Props {
  clientsList: any;
}
const MachineSiteChange: FC<Props> = ({}) => {

  const history = useHistory();
  const machineDetails = useSelector(displayMachineDetails);
  const newSiteDetails = useSelector(getSiteDetailsData);
  const previousSiteDetails = useSelector(getInitialSiteDetailsData);

  const continueToChangeMachinePosition = () => {
    history.push(ROUTES.CHANGE_MACHINE_POSITION);
  };

  return (
    <StyledMachineSiteChange className="height-controller-wrapper">
      <Header title="Machine site change"/>
      <div className="new-site-details">
        <div className="title new-title">New site</div>
        <ChangeSiteDetailsSection siteDetails={newSiteDetails} isPreviousSite={false}/>
      </div>
      <MachineType itemDetails={machineDetails} hasMenu={false}/>
      <div className="shuffle-icon"><ShuffleIcon/></div>
      <div className="previous-site-details">
        <div className="title previous-title">Previous site</div>
        <ChangeSiteDetailsSection siteDetails={previousSiteDetails} isPreviousSite={true}/>
      </div>
      <p className="continue-instructions">{CONTINUE_BUTTON_INSTRUCTIONS_1}<br/>{CONTINUE_BUTTON_INSTRUCTIONS_2}</p>
      <div className="actions-wrapper">
        <OutlinedButton className="back-button" onClick={() => {history.goBack()}}>Back</OutlinedButton>
        <PrimaryButton className="continue-button" onClick={continueToChangeMachinePosition}>Continue</PrimaryButton>
      </div>
    </StyledMachineSiteChange>
  );
};

export default MachineSiteChange;
