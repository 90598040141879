import {
  ADD_CASH_COLLECTED_VALUE,
  HISTORY_COLLECTION_LIST_FAILURE,
  HISTORY_COLLECTION_LIST_REQUEST,
  HISTORY_COLLECTION_LIST_SUCCESS,
} from "./constants";
import {IHistoryCollectionList} from "./model";



export const addCashCollectedValue = (data: any) => ({
  type: ADD_CASH_COLLECTED_VALUE,
  payload: {...data}
});

export const historyCollectionListAttempt = () => ({
  type: HISTORY_COLLECTION_LIST_REQUEST,
});

export const historyCollectionListSuccess = (data: IHistoryCollectionList) => ({
  type: HISTORY_COLLECTION_LIST_SUCCESS,
  payload: { ...data},
});

export const historyCollectionFailure = (error: IHistoryCollectionList) => ({
  type: HISTORY_COLLECTION_LIST_FAILURE,
  payload: error
});
