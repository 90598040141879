import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";


const StyledModal = styled.div `
  ${media.desktopMedium} {
  margin: 0;
  }
  `;

export { StyledModal } ;

export const useModalStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      color: paymeTheme.errorColor,
      paddingBottom: '20px',
      textAlign: 'center',
      '& .MuiFilledInput-input': {
        padding: paymeTheme.paddingRemXs
      }
    },
    footer: {
      display: 'flex'
    }
  }),
);

