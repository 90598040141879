import { ACCOUNTS_REQUEST, ADD_ACCOUNT_REQUEST, DELETE_ACCOUNT_REQUEST, ADD_ACCOUNT_SUCCESS, ADD_ACCOUNT_FAILURE, DELETE_ACCOUNT_FAILURE, DELETE_ACCOUNT_SUCCESS } from "./constants";

export const loadAccountsAction = () => ({
  type: ACCOUNTS_REQUEST
});

export const addAccountAction = (data: any) => ({
  type: ADD_ACCOUNT_REQUEST,
  data
});

export const addAccountSuccessAction = (data: any, id: number) => ({ 
  type: ADD_ACCOUNT_SUCCESS, 
  account: { ...data, id },
});

export const addAccountFailureAction = (error: any) => ({ 
  type: ADD_ACCOUNT_FAILURE, 
  error
});

export const deleteAccountAction = (id: number) => ({
  type: DELETE_ACCOUNT_REQUEST,
  id
});

export const deleteAccountSuccessAction = (id: number) => ({
  type: DELETE_ACCOUNT_SUCCESS,
  id
});

export const deleteAccountFailureAction = (error: any) => ({
  type: DELETE_ACCOUNT_FAILURE,
  error
});
