import React, {useEffect, useState} from "react";
import {StyledGroupDetails} from "./styles";
import Header from "../../components/Header";
import {useHistory} from "react-router-dom";
import {OutlinedButton, PrimaryButton} from "../../components/Buttons";
import {GeneralTextField} from "../../components/Inputs";
import {asyncRequest} from "../../api";
import {SiteMenuAppBar} from "../SitePage/SiteMenu";
import ModalComponent from "../../components/Modal";
import {useModalStyles} from "../../components/Modal/styles";
import {useDispatch, useSelector} from "react-redux";
import {getSelectedGroupData} from "../ListingCompanyGroups/selectors";
import {addSelectedGroupDetails} from "../ListingCompanyGroups/actions";
import SiteCardComponent from "../../components/SiteCard";
import * as ROUTES from "../../components/Containers/Main/constants";
import { displaySiteDetailsFailure, displaySiteDetailsSuccess } from "../AllClientsSitesPage/actions";
import { addMachineDetails } from "../MachineDetailsPage/actions";
import { displayMachineDetails } from "../MachineDetailsPage/selectors";

const GroupDetails = () => {
  const classes = useModalStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedGroupData = useSelector(getSelectedGroupData);
  const machineDetails = useSelector(displayMachineDetails);
  const selectedGroupId = selectedGroupData.id;
  const groupSites = selectedGroupData.sites;
  const [groupName, setGroupName] = useState(selectedGroupData.name);
  const [isEditingGroupName, setIsEditingGroupName] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [isEmptyField, setIsEmptyField] = useState(false);

  useEffect(() => {
    setGroupName(selectedGroupData.name);
  }, [selectedGroupData.name]);

  const editGroupNameHandler = () => {
    setIsEditingGroupName(true);
  };

  const onSubmitEditingName: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (!isEmptyField && event.key === 'Enter') {
      event.preventDefault();
      asyncRequest({
        config:{
          method: "PATCH",
          data: {
            id: selectedGroupId,
            name: groupName,
            groupType: 'SITE'
          }
        },
        endpoint: 'api/group',
      }).then(response => {
        setIsEditingGroupName(false);
        dispatch(addSelectedGroupDetails({
          ...selectedGroupData,
          name: groupName
        }));
      })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const onChangeGroupNameHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (!event.target.value) {
      setIsEmptyField(true);
    } else {
      setIsEmptyField(false);
    }
    setGroupName(event.target.value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const confirmDeleteGroupHandler = () => {
    setOpen(false);
    asyncRequest({
      config:{
        method: "DELETE",
      },
      endpoint: `api/group/${selectedGroupId}`,
    }).then(response => {
      history.push(ROUTES.GROUPS);
    })
      .catch(err => {
        console.log(err);
      });
  };

  const body = (
    <div className="modal-wrapper">
      <div className={classes.body}>
        Are you sure you want to delete this group?
      </div>
      <div className="modal-footer">
        <div className={classes.footer}>
          <OutlinedButton className="back-button" onClick={handleClose}>Cancel</OutlinedButton>
          <PrimaryButton type="button" onClick={confirmDeleteGroupHandler}>Confirm</PrimaryButton>
        </div>
      </div>
    </div>
  );

  const menuGroupOptions = [
    {
      name: 'Delete group',
      path: 'isDeleteGroup',
      onClickHandler: handleOpen,
    },
    {
      name: 'Edit group',
      path: 'isEditGroup',
      onClickHandler: editGroupNameHandler,
    }
  ];


  const onClickListItemHandler = (id: number) => {
    asyncRequest({
      config:{
        method: "GET",
      },
      endpoint: `api/site/${id}`,
    }).then(response => {
      dispatch(displaySiteDetailsSuccess(response));
      dispatch(addMachineDetails({
        ...machineDetails,
        hubTagId: response.hubTagId
      }));
    })
    .then(() => {
      history.push(`${ROUTES.SITE_PAGE}/${id}`);
    })
      .catch(err => {
        console.log(err);
        dispatch(displaySiteDetailsFailure(err));
      });

    dispatch(addMachineDetails({
      ...machineDetails,
      siteId: id,
    }));
  };

  const mappedGroupSites = groupSites && groupSites.map((item: any, index: number) =>
    <div key={index} onClick={() => onClickListItemHandler(item.id)}>
      <SiteCardComponent item={item}/>
    </div>);

  return (
    <StyledGroupDetails className="height-controller-wrapper">
      <Header title="Group details"/>
      <div className="group-header">
        {!isEditingGroupName && <div className="group-name">{groupName}</div>}
        {isEditingGroupName && (
            <GeneralTextField id="new-group-name"
                              value={groupName}
                              required={true}
                              variant="filled"
                              autoFocus
                              error={isEmptyField}
                              placeholder="New group name"
                              helperText={isEmptyField && "Please fill the group name"}
                              onKeyDown={onSubmitEditingName}
                              onChange={onChangeGroupNameHandler}
            />
        )}
        {!isEditingGroupName && <div className="site-menu"><SiteMenuAppBar menuList={menuGroupOptions}/></div>}
      </div>
      {mappedGroupSites}
      <div className="actions-wrapper">
        <OutlinedButton className="back-button" onClick={() => {history.goBack()}}>Back</OutlinedButton>
      </div>
      <ModalComponent open={open} handleClose={handleClose} body={body}/>
    </StyledGroupDetails>
  );
};

export default GroupDetails;
