import React, {FC, useEffect, useState} from "react";
import {StyledMachineDetailsPage} from "./styles";
import Header from "../../components/Header";
import {OutlinedButton, PrimaryButton} from "../../components/Buttons";
import {useHistory} from "react-router-dom";
import {GeneralTextField} from "../../components/Inputs";
import CustomSelect from "../../components/Selects";
import { FormControl } from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {
  displayUnitBatteryPoweredAttempt,
  displayUnitHostTypeAttempt,
  displayUnitManufacturerAttempt,
  addMachineDetails
} from "./actions";
import {
  displayMachineDetails,
  getUnitBatteryPoweredData,
  getUnitHostTypeData,
  getUnitManufacturerData
} from "./selectors";
import {asyncRequest} from "../../api";
import {IHostType, IManufacturerDetails, IUnitPowerSource} from "../AllClientsSitesPage/model";
import {IHostDetails} from "./model";
import {getOffSiteDetailsData, getSiteDetailsData} from "../AllClientsSitesPage/selectors";
import * as ROUTES from "../../components/Containers/Main/constants";
import {socketReceivedMessage, socketSentMessage} from "../../components/SocketCommunication/actions";
import {getReceivedSocketMessageDetails} from "../../components/SocketCommunication/selectors";
import {NotificationError} from "../../components/NotificationMessages";
import LoadingComponent from "../../components/Loading";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import {StyledBoxSection} from "../MachineTest/styles";
import {MIN_CREDIT_VALUE, NO_HARDWARE, ERROR_HARDWARE} from "./constants";
import {useModalStyles} from "../../components/Modal/styles";
import ModalComponent from "../../components/Modal";


interface Props {
  clientsList: any;
}
const MachineDetailsPage: FC<Props> = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useModalStyles();
  const unitHostTypeList = useSelector(getUnitHostTypeData);
  const unitManufacturerList = useSelector(getUnitManufacturerData);
  const unitBatteryPowered = useSelector(getUnitBatteryPoweredData);
  const offSiteDetails = useSelector(getOffSiteDetailsData);
  const machineDetails = useSelector(displayMachineDetails);
  const machineCreditType = machineDetails &&
    machineDetails.creditType && machineDetails.creditType.name;
  const siteDetails = useSelector(getSiteDetailsData);
  const CoinMecAdptProg = useSelector(displayMachineDetails).CoinMecAdptProg;
  const siteId = siteDetails && siteDetails.id;
  const isOffSite = siteDetails && siteDetails.offSite;
  const [unitTypeDetails, setUnitTypeDetails] = useState(machineDetails.hostType);
  const isPoolTableorFootball = unitTypeDetails &&
    (unitTypeDetails.code === "PoolTable" || unitTypeDetails.code === "Football");
  const [positionOnSite, setPositionOnSite] = useState(machineDetails.position);
  const [creditTypeLabel, setCreditTypeLabel] = useState(machineCreditType);
  // const [isSelectedMetalCabinetOption, setIsSelectedMetalCabinetOption] = useState(false);
  // const [metalCabinetOption, setMetalCabinetOption] = useState(machineDetails.metalCabinetOption);
  // const [metalCabinetOptionValue, setMetalCabinetOptionValue] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [creditType, setcreditType] = useState(machineDetails.creditType);
  const [defaultTransactionAmount, setDefaultTransactionAmount] = useState(machineDetails.defaultTransactionAmount);
  const [creditTestValue, setCreditTestValue] = useState('');
  const [manufacturerDetails, setManufacturerDetails] = useState(machineDetails.manufacturer);
  const [batteryPoweredDetails, setBatteryPoweredDetails] = useState(machineDetails.unitPowerSource);
  const receivedSocketMessage = useSelector(getReceivedSocketMessageDetails);
  const [isLoading, setIsLoading] = useState(false);
  const [isHardwareConnected, setIsHardwareConnected] = useState(true);
  const [wasSentMT7, setWasSentMT7] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [hardwareError, setHardwareError] = useState("");
  const [searchForResponseMT8, setSearchForResponseMT8] = useState(1);

  const onChangePositionHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setPositionOnSite(event.target.value);
  };

  const onChangeHostTypeHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    unitHostTypeList.filter((type: IHostType) => type.name === event.target.value).length > 0 &&
    setUnitTypeDetails({
      ...unitTypeDetails,
      name: event.target.value,
      iconUrl: unitHostTypeList.filter((type: IHostType) => type.name === event.target.value)[0].iconUrl,
      hostTypeId: unitHostTypeList.filter((type: IHostType) => type.name === event.target.value)[0].hostTypeId,
      code: unitHostTypeList.filter((type: IHostType) => type.name === event.target.value)[0].code,
      maxTransactionAmount: unitHostTypeList.filter((type: IHostDetails) => type.name === event.target.value)[0].maxTransactionAmount,
      hostCategory: {
        id: unitHostTypeList.filter((type: IHostDetails) => type.name === event.target.value)[0].hostCategory.id,
        name: unitHostTypeList.filter((type: IHostDetails) => type.name === event.target.value)[0].hostCategory.name,
        code: unitHostTypeList.filter((type: IHostDetails) => type.name === event.target.value)[0].hostCategory.code,
      },
    });

    if (['Pool Table', 'Football Table'].indexOf(event.target.value) !== -1 ) {
      setBatteryPoweredDetails({id: 1, code: 'BATTERY', name: 'Battery'});
    }

    const matchedType = unitHostTypeList.filter((type: IHostType) => type.name === event.target.value);
    const currentTypeId = matchedType && matchedType.length > 0 && matchedType[0].hostTypeId;
    currentTypeId && dispatch(displayUnitManufacturerAttempt({hostTypeId: currentTypeId}));
    currentTypeId && getSpecificCreditValue(currentTypeId);

  };

  const onChangeManufacturerHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    unitManufacturerList.filter((type: IManufacturerDetails) => type.name === event.target.value).length > 0 &&
    setManufacturerDetails({
      ...manufacturerDetails,
      name: event.target.value,
      id: unitManufacturerList.filter((type: IManufacturerDetails) => type.name === event.target.value)[0].id,
    })
  };

  const getSpecificCreditValue = (hostTypeId: number) => {
    if (hostTypeId !== -1) {
      asyncRequest({
        config:{
          method: "GET",
        },
        endpoint: `api/unit/hostType/${hostTypeId}/creditType`,
      }).then(response => {
        if (response.code === 'HOST_SING_VAL') {
          setDefaultTransactionAmount('1.00');
          setCreditTypeLabel('Change price of play')
        } else {
          setDefaultTransactionAmount('1.00');
          setCreditTypeLabel('Maximum credit value');

        }
        setcreditType({
            ...creditType,
            id: response.id,
            code: response.code,
            name: response.name
          }
        );

      })
        .catch(err => console.log(err));
    }
  };

  useEffect(() => {
    if (!!machineDetails.defaultTransactionAmount) {
      setDefaultTransactionAmount(machineDetails.defaultTransactionAmount)
    } else {
      setDefaultTransactionAmount('1.00');
    }
  },[machineDetails.defaultTransactionAmount]);

  useEffect(() => {
    if (unitManufacturerList && unitManufacturerList.length === 1) {
      setManufacturerDetails({
        ...manufacturerDetails,
        name: unitManufacturerList[0].name,
        id: unitManufacturerList[0].id,
      })
    }
    if (unitManufacturerList && unitManufacturerList.length > 1 && !manufacturerDetails.name) {
      setManufacturerDetails(machineDetails.manufacturer);
    }
  }, [unitManufacturerList, manufacturerDetails.name]);

  const onChangeBatteryHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    unitBatteryPowered.filter((type: IUnitPowerSource) => type.name === event.target.value).length > 0 &&
    setBatteryPoweredDetails({
      ...batteryPoweredDetails,
      name: event.target.value,
      id: unitBatteryPowered.filter((type: IUnitPowerSource) => type.name === event.target.value)[0].id,
      code: unitBatteryPowered.filter((type: IUnitPowerSource) => type.name === event.target.value)[0].code,
    })
  };

  // const onChangeMetalCabinetHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
  //   setMetalCabinetOptionValue(event.target.value);
  //   setIsSelectedMetalCabinetOption(true);
  //   if (event.target.value === 'yes') {
  //     setMetalCabinetOption(true);
  //   } else if (event.target.value === 'no') {
  //     setMetalCabinetOption(false);
  //   } else {
  //     return;
  //   }
  // };

  const continueOnClickHandler = () => {
    setOpenModal(false);
    setWasSentMT7(true);

    let batteryPoweredDetailsToSend = isPoolTableorFootball ? batteryPoweredDetails : {id: 2, code: "MAINS", name: "Mains"};

    dispatch(addMachineDetails({
      ...machineDetails,
      siteId,
      position: positionOnSite,
      hostType: unitTypeDetails,
      manufacturer: manufacturerDetails,
      unitPowerSource: batteryPoweredDetailsToSend,
      // metalCabinetOption,
      creditType,
      defaultTransactionAmount,
      creditTestValue,
    }));

    if (unitTypeDetails.hostCategory.name === "Vending") {
      history.push(ROUTES.MACHINE_CONNECTION_TYPE);
    } else {
      setIsLoading(true);
      dispatch(
        socketSentMessage({
          MT: "7",
          unitId: machineDetails.hubTagId,
          CoinMecAdptProg,
          hostTypeId: unitTypeDetails.hostTypeId,
          manufacturerId: manufacturerDetails.id,
          topic: "/server/client/unitConfiguration",
          PwrSrc: batteryPoweredDetailsToSend.code === "BATTERY" ? "B" : "M",
        })
      );
    }
  };

  const handleModalClose = () => {
    history.push(ROUTES.HOME);
    setOpenModal(false);
  };

  const retryModalBody = (
    <div className="modal-wrapper">
      <div className={classes.body}>
        {hardwareError}
      </div>
      <div className="modal-footer">
        <div className={classes.footer}>
          <OutlinedButton className="back-button" onClick={handleModalClose}>Abort</OutlinedButton>
          <PrimaryButton className="continue-button" onClick={continueOnClickHandler}>Try Again</PrimaryButton>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    if (wasSentMT7) {
      if (receivedSocketMessage === null || Object.keys(receivedSocketMessage).length === 0) {
        setIsLoading(false);
        setOpenModal(true);
        setHardwareError(NO_HARDWARE);
        setWasSentMT7(false);
      } 
    }
  }, [searchForResponseMT8]);

  const timeoutFunction = () => {
    setSearchForResponseMT8(currState => currState + 1);
  };

  useEffect(() => {
    if (wasSentMT7) {
      setTimeout(timeoutFunction, 10000);
    }
  }, [wasSentMT7, receivedSocketMessage]);

  useEffect(() => {
    if (receivedSocketMessage && receivedSocketMessage.MT) {
      setIsLoading(false);
      if (
        receivedSocketMessage &&
        receivedSocketMessage.MT === 8 &&
        receivedSocketMessage.Sts === 1
      ) {
        history.push(ROUTES.MACHINE_SUMMARY);
      } else {
        setOpenModal(true);
        setHardwareError(ERROR_HARDWARE);      
        setWasSentMT7(false);
        dispatch(socketReceivedMessage(null));
      }
    }
    if (receivedSocketMessage && receivedSocketMessage.MT === 24) {
      setIsHardwareConnected(false);
    }
  }, [receivedSocketMessage]);

  useEffect(() => {
    if (!unitHostTypeList && !unitManufacturerList && !unitBatteryPowered) {
      dispatch(displayUnitHostTypeAttempt());
      dispatch(displayUnitBatteryPoweredAttempt());
    } else {
      setIsButtonDisabled(false);
      // setIsSelectedMetalCabinetOption(true);
      // setMetalCabinetOptionValue(metalCabinetOption ? 'yes' : 'no');
    }
  }, []);

  useEffect(() => {
    if (machineDetails && machineDetails.hostType && machineDetails.hostType.hostTypeId) {
      setManufacturerDetails(machineDetails.manufacturer);
      dispatch(displayUnitManufacturerAttempt({hostTypeId: machineDetails.hostType.hostTypeId}));
      machineDetails.creditType && machineDetails.creditType.code === "" &&
      getSpecificCreditValue(machineDetails.hostType.hostTypeId);
      setIsButtonDisabled(false);
    }
  }, []);


  useEffect(() => {
    if (!!unitTypeDetails && !!unitTypeDetails.name &&
      !!manufacturerDetails && !!manufacturerDetails.name &&
      ((!!batteryPoweredDetails && !!batteryPoweredDetails.name) || !isPoolTableorFootball)) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [unitTypeDetails, manufacturerDetails, batteryPoweredDetails, isPoolTableorFootball]);

  const extractNamesArr = (arr: []) => {
    const optionsNamesArray:string[] = [];
    arr && arr.length && arr.forEach((item: any, index: number) => {
      optionsNamesArray.push(item.name);
    });

    return optionsNamesArray;
  };

  const increaseCreditValue = () => {
    setDefaultTransactionAmount(prevState => (parseFloat(prevState) + 0.10).toFixed(2));
  };

  const decreaseCreditValue = () => {
    defaultTransactionAmount >= (parseFloat(MIN_CREDIT_VALUE) + 0.10).toFixed(2) &&
    setDefaultTransactionAmount(prevState => (parseFloat(prevState) - 0.10).toFixed(2));
  };

  return (
    <StyledMachineDetailsPage className="height-controller-wrapper">
      <Header title="Machine details"/>
      <form>
        {
          !isOffSite && <GeneralTextField id="site-position"
                                          value={positionOnSite}
                                          onChange={onChangePositionHandler}
                                          variant="filled"
                                          placeholder="Position on site"/>
        }
        <FormControl>
          <CustomSelect id="host-type"
                        value={unitTypeDetails.name}
                        onChangeHandler={onChangeHostTypeHandler}
                        defaultValue="Type"
                        optionsArray={extractNamesArr(unitHostTypeList)}/>
        </FormControl>

        <FormControl>
          <CustomSelect id="manufacturer"
                        value={manufacturerDetails.name}
                        onChangeHandler={onChangeManufacturerHandler}
                        defaultValue="Manufacturer"
                        optionsArray={extractNamesArr(unitManufacturerList)}/>
        </FormControl>

        {isPoolTableorFootball && <FormControl>
          <CustomSelect id="battery"
                        value={batteryPoweredDetails.name}
                        onChangeHandler={onChangeBatteryHandler}
                        defaultValue="Power Source"
                        optionsArray={extractNamesArr(unitBatteryPowered)}/>
        </FormControl>}
        {/* <FormControl>
          <CustomSelect id="metal-cabinet"
                        value={metalCabinetOptionValue}
                        onChangeHandler={onChangeMetalCabinetHandler}
                        defaultValue="Metal cabinet?"
                        optionsArray={['yes', 'no']}/>
        </FormControl> */}
        <StyledBoxSection>
          <div className="credit-box-section">
          <div className="credit-type-label">{creditTypeLabel}</div>
            <div className="credit-value-wrapper">
              {!!creditType && creditType.code === "HOST_SING_VAL" && <RemoveIcon onClick={decreaseCreditValue}/>}
              <div className="credit-value">
                <span>£</span>
                <span>
                  {!!creditType && creditType.code === "HOST_SING_VAL" ?
                    parseFloat(defaultTransactionAmount).toFixed(2) :
                    unitTypeDetails && unitTypeDetails.maxTransactionAmount ?
                      unitTypeDetails.maxTransactionAmount.toFixed(2) : MIN_CREDIT_VALUE
                  }
                </span>
              </div>
              {!!creditType && creditType.code === "HOST_SING_VAL" && <AddIcon onClick={increaseCreditValue}/>}
            </div>
          </div>
        </StyledBoxSection>
      </form>
      {!isHardwareConnected &&
      <NotificationError messageClass="error" message="Hardware is not connected. Press Try again button"/>}
      <div className="actions-wrapper">
        { isHardwareConnected ?
          <OutlinedButton className="back-button"
                          onClick={() => {history.goBack(); dispatch(socketReceivedMessage(null));}}>
            Back
          </OutlinedButton> :
          <OutlinedButton className="back-button"
                          onClick={() => {
                            history.push(ROUTES.HOME);
                            dispatch(socketReceivedMessage(null));
                          }}>Abort</OutlinedButton>
        }
        <PrimaryButton className="continue-button" onClick={continueOnClickHandler} disabled={isButtonDisabled}>
          {!isHardwareConnected ? "Try again" : "Continue"}
        </PrimaryButton>
      </div>
      {isLoading && <LoadingComponent/>}
      <ModalComponent open={openModal} handleClose={handleModalClose} body={retryModalBody}/>
    </StyledMachineDetailsPage>
  );
};

export default MachineDetailsPage;
