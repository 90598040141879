import styled from 'styled-components';
import media from "../../../assets/styling/payme-media-queries";




const StyledAllCollectionsList = styled.div `

  &.height-controller-wrapper {
     min-height: calc(100vh - 180px);
     padding-bottom: 0;
  }
  
  ${media.desktop} {
    
  }

`;

export { StyledAllCollectionsList } ;
