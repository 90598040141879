import React from 'react';
import {StyledTechnicianAllClientsSites} from "./styles";
import {AllClientsSites} from "../AllClientsSitesPage";
import {OutlinedButton, PrimaryButton} from "../../components/Buttons";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getOffSiteDetailsData, getSiteDetailsData} from "../AllClientsSitesPage/selectors";
import {displaySiteDetailsSuccess} from "../AllClientsSitesPage/actions";
import {addMachineDetails} from "../MachineDetailsPage/actions";
import {displayMachineDetails} from "../MachineDetailsPage/selectors";
import * as ROUTES from "../../components/Containers/Main/constants";
import { getFlowType } from '../../components/Containers/Main/selectors';

export const TechnicianAllClientsSites = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const offSiteItemDetails = useSelector(getOffSiteDetailsData);
  const machineDetails = useSelector(displayMachineDetails);
  const flowType = useSelector(getFlowType);
  const siteDetails = useSelector(getSiteDetailsData);
  
  const isRegistered = flowType.isUnitRegistered;

  const skipSiteHandler = () => {
    history.push(ROUTES.MACHINE_DETAILS);
    if (!isRegistered) {
      dispatch(displaySiteDetailsSuccess(offSiteItemDetails));
    }
    dispatch(addMachineDetails({
      ...machineDetails,
      siteId: isRegistered ? siteDetails.id : offSiteItemDetails.id,
    }));
    history.push(ROUTES.MACHINE_DETAILS);
  };

  return (
    <StyledTechnicianAllClientsSites className="height-controller-wrapper">
      <AllClientsSites isTechnician={true}/>
      <div className="actions-wrapper">
        <OutlinedButton className="back-button" onClick={() => {history.goBack()}}>Back</OutlinedButton>
        <PrimaryButton className="continue-button" onClick={skipSiteHandler}>Skip</PrimaryButton>
      </div>
    </StyledTechnicianAllClientsSites>
  );
};
