import { put, takeLatest } from "redux-saga/effects";
import { LOGIN_SUCCESS, LOGIN_FAILURE, LOGIN_REQUEST } from "./constants";
import {asyncRequest} from "../../api";
import {push} from "connected-react-router";
import * as ROUTES from "../../components/Containers/Main/constants";
import {IS_END_USER} from "../../components/Containers/Main/constants";

export function* fetchAccountsSaga(action: any) {
  try {
    const authResults = yield asyncRequest({
      config: {
        method: "POST",
        data: {
          email: action.payload.email,
          password: action.payload.password,
          isEndUser: IS_END_USER,
        }
      },
      endpoint: 'api/login'
    });

    navigator.geolocation.getCurrentPosition(function(position) {
      const userLatLng = {lat: position.coords.latitude, lng: position.coords.longitude};
      localStorage.setItem('userLatLng', JSON.stringify(userLatLng));
    });

    yield put({ type: LOGIN_SUCCESS, payload: authResults });
    localStorage.setItem('token', authResults.id_token);
    window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({token: authResults.id_token}));
    yield put(push(ROUTES.HOME));
  } catch (error) {
    console.log(error);
    localStorage.removeItem('token');
    yield put({
      type: LOGIN_FAILURE,
      payload: error && error.response && error.response.data && error.response.data ? error.response.data : 'Unknown error'
    });
  }
}

export default  [
  takeLatest(LOGIN_REQUEST, fetchAccountsSaga),
]
