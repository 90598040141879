import React, {FC, useState} from 'react';
import {StyledTeamActivityLogList} from "./styles";
import Header from "../../../components/Header";
import UserProfileCard from "../../../components/UserProfileCard";
import {OutlinedButton, PrimaryButton} from "../../../components/Buttons";
import {useHistory} from "react-router-dom";
import {StyledTeamLog} from "../styles";
import * as ROUTES from "../../../components/Containers/Main/constants";

export const TeamActivityLogList: FC = () => {
  const history = useHistory();

  const goToTeamPageHandler = () => {
    history.push(ROUTES.TEAM);
  };

  return (
    <StyledTeamLog>
      <StyledTeamActivityLogList className="height-controller-wrapper">
        <Header title="Team activity log"/>
        <div className="fixed-header-page">
          <div className="header-list">
            <div className="name-section">Name</div>
            <div className="role-section">Role</div>
            <div className="date-section">Date</div>
          </div>
        </div>
        <div className="user-cards-list">
          <UserProfileCard item={null} hasDate={true}/>
          <UserProfileCard item={null} hasDate={true}/>
          <UserProfileCard item={null} hasDate={true}/>
          <UserProfileCard item={null} hasDate={true}/>
          <UserProfileCard item={null} hasDate={true}/>
          <UserProfileCard item={null} hasDate={true}/>
          <UserProfileCard item={null} hasDate={true}/>
        </div>
        <div className="actions-wrapper">
          <OutlinedButton className="back-button" onClick={() => {history.goBack()}}>Back</OutlinedButton>
          <PrimaryButton className="continue-button" onClick={goToTeamPageHandler}>Finish</PrimaryButton>
        </div>
      </StyledTeamActivityLogList>
    </StyledTeamLog>
  );
};
