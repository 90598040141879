import React, {FC, useEffect, useReducer, useState} from "react";
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import InputAdornment from '@material-ui/core/InputAdornment';
import {PrimaryButton} from "../Buttons";
import {LoginInput} from "../Inputs";
import {loginReducer, LoginState} from "./reducer";
import {loginAttempt, loginFailure} from "../../pages/LoginPage/actions";
import {useDispatch, useSelector} from "react-redux";
import {getIsLoginLoading, getLoginError} from "../../pages/LoginPage/selectors";
import {LoginErrorNotification} from "../NotificationMessages";
import {StyledLoginForm} from "./styles";
import { useHistory } from "react-router-dom";

const initialState: LoginState = {
  email: '',
  password: '',
  isButtonDisabled: true,
  helperText: '',
  isError: false,
  isEmptyPassError: false,
};

export const LoginForm: FC = () => {
  const [state, emit] = useReducer(loginReducer, initialState);
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoginLoading);
  const error = useSelector(getLoginError);

  const [loginError, setLoginError] = useState();

  useEffect(() => {
    if (state.email.trim() && state.password.trim()) {
      emit({
        type: 'setIsButtonDisabled',
        payload: false
      });
    } else {
      emit({
        type: 'setIsButtonDisabled',
        payload: true
      });
    }
  }, [state.username, state.password, state.isError]);

  const handleLogin = () => {

    error && dispatch(loginFailure(null));

    if (state.email && state.password) {
      dispatch(loginAttempt(state.email, state.password));
      emit({
        type: 'setEmptyPassError',
        payload: false
      });
    } else {
      emit({
        type: 'setEmptyPassError',
        payload: true
      });
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.keyCode === 13 || event.which === 13) {
      state.isButtonDisabled || handleLogin();
    }
  };

  const onInputFocusHandler = () => {
    error && dispatch(loginFailure(null));
  };

  const handleUsernameChange: React.ChangeEventHandler<HTMLInputElement> =
    (event) => {
      let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!regEmail.test(event.target.value)) {
        emit({
          type: 'setIsError',
          payload: true
        });
      } else {
        emit({
          type: 'setIsError',
          payload: false
        });
      }
      emit({
        type: 'setUsername',
        payload: event.target.value
      });
    };

  const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> =
    (event) => {
      emit({
        type: 'setPassword',
        payload: event.target.value
      });
    };

  const setSpecificLoginError = () => {
    if (error.status === 401) {
      setLoginError('Please enter correct details');
    }
  };

  useEffect(() => {
    error && setSpecificLoginError();
  }, [error]);

  return (
    <StyledLoginForm>
      <form>
        <LoginInput id="username"
                    placeholder="Email Address"
                    required={true}
                    value={state.email}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">
                        <EmailIcon style={{fill: '#0090DF'}}/>
                      </InputAdornment>,
                    }}
                    error={state.isError}
                    helperText={state.isError && "Incorrect username"}
                    onChange={handleUsernameChange}
                    onFocus={onInputFocusHandler}
                    onKeyPress={handleKeyPress}
                    type="email"/>
        <LoginInput id="password"
                    placeholder="Password"
                    required={true}
                    value={state.password}
                    error={state.isEmptyPassError}
                    helperText={state.isEmptyPassError && "Please enter password"}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">
                        <LockIcon style={{fill: '#0090DF'}}/>
                      </InputAdornment>,
                    }}
                    onChange={handlePasswordChange}
                    onFocus={onInputFocusHandler}
                    onKeyPress={handleKeyPress}
                    type="password"/>
        {error && <LoginErrorNotification message={loginError ? loginError : error.title}/>}
        <PrimaryButton variant="contained"
                       onClick={handleLogin}
                       disabled={isLoading}>
          Sign In
        </PrimaryButton>
      </form>
    </StyledLoginForm>
  );
};

