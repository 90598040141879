import styled from 'styled-components';
import paymeTheme from "../../assets/styling/payme-theme";
import media from "../../assets/styling/payme-media-queries";


const StyledCollectionSummaryHeader = styled.div `
  color: ${paymeTheme.colorBlack};
  font-size: ${paymeTheme.fontRemSizeNormal};
  padding: ${paymeTheme.paddingRem} 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  
  .title {
    width: ${paymeTheme.widthXLg};
    font-weight: ${paymeTheme.fontBold};
    display: flex;
    justify-content: space-around;
    text-align: center;
    width: 100%;
  }
  
  svg {
    color: ${paymeTheme.floteColor};
    margin-right: ${paymeTheme.paddingRemXs};
  }
  
  .site-group {
    display: flex;
    align-items: center;
  }

  .site-name {
    color: ${paymeTheme.colorPurple};
  }

  .client-name {
    color: ${paymeTheme.colorBlack};
  }

  .site-town {
    color: ${paymeTheme.customColor};
  }

  .site-name,
  .client-name {
    padding-right: ${paymeTheme.paddingRemXs};
  }
  
  ${media.desktop} {
    min-width: ${props => props.theme.isSiteCollection ? "30%" : ""};
    max-width: ${props => props.theme.isSiteCollection ? "50%" : ""};
    width: ${props => props.theme.isSiteCollection ? "fit-content" : ""};
    margin: 0 auto;
    padding-top: 2.5rem;

    .title {
      width: unset;
    }

    .client-name,
    .site-name {
      padding-right: ${paymeTheme.paddingRemLg};
    }
  }

`;

export { StyledCollectionSummaryHeader } ;
