import {GET_COMPANY_DETAILS_FAILURE, GET_COMPANY_DETAILS_REQUEST, GET_COMPANY_DETAILS_SUCCESS} from "./constants";

export const displayCompanyDetailsAttempt = () => ({
  type: GET_COMPANY_DETAILS_REQUEST,
});

export const displayCompanyDetailsSuccess = (data: any) => ({
  type: GET_COMPANY_DETAILS_SUCCESS,
  payload: { ...data},
});

export const displayCompanyDetailsFailure = (error: any) => ({
  type: GET_COMPANY_DETAILS_FAILURE,
  payload: error
});

