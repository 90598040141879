import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";




const StyledMachineDeactivationConfirmation = styled.div `
  .helper-screen-description {
    font-size: ${paymeTheme.fontRemSizeNormal};
  }
  
  ${media.desktop} {
    
  }

`;

export { StyledMachineDeactivationConfirmation } ;
