import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";




const StyledCollectorMachineOptions = styled.div `
  .machine-summary-wrapper {
    padding-left: ${paymeTheme.paddingRemLg};
    font-size: ${paymeTheme.fontRemSizeSmall};
  }
  
  .actions-wrapper .back-button {
    margin-right: auto;
  }
  .machine-type {
    color: ${paymeTheme.floteColor};
    font-size: ${paymeTheme.fontRemSizeLg};
    padding: ${paymeTheme.paddingRemSm} 0;
  }
  
  .credit-value {
    font-weight: ${paymeTheme.fontBold};
  }
  ${media.desktop} {
    
  }

`;

export { StyledCollectorMachineOptions } ;
