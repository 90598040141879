import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";



const StyledTechnicianSiteSummary = styled.div `

  .height-controller-wrapper {
    min-height: auto;
  }

  .adding-machine-section {
    color: ${paymeTheme.errorColor};
    text-align: center;
    font-size: ${paymeTheme.fontRemSizeH5};
    padding: ${paymeTheme.paddingRemSm} ${paymeTheme.paddingRemLg};
  }
  
  ${media.desktop} {
    
  }

`;

export { StyledTechnicianSiteSummary } ;
