import React, {FC, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import Header from "../../../components/Header";
import {PrimaryButton} from "../../../components/Buttons";
import LoadingComponent from "../../../components/Loading";
import {GeneralTextField} from "../../../components/Inputs";
import {validateEmail} from "../../../utils/validateEmail";
import {StyledResetPassword} from "../styles";
import {useDispatch, useSelector} from "react-redux";
import {userEmailAttempt, userEmailFailure, userEmailSuccess} from "../actions";
import {getIsResetPasswordLoading, getResetPasswordData, getResetPasswordError} from "../selectors";
import * as ROUTES from "../../../components/Containers/Main/constants";

export const AddUserEmail: FC = () => {
  const history = useHistory();
  const userToken = localStorage.getItem('token');
  const dispatch = useDispatch();

  const errorRequest = useSelector(getResetPasswordError);
  const successRequest = useSelector(getResetPasswordData);
  const isLoadingDisplayed = useSelector(getIsResetPasswordLoading);

  const [userEmail, setUserEmail] = useState();
  const [isEmailEmpty, setIsEmailEmpty] = useState(false);
  const [isEmailValidated, setIsEmailValidated] = useState(true);
  const [helperTextEmail, setHelperTextEmail] = useState();
  const [displayHelperTextEmail, setDisplayHelperTextEmail] = useState();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [errorRequestMessage, setErrorRequestMessage] = useState();
  const [successRequestMessage, setRequestSuccessMessage] = useState();

  useEffect(() => {
    if (userToken) {
      localStorage.removeItem('token');
    }
  }, []);

  useEffect(() => {
    if (!!successRequest) {
      setRequestSuccessMessage(successRequest);
    }
  }, [successRequest]);

  useEffect(() => {
    if (!!errorRequest) {
      setErrorRequestMessage(errorRequest);
    }
  }, [errorRequest]);

  useEffect(() => {
    if (!!userEmail) {
      if (!isEmailEmpty && !!isEmailValidated) {
        setIsButtonDisabled(false);
      }

      if (!!isEmailEmpty || !isEmailValidated) {
        setIsButtonDisabled(true);
      }
    }
  }, [isEmailEmpty, isEmailValidated, userEmail]);

  useEffect(() => {
    displayHelperTextEmailHandler();
  }, [isEmailValidated, isEmailEmpty]);

  const onClickSendEmailHandler = () => {
    setRequestSuccessMessage('');
    setErrorRequestMessage('');
    dispatch(userEmailAttempt(userEmail));
  };

  const onChangeEmailAddressHandler: React.ChangeEventHandler<HTMLInputElement> =
    (event) => {
      setUserEmail(event.target.value);
      if (event.target.value !== '') {
        setIsEmailEmpty(false);
        if (validateEmail(event.target.value)) {
          setIsEmailValidated(true);
        } else {
          setIsEmailValidated(false);
        }
      } else {
        setIsEmailEmpty(true);
      }
    };

  const displayHelperTextEmailHandler = () => {
    if (isEmailEmpty) {
      setHelperTextEmail("Fill the Email address");
      setDisplayHelperTextEmail(true);
    }
    if (!isEmailEmpty && !isEmailValidated) {
      setHelperTextEmail("Fill a valid email format");
      setDisplayHelperTextEmail(true);
    }
    if (!isEmailEmpty && isEmailValidated) {
      setHelperTextEmail('');
      setDisplayHelperTextEmail(false);
    }
  };

  return (
    <StyledResetPassword className="height-controller-wrapper">
      <Header title="Add existing email account"/>
      {
        !successRequestMessage &&
        <form className="existing-email-form">
          <GeneralTextField id="email-address"
                            type="email"
                            value={userEmail}
                            onChange={onChangeEmailAddressHandler}
                            variant="filled"
                            required={true}
                            helperText={displayHelperTextEmail && helperTextEmail}
                            placeholder="User email address"/>
        </form>
      }
      {
        !successRequestMessage ?
        <div className="actions-wrapper">
          <PrimaryButton onClick={onClickSendEmailHandler} disabled={isButtonDisabled}>Confirm</PrimaryButton>
        </div> :
        <div className="activation-wrapper">
          <div className="success-message">{successRequestMessage}</div>
          <div className="actions-wrapper">
            <PrimaryButton
              onClick={() => {
                history.push(ROUTES.LOGIN);
                dispatch(userEmailSuccess(undefined));
                dispatch(userEmailFailure(undefined));
              }}>
              Sign in
            </PrimaryButton>
          </div>
        </div>
      }
      {!!errorRequestMessage && <div className="error-message">{errorRequestMessage}</div>}
      {!!isLoadingDisplayed && <LoadingComponent/>}
    </StyledResetPassword>
  );
};
