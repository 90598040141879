import {LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS} from "./constants";


export const loginAttempt = (email: string, password: string) => ({
  type: LOGIN_REQUEST,
  payload: {email, password}
});

export const loginSuccess = (data: any) => ({
  type: LOGIN_SUCCESS,
  payload: { ...data},
});

export const loginFailure = (error: any) => ({
  type: LOGIN_FAILURE,
  payload: error
});
