import { IFlowTypeState } from "../../components/Containers/Main/model";
import {
  PUT_PRICE_PLAY_REQUEST,
} from "./constants";

export const putPricePlayAttempt = (playPrice: string, hubTagId: string, siteId: number, flowType: IFlowTypeState) => ({
  type: PUT_PRICE_PLAY_REQUEST,
  payload: {playPrice, hubTagId, siteId, flowType}
});

  