import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";




const StyledMachineTestResultsPage = styled.div ` 
  .warning-section {
    text-align: center;
    padding: ${paymeTheme.paddingRemXs};
    color: ${paymeTheme.errorColor};
  }
  
  .background-notification-wrapper {
    margin-top: ${paymeTheme.paddingRem};
  }
 
  .actions-wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    
    button.centered-button {
      margin: ${paymeTheme.paddingRemSm} auto;
    }
    
  }
  ${media.desktop} {
    
  }

`;

export { StyledMachineTestResultsPage } ;
