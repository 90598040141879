import React, {FC} from "react";
import { StyledMachineType } from "./styles";
import { SiteMenuAppBar } from "../SiteMenu";
import { ISiteDetailsData, IUnitDetails } from "../../AllClientsSitesPage/model";
import {
  ROLE_ADMIN,
  ROLE_COLLECTOR,
  ROLE_MASTER,
  ROLE_OPERATIVE,
  ROLE_TECHNICIAN, StorageKeys,
} from "../../../constants";
import { parseJwt } from "../../../utils/parseDetailsFromToken";
import { useHistory, useLocation } from "react-router-dom";
import { socketSentMessage } from "../../../components/SocketCommunication/actions";
import { useDispatch, useSelector } from "react-redux";
import { addMachineDetails } from "../../MachineDetailsPage/actions";
import { displayMachineDetails } from "../../MachineDetailsPage/selectors";
import { onClickMenuItemHandler } from "../../../utils/menuItemsHandler";
import { addCashCollectedValue } from "../../CollectionReceipt/actions";
import { getCashCollectedValues } from "../../CollectionReceipt/selectors";
import {getSiteDetailsData} from "../../AllClientsSitesPage/selectors";
import {displayInitialSiteDetailsSuccess} from "../../MachineSiteChange/actions";
import * as ROUTES from "../../../components/Containers/Main/constants";
import { setFlowType } from "../../../components/Containers/Main/actions";
import { getFlowType } from "../../../components/Containers/Main/selectors";

interface Props {
  itemDetails: IUnitDetails | any;
  hasMenu?: boolean;
  site?: ISiteDetailsData;
  isClientPage?: boolean;
  clientId?: number;
}

const MachineType: FC<Props> = ({ site, isClientPage, itemDetails, clientId, hasMenu = true }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const userToken = localStorage.getItem("token");
  const userRole = userToken && parseJwt(userToken).auth.split(",")[0];
  const machineDetails = useSelector(displayMachineDetails);
  const siteDetails = useSelector(getSiteDetailsData);
  const cashCollectedValuesData = useSelector(getCashCollectedValues);
  const flowType = useSelector(getFlowType);
  const isChangeSiteFlow = flowType.isChangeSiteFlow;
  const isReplaceTagFlow = flowType.isReplaceTagFlow;
  const isChangePositionFlow = flowType.isChangePositionFlow;
  const isOnlyTestFlow = flowType.isOnlyTestFlow;
  const isVending = itemDetails && itemDetails.hostType && itemDetails.hostType.hostCategory &&
    itemDetails.hostType.hostCategory.code === "VENDING";
  const isUnitDeactivated = itemDetails && itemDetails.unitStatus && itemDetails.unitStatus.code === "DEACTIVATED";
  const apiBaseUrlByEnvironment = `${process.env.REACT_APP_BASE_API_URL}`;
  const environmentTypeName = apiBaseUrlByEnvironment.split('//')[1].split('.')[0];
  const isProdEnv = environmentTypeName !== 'dev' && environmentTypeName !== "test";
  const isOffSite = siteDetails.siteType.code === "OFF_SITE";

  const onChangePlayPriceHandler = () => {
    history.push({
      pathname: ROUTES.MACHINE_SUMMARY,
      state: { initialUrl: location.pathname },
    });
    if (isClientPage) {
      dispatch(setFlowType({
        ...flowType,
        clientPageId: clientId.toString(),
      }));
    }

    dispatch(setFlowType({
      ...flowType,
      isChangePricePlayFlow: true,
    }));

    dispatch(addMachineDetails({ ...itemDetails }));
  }

  const onClickUnitDetails = () => {
    dispatch(addMachineDetails({  ...itemDetails }));
    history.push({
      pathname: ROUTES.UNIT_DETAILS_PAGE,
      state: { initialUrl: location.pathname },
    });
  }

  const onClickMachineCollectionHandler = () => {
    history.push({
      pathname: ROUTES.MACHINE_COLLECTION,
      state: { initialUrl: location.pathname },
    });
    dispatch(
      addCashCollectedValue({
        ...cashCollectedValuesData,
        cashCollectedValues: [],
      })
    );
    dispatch(addMachineDetails({ ...itemDetails }));
  };

  const onClickDeactivateHandler = () => {
    history.push(ROUTES.MACHINE_DEACTIVATION);
    dispatch(addMachineDetails({ ...itemDetails }));
  };

  const onClickAddStockHandler = () => {
    history.push(ROUTES.MACHINE_STOCK_DETAILS);
    const socketMessageToSend = {
      MT: "13",
      unitId: itemDetails.hubTagId,
      topic: "/server/client/unitStocksConfiguration",
    };
    dispatch(addMachineDetails({ ...itemDetails }));
    dispatch(socketSentMessage(socketMessageToSend));
  };

  const onClickReplacementTagHandler = () => {
    history.push(ROUTES.EXTERNAL_NFC_TAG);
    dispatch(setFlowType({
        ...flowType,
        isReplaceTagFlow: true,
    }));
    isChangePositionFlow && dispatch(setFlowType({
      ...flowType,
      isChangePositionFlow: false,
    }));
    dispatch(addMachineDetails({ ...itemDetails }));
    dispatch(displayInitialSiteDetailsSuccess(siteDetails));
  };

  const onChangeSiteHandler = () => {
    history.push(ROUTES.ALL_CLIENTS_SITES);
    dispatch(setFlowType({
        ...flowType,
        isChangeSiteFlow: true,
    }));

    isChangePositionFlow && dispatch(setFlowType({
      ...flowType,
      isChangePositionFlow: false,
    }));
    dispatch(addMachineDetails({ ...itemDetails }));
    dispatch(displayInitialSiteDetailsSuccess(siteDetails));
  };

  const onChangePositionHandler = () => {
    history.push(ROUTES.CHANGE_MACHINE_POSITION);
    isChangeSiteFlow && dispatch(setFlowType({
      ...flowType,
      isChangeSiteFlow: false,
    }));

    dispatch(setFlowType({
      ...flowType,
      isChangePositionFlow: true,
    }));
    dispatch(addMachineDetails({ ...itemDetails }));
  };

  const onChangeTestHandler = () => {
    history.push(ROUTES.MACHINE_TEST);
    dispatch(setFlowType({
      ...flowType,
      isOnlyTestFlow: true,
    }));
    dispatch(addMachineDetails({ ...itemDetails }));
  };

  const onChangeMachineDetailsHandler = () => {
    // to not commit until I don't finish fixing list of units for site Details from collector machine
    history.push(ROUTES.MACHINE_OPTIONS);
    dispatch(addMachineDetails({ ...itemDetails }));
  };

  const collectorMenuOptionsMachine = [
    {
      name: "Change position",
      path: "",
      onClickHandler: onChangePositionHandler,
    },
    {
      name: "Collection - machine",
      path: ROUTES.MACHINE_COLLECTION,
      onClickHandler: onClickMachineCollectionHandler,
    },
  ];

  const defaultMenuOptionsMachine = [
    {
      name: "Deactivate",
      path: "",
      onClickHandler: onClickDeactivateHandler,
    },
    {
      name: "Machine details",
      path: "",
      onClickHandler: onClickMenuItemHandler,
    },
    {
      name: "Replace QR code",
      path: "",
      onClickHandler: onClickReplacementTagHandler,
    },
    {
      name: "Change site",
      path: "",
      onClickHandler: onChangeSiteHandler,
    },
    {
      name: "Change position",
      path: "",
      onClickHandler: onChangePositionHandler,
    },
    {
      name: "Test",
      path: "",
      onClickHandler: onChangeTestHandler,
    },
    {
      name: "Change play price",
      path: ROUTES.MACHINE_SUMMARY,
      onClickHandler: onChangePlayPriceHandler,
    },
  ];

  const menuOptions = (roleType: string) => {
    let menuOptionsByRole;

    switch (roleType) {
      case ROLE_ADMIN:
      case ROLE_MASTER:
      case ROLE_TECHNICIAN:
      case ROLE_OPERATIVE:
          let notProdEnv = [...defaultMenuOptionsMachine];
          notProdEnv.splice(8, 0, {
            name: "Unit details",
            path: "",
            onClickHandler: onClickUnitDetails,
          });
          menuOptionsByRole = isProdEnv ? defaultMenuOptionsMachine : notProdEnv;
          break;
      case ROLE_COLLECTOR:
        if (isVending) {
          let collectorOptions = [...defaultMenuOptionsMachine];
          collectorOptions.splice(7, 0,{
            name: "Add stock",
            path: ROUTES.MACHINE_STOCK_DETAILS,
            onClickHandler: onClickAddStockHandler,
          });
          let notProdEnv = [...collectorOptions];
          notProdEnv.splice(8, 0, {
            name: "Unit details",
            path: "",
            onClickHandler: onClickUnitDetails,
          });
          menuOptionsByRole = isProdEnv ? collectorOptions : notProdEnv;
        } else {
          let notProdEnv = [...defaultMenuOptionsMachine];
          notProdEnv.splice(8, 0, {
            name: "Unit details",
            path: "",
            onClickHandler: onClickUnitDetails,
          });
          menuOptionsByRole = isProdEnv ? defaultMenuOptionsMachine : notProdEnv;        
        }
        break;
      default:
        let notProd = [...defaultMenuOptionsMachine];
        notProd.splice(8, 0, {
          name: "Unit details",
          path: "",
          onClickHandler: onClickUnitDetails,
        });
        menuOptionsByRole = isProdEnv ? defaultMenuOptionsMachine : notProd;    
      }

      let notOffSite = [...menuOptionsByRole];
      notOffSite.splice(7, 0, {
        name: "Collection - machine",
        path: ROUTES.MACHINE_COLLECTION,
        onClickHandler: onClickMachineCollectionHandler,
      });

      return isOffSite ? menuOptionsByRole : notOffSite;
  };

  return (
    <StyledMachineType className="machine-type-card">
      <div className="machine-type-icon">
        <img src={itemDetails.hostType.iconUrl} alt="icon-unit"/>
      </div>
      <div className="machine-type-wrapper">
        <div className="machine-type-header">
          {itemDetails.hostType && itemDetails.hostType.name && (
            <div className="machine-type-name">{itemDetails.hostType.name}</div>
          )}
          {itemDetails.position && (
            <div className="machine-type-position">{itemDetails.position}</div>
          )}
        </div>
        <div className="machine-type-details">
          {itemDetails.manufacturer && itemDetails.manufacturer.name && (
            <span className="machine-type-detail">
              {itemDetails.manufacturer.name}
            </span>
          )}
          <span className="machine-type-detail">Model</span>
          <span className="machine-type-detail">Name</span>
          {itemDetails.lastSeen && (
            <span className="machine-type-detail">
              Last seen {itemDetails.lastSeen}
            </span>
          )}
          {itemDetails.unitPowerSource && itemDetails.unitPowerSource.name && (
            <span className="machine-type-detail machine-type-battery">
              {itemDetails.unitPowerSource.name}
            </span>
          )}
        </div>
        {itemDetails.unitStatus.code && (
          <div
            className={`${
              isUnitDeactivated
                ? "machine-type-status deactivated"
                : "machine-type-status"
            }`}
          >
            Status {itemDetails.unitStatus.code}
          </div>
        )}
      </div>
      {hasMenu && !isReplaceTagFlow && (
        <div className="site-menu">
          <SiteMenuAppBar
            isClientPage={isClientPage}
            site={site}
            isMachine={true}
            itemDetails={itemDetails}
            menuList={menuOptions(userRole)}
          />
        </div>
      )}
    </StyledMachineType>
  );
};

export default MachineType;
