import {IState} from "../../store/models";



export const getUnitHostTypeData = (state: IState) => state.unitHostTypeList.data;
export const getUnitHostTypeLoading = (state: IState) => state.unitHostTypeList.loading;
export const getUnitHostTypeError = (state: IState) => state.unitHostTypeList.error;

export const getUnitManufacturerData = (state: IState) => state.unitManufacturerList.data;
export const getUnitManufacturerLoading = (state: IState) => state.unitManufacturerList.loading;
export const getUnitManufacturerError = (state: IState) => state.unitManufacturerList.error;

export const getUnitBatteryPoweredData = (state: IState) => state.unitBatteryPoweredList.data;
export const getUnitBatteryPoweredLoading = (state: IState) => state.unitBatteryPoweredList.loading;
export const getUnitBatteryPoweredError = (state: IState) => state.unitBatteryPoweredList.error;

export const getUnitCreditTypeData = (state: IState) => state.unitCreditTypeList.data;
export const getUnitCreditTypeLoading = (state: IState) => state.unitCreditTypeList.loading;
export const getUnitCreditTypeError = (state: IState) => state.unitCreditTypeList.error;

export const displayMachineDetails = (state: IState) => state.machineDetails.data;

export const getMachineDetailsLoading = (state: IState) => state.addMachineDetails.loading;
export const getMachineDetailsError = (state: IState) => state.addMachineDetails.error;
export const getMachineDetailsSuccessRequest = (state: IState) => state.addMachineDetails.data;
