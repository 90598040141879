import React, {FC, useEffect, useState} from "react";
import { StyledScanQRCodeError } from "./styles";
import { PrimaryButton } from "../../components/Buttons";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { displayMachineDetails } from "../MachineDetailsPage/selectors";
import NfcTagSection from "../../components/NfcTagIcon";
import {useWindowSize} from "../../utils/detectWindowSize";
import {QRIcon} from "../../components/QRCodeSection/QRIcon";
import * as ROUTES from "../../components/Containers/Main/constants";

const ScanQRCodeError: FC = () => {
  const machineDetails = useSelector(displayMachineDetails);
  const [dotColor, setDotColor] = useState('');
  const [isQRCodeDisplayed, setIsQRCodeDisplayed] = useState(false);
  const QR_CODE_ERROR = "This is the incorrect Tag for this machine, please continue to see the correct Tag for this machine";
  const history = useHistory();
  const windowSize = useWindowSize();
  const isDesktop = windowSize.width && windowSize.width > 1170;

  const setExternalTagVariations = () => {
    if (machineDetails.hostType.hostCategory.name === "Vending") {
      setIsQRCodeDisplayed(true);
      if (!machineDetails.metalCabinetOption) {
        setDotColor('green');
      } else {
        setDotColor('yellow');
      }

    } else {
      if (machineDetails.unitPowerSource.code === 'BATTERY') {
        setDotColor('red');
        setIsQRCodeDisplayed(false);
        return;
      }
      if (machineDetails.unitPowerSource.code === 'MAINS' && !machineDetails.metalCabinetOption) {
        setDotColor('green');
        setIsQRCodeDisplayed(true);
        return;
      }
      if (machineDetails.unitPowerSource.code === 'MAINS' && machineDetails.metalCabinetOption) {
        setDotColor('yellow');
        setIsQRCodeDisplayed(true);
        return;
      }
    }
  };

  const goToExternalNFCTag = () => {
    history.push(ROUTES.EXTERNAL_NFC_TAG);
  };

  const qRCodeHandler = () => {
    history.push(ROUTES.EXTERNAL_NFC_TAG);
  };

  useEffect(() => {
    setExternalTagVariations();
  }, []);

  return (
    <StyledScanQRCodeError className="height-controller-wrapper">
      <Header title="External NFC Tag?" />
      <div className="scanning-icons-wrapper">
        <NfcTagSection
          title=""
          isDesktop={isDesktop}
        />
        {isQRCodeDisplayed &&
        <QRIcon onClick={qRCodeHandler}/>
        }
        <div className={`${dotColor} dot-color`}></div>
      </div>
      <div className="helper-screen-description">
        {QR_CODE_ERROR}
      </div>
      <div className="actions-wrapper">
        <PrimaryButton
          className="continue-button"
          onClick={goToExternalNFCTag}
        >
          Continue
        </PrimaryButton>
      </div>
    </StyledScanQRCodeError>
  );
};

export default ScanQRCodeError;
