import styled from 'styled-components';
import paymeTheme from "../../../assets/styling/payme-theme";
import media from "../../../assets/styling/payme-media-queries";



const StyledCollectionHistoryCard = styled.div `
  padding: ${paymeTheme.paddingRemXs} 0;
  margin: 0;
  background-color: ${paymeTheme.colorWhite};
  border-bottom: 1px solid ${paymeTheme.borderGrey};
  font-size: ${paymeTheme.fontRemSizeSmall};
  font-weight: ${paymeTheme.fontBold};
  display: flex;
  justify-content: space-between;

  .card-wrapper {
    width: 100%;
  }

  .svg-wrapper {
    display: flex;
    align-items: center;
  }
  
  .collection-header,
  .collection-card-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .site-group {
      display: flex;
    }
    
    .header-content {
      width: 100%;

      .title {
        display: flex;
      }
    }
  }
  
  .period {
    color: ${paymeTheme.colorBlack};
  }

  .client-name {
    color: ${paymeTheme.colorBlack};
  }

  .site-town {
    color: ${paymeTheme.customColor};
  }

  .client-name, 
  .site-name,
  .site-town {
    width: 32%;
  }

  .collection-header {
    color: ${paymeTheme.colorBlack};
  }
  
  .body-content {
    color: purple;
    width: 90%;
    display: flex;
    justify-content: space-between;

    .price-wrapper {
      display: flex;
      align-items: center;
    }

    svg {
      width: 0.25rem;
      height: unset;
      padding-right: 0.5rem;
      fill: purple;
    }
  }

  .align-row-wrappers {
    color: transparent;
  }
  
  ${media.desktop} {
    margin: 0 auto;
  }

`;

export { StyledCollectionHistoryCard } ;
