import React, { FC } from "react";
import {StyledMachineDetails} from "./styles";
import {useSelector} from "react-redux";
import {displayMachineDetails} from "../../pages/MachineDetailsPage/selectors";
import {IMachineDetailsState} from "../../pages/MachineDetailsPage/model";


const MachineDetails: FC = () => {

  const machineSummaryDetails: IMachineDetailsState = useSelector(displayMachineDetails);

  return (
    <StyledMachineDetails>
      <div className="machine-type-icon">
        <img src={machineSummaryDetails.hostType.iconUrl} alt="machine-type-icon"/>
      </div>
      <div className="machine-content-wrapper">
        <div className="machine-type">{machineSummaryDetails.hostType.name}</div>
        <div className="machine-summary-wrapper">
          <div>{machineSummaryDetails.position}</div>
          <div>{machineSummaryDetails.manufacturer.name}</div>
          <div>{machineSummaryDetails.unitPowerSource.name}</div>
          {machineSummaryDetails.defaultTransactionAmount &&
          <div className="credit-value">£ {machineSummaryDetails.defaultTransactionAmount} per play</div>}
        </div>
      </div>
    </StyledMachineDetails>
  );
};

export default MachineDetails;
