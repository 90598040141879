import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";


const StyledVendingLayoutSelection = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: "Exo 2"!important;
  
  .message-content {
    color: ${paymeTheme.customColor};
    font-size: ${paymeTheme.fontRemSizeH5};
  }
  
`;

export { StyledVendingLayoutSelection } ;
