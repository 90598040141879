import React, { FC } from "react";
import { StyledGroupCardComponent } from "./styles";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

interface Props {
  item: any;
  onClickHandler: () => void;
}

const GroupCardComponent: FC <Props>= ({item, onClickHandler}) => {
  return (
    <StyledGroupCardComponent onClick={onClickHandler}>
        <div className="site-name group-name item-name">{item.name}</div>
        <ArrowForwardIosIcon/>
    </StyledGroupCardComponent>
  );
};

export default GroupCardComponent;
