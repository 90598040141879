import React, {FC, useEffect, useState} from "react";
import {StyledMachineDeactivationConfirmation} from "./styles";
import Header from "../../components/Header";
import {PrimaryButton} from "../../components/Buttons";
import {useHistory} from "react-router-dom";
import SummaryHeader from "../../components/SummaryHeader";
import {asyncRequest} from "../../api";
import {useDispatch, useSelector} from "react-redux";
import {displayMachineDetails} from "../MachineDetailsPage/selectors";
import {displaySiteDetailsFailure, displaySiteDetailsSuccess} from "../AllClientsSitesPage/actions";
import MachineType from "../SitePage/MachineTypeSection";
import {addMachineDetails} from "../MachineDetailsPage/actions";
import {getSiteDetailsData} from "../AllClientsSitesPage/selectors";
import * as ROUTES from "../../components/Containers/Main/constants";


const MachineDeactivationConfirmation: FC= () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const machineDetails = useSelector(displayMachineDetails);
  const siteDetails = useSelector(getSiteDetailsData);
  const [machineItemDetails, setMachineItemDetails] = useState(machineDetails);

  useEffect(() => {
    setMachineItemDetails(machineDetails);
  },[machineDetails]);

  const onClickSiteDetailsHandler = () => {
    const siteId = siteDetails.id;
    history.push(`${ROUTES.SITE_PAGE}/${siteId}`);
    asyncRequest({
      config:{
        method: "GET",
      },
      endpoint: `api/site/${siteId}`,
    }).then(response => {
      dispatch(displaySiteDetailsSuccess(response));
    })
      .catch(err => {
        console.log(err);
        dispatch(displaySiteDetailsFailure(err));
      });

    dispatch(addMachineDetails({
      ...machineDetails,
      siteId: siteId,
    }));
  };

  const finishDeactivationHandler = () => {
    history.push(ROUTES.HOME);
  };

  return (
    <StyledMachineDeactivationConfirmation className="height-controller-wrapper">
      <Header title="Deactivation"/>
      <div onClick={onClickSiteDetailsHandler}><SummaryHeader isMenuDisplayed={false} isArrowDisplayed={true}/></div>
      <MachineType itemDetails={machineItemDetails} hasMenu={false}/>
      <div className="helper-screen-description">
        This machine has been deactivated and added to your "Off site"
      </div>
      <div className="actions-wrapper">
        <PrimaryButton onClick={finishDeactivationHandler}>Finish</PrimaryButton>
      </div>
    </StyledMachineDeactivationConfirmation>
  );
};

export default MachineDeactivationConfirmation;
