import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";

const StyledMachineSummaryPage = styled.div `
  .machine-summary-wrapper {
    font-size: ${paymeTheme.fontRemSizeSmall};
  }
  .machine-type {
    color: ${paymeTheme.floteColor};
    font-size: ${paymeTheme.fontRemSizeLg};
    padding: ${paymeTheme.paddingRemSm} 0;
  }
  
  .credit-value {
    font-weight: ${paymeTheme.fontBold};
  }
  .test-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-grow: 4;
    .title {
      text-align: center;
      color: ${paymeTheme.errorColor};
      font-weight: ${paymeTheme.fontBold};
      padding-bottom: ${paymeTheme.paddingRemLg};
      font-size: ${paymeTheme.fontRemSizeLg};
    }
    .continue-button {
      margin: 0 auto;
    }
  }

  .actions-wrapper {
    .back-button {
      margin-right: ${props => props.theme.isHostSingleValue ? paymeTheme.paddingRemSm : 'auto'};
    }
  }

  .title {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30vh;
  }

  ${media.desktop} {
    
  }

`;

export { StyledMachineSummaryPage } ;
