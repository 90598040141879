export const extractVendingRefsByRow = (rowsMap, vendingRefs, vLowMaxs, vMedMaxs, vQtys, rowRefs, columnRefs, vAmts, vNames) => {
  for (let idx = 0; idx< rowRefs.length; idx++ ) {
    const key = rowRefs[idx];
    if (rowsMap.has(key)) {
      const currentValue = rowsMap.get(key);
      currentValue.push({
        col: columnRefs[idx],
        currentQty: vQtys[idx],
        vLowQty: vLowMaxs[idx],
        vMedQty: vMedMaxs[idx],
        selectionName: vendingRefs[idx],
        price: vAmts[idx],
        vName: vNames[idx]
      });
      rowsMap.set(key, currentValue) ;
    } else {
      rowsMap.set(key, [{
        col: columnRefs[idx],
        currentQty: vQtys[idx],
        vLowQty: vLowMaxs[idx],
        vMedQty: vMedMaxs[idx],
        selectionName: vendingRefs[idx],
        price: vAmts[idx],
        vName: vNames[idx]
      }]);
    }
  }
}
