import {IClientDetailsState, IClientState, ISiteTypeState} from "./model";
import {
  ADD_CLIENT_DETAILS,
  ADD_CLIENT_FAILURE,
  ADD_CLIENT_NAME,
  ADD_CLIENT_REQUEST,
  ADD_CLIENT_SUCCESS,
  ADD_SITE_TO_CLIENT_FAILURE,
  ADD_SITE_TO_CLIENT_REQUEST, DELETE_CLIENT_FAILURE, EDIT_CLIENT_NAME_FAILURE, EDIT_SITE_DETAILS_FAILURE,
  GET_SITE_TYPE_FAILURE,
  GET_SITE_TYPE_REQUEST,
  GET_SITE_TYPE_SUCCESS
} from "./constants";


const clientInitialState: IClientState = {
  data: {clientName: '', hasMultipleSites: true},
  loading: false,
  error: null
};

const clientDetailsInitialState: IClientDetailsState = {
  data: {clientName: '', sitesDetailsArray: [{name: '', town: '', postCode: '', siteType: {id: -1, code: '', name: ''}, what3Words: ''}]},
  loading: false,
  error: null
};

export const clientNameReducer = (state = clientInitialState, action: any) => {
  switch (action.type) {
    case ADD_CLIENT_NAME:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

export const clientDetailsReducer = (state = clientDetailsInitialState, action: any) => {
  switch (action.type) {
    case ADD_CLIENT_DETAILS:
      if (state.data.clientName !== action.payload.clientName) {
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: null
        };
      }
      if (!state.data.clientName) {
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: null
        };
      }
      return {
          ...state,
          data: {
            clientName: action.payload.clientName,
            sitesDetailsArray: [...state.data.sitesDetailsArray, action.payload.sitesDetailsArray[0]]
          },
          loading: false,
          error: null
        };
    case ADD_CLIENT_REQUEST:
    case ADD_SITE_TO_CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ADD_CLIENT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case ADD_CLIENT_FAILURE:
    case ADD_SITE_TO_CLIENT_FAILURE:
    case DELETE_CLIENT_FAILURE:
    case EDIT_CLIENT_NAME_FAILURE:
    case EDIT_SITE_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};


const siteTypeInitialState: ISiteTypeState = {
  data: [],
  loading: false,
  error: null
};

export const getSiteTypeReducer = (state = siteTypeInitialState, action: any) => {
  switch (action.type) {
    case GET_SITE_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_SITE_TYPE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case GET_SITE_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};
