import React, {useEffect, useState, FC} from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {extractVendingRefsByFirstLetter} from "../../../utils/extractVendingRefsByFirstLetter";
import {getListStyle, move, reorderElems} from "./utils";
import {useDispatch, useSelector} from "react-redux";
import {displayMachineDetails} from "../../MachineDetailsPage/selectors";
import {addMachineDetails} from "../../MachineDetailsPage/actions";


interface Props {
  vendingRefs: string[];
}


export const DroppableList: FC<Props> = ({ vendingRefs }) => {

  const machineDetails = useSelector(displayMachineDetails);
  const dispatch = useDispatch();

  const dictionaryMap = new Map();
  let gridArray = [];
  const refsArray = [];
  const refsRowPosArray = [];
  const refsColumnPosArray = [];
  const [state, setState] = useState([]);


  const createRefsArrays = () => {
    for (let i = 0; i < state.length; i++) {
      for (let j = 0; j < state[i].length; j++) {
        refsArray.push(state[i][j].content);
        refsColumnPosArray.push(j);
        refsRowPosArray.push(i);
      }
    }
  };

  useEffect(() => {
    extractVendingRefsByFirstLetter(vendingRefs, dictionaryMap);
    dictionaryMap.forEach(value => gridArray.push(value));
    setState(gridArray);
  }, [vendingRefs]);

  useEffect(() => {
    createRefsArrays();
    dispatch(addMachineDetails({
      ...machineDetails,
      VRow: [...refsRowPosArray],
      VCol: [...refsColumnPosArray],
      VendingRefs: [...refsArray]
    }));
  }, [state])

  function onDragEnd(result) {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorderElems(state[sInd], source.index, destination.index);
      const newState = [...state];
      newState[sInd] = items;
      setState(newState);
    } else {
      const result = move(state[sInd], state[dInd], source, destination);
      const newState = [...state];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];

      setState(newState.filter(group => group.length));
    }
  }

  return (
      <div style={{ display: "inline-flex", flexDirection: "column", width: "95vw", overflowY: "auto", paddingBottom: "10px"}}>
        <DragDropContext onDragEnd={onDragEnd}>
          {state.map((el, ind) => (
            <Droppable key={ind} droppableId={`${ind}`} direction="horizontal">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  {...provided.droppableProps}
                >
                  {el.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              alignItems: "center",
                              backgroundColor: "#0090DF",
                              color: "white",
                              fontSize: "22px",
                              minWidth: "8.5vw",
                              height: "40px",
                              padding: "0",
                              width: `${el.length === 1 ? '94vw' : `${90 / el.length}vw`}`,
                              margin: "2px auto",
                              border: "1px solid black",
                              borderRadius: "6px",
                            }}
                          >
                            {item.content}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </DragDropContext>
      </div>
  );
}

