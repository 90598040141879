import {IClientsState, ISIteDetailsState, ISitesState} from "./model";
import {
  GET_CLIENTS_FAILURE,
  GET_CLIENTS_REQUEST,
  GET_CLIENTS_SUCCESS, GET_OFF_SITE_DETAILS_FAILURE, GET_OFF_SITE_DETAILS_SUCCESS, GET_SITE_DETAILS_FAILURE,
  GET_SITE_DETAILS_SUCCESS,
  GET_SITES_FAILURE,
  GET_SITES_REQUEST,
  GET_SITES_SUCCESS
} from "./constants";
import { ADD_SITE_TO_CLIENT_SUCCESS, EDIT_SITE_DETAILS_SUCCESS_ON_SITE, EDIT_SITE_DETAILS_SUCCESS_ON_CLIENT } from "../AddNewClientPage/constants";



const clientsListInitialState: IClientsState = {
  data: null,
  loading: false,
  error: null
};

export const getClientsListReducer = (state = clientsListInitialState, action: any) => {
  switch (action.type) {
    case GET_CLIENTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_CLIENTS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case GET_CLIENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case EDIT_SITE_DETAILS_SUCCESS_ON_CLIENT:
      const sitesItems = state.data.items.map((item, index) => {
        if (`${item.id}` === action.payload.clientId) {
          const site = item.sites.filter((siteItem) => siteItem.id == action.payload.siteId)[0];
          
          const updatedSite = {
            clientName: site.clientName,
            id: action.payload.siteId,
            name: action.payload.name,
            offSite: site.offSite,
            postcode: action.payload.postCode,
            siteType: action.payload.siteType,
            town: action.payload.town,
            units: site.units,
            what3Words: action.payload.what3Words,
          }
          

          return {
            ...item,
            sites: item.sites.map((site) => site.id == action.payload.siteId ? updatedSite : site)
          };
        } else {
          return state.data.items[index];
        }
      })

      return {
        ...state,
        data: {
          ...state.data,
          items: sitesItems
        },
        loading: false,
      }
    case ADD_SITE_TO_CLIENT_SUCCESS:
      const site = action.payload.site;
      const items = state.data.items.map((item, index) => {
        if (item.id === parseInt(action.payload.clientId)) {
          return {...state.data.items[index], sites: [...state.data.items[index].sites, site]}
        } else {
          return state.data.items[index];
        }
      });
      return {
        ...state,
        data: {
          ...state.data,
          items
        },
        loading: false,
        error: null
      };
    default:
      return state;
  }
};

const sitesListInitialState: ISitesState = {
  data: null,
  loading: false,
  error: null
};

export const getSitesListReducer = (state = sitesListInitialState, action: any) => {
  switch (action.type) {
    case GET_SITES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_SITES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case GET_SITES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

const siteDetailsInitialState: ISIteDetailsState = {
  data: {
    clientName: '',
    id: -1,
    name: '',
    postcode: '',
    siteType: {id: -1, code: '', name:''},
    town: '',
    units: [{id: -1,
      hostType: {hostTypeId: -1, name: '', code: '', maxTransactionAmount: -1, iconUrl: '', hostCategory: {
          id: -1, name: '', code: ''
        }},
      lastSeen: '',
      position: '',
      manufacturer: {id: -1, name:  ''},
      unitPowerSource: {id: -1, code: '', name: ''},
      unitStatus: {id: -1, code: '', name: ''}
    }],
    what3Words: '',
  },
  loading: false,
  error: null,
};

export const getSiteDetailsReducer = (state = siteDetailsInitialState, action: any) => {
  switch (action.type) {
    case GET_SITE_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case GET_SITE_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case EDIT_SITE_DETAILS_SUCCESS_ON_SITE: 
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          id: action.payload.siteId,
          name: action.payload.name,
          postcode: action.payload.postCode,
          siteType: action.payload.siteType,
          town: action.payload.town,
          what3Words: action.payload.what3Words,
        }
      }
    default:
      return state;
  }
};

export const getOffSiteDetailsReducer = (state = siteDetailsInitialState, action: any) => {
  switch (action.type) {
    case GET_OFF_SITE_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case GET_OFF_SITE_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

