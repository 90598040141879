import {IUserDetailsState} from "./model";
import {
  ADD_NEW_MEMBER_FAILURE,
  ADD_NEW_MEMBER_REQUEST,
  ADD_NEW_MEMBER_SUCCESS,
  DISPLAY_USER_DETAILS, USER_DETAILS_ATTEMPT, USER_DETAILS_FAILURE, USER_DETAILS_SUCCESS
} from "./constants";


const userDetailsInitialState: IUserDetailsState = {
  data: null,
  loading: false,
  error: null
};


export const userDetailsReducer = (state = userDetailsInitialState , action: any) => {
  switch (action.type) {
    case DISPLAY_USER_DETAILS:
    case USER_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case USER_DETAILS_ATTEMPT:
      return {
        ...state,
        loading: true,
        error: null
      };
    case USER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};


export const addNewMemberReducer = (state = userDetailsInitialState, action: any) => {
  switch (action.type) {
    case ADD_NEW_MEMBER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ADD_NEW_MEMBER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case ADD_NEW_MEMBER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};


