import {ADD_STOCK_DETAILS} from "./constants";
import {IStockSelectionDetailsState} from "./model";


const stockDetailsState: IStockSelectionDetailsState = {
  data: {
    col: -1,
    currentQty: -1,
    vLowQty: -1,
    vMedQty: -1,
    selectionName: '',
    price: -1,
    vName: ''
  }
};


export const stockSelectionDetailsReducer = (state = stockDetailsState, action: any) => {
  switch (action.type) {
    case ADD_STOCK_DETAILS:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

