import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";




const StyledCollectionReceipt = styled.div `
  .collection-summary-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 0.875rem;
  }
  .site-menu {
    svg {
      color: ${paymeTheme.floteColor};
    }
  }
  .total-cash-collected {
    color: ${paymeTheme.floteColor};
    font-weight: ${paymeTheme.fontSemiBold};
  }
  ${media.desktop} {
    .collection-summary-wrapper {
      min-width: 30%;
      max-width: 50%;
      position: relative;
      right: 0;
      left: 0;
      margin: auto;
      padding-right: 0;

      svg {
        padding-top: ${paymeTheme.paddingRem};
        padding-left: ${paymeTheme.paddingRem};
      }
    }

    .title {
      width: unset;
    }
  }

`;

export { StyledCollectionReceipt } ;
