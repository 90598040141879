export const GET_HOST_TYPE_SUCCESS = "GET_HOST_TYPE_SUCCESS";
export const GET_HOST_TYPE_REQUEST = "GET_HOST_TYPE_REQUEST";
export const GET_HOST_TYPE_FAILURE = "GET_HOST_TYPE_FAILURE";


export const GET_UNIT_MANUFACTURER_SUCCESS = "GET_UNIT_MANUFACTURER_SUCCESS";
export const GET_UNIT_MANUFACTURER_REQUEST = "GET_UNIT_MANUFACTURER_REQUEST";
export const GET_UNIT_MANUFACTURER_FAILURE = "GET_UNIT_MANUFACTURER_REQUEST";

export const GET_UNIT_BATTERY_POWERED_SUCCESS = "GET_UNIT_BATTERY_POWERED_SUCCESS";
export const GET_UNIT_BATTERY_POWERED_REQUEST = "GET_UNIT_BATTERY_POWERED_REQUEST";
export const GET_UNIT_BATTERY_POWERED_FAILURE = "GET_UNIT_BATTERY_POWERED_FAILURE";

export const GET_UNIT_CREDIT_TYPE_SUCCESS = "GET_UNIT_CREDIT_TYPE_SUCCESS";
export const GET_UNIT_CREDIT_TYPE_REQUEST = "GET_UNIT_CREDIT_TYPE_REQUEST";
export const GET_UNIT_CREDIT_TYPE_FAILURE = "GET_UNIT_CREDIT_TYPE_FAILURE";

export const ADD_MACHINE_DETAILS = "ADD_MACHINE_DETAILS";
export const MACHINE_DETAILS_REQUEST = "MACHINE_DETAILS_REQUEST";
export const MACHINE_DETAILS_SUCCESS = "MACHINE_DETAILS_SUCCESS";
export const MACHINE_DETAILS_FAILURE = "MACHINE_DETAILS_FAILURE";

export const MIN_CREDIT_VALUE = "1.00";

export const NO_HARDWARE = "There is no response from the hardware. Please try again or abort!";
export const ERROR_HARDWARE = "There was an error from the hardware. Please try again or abort!";