import React, {useEffect, useState} from "react";
import RoomIcon from "@material-ui/icons/Room";
import {StyledClientPage} from "../../ClientPage/styles";
import Header from "../../../components/Header";
import SummaryHeader from "../../../components/SummaryHeader";
import {useDispatch, useSelector} from "react-redux";
import {getClientsListData, getClientsListError, getClientsListLoading} from "../selectors";
import LoadingComponent from "../../../components/Loading";
import {NotificationError} from "../../../components/NotificationMessages";
import {IClientItem, ISiteDetailsData} from "../model";
import {OutlinedButton, PrimaryButton} from "../../../components/Buttons";
import {useHistory, useParams} from "react-router-dom";
import {asyncRequest} from "../../../api";
import {addClientDetails} from "../../AddNewClientPage/actions";
import * as ROUTES from "../../../components/Containers/Main/constants";
import { displayMachineDetails } from "../../MachineDetailsPage/selectors";
import { displaySiteDetailsFailure, displaySiteDetailsSuccess } from "../actions";
import {addMachineDetails} from "../../MachineDetailsPage/actions";
import { getFlowType } from "../../../components/Containers/Main/selectors";
import { parseJwt } from "../../../utils/parseDetailsFromToken";
import { getInitialSiteDetailsData } from "../../MachineSiteChange/selectors";

const ClientSitesListPage = () => {
  const dispatch = useDispatch();
  const clientsList = useSelector(getClientsListData);
  const errorClientDetails = useSelector(getClientsListError);
  const loadingClientDetails = useSelector(getClientsListLoading);
  const machineDetails = useSelector(displayMachineDetails);
  const flowType = useSelector(getFlowType);
  const isChangeSiteFlow = flowType.isChangeSiteFlow;
  const userToken = localStorage.getItem("token");
  const userCompanyType = userToken && parseJwt(userToken).companyType;
  const siteDetails = useSelector(getInitialSiteDetailsData);
  
  const [clientDetailsState, setClientDetailsState] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setIsError] = useState('');
  const [isContinueBtnDisabled, setIsContinueBtnDisabled] = useState(true);
  const [selectedId, setselectedId] = useState();

  const { id } = useParams();
  const history = useHistory();
  const sites = !!clientDetailsState && !!clientDetailsState.sites && !!clientDetailsState.sites.length &&
    clientDetailsState.sites.map((item: ISiteDetailsData | undefined, index: number) => (
        isChangeSiteFlow && item && item.id == siteDetails.id ?
          (<div key={`${index}-site`} className="disabled-site">
            <div className="site-header">
              {userCompanyType !== "SOLO" && item &&
                item.clientName && (
                  <p className="client-name">{item.clientName}</p>
              )}
              {item && item.name && (
                <p className={"site-name " + userCompanyType !== "SOLO" && item.clientName ? "site-and-client-name" : ""}>
                  {item.name}
                </p>
              )}
            </div>
            <p>Show all details</p>
            <RoomIcon />
          </div>) : 
          (
          <div key={`${index}-site`} onClick={() => onClickListItemHandler(item.id, index)}>
                      <SummaryHeader isMenuDisplayed={false}
                                    isArrowDisplayed={false}
                                    site={item}
                                    isClientPage={true}/>
          </div>)
    ));

  useEffect(() => {
    if (clientsList && clientsList.items && clientsList.items.length > 0 &&
      clientsList.items.filter((client: IClientItem) => client.id == id).length > 0) {
      setClientDetailsState(clientsList.items.filter((client: IClientItem) => client.id == id)[0]);
    }
  }, []);


  useEffect(() => {
    clientDetailsState && dispatch(addClientDetails(clientDetailsState.name, clientDetailsState.sites))
  }, [clientDetailsState]);

  useEffect(() => {
    if (!!id && !clientsList) {
      setIsLoading(true);
      asyncRequest({
        config: {
          method: "GET",
        },
        endpoint: `api/client/${id}`,
      })
        .then((response) => {
          dispatch(addClientDetails(response.name, response.sites));
          setClientDetailsState(response);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsError(err.response.data.title);
          setIsLoading(false);
        });
    }
  }, [clientsList]);

  const onClickListItemHandler = (siteId: number, id: number) => {
    setselectedId(id);
    setIsContinueBtnDisabled(false);

    asyncRequest({
      config:{
        method: "GET",
      },
      endpoint: `api/site/${siteId}`,
    }).then(response => {
      dispatch(displaySiteDetailsSuccess(response));
    })
    .then(() => {
      dispatch(addMachineDetails({
        ...machineDetails,
        siteId
      }));
    })
      .catch(err => {
        console.log(err);
        dispatch(displaySiteDetailsFailure(err));
      });
  };


  const machineSiteChangeHandler = () => {
    history.push(ROUTES.MACHINE_SITE_CHANGE);
  };
  
  return (
    <StyledClientPage className="height-controller-wrapper" theme={{id: selectedId}}>
      {loadingClientDetails && <LoadingComponent/>}
      {errorClientDetails && <NotificationError message={errorClientDetails} messageClass="error"/>}
      <Header title="Client sites list"/>
      <span className="is-selected-wrapper">
        <div className="none"></div>
        {sites}
      </span>
      <div className="actions-wrapper">
        <OutlinedButton className="back-button" onClick={() => {history.goBack()}}>Back</OutlinedButton>
        <PrimaryButton className="continue-button"
                        disabled={isContinueBtnDisabled}
                        onClick={machineSiteChangeHandler}>Continue</PrimaryButton>
      </div>
      {isLoading && <LoadingComponent/>}
      {error && <NotificationError messageClass="error" message={error}/>}
    </StyledClientPage>
  );
};

export default ClientSitesListPage;
