import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";




const StyledMachineDetailsPage = styled.div `
  form {
    padding: ${paymeTheme.paddingRemLg};
  }
  
  ${media.desktop} {
    form {
      padding-top: 3.5rem;
    }
  }

`;

export { StyledMachineDetailsPage } ;
