import React, {FC, useEffect, useState} from 'react';
import IconButton from '@material-ui/core/IconButton';
import {SiteAppBarStyles, StyledMenu, StyledMenuItem, StyledSubMenuItem, useStyles} from "./styles";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {getSiteDetailsData} from "../../AllClientsSitesPage/selectors";
import {useDispatch, useSelector} from "react-redux";
import { ISiteDetailsData } from '../../AllClientsSitesPage/model';
import { displaySiteDetailsSuccess } from '../../AllClientsSitesPage/actions';
import { addMachineDetails } from '../../MachineDetailsPage/actions';
import { displayMachineDetails } from '../../MachineDetailsPage/selectors';

interface Props {
  isClientPage?: boolean,
  isMachine?: boolean,
  site?: ISiteDetailsData,
  menuList: any;
  itemDetails?: any;
  isGroupedClicked?: boolean;
  groupList?: any;
  onGroupOptionsClickHandler?: any;
}


export const SiteMenuAppBar: FC<Props> = ({ isClientPage, isMachine, site, menuList, itemDetails, isGroupedClicked, groupList, onGroupOptionsClickHandler }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const machineDetails = useSelector(displayMachineDetails);

  useEffect(() => {
    if (open) {
      if (isClientPage) {
        dispatch(displaySiteDetailsSuccess(site));
        if (isMachine) {
          dispatch(addMachineDetails({
            ...machineDetails,
            siteId: site.id,
          }));
        }
      }
    }
  }, [open]);

  const siteDetails = useSelector(getSiteDetailsData);
  const siteId = siteDetails.id;

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const groupMenuList = groupList && groupList.length > 0 && groupList.map((item: any, position: number) => {
    return (<StyledSubMenuItem key={`sub-item-${position}`}
                               style={{backgroundColor: item.partyId.indexOf(siteId) !== -1 ? 'yellow' : 'transparent'}}
                               onClick={() => onGroupOptionsClickHandler(item.id, item.partyId)}>
      {item.name}</StyledSubMenuItem>);
  });

  const menuOptionsList = menuList && menuList.length && menuList.map((item: any, index: number) => {
    if (item.hasSubmenu) {
      return (
        <div>
          <StyledMenuItem key={`item-${index}`}
                          onClick={() => {item.onClickHandler()}}>
            {item.name}
          </StyledMenuItem>
          {isGroupedClicked && groupMenuList}
        </div>
      )
    }
    return (
      <StyledMenuItem key={`item-${index}`}
                      onClick={() => {item.onClickHandler(); handleClose()}}>
        {item.name}
      </StyledMenuItem>
    )
  });



  return (
    <div className={classes.root}>
      <SiteAppBarStyles>
        <IconButton
          aria-label="site-menu"
          aria-controls="site-menu"
          aria-haspopup="true"
          color="inherit"
          onClick={handleMenu}
        >
          <MoreVertIcon/>
        </IconButton>
        <StyledMenu
          id="site-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={handleClose}
        >
          {menuOptionsList}
        </StyledMenu>
      </SiteAppBarStyles>
    </div>
  );
};
