import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";

import * as serviceWorker from "./serviceWorker";
import configureStore, { history } from "./store/configureStore";
import "./index.css";
import Main from "./components/Containers/Main";
import dotenv from "dotenv";

const { store } = configureStore();
dotenv.config();

ReactDOM.render(
  <Provider store={store}>
     <ConnectedRouter history={history}>
         <Main />
     </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

