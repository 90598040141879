import * as React from "react";

function QRIcon(props) {
  return (
    <svg
      height={512}
      viewBox="0 0 24 24"
      width={512}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M23 8.696a1 1 0 01-1-1v-4.74A.957.957 0 0021.045 2h-4.74a1 1 0 110-2h4.74A2.958 2.958 0 0124 2.956v4.74a1 1 0 01-1 1zM1 8.696a1 1 0 01-1-1v-4.74A2.958 2.958 0 012.955 0h4.74a1 1 0 110 2h-4.74A.957.957 0 002 2.956v4.74a1 1 0 01-1 1zM21.045 24h-4.74a1 1 0 110-2h4.74a.957.957 0 00.955-.956v-4.74a1 1 0 112 0v4.74A2.958 2.958 0 0121.045 24zM7.695 24h-4.74A2.958 2.958 0 010 21.044v-4.74a1 1 0 112 0v4.74c0 .527.429.956.955.956h4.74a1 1 0 110 2z" />
      <path d="M9.478 11.159H4.5a1 1 0 01-1-1V4.5a1 1 0 011-1h4.978a1 1 0 011 1v5.659a1 1 0 01-1 1zm-3.978-2h2.978V5.5H5.5zM19.5 20.5h-4.75a1 1 0 01-1-1v-3.182a1 1 0 112 0V18.5h2.75v-3h-1.182a1 1 0 110-2H19.5a1 1 0 011 1v5a1 1 0 01-1 1zM19.5 9.591h-4.091a1 1 0 01-1-1V4.5a1 1 0 011-1H19.5a1 1 0 011 1v4.091a1 1 0 01-1 1zm-3.091-2H18.5V5.5h-2.091z" />
      <path d="M12.478 14.136H4.5a1 1 0 110-2h6.978V5.409a1 1 0 112 0v7.727a1 1 0 01-1 1zM15.25 13.886a1 1 0 01-1-1v-1.363a1 1 0 011-1h4.5a1 1 0 110 2h-3.5v.363a1 1 0 01-1 1zM12.022 19.201a1 1 0 01-1-1v-2.272a1 1 0 112 0v2.272a1 1 0 01-1 1zM9.25 20.5H4.5a1 1 0 01-1-1v-3.636a1 1 0 011-1h4.75a1 1 0 011 1V19.5a1 1 0 01-1 1zm-3.75-2h2.75v-1.636H5.5z" />
    </svg>
  )
}

function HomeQRIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={400}
      height={378.667}
      viewBox="0 0 300 284"
      {...props}
    >
      <path d="M21 47v36h72V11H21v36zm62 0v26H31V21h52v26z" />
      <path d="M42 47.5V63h31V32H42v15.5zM114.7 11.6c-.4.4-.7 2.7-.7 5v4.3l-4.7.3-4.8.3v10l4.8.3 4.7.3v9.8l-4.7.3-4.8.3-.3 4.7-.3 4.6 5.3.4c2.9.1 7.6.1 10.3-.2l5-.5.5-4.5.5-4.5h9l.3 4.7.3 4.8h9.9l.1-4.8c.1-2.6 0-6.2-.1-7.9-.4-7 .1-7.3 11-7.3h9.9l.3 4.7.3 4.8h10v-30h-10l-.3 4.7-.3 4.7-15.2.3-15.2.3-.3 4.7c-.3 4.1-.7 4.9-2.7 5.4-1.2.3-3.4.4-4.6.2-2.3-.3-2.4-.7-2.9-10.3l-.5-10-4.5-.3c-2.5-.2-4.9 0-5.3.4zM187.7 11.7c-.4.3-.7 2.6-.7 5V21h10V11h-4.3c-2.4 0-4.7.3-5 .7zM208 47v36h72V11h-72v36zm62 0v26h-52V21h52v26z" />
      <path d="M228 47.5V63h32V32h-32v15.5zM186.8 36.7l-.3 4.8-4.7.3-4.8.3V52h3.8c5.4 0 6.4 1 6 6.1l-.3 4.4-15.2.3-15.3.3v9.8l4.8.3 4.7.3.3 4.5c.4 5 1.3 6 5.7 6 4.2 0 5.5-1.6 5.5-6.8v-4.3l4.8.3 4.7.3.5 10 .5 10 4.8.3 4.7.3v-5c0-2.8.1-12.3.1-21.1s-.1-20.5-.1-26V32h-9.9l-.3 4.7zM156.7 42.7c-.4.3-.7 2.6-.7 5V52h10V42h-4.3c-2.4 0-4.7.3-5 .7zM125.5 53.2c-.3.8-.4 7.9-.3 15.8l.3 14.5h9v-31l-4.3-.3c-2.8-.2-4.4.1-4.7 1zM146.2 53.2c-1.4 1.4-1.6 6.7-.3 8.6.5.9 2.4 1.2 5.2 1l4.4-.3.3-4.4c.2-2.8-.1-4.7-1-5.2-1.9-1.3-7.2-1.1-8.6.3zM104.2 73.2l.3 10.3h9l.3-10.3.3-10.2h-10.2l.3 10.2zM145.5 75.2c-.3 1.3-.4 8.1-.3 15.3l.3 13 10.3.3 10.2.3V84h-3.7c-5.2 0-6.1-.9-6.5-6l-.3-4.5-4.7-.3c-4.2-.3-4.8-.1-5.3 2zM21.4 95.3c-.3.8-.4 5.4-.2 10.3l.3 8.9h9l.3-4c.4-5.1 1.7-6.5 5.7-6.5s5.3 1.4 5.7 6.5l.3 4 4.7.3 4.7.3.3 4.7.3 4.7 4.7.3 4.7.3.3 4.7c.3 4.4.5 4.7 3.3 5 7.4.6 7.6.9 7.3 6l-.3 4.7-4.7.3c-4.1.3-4.9.7-5.4 2.8-.3 1.3-.3 3.5 0 4.9.6 2.5.8 2.5 10.6 2.5h9.9l.3-4.8.3-4.7 4.5-.3c5-.4 6-1.3 6-5.8 0-3.9-1.4-5.2-6.5-5.6l-4-.3-.3-4.7-.3-4.7-4.7-.3-4.7-.3-.3-4.8-.3-4.7h-4.3c-5 0-6.6-1.3-6.6-5.5s1.6-5.5 6.8-5.5h4.3l-.3-4.8-.3-4.7-25.3-.3c-20.4-.2-25.3 0-25.8 1.1z" />
      <path d="M83.7 94.7c-1.3 1.2-.7 8.3.6 8.9.8.3 7.6.6 15.3.7l13.9.2.3 10.3.3 10.3-4.8-.3-4.8-.3-.3-4.7-.3-4.6-5.2-.4c-2.9-.1-7.4-.1-10.2.2l-5 .5v9l5 .5 5 .5.3 4.7.3 4.7 9.7.3 9.7.3v10l-4.7.3-4.8.3v9.9h9.9c8.1 0 10 .3 10.5 1.6.3.9.6 5.6.6 10.5v9l4.8-.3 4.7-.3.3-15.2.3-15.2-4.8-.3-4.8-.3-.3-4.7c-.3-4.4-.1-4.8 2.2-5.4 1.5-.3 3.7-.3 5 0 2.1.5 2.5 1.3 2.8 5.4l.3 4.7 4.8.3 4.7.3.1-5.3v-9.3c-.2-6.3.4-6.6 11-6.3l9.4.3.3 4.8.3 4.8 5.2-.3 5.2-.3.3-13.9c.3-15.9-.1-16.9-6.2-16.4l-4.1.3-.5 5-.5 5-10 .2c-5.5.1-12.3.2-15.2.3h-5.2l-.3 4.7-.3 4.8-4.8.3-4.8.3.3-10.3.3-10.3 4.8-.3 4.7-.3V94h-25.3c-14 0-25.7.3-26 .7zM177 98.9v5.1h10.1l-.3-4.8-.3-4.7-4.7-.3-4.8-.3v5zM197.7 94.6c-.4.4-.7 2.7-.7 5v4.3l-4.7.3-4.8.3v10l4.7.3 4.7.3.3 4.7.3 4.7 10.3.3 10.2.3v-10l-4.7-.3-4.8-.3-.5-10-.5-10-4.5-.3c-2.5-.2-4.9 0-5.3.4zM218.4 95.3c-.3.8-.4 5.4-.2 10.3l.3 8.9 4.8.3 4.7.3v3.7c0 5.4 1 6.4 6.1 6l4.4-.3v-20l-4.5-.3c-5.1-.4-6-1.3-6-6.5V94h-4.5c-2.9 0-4.8.5-5.1 1.3zM239 99v5h10V94h-10v5zM260 98.9v5.1h10.1l-.3-4.8-.3-4.7-4.7-.3-4.8-.3v5zM270.4 105.3c-.3.8-.4 3.2-.2 5.3l.3 3.9h9v-10l-4.3-.3c-3-.2-4.4.1-4.8 1.1zM249.4 116.3c-.3.9-.4 3-.2 4.9.3 3.3.4 3.3 5.3 3.3h5v-9l-4.8-.3c-3.4-.2-4.9.1-5.3 1.1zM21.7 125.6c-.4.4-.7 2.7-.7 5v4.3l4.8.3 4.7.3.5 5 .5 5 5 .5 5 .5.3 4.7.3 4.8h9.8l.3-4.8.3-4.7 4.8-.3 4.8-.3-.3-5.2-.3-5.2-4.5-.5-4.5-.5-.5-4.5-.5-4.5-14.6-.3c-8-.1-14.8.1-15.2.4z" />
      <path d="M156.4 136.3c-.3.8-.4 3.2-.2 5.3l.3 3.9h9v-10l-4.3-.3c-3-.2-4.4.1-4.8 1.1zM177.7 135.7c-.4.3-.7 2.2-.7 4.2 0 5-.9 5.9-6 6.3l-4.5.3v9l4.7.3c5.1.3 5.4.7 6 7.7.3 2.8.5 3 4.8 3h4.5l.5-5 .5-5 4.8-.3 4.7-.3v-3.7c0-6 .6-6.3 11.1-6l9.4.3.5 4.5.5 4.5 4.5.5c4.4.5 4.5.6 4.8 4.5.4 5.1 1.7 6.5 5.6 6.5 5.1 0 5.5-.8 5.8-11l.3-9.5 4.5-.5 4.5-.5v-10l-35.1-.3c-19.3-.1-35.3.1-35.7.5zM260.7 135.7c-.4.3-.7 2.8-.7 5.4v4.8l4.8.3 4.7.3v9l-10 .5-10 .5-.3 4.7c-.3 5.1-.1 5.4 7.3 6 2.8.3 3 .6 3.3 5l.3 4.7 4.7.3 4.7.3.3 9.4c.2 6.8-.1 9.7-1 10.2-.7.4-5 .8-9.7.8-4.6 0-8.8.4-9.3.9s-.8 2.7-.6 4.8l.3 3.9 15.3.3 15.2.3.1-5.3c.2-24.5-.2-66.1-.6-66.9-.7-1-17.8-1.3-18.8-.2zM21 166.5V187h9.9l.3-9.8c.2-5.3.2-14.5 0-20.5l-.3-10.7H21v20.5zM145.4 148.4c-.3 1.5-.3 3.7 0 5.2.6 2.3 1 2.5 5.4 2.2l4.7-.3v-9l-4.7-.3c-4.4-.3-4.8-.1-5.4 2.2zM52.7 156.7c-.4.3-.7 2.6-.7 4.9 0 3.8.3 4.4 2.4 5 1.3.3 3.5.4 4.7.2 2.1-.3 2.4-.9 2.7-5.6l.3-5.2h-4.4c-2.4 0-4.7.3-5 .7zM83.7 156.7c-.4.3-.7 2.5-.7 4.9 0 2.9.5 4.4 1.6 4.8 2.6 1 6.9.7 8.2-.6 1.3-1.3 1.6-5.6.6-8.2-.4-1.1-1.9-1.6-4.8-1.6-2.4 0-4.6.3-4.9.7z" />
      <path d="M156.7 156.6c-.4.4-.7 2.7-.7 5 0 4.7-.4 5-7.5 5.6l-3 .3v19l10 .5 10 .5.5 5 .5 5 5 .5 5 .5.3 4.7.3 4.7 4.7.3 4.7.3.3 4.4c.4 5.1-.6 6.1-6.1 6.1h-3.8l.3 4.7.3 4.8 4.5.5 4.5.5.3 4.8.3 4.8 10.2-.3 10.2-.3.3-4.8.3-4.7h20l-.3 10.2-.3 10.3-3.6.3c-4.3.4-5.6-1-6.1-6.3l-.3-4h-9l-.3 4c-.5 5.4-1.8 6.7-6.6 6.5-2.3-.1-6.5-.1-9.3-.1l-5.3.1v20h10v-3.4c0-7-1.5-6.6 26.6-6.6H249v-9.9l-4.7-.3-4.8-.3v-20l4.5-.5 4.5-.5.3-10.2.3-10.2-4.8-.3-4.8-.3-.3-9.4c-.3-10.5 0-11.1 6.1-11.1h3.7v-10h-25.4c-28.1 0-26.6.4-26.6-6.6V167h-9.9l-.3 4.7-.3 4.8h-20l-.5-10-.5-10-4-.3c-2.3-.2-4.4 0-4.8.4zm71.1 46.2l.3 15.3-15.3-.3-15.3-.3-.2-14c-.1-8.5.2-14.4.8-15.2.8-.9 4.8-1.2 15.2-1l14.2.2.3 15.3z" />
      <path d="M208 203v5h10v-10h-10v5zM197.7 156.6c-1.3 1.3-.7 8.1.7 9.3.9.8 4.6 1.1 10.3.9l8.8-.3v-10l-9.5-.3c-5.3-.1-9.9 0-10.3.4zM42 176.9v10l10.8.3c5.9.2 17.5.1 25.7-.2l15-.5v-9l-5-.5-5-.5-.3-4.8-.3-4.7h-9.8l-.3 4.7-.3 4.8h-20l-.3-4.8-.3-4.7H42v9.9zM94 172.1v5l4.8-.3 4.7-.3.3-4.8.3-4.7H94v5.1zM104.7 187.6c-.4.4-.7 5.1-.7 10.5v9.8l15.3.3 15.2.3.5 10 .5 10 3.9.3c2.1.2 4.3-.1 4.8-.6s.9-4.7.9-9.3c0-4.7.3-9 .7-9.6.4-.6 2.8-1.3 5.2-1.5l4.5-.3.3-4.8.3-4.7-5.3-.1h-9.3c-5.4.2-6.3-.7-6.7-5.9l-.3-4.5-14.6-.3c-8-.1-14.8.1-15.2.4zM21 234v36h72v-72H21v36zm62 0v26H31v-52h52v26z" />
      <path d="M42 234v16h31v-32H42v16zM104 244.4v25.5l5.3.3c2.8.2 9.8.2 15.5 0l10.2-.3V260h-10c-9.7 0-10-.1-10.6-2.4-1.1-4.7-.1-7.1 3.1-7.4 7.4-.6 7.6-.9 7.3-6l-.3-4.7-4.7-.3c-4.1-.3-4.9-.7-5.4-2.8-.3-1.3-.3-3.5 0-4.9.6-2.2 1.2-2.5 5.1-2.5 3.9 0 4.5-.3 5.1-2.4.3-1.3.4-3.5.2-4.7-.3-2.1-.9-2.4-5-2.7l-4.7-.3-.4 4.8-.3 4.8-.2-4.8-.2-4.7h-10v25.4zM145.4 231.5c-.3 1.4-.3 3.6 0 4.9.5 2.1 1.3 2.5 5.4 2.8l4.7.3.3 4.7c.3 5.1.1 5.4-7.3 6l-3 .3v19l5.2.3 5.2.3.3-4.8.3-4.8 4.7-.3 4.7-.3.3-4.7.3-4.7 4-.3c5.1-.4 6.5-1.7 6.5-5.6 0-4.5-1-5.4-6-5.8l-4.5-.3-.3-4.8-.3-4.7H156c-9.8 0-10 0-10.6 2.5zM260 233.9v5l4.8.3 4.7.3.5 5 .5 5h9l.3-10.3.3-10.2H260v4.9zM249.7 239.6c-.4.4-.7 2.6-.7 5 0 4.5 1 5.4 6 5.4 3.7 0 5.2-2.1 4.8-6.7-.3-3.7-.4-3.8-4.8-4.1-2.5-.2-4.9 0-5.3.4zM259.8 254.7l-.3 4.8-5 .5-5 .5v9l5 .5c2.8.3 9.6.4 15.3.2l10.2-.3v-9.8l-4.7-.3-4.8-.3-.3-4.8-.3-4.7h-9.8l-.3 4.7zM166.4 261.3c-.3.9-.4 3-.2 4.9.3 3.3.4 3.3 5.3 3.3h5v-9l-4.8-.3c-3.4-.2-4.9.1-5.3 1.1z" />
    </svg>
  )
}

const DisabledQRIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={400}
      height={378.667}
      viewBox="0 0 300 284"
      {...props}
    >
      <style>
        {
          ".disabled-qr{fill:#D3D3D3}"
        }
      </style>
      <path             
        className="disabled-qr"
        d="M21 47v36h72V11H21v36zm62 0v26H31V21h52v26z" 
      />
      <path             
        className="disabled-qr"
        d="M42 47.5V63h31V32H42v15.5zM114.7 11.6c-.4.4-.7 2.7-.7 5v4.3l-4.7.3-4.8.3v10l4.8.3 4.7.3v9.8l-4.7.3-4.8.3-.3 4.7-.3 4.6 5.3.4c2.9.1 7.6.1 10.3-.2l5-.5.5-4.5.5-4.5h9l.3 4.7.3 4.8h9.9l.1-4.8c.1-2.6 0-6.2-.1-7.9-.4-7 .1-7.3 11-7.3h9.9l.3 4.7.3 4.8h10v-30h-10l-.3 4.7-.3 4.7-15.2.3-15.2.3-.3 4.7c-.3 4.1-.7 4.9-2.7 5.4-1.2.3-3.4.4-4.6.2-2.3-.3-2.4-.7-2.9-10.3l-.5-10-4.5-.3c-2.5-.2-4.9 0-5.3.4zM187.7 11.7c-.4.3-.7 2.6-.7 5V21h10V11h-4.3c-2.4 0-4.7.3-5 .7zM208 47v36h72V11h-72v36zm62 0v26h-52V21h52v26z" />
      <path             
        className="disabled-qr"
        d="M228 47.5V63h32V32h-32v15.5zM186.8 36.7l-.3 4.8-4.7.3-4.8.3V52h3.8c5.4 0 6.4 1 6 6.1l-.3 4.4-15.2.3-15.3.3v9.8l4.8.3 4.7.3.3 4.5c.4 5 1.3 6 5.7 6 4.2 0 5.5-1.6 5.5-6.8v-4.3l4.8.3 4.7.3.5 10 .5 10 4.8.3 4.7.3v-5c0-2.8.1-12.3.1-21.1s-.1-20.5-.1-26V32h-9.9l-.3 4.7zM156.7 42.7c-.4.3-.7 2.6-.7 5V52h10V42h-4.3c-2.4 0-4.7.3-5 .7zM125.5 53.2c-.3.8-.4 7.9-.3 15.8l.3 14.5h9v-31l-4.3-.3c-2.8-.2-4.4.1-4.7 1zM146.2 53.2c-1.4 1.4-1.6 6.7-.3 8.6.5.9 2.4 1.2 5.2 1l4.4-.3.3-4.4c.2-2.8-.1-4.7-1-5.2-1.9-1.3-7.2-1.1-8.6.3zM104.2 73.2l.3 10.3h9l.3-10.3.3-10.2h-10.2l.3 10.2zM145.5 75.2c-.3 1.3-.4 8.1-.3 15.3l.3 13 10.3.3 10.2.3V84h-3.7c-5.2 0-6.1-.9-6.5-6l-.3-4.5-4.7-.3c-4.2-.3-4.8-.1-5.3 2zM21.4 95.3c-.3.8-.4 5.4-.2 10.3l.3 8.9h9l.3-4c.4-5.1 1.7-6.5 5.7-6.5s5.3 1.4 5.7 6.5l.3 4 4.7.3 4.7.3.3 4.7.3 4.7 4.7.3 4.7.3.3 4.7c.3 4.4.5 4.7 3.3 5 7.4.6 7.6.9 7.3 6l-.3 4.7-4.7.3c-4.1.3-4.9.7-5.4 2.8-.3 1.3-.3 3.5 0 4.9.6 2.5.8 2.5 10.6 2.5h9.9l.3-4.8.3-4.7 4.5-.3c5-.4 6-1.3 6-5.8 0-3.9-1.4-5.2-6.5-5.6l-4-.3-.3-4.7-.3-4.7-4.7-.3-4.7-.3-.3-4.8-.3-4.7h-4.3c-5 0-6.6-1.3-6.6-5.5s1.6-5.5 6.8-5.5h4.3l-.3-4.8-.3-4.7-25.3-.3c-20.4-.2-25.3 0-25.8 1.1z" />
      <path             
        className="disabled-qr"
        d="M83.7 94.7c-1.3 1.2-.7 8.3.6 8.9.8.3 7.6.6 15.3.7l13.9.2.3 10.3.3 10.3-4.8-.3-4.8-.3-.3-4.7-.3-4.6-5.2-.4c-2.9-.1-7.4-.1-10.2.2l-5 .5v9l5 .5 5 .5.3 4.7.3 4.7 9.7.3 9.7.3v10l-4.7.3-4.8.3v9.9h9.9c8.1 0 10 .3 10.5 1.6.3.9.6 5.6.6 10.5v9l4.8-.3 4.7-.3.3-15.2.3-15.2-4.8-.3-4.8-.3-.3-4.7c-.3-4.4-.1-4.8 2.2-5.4 1.5-.3 3.7-.3 5 0 2.1.5 2.5 1.3 2.8 5.4l.3 4.7 4.8.3 4.7.3.1-5.3v-9.3c-.2-6.3.4-6.6 11-6.3l9.4.3.3 4.8.3 4.8 5.2-.3 5.2-.3.3-13.9c.3-15.9-.1-16.9-6.2-16.4l-4.1.3-.5 5-.5 5-10 .2c-5.5.1-12.3.2-15.2.3h-5.2l-.3 4.7-.3 4.8-4.8.3-4.8.3.3-10.3.3-10.3 4.8-.3 4.7-.3V94h-25.3c-14 0-25.7.3-26 .7zM177 98.9v5.1h10.1l-.3-4.8-.3-4.7-4.7-.3-4.8-.3v5zM197.7 94.6c-.4.4-.7 2.7-.7 5v4.3l-4.7.3-4.8.3v10l4.7.3 4.7.3.3 4.7.3 4.7 10.3.3 10.2.3v-10l-4.7-.3-4.8-.3-.5-10-.5-10-4.5-.3c-2.5-.2-4.9 0-5.3.4zM218.4 95.3c-.3.8-.4 5.4-.2 10.3l.3 8.9 4.8.3 4.7.3v3.7c0 5.4 1 6.4 6.1 6l4.4-.3v-20l-4.5-.3c-5.1-.4-6-1.3-6-6.5V94h-4.5c-2.9 0-4.8.5-5.1 1.3zM239 99v5h10V94h-10v5zM260 98.9v5.1h10.1l-.3-4.8-.3-4.7-4.7-.3-4.8-.3v5zM270.4 105.3c-.3.8-.4 3.2-.2 5.3l.3 3.9h9v-10l-4.3-.3c-3-.2-4.4.1-4.8 1.1zM249.4 116.3c-.3.9-.4 3-.2 4.9.3 3.3.4 3.3 5.3 3.3h5v-9l-4.8-.3c-3.4-.2-4.9.1-5.3 1.1zM21.7 125.6c-.4.4-.7 2.7-.7 5v4.3l4.8.3 4.7.3.5 5 .5 5 5 .5 5 .5.3 4.7.3 4.8h9.8l.3-4.8.3-4.7 4.8-.3 4.8-.3-.3-5.2-.3-5.2-4.5-.5-4.5-.5-.5-4.5-.5-4.5-14.6-.3c-8-.1-14.8.1-15.2.4z" />
      <path             
        className="disabled-qr"
        d="M156.4 136.3c-.3.8-.4 3.2-.2 5.3l.3 3.9h9v-10l-4.3-.3c-3-.2-4.4.1-4.8 1.1zM177.7 135.7c-.4.3-.7 2.2-.7 4.2 0 5-.9 5.9-6 6.3l-4.5.3v9l4.7.3c5.1.3 5.4.7 6 7.7.3 2.8.5 3 4.8 3h4.5l.5-5 .5-5 4.8-.3 4.7-.3v-3.7c0-6 .6-6.3 11.1-6l9.4.3.5 4.5.5 4.5 4.5.5c4.4.5 4.5.6 4.8 4.5.4 5.1 1.7 6.5 5.6 6.5 5.1 0 5.5-.8 5.8-11l.3-9.5 4.5-.5 4.5-.5v-10l-35.1-.3c-19.3-.1-35.3.1-35.7.5zM260.7 135.7c-.4.3-.7 2.8-.7 5.4v4.8l4.8.3 4.7.3v9l-10 .5-10 .5-.3 4.7c-.3 5.1-.1 5.4 7.3 6 2.8.3 3 .6 3.3 5l.3 4.7 4.7.3 4.7.3.3 9.4c.2 6.8-.1 9.7-1 10.2-.7.4-5 .8-9.7.8-4.6 0-8.8.4-9.3.9s-.8 2.7-.6 4.8l.3 3.9 15.3.3 15.2.3.1-5.3c.2-24.5-.2-66.1-.6-66.9-.7-1-17.8-1.3-18.8-.2zM21 166.5V187h9.9l.3-9.8c.2-5.3.2-14.5 0-20.5l-.3-10.7H21v20.5zM145.4 148.4c-.3 1.5-.3 3.7 0 5.2.6 2.3 1 2.5 5.4 2.2l4.7-.3v-9l-4.7-.3c-4.4-.3-4.8-.1-5.4 2.2zM52.7 156.7c-.4.3-.7 2.6-.7 4.9 0 3.8.3 4.4 2.4 5 1.3.3 3.5.4 4.7.2 2.1-.3 2.4-.9 2.7-5.6l.3-5.2h-4.4c-2.4 0-4.7.3-5 .7zM83.7 156.7c-.4.3-.7 2.5-.7 4.9 0 2.9.5 4.4 1.6 4.8 2.6 1 6.9.7 8.2-.6 1.3-1.3 1.6-5.6.6-8.2-.4-1.1-1.9-1.6-4.8-1.6-2.4 0-4.6.3-4.9.7z" />
      <path             
        className="disabled-qr"
        d="M156.7 156.6c-.4.4-.7 2.7-.7 5 0 4.7-.4 5-7.5 5.6l-3 .3v19l10 .5 10 .5.5 5 .5 5 5 .5 5 .5.3 4.7.3 4.7 4.7.3 4.7.3.3 4.4c.4 5.1-.6 6.1-6.1 6.1h-3.8l.3 4.7.3 4.8 4.5.5 4.5.5.3 4.8.3 4.8 10.2-.3 10.2-.3.3-4.8.3-4.7h20l-.3 10.2-.3 10.3-3.6.3c-4.3.4-5.6-1-6.1-6.3l-.3-4h-9l-.3 4c-.5 5.4-1.8 6.7-6.6 6.5-2.3-.1-6.5-.1-9.3-.1l-5.3.1v20h10v-3.4c0-7-1.5-6.6 26.6-6.6H249v-9.9l-4.7-.3-4.8-.3v-20l4.5-.5 4.5-.5.3-10.2.3-10.2-4.8-.3-4.8-.3-.3-9.4c-.3-10.5 0-11.1 6.1-11.1h3.7v-10h-25.4c-28.1 0-26.6.4-26.6-6.6V167h-9.9l-.3 4.7-.3 4.8h-20l-.5-10-.5-10-4-.3c-2.3-.2-4.4 0-4.8.4zm71.1 46.2l.3 15.3-15.3-.3-15.3-.3-.2-14c-.1-8.5.2-14.4.8-15.2.8-.9 4.8-1.2 15.2-1l14.2.2.3 15.3z" />
      <path             
        className="disabled-qr"
        d="M208 203v5h10v-10h-10v5zM197.7 156.6c-1.3 1.3-.7 8.1.7 9.3.9.8 4.6 1.1 10.3.9l8.8-.3v-10l-9.5-.3c-5.3-.1-9.9 0-10.3.4zM42 176.9v10l10.8.3c5.9.2 17.5.1 25.7-.2l15-.5v-9l-5-.5-5-.5-.3-4.8-.3-4.7h-9.8l-.3 4.7-.3 4.8h-20l-.3-4.8-.3-4.7H42v9.9zM94 172.1v5l4.8-.3 4.7-.3.3-4.8.3-4.7H94v5.1zM104.7 187.6c-.4.4-.7 5.1-.7 10.5v9.8l15.3.3 15.2.3.5 10 .5 10 3.9.3c2.1.2 4.3-.1 4.8-.6s.9-4.7.9-9.3c0-4.7.3-9 .7-9.6.4-.6 2.8-1.3 5.2-1.5l4.5-.3.3-4.8.3-4.7-5.3-.1h-9.3c-5.4.2-6.3-.7-6.7-5.9l-.3-4.5-14.6-.3c-8-.1-14.8.1-15.2.4zM21 234v36h72v-72H21v36zm62 0v26H31v-52h52v26z" />
      <path             
        className="disabled-qr"
        d="M42 234v16h31v-32H42v16zM104 244.4v25.5l5.3.3c2.8.2 9.8.2 15.5 0l10.2-.3V260h-10c-9.7 0-10-.1-10.6-2.4-1.1-4.7-.1-7.1 3.1-7.4 7.4-.6 7.6-.9 7.3-6l-.3-4.7-4.7-.3c-4.1-.3-4.9-.7-5.4-2.8-.3-1.3-.3-3.5 0-4.9.6-2.2 1.2-2.5 5.1-2.5 3.9 0 4.5-.3 5.1-2.4.3-1.3.4-3.5.2-4.7-.3-2.1-.9-2.4-5-2.7l-4.7-.3-.4 4.8-.3 4.8-.2-4.8-.2-4.7h-10v25.4zM145.4 231.5c-.3 1.4-.3 3.6 0 4.9.5 2.1 1.3 2.5 5.4 2.8l4.7.3.3 4.7c.3 5.1.1 5.4-7.3 6l-3 .3v19l5.2.3 5.2.3.3-4.8.3-4.8 4.7-.3 4.7-.3.3-4.7.3-4.7 4-.3c5.1-.4 6.5-1.7 6.5-5.6 0-4.5-1-5.4-6-5.8l-4.5-.3-.3-4.8-.3-4.7H156c-9.8 0-10 0-10.6 2.5zM260 233.9v5l4.8.3 4.7.3.5 5 .5 5h9l.3-10.3.3-10.2H260v4.9zM249.7 239.6c-.4.4-.7 2.6-.7 5 0 4.5 1 5.4 6 5.4 3.7 0 5.2-2.1 4.8-6.7-.3-3.7-.4-3.8-4.8-4.1-2.5-.2-4.9 0-5.3.4zM259.8 254.7l-.3 4.8-5 .5-5 .5v9l5 .5c2.8.3 9.6.4 15.3.2l10.2-.3v-9.8l-4.7-.3-4.8-.3-.3-4.8-.3-4.7h-9.8l-.3 4.7zM166.4 261.3c-.3.9-.4 3-.2 4.9.3 3.3.4 3.3 5.3 3.3h5v-9l-4.8-.3c-3.4-.2-4.9.1-5.3 1.1z" />
    </svg>
  )
};


export {
  QRIcon,
  HomeQRIcon,
  DisabledQRIcon
}
