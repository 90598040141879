import { put, takeLatest } from "redux-saga/effects";
import {
  PUT_PRICE_PLAY_REQUEST,
} from "./constants";
import {asyncRequest} from "../../api";
import { MACHINE_DETAILS_FAILURE } from "../MachineDetailsPage/constants";
import * as ROUTES from "../../components/Containers/Main/constants";
import { push } from "connected-react-router";
import { setFlowType } from "../../components/Containers/Main/actions";

export function* fetchPutPricePlaySaga(action: any) {
  try {
    asyncRequest({
      config: {
          method: "PATCH",
          data: {
            transactionAmount: parseFloat(action.payload.playPrice),
          },
      },
      endpoint: `api/unit/${action.payload.hubTagId}/defaultTransactionAmount`,
    })
    
    yield put({ type: ROUTES.SET_FLOW_TYPE, payload: {
      ...action.payload.flowType,
      isChangePricePlayFlow: false
    }});
    if (!!action.payload.flowType.clientPageId) {
      yield put({ type: ROUTES.SET_FLOW_TYPE, payload: {
        ...action.payload.flowType,
        clientPageId: ""
      }});
      yield put(push(`${ROUTES.CLIENT_PAGE}/${action.payload.flowType.clientPageId}`));
    } else {
      yield put(push(`${ROUTES.SITE_PAGE}/${action.payload.siteId}`));
    }

  } catch (error) {
    console.log(error);
    yield put({
        type: MACHINE_DETAILS_FAILURE,
        payload: error && error.response && error.response.data && error.response.data.title
          ? error.response.data.title : 'Unknown error'
      });
  }
}

export default  [
  takeLatest(PUT_PRICE_PLAY_REQUEST, fetchPutPricePlaySaga),
]


