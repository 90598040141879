import React, {FC, useEffect, useState} from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Collapse from '@material-ui/core/Collapse'

import IconExpandLess from '@material-ui/icons/ExpandLess'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import {useHistory} from "react-router-dom";
import {useWindowSize} from "../../../utils/detectWindowSize";
import {Tooltip} from "@material-ui/core";
import {WARNING_DEVICE_MESSAGE} from "../../../pages/HomeLoggedUser/constants";
import {setFlowType} from "../../Containers/Main/actions";
import {useDispatch, useSelector} from "react-redux";
import {getFlowType} from "../../Containers/Main/selectors";

interface AppMenuItemProps {
  name: string,
  path: string,
  isOnlyMobile: boolean,
  hasSubmenu: boolean,
  onClickHandlerType: string;
  onClick: string,
  subMenuList: any[],
  handleClose: () => void;
};


const AppMenuItem: FC<AppMenuItemProps> = props => {
  const { name, path, isOnlyMobile, hasSubmenu, subMenuList = [], handleClose, onClickHandlerType } = props;

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const windowSize = useWindowSize();
  const isDesktop = windowSize.width && windowSize.width > 1170;
  const flowType = useSelector(getFlowType);

  const [hasTooltip, setHasTooltip] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    isDesktop && isOnlyMobile && setHasTooltip(true);
    !isDesktop && setHasTooltip(false);
  }, [isDesktop]);

  const handleMenuItemClick = (route: string) => {
    if (isOnlyMobile && isDesktop) {
      return;
    }
    if (onClickHandlerType === 'onClickHandlerTypeSetup') {
      window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ goToIdentifyUnit: "true" })
      );
      dispatch(setFlowType({
        ...flowType,
        isSetupFlow: true,
      }));
    }
    history.push(route);
    setOpen(!open);
    !hasSubmenu && handleClose();
  };

  const MenuItemRoot = (
    <Tooltip
      title={hasTooltip ? WARNING_DEVICE_MESSAGE : ""}
      aria-label="disabled button"
    >
      <ListItem button className={classes.menuItem} onClick={() => handleMenuItemClick(path)}>
        <ListItemText primary={name} />
        {/* Display the expand menu if the item has children */}
        {hasSubmenu && !open && <IconExpandMore />}
        {hasSubmenu && open && <IconExpandLess/>}
      </ListItem>
    </Tooltip>
  );

  const MenuItemChildren = hasSubmenu ? (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Divider />
      <List component="div" disablePadding>
        {subMenuList.map((item, index) => (
          <AppMenuItem {...item} key={`submenu-item-${index}`} handleClose={handleClose}/>
        ))}
      </List>
    </Collapse>
  ) : null

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    menuItem: {},
    menuItemIcon: {
      color: '#0090DF',
    },
  }),
)

export default AppMenuItem;
