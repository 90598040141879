import React, { FC, useEffect, useState } from "react";
import { StyledCollectorMachineOptions } from "./styles";
import Header from "../../components/Header";
import { OutlinedButton, PrimaryButton } from "../../components/Buttons";
import { useHistory } from "react-router-dom";
import SummaryHeader from "../../components/SummaryHeader";
import { asyncRequest } from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { displayMachineDetails } from "../MachineDetailsPage/selectors";
import {
  displaySiteDetailsFailure,
  displaySiteDetailsSuccess,
} from "../AllClientsSitesPage/actions";
import MachineType from "../SitePage/MachineTypeSection";
import LoadingComponent from "../../components/Loading";
import { addMachineDetails } from "../MachineDetailsPage/actions";
import { getSiteDetailsData } from "../AllClientsSitesPage/selectors";
import * as ROUTES from "../../components/Containers/Main/constants";
import {isAndroid, isIOS} from "react-device-detect";
import {socketReceivedMessage, socketSentMessage} from "../../components/SocketCommunication/actions";
import {getReceivedSocketMessageDetails} from "../../components/SocketCommunication/selectors";
import {NotificationError} from "../../components/NotificationMessages";
import {useModalStyles} from "../../components/Modal/styles";
import ModalComponent from "../../components/Modal";
import { getFlowType } from "../../components/Containers/Main/selectors";

const CollectorMachineOptions: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useModalStyles();
  const flowType = useSelector(getFlowType);
  const isReplaceTagFlow = flowType.isReplaceTagFlow;
  const machineDetails = useSelector(displayMachineDetails);
  const receivedSocketMessage = useSelector(getReceivedSocketMessageDetails);
  const siteDetails = useSelector(getSiteDetailsData);

  const [unitId, setUnitId] = useState(machineDetails.hubTagId);
  const [unregisteredUnit, setUnregisteredUnit] = useState(false);
  const [wasSentMT7, setWasSentMT7] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [redirectTo, setRedirectTo] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isHardwareConnected, setIsHardwareConnected] = useState(true);
  const [machineItemDetails, setMachineItemDetails] = useState({
    id: -1,
    QrCodeId: "",
    hostType: {
      hostTypeId: -1,
      name: "",
      iconUrl: "",
      hostCategory: {
        id: -1,
        name: "",
        code: "",
      },
    },
    hubTagId: "",
    defaultTransactionAmount: "",
    creditType: { id: -1, code: "", name: ""},
    lastSeen: "",
    position: "",
    manufacturer: { id: -1, name: "" },
    unitPowerSource: { id: -1, code: "", name: "" },
    unitStatus: { id: -1, code: "", name: "" },
  });

  const handleNativeUnitId = (event: any) => {

    if (!isIOS && !isAndroid) {
      return;
    }
    try {
      const unitDetailsFromRN = JSON.parse(event.data);
      const unitId = unitDetailsFromRN.unitID;
      const redirectTo = unitDetailsFromRN.redirectTo;

      redirectTo && setRedirectTo(redirectTo);
      unitId && setUnitId(unitId);
    } catch (e) {
      console.warn('Error parsing unitDetailsFromRN, just turn on native app!');
    }
  };

  const setUnitIdDependingOnStoreData = () => {
    if (machineDetails) {
      if (machineDetails.hubTagId) {
        setUnitId(machineDetails.hubTagId);
        return;
      }

      if (machineDetails.externalTagId) {
        setUnitId(machineDetails.externalTagId);
        return;
      }

      if (machineDetails.QrCodeId) {
        setUnitId(machineDetails.QrCodeId);
      }
    }
  };

  const clearAtUnmount = () => {
    window.addEventListener("message", handleNativeUnitId);
    dispatch(socketReceivedMessage(null));
    dispatch(socketSentMessage(null));
  };

  useEffect(() => {
    redirectTo === 'HOME' && history.push(ROUTES.HOME);
  }, [redirectTo]);

  useEffect(() => {
    if (isIOS) {
      window.addEventListener("message", handleNativeUnitId);
      return () => clearAtUnmount();
    }
    if (isAndroid) {
      document.addEventListener("message", handleNativeUnitId);
      return () => clearAtUnmount();
    }
    window.addEventListener("message", handleNativeUnitId);
    return () => clearAtUnmount();
  }, []);

  useEffect(() => {
    setUnitIdDependingOnStoreData();
  }, []);

  useEffect(() => {
    if (unitId && unitId !== "") {
      asyncRequest({
        config: {
          method: "GET",
        },
        endpoint: `api/unit/${unitId}`,
      })
        .then((response) => {
          setIsLoading(false);
          dispatch(displaySiteDetailsSuccess({ ...response.siteDetailsDto }));
          setMachineItemDetails({
            id: response.id,
            QrCodeId: response.qrCodeId,
            hostType: {...response.hostType},
            hubTagId: response.hubTagId,
            lastSeen: response.lastSeen,
            position: response.position,
            creditType: {...response.creditType},
            manufacturer: {...response.manufacturer},
            unitPowerSource: {...response.unitPowerSource},
            unitStatus: {...response.unitStatus},
            defaultTransactionAmount: response.defaultTransactionAmount
          });

          dispatch(
            addMachineDetails({
              ...machineDetails,
              id: response.id,
              QrCodeId: response.qrCodeId,
              hostType: {...response.hostType},
              hubTagId: response.hubTagId,
              lastSeen: response.lastSeen,
              position: response.position,
              creditType: {...response.creditType},
              manufacturer: {...response.manufacturer},
              unitPowerSource: {...response.unitPowerSource},
              unitStatus: {...response.unitStatus},
              defaultTransactionAmount: response.defaultTransactionAmount
            })
          );
        })
        .catch((err) => {
          console.log(err);
          dispatch(displaySiteDetailsFailure(err));
          err && err.response && err.response.status === 404 && setUnregisteredUnit(true);
          setIsLoading(false);
        });
    }
  }, [unitId]);

  useEffect(() => {
    if (receivedSocketMessage && receivedSocketMessage.MT) {
      setIsLoading(false);
    }
    if (
      receivedSocketMessage &&
      receivedSocketMessage.MT === 8 &&
      receivedSocketMessage.Sts === 1
    ) {
      history.push(ROUTES.EXTERNAL_NFC_TAG);
    }
    if (receivedSocketMessage && receivedSocketMessage.MT === 24) {
      setIsHardwareConnected(false);
    }
  }, [receivedSocketMessage]);

  useEffect(() => {
    if (!!wasSentMT7) {
      setTimeout(() => {
        if (!receivedSocketMessage) {
          setIsLoading(false);
          setOpenModal(true);
          setWasSentMT7(false);
        }
      }, 10000)
    }
  }, [wasSentMT7, receivedSocketMessage]);

  const onClickSiteDetailsHandler = () => {
    if (!isReplaceTagFlow) {
      const siteId = siteDetails.id;
      history.push(`${ROUTES.SITE_PAGE}/${siteId}`);
      asyncRequest({
        config: {
          method: "GET",
        },
        endpoint: `api/site/${siteId}`,
      })
        .then((response) => {
          dispatch(displaySiteDetailsSuccess(response));
        })
        .catch((err) => {
          console.log(err);
          dispatch(displaySiteDetailsFailure(err));
        });

      dispatch(
        addMachineDetails({
          ...machineDetails,
          siteId: siteId,
        })
      );
    }
  };
  const continueToExternalTagHandler = () => {
    setIsLoading(true);
    setOpenModal(false);
    setWasSentMT7(true);
    dispatch(
      socketSentMessage({
        MT: "7",
        unitId: machineDetails.hubTagId,
        CoinMecAdptProg: machineDetails.CoinMecAdptProg,
        hostTypeId: machineDetails.hostType.hostTypeId,
        manufacturerId: machineDetails.manufacturer.id,
        topic: "/server/client/unitConfiguration",
        PwrSrc: machineDetails.unitPowerSource.code === "BATTERY" ? "B" : "M",
      })
    );
  };

  const handleModalClose = () => {
    history.push(ROUTES.HOME);
    setOpenModal(false);
  };

  const retryModalBody = (
    <div className="modal-wrapper">
      <div className={classes.body}>
        There is no response from the hardware. Please try again or abort!
      </div>
      <div className="modal-footer">
        <div className={classes.footer}>
          <OutlinedButton className="back-button" onClick={handleModalClose}>Abort</OutlinedButton>
          <PrimaryButton className="continue-button" onClick={continueToExternalTagHandler}>Try Again</PrimaryButton>
        </div>
      </div>
    </div>
  );

  return (
    <StyledCollectorMachineOptions className="height-controller-wrapper">
      <Header title="Machine Summary" />
      {unregisteredUnit &&
      <div className="unidentified-unit-wrapper">
        <div className="unidentified-unit-title">Unregistered unit</div>
        <div>Go to Set up to register unit</div>
      </div>}
      {
        !unregisteredUnit && <div>
          <div onClick={onClickSiteDetailsHandler}>
            <SummaryHeader isMenuDisplayed={false} />
          </div>
          <MachineType itemDetails={machineItemDetails} />
        </div>
      }
      {!isHardwareConnected && <NotificationError messageClass="error"
                                                  message="Hardware is not connected. Press Try again button"/>}
      <div className="actions-wrapper">
        <OutlinedButton
          className="back-button"
          onClick={() => {
            !!isHardwareConnected ? history.goBack() : history.push(ROUTES.HOME);
            dispatch(socketReceivedMessage(null));
          }}
        >
          {!!isHardwareConnected ? "Back" : "Home"}
        </OutlinedButton>
        {isReplaceTagFlow && !unregisteredUnit && (
          <PrimaryButton onClick={continueToExternalTagHandler}>
            {!!isHardwareConnected ? "Continue" : "Try again"}
          </PrimaryButton>
        )}
      </div>
      {isLoading && <LoadingComponent />}
      <ModalComponent open={openModal} handleClose={handleModalClose} body={retryModalBody}/>
    </StyledCollectorMachineOptions>
  );
};

export default CollectorMachineOptions;
