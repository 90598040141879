import {ADD_VENDING_BUTTONS} from "./constants";
import {IVendingButtonsState} from "./model";


const vendingButtonsState: IVendingButtonsState = {
  data: {
    vendingButtons: {
      digits: [],
      letters: [],
      extraButtons: []
    }
  },
};


export const vendingButtonsReducer = (state = vendingButtonsState, action: any) => {
  switch (action.type) {
    case ADD_VENDING_BUTTONS:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

