import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { OutlinedButton } from "../../components/Buttons";
import Header from "../../components/Header";
import { displayMachineDetails } from "../MachineDetailsPage/selectors";
import {StyledUnitDetailsPage} from "./styles";

const UnitDetailsPage: FC = () => {
    const unitDetails = useSelector(displayMachineDetails);
    const history = useHistory();
    
    let displayUnitDetailsKeys = Object.keys(unitDetails);
    let displayUnitDetailsValues = Object.values(unitDetails);

    const mapUnitDetails = displayUnitDetailsKeys.map((key, index) => {
        return (
            typeof displayUnitDetailsValues[index] !== "object" || displayUnitDetailsKeys[index] === null ?
                (<div className="key-value-wrapper">
                    <span key={index} className="key">
                        {key}:{" "}
                    </span>
                    <span>
                        {displayUnitDetailsValues[index] ? displayUnitDetailsValues[index] : "null"}
                    </span>
                </div>) : ""
        );
    });

    const displayIdCodeName = (key: string, targetObject: object) => {
            return(<div className="key-value-wrapper">
                    <div>
                        <span className="key">
                            {key}-id:{" "}
                        </span>
                        <span>
                            {targetObject.id ? targetObject.id : "null"}
                        </span>
                    </div>
                    <div>
                        <span className="key">
                            {key}-name:{" "}
                        </span>
                        <span>
                            {targetObject.name ? targetObject.name : "null"}
                        </span>
                    </div>
                    <div>
                        <span className="key">
                            {key}-code:{" "}
                        </span>
                        <span>
                            {targetObject.code ? targetObject.code : "null"}
                        </span>
                    </div>
                </div>)
    }

    const mapIdCodeNameObjectsUnitDetails = displayUnitDetailsKeys.map((key, index) => {
        const numberOfKeys = unitDetails && unitDetails[key] ? Object.keys(unitDetails[key]).length : 0; 
        return numberOfKeys === 2 || numberOfKeys === 3 ? displayIdCodeName(key, unitDetails[key]) : "";
    });
    
    return (
        <StyledUnitDetailsPage className="height-controller-wrapper">
            <Header title="Unit details" />
            <div className="unit-details-container">
                {mapUnitDetails}
                {mapIdCodeNameObjectsUnitDetails}
                <div>
                    <div>
                        <span className="key">hostType hostTypeId: </span>
                        {unitDetails.hostType.hostTypeId ? unitDetails.hostType.hostTypeId : "null"}
                    </div>
                    <div>
                        <span className="key">hostType code: </span>
                        {unitDetails.hostType.code ? unitDetails.hostType.code : "null"}
                    </div>
                    <div>
                        <span className="key">hostType maxTransactionAmount: </span>
                        {unitDetails.hostType.maxTransactionAmount ? unitDetails.hostType.maxTransactionAmount : "null"}
                    </div>
                    <div>
                        <span className="key">hostType name: </span>
                        {unitDetails.hostType.name ? unitDetails.hostType.name : "null"}
                    </div>
                    <div>
                        <span className="key">hostType iconUrl: </span>
                        {unitDetails.hostType.iconUrl ? unitDetails.hostType.iconUrl : "null"}
                    </div>
                {displayIdCodeName('hostType hostCategory', unitDetails.hostType.hostCategory)}
                </div>
            </div>

            <div className="actions-wrapper">
                <OutlinedButton className="back-button" onClick={() => {history.goBack()}}>Back</OutlinedButton>
            </div>
        </StyledUnitDetailsPage>
    );
};

export default UnitDetailsPage;