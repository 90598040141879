import {IUserResetPaswordState} from "./model";
import {
  USER_EMAIL_REQUEST,
  USER_RESET_PASSWORD_REQUEST,
  USER_EMAIL_SUCCESS,
  USER_RESET_PASSWORD_SUCCESS, USER_EMAIL_FAILURE, USER_RESET_PASSWORD_FAILURE
} from "./constants";

const userResetPasswordInitialState: IUserResetPaswordState = {
  data: null,
  loading: false,
  error: null
};

export const userResetPasswordReducer = (state = userResetPasswordInitialState, action: any) => {
  switch (action.type) {
    case USER_EMAIL_REQUEST:
    case USER_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case USER_EMAIL_SUCCESS:
    case USER_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case USER_EMAIL_FAILURE:
    case USER_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};
