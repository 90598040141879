import { IFlowTypeState } from "../../components/Containers/Main/model";
import {
  ADD_CLIENT_DETAILS,
  ADD_CLIENT_FAILURE,
  ADD_CLIENT_NAME,
  ADD_CLIENT_REQUEST,
  ADD_CLIENT_SUCCESS,
  ADD_SITE_TO_CLIENT_FAILURE,
  ADD_SITE_TO_CLIENT_REQUEST,
  ADD_SITE_TO_CLIENT_SUCCESS,
  DELETE_CLIENT_FAILURE,
  DELETE_CLIENT_REQUEST,
  DELETE_CLIENT_SUCCESS,
  DELETE_SITE_FAILURE,
  DELETE_SITE_REQUEST,
  DELETE_SITE_SUCCESS,
  EDIT_CLIENT_NAME_REQUEST, EDIT_SITE_DETAILS_REQUEST,
  EDIT_SITE_DETAILS_SUCCESS_ON_CLIENT,
  EDIT_SITE_DETAILS_SUCCESS_ON_SITE,
  GET_SITE_TYPE_FAILURE,
  GET_SITE_TYPE_REQUEST,
  GET_SITE_TYPE_SUCCESS
} from "./constants";
import {ISitesDetailsArray} from "./model";

export const addSiteToClientAttempt = (siteDetails: ISitesDetailsArray, clientId: string, clientName: string, flowType: IFlowTypeState) => ({
  type: ADD_SITE_TO_CLIENT_REQUEST,
  payload: {site: {...siteDetails, clientName}, clientId, flowType}
});

export const addSiteToClientSuccess = (data: any) => ({
  type: ADD_SITE_TO_CLIENT_SUCCESS,
  payload: { ...data},
});

export const addSiteToClientFailure = (error: any) => ({
  type: ADD_SITE_TO_CLIENT_FAILURE,
  payload: error
});

export const editClientNameAttempt = (clientId: string, clientName: string) => ({
  type: EDIT_CLIENT_NAME_REQUEST,
  payload: {clientName, clientId}
});

// delete client actions

export const deleteClientAttempt = (clientId: string) => ({
  type: DELETE_CLIENT_REQUEST,
  payload: {clientId}
});

export const deleteClientSuccess = (data: any) => ({
  type: DELETE_CLIENT_SUCCESS,
  payload: { ...data},
});

export const deleteClientFailure = (error: any) => ({
  type: DELETE_CLIENT_FAILURE,
  payload: error
});

export const editSiteDetailsAttempt = (siteId: string, siteDetails: ISitesDetailsArray, clientId: string, flowType: IFlowTypeState) => ({
  type: EDIT_SITE_DETAILS_REQUEST,
  payload: {siteId, site: {...siteDetails}, clientId, flowType}
});

export const editSiteDetailsSuccessOnClient = (data: any) => ({
  type: EDIT_SITE_DETAILS_SUCCESS_ON_CLIENT,
  payload: { ...data}
});

export const editSiteDetailsSuccessOnSite = (data: any) => ({
  type: EDIT_SITE_DETAILS_SUCCESS_ON_SITE,
  payload: { ...data}
});
// delete site actions

export const deleteSiteAttempt = (siteId: number) => ({
  type: DELETE_SITE_REQUEST,
  payload: {siteId}
});

export const deleteSiteSuccess = (data: any) => ({
  type: DELETE_SITE_SUCCESS,
  payload: { ...data},
});

export const deleteSiteFailure = (error: any) => ({
  type: DELETE_SITE_FAILURE,
  payload: error
});

export const addClientName = (clientName: string, hasMultipleSites: boolean) => ({
  type: ADD_CLIENT_NAME,
  payload: {clientName, hasMultipleSites}
});

export const addClientDetails = (clientName: string, sitesDetailsArray: []) => ({
  type: ADD_CLIENT_DETAILS,
  payload: {clientName, sitesDetailsArray},
});

export const addClientAttempt = (clientName: string, sitesDetailsArray: ISitesDetailsArray[]) => ({
  type: ADD_CLIENT_REQUEST,
  payload: {clientName, sitesDetailsArray}
});

export const addClientSuccess = (data: any) => ({
  type: ADD_CLIENT_SUCCESS,
  payload: { ...data},
});

export const addClientFailure = (error: any) => ({
  type: ADD_CLIENT_FAILURE,
  payload: error
});


// unit site types actions

export const displaySiteTypeAttempt = () => ({
  type: GET_SITE_TYPE_REQUEST,
});

export const displaySiteTypeSuccess = (data: any) => ({
  type: GET_SITE_TYPE_SUCCESS,
  payload: { ...data},
});

export const displaySiteTypeFailure = (error: any) => ({
  type: GET_SITE_TYPE_FAILURE,
  payload: error
});

