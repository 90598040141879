import React, {FC, useState} from "react";
import {StyledAddNewStock, StyledSlider, useStyles} from "./styles";
import {OutlinedButton, PrimaryButton} from "../../components/Buttons";
import Header from "../../components/Header";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getStockSelectionDetails} from "../MachineStockDetails/selectors";
import {GeneralTextField} from "../../components/Inputs";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import {stockColor} from "../../utils/extractStockColor";
import Typography from '@material-ui/core/Typography';
import {stockSelectionDetails} from "../MachineStockDetails/actions";
import {displayMachineDetails} from "../MachineDetailsPage/selectors";
import * as ROUTES from "../../components/Containers/Main/constants";


const AddNewStock: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const stockSelectionDetailsItem = useSelector(getStockSelectionDetails);
  const machineDetails = useSelector(displayMachineDetails);

  const [inputVNameValue, setInputVNameValue] = useState(stockSelectionDetailsItem.vName);
  const [stockQty, setStockQty] = useState(stockSelectionDetailsItem.currentQty);
  const [rangeValue, setRangeValue] = useState([stockSelectionDetailsItem.vLowQty, stockSelectionDetailsItem.vMedQty]);

  const marks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: rangeValue[0],
      label: `${rangeValue[0]}+`,
    },
    {
      value: rangeValue[1],
      label: `${rangeValue[1]}+`,
    },
    {
      value: 20,
      label: '20',
    },
  ];

  const onChange = (e, value) => {
    const [min, max] = value;
    if (min < max && max > min && max <= 20 && min > 0) {
      setRangeValue(value);
    }
  };

  const confirmAddingStock = () => {
    const newStockDetails = {
      ...stockSelectionDetailsItem,
      currentQty: stockQty,
      vLowQty: rangeValue[0],
      vMedQty: rangeValue[1],
      vName: inputVNameValue
    };
    dispatch(stockSelectionDetails(newStockDetails));
    history.push(ROUTES.MACHINE_STOCK_DETAILS);
  };

  const onChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setInputVNameValue(event.target.value);
  };

  const increaseStockQtyHandler = () => {
    setStockQty(prevState => prevState + 1)
  };

  const decreaseStockQtyHandler = () => {
    setStockQty(prevState => prevState - 1)
  };

  return (
      <StyledAddNewStock className="height-controller-wrapper">
        <Header title="Add new stock" />
        <div className="machine-details-header">
          <div className="machine-name">{machineDetails.hostType.name}</div>
          <div>{machineDetails.position}</div>
        </div>
        <div className="selection-wrapper"
             style={{backgroundColor: stockColor(stockQty, rangeValue[0], rangeValue[1])}}>
          <div className="selection-name">{stockSelectionDetailsItem.selectionName}</div>
          <form><GeneralTextField value={inputVNameValue} onChange={onChangeHandler}/></form>
          <div className="selection-price">{stockSelectionDetailsItem.price} £</div>
          <div className="increase-stock-wrapper">
            <RemoveIcon onClick={decreaseStockQtyHandler}/>
            <span className="stock-qty">{stockQty}</span>
            <AddIcon onClick={increaseStockQtyHandler}/>
          </div>
        </div>
        <div className={classes.root}>
          <Typography id="discrete-slider" gutterBottom>
            Set stock level colours
          </Typography>
          <StyledSlider
            classes={{
              thumb: classes.thumb,
              track: classes.track,
              valueLabel: classes.valueLabel,
              mark: classes.mark,
            }}
            valueLabelDisplay="auto"
            value={rangeValue}
            step={1}
            marks={marks}
            min={0}
            max={20}
            onChange={onChange}
            rangeValue={rangeValue}
          />
        </div>

        <div className="actions-wrapper">
          <OutlinedButton className="back-button" onClick={() => {history.goBack()}}>Back</OutlinedButton>
          <PrimaryButton className="continue-button"
                         onClick={confirmAddingStock}>
            Confirm
          </PrimaryButton>
        </div>
      </StyledAddNewStock>
  );
};

export default AddNewStock;
