import styled from 'styled-components';
import media from "../../../../assets/styling/payme-media-queries";
import paymeTheme from "../../../../assets/styling/payme-theme";




const StyledAccordionWrapper = styled.div `
    .accordion-summary-wrapper {
      align-items: flex-start;
      justify-content: flex-start;
      padding: 0;
      
      .MuiAccordionSummary-content {
        margin: 0;
        width: auto;
        flex-grow: 0;
        color: ${paymeTheme.colorPurple};
      }
      .MuiAccordionSummary-expandIcon {
        padding: 0;
      }   
      .MuiSvgIcon-root {
        fill: ${paymeTheme.colorPurple};
      }  
    }
    
   .MuiAccordionSummary-root {
      min-height: auto;
      padding-left: 10%;
   }
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: auto;
  }
    
    .MuiAccordion-root {
        box-shadow: none;
    }
    
    .item-site-wrapper {
      font-size: ${paymeTheme.fontRemSizeSmall};
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0;
    }
    
    .MuiAccordionDetails-root.accordion-details-wrapper {
      padding: 16px 0;
      padding-left: 10%;
      flex-direction: column;
    }
  
  ${media.desktop} {
    
  }

`;

export { StyledAccordionWrapper } ;
