import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";




const StyledPricePlayInput = styled.div `
  .change-price {
    margin-top: 3rem!important;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;

    .change-price-description {
      font-size: ${paymeTheme.fontRemSizeH6};
      color: ${paymeTheme.colorBlack};
    }

    .change-price-functionality {
      min-height: 60px;
      .MuiIconButton-root {
        padding: 0;
      }
      display: flex;
      align-items: center;
      svg {
        width: 15vw;
        height: 15vh;
        max-width: 115px;
        max-height: 60px;
        color: ${paymeTheme.floteColor};
      }
      div {
        border: 2px solid ${props => props.theme.isHostSingleValue ? 'deeppink' : paymeTheme.floteColor};
        width: 30vw;
        max-width: 130px;
        height: 5vh;
        border-radius: 10px;
        padding: 0.2rem 0.7rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          font-size: ${paymeTheme.fontRemSizeH3};
          color: ${paymeTheme.colorBlack};
        }
      }
    }
  }

`;

export { StyledPricePlayInput } ;
