import React, {FC, Fragment, useEffect, useState} from "react";
import {Route, Switch, RouteComponentProps} from "react-router-dom";
import Footer from "../../Footer";
import MainPageLogin from "../../../pages/LoginPage";
import LoggedHomepage from "../../../pages/HomeLoggedUser";
import {MenuAppBar} from "../../Menu";
import EnterClientDetailsStep from "../../../pages/AddNewClientPage/EnterClientDetailsStep";
import {GeneralStyles} from "./styles";
import DisplayClientDetailsStep from "../../../pages/AddNewClientPage/DisplayClientDetailsStep";
import {AllClientsSites} from "../../../pages/AllClientsSitesPage";
import SitePage from "../../../pages/SitePage";
import ClientPage from "../../../pages/ClientPage";
import MachineDetailsPage from "../../../pages/MachineDetailsPage";
import ExternalNFCStep from "../../../pages/ExternalNFCPage";
import MachineSummaryPage from "../../../pages/MachineSummaryPage";
import {TechnicianAllClientsSites} from "../../../pages/TechnicianAllClientsSites";
import {TechnicianSiteSummary} from "../../../pages/TechnicianSitePage";
import MachineTestPage from "../../../pages/MachineTest";
import MachineTestResultsPage from "../../../pages/MachineTestResults";
import VendingConnectionStep from "../../../pages/MachineConnectionType";
import VendingMachineButtons from "../../../pages/VendingMachineButtons";
import VendingMachineButtonsConfirmation from "../../../pages/VendingMachineButtonsConfirmation";
import VendingLayoutSelection from "../../../pages/VendingSelectionLayout";
import MachineStockDetails from "../../../pages/MachineStockDetails";
import AddNewStock from "../../../pages/AddNewStockVending";
import {useDispatch, useSelector} from "react-redux";
import {getSentSocketMessageDetails} from "../../SocketCommunication/selectors";
import {getAuthData} from "../../../pages/LoginPage/selectors";
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import {socketReceivedMessage} from "../../SocketCommunication/actions";
import ListingCompanyGroups from "../../../pages/ListingCompanyGroups";
import GroupDetails from "../../../pages/GroupDetails";
import CollectorMachineOptions from "../../../pages/CollectorMachineOptions";
import SiteCollection from "../../../pages/SiteCollection";
import CollectionReceipt from "../../../pages/CollectionReceipt";
import MachineCollectionDetails from "../../../pages/MachineCollectionDetails";
import {CollectionHistory} from "../../../pages/CollectionHistory";
import MachineDeactivation from "../../../pages/MachineDeactivation";
import MachineDeactivationConfirmation from "../../../pages/MachineDeactivationConfirmation";
import ScanQRCodeStep from "../../../pages/ScanQRCodeStep";
import ScanQRCodeError from "../../../pages/ScanQRCodeErrorStep";
import MachineSiteChange from "../../../pages/MachineSiteChange";
import ChangeMachinePosition from "../../../pages/ChangeMachinePosition";
import {TeamList} from "../../../pages/TeamListPages/TeamList";
import {TeamActivityLogList} from "../../../pages/TeamListPages/TeamActivityLog";
import {NewTeamMember} from "../../../pages/TeamMemberPages/NewTeamMember";
import {EditTeamMember} from "../../../pages/TeamMemberPages/EditTeamMember";
import {ActivateMemberAccount} from "../../../pages/ActivateMemberAccount";
import {AddUserEmail} from "../../../pages/ResetPassword/AddEmail";
import {CreateNewPassword} from "../../../pages/ResetPassword/AddNewPassword";
import UnitSummarySetup from "../../../pages/UnitSummarySetup";
import UnitDetailsPage from "../../../pages/UnitDetailsPage";
import * as ROUTES from "./constants";
import TehnicianClientPage from "../../../pages/TehnicianClientPage";
import EditClientPage from "../../../pages/AddNewClientPage/EditClientDetails";
import EditSitePage from "../../../pages/AddNewClientPage/EditSiteDetails";
import {MemberProfile} from "../../../pages/TeamMemberPages/MemberProfile";
import {CompanyProfile} from "../../../pages/CompanyProfile";
import ClientSitesListPage from "../../../pages/AllClientsSitesPage/ClientSitesListPage";

interface Props extends RouteComponentProps<any> {}
const initialHeight = window.innerHeight;

const Main: FC = () => {
  const [keyboard, setKeyboard] = useState(false);
  const [isFeedbackPressed, setIsFeedbackPressed] = useState(false);

  const apiBaseUrlByEnvironment = `${process.env.REACT_APP_BASE_API_URL}`;
  const environmentTypeName = apiBaseUrlByEnvironment.split('//')[1].split('.')[0];

  useEffect(() => {
    document.addEventListener("keypress", checkIfPressedKeyIsEnter);
    return () => {document.removeEventListener("keypress", checkIfPressedKeyIsEnter)};
  }, []);

  useEffect(() => {
    window.addEventListener('resize', checkIfKeyboardOpened);
    return () => {
      document.removeEventListener("resize", checkIfKeyboardOpened)
    };
  }, []);


  useEffect(() => {
    if (keyboard) {
      document.documentElement.style.setProperty('overflow', 'auto');
      const metaViewport = document.querySelector('meta[name=viewport]');
      if (metaViewport) {
        metaViewport.setAttribute('content', 'height=' + initialHeight + 'px, width=device-width, initial-scale=1.0');
      }
    } else {
      const metaViewport = document.querySelector('meta[name=viewport]');
      if (metaViewport) {
        metaViewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');
      }
    }
  }, [keyboard]);


  const socketMessageForSending = useSelector(getSentSocketMessageDetails);
  const authDetails = useSelector(getAuthData);
  const [messageToSend, setMessageToSend] = useState(socketMessageForSending);
  const [userToken, setUserToken] = useState('');
  const localStorageToken = localStorage.getItem('token');
  const [socketConnection, setSocketConnection] = useState(null);
  const [stompClientState, setStompClientState] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorageToken !== null) {
      setUserToken(localStorageToken);
    } else {
      authDetails && setUserToken(authDetails.id_token);
    }
  }, [authDetails, localStorageToken]);

  useEffect(() => {
    setMessageToSend(socketMessageForSending);
  }, [socketMessageForSending]);


  const socketConnectionTry = () => {
    const socket = new SockJS(`${process.env.REACT_APP_SOCK_BASE_URL}`);
    const stompClient = Stomp.over(socket);
    stompClient.connect({Authorization: `Bearer ${userToken}`}, () => {
      setSocketConnection(socket);
      setStompClientState(stompClient);
      stompClient.subscribe( '/user/clientApp/unitConfigurationResponse', (response: any) => {
        dispatch(socketReceivedMessage(JSON.parse(response.body)));
      });
      stompClient.subscribe( '/user/clientApp/unitStocksConfigurationResponse', (response: any) => {
        dispatch(socketReceivedMessage(JSON.parse(response.body)));
      });

    }, (err: any) => {
      console.log('socket error connection = ', err);
      socket.close();
      socketConnectionTry();
    });
  };

  useEffect(() => {
    if (!userToken) {
      !!socketConnection && socketConnection.close();
      return;
    }
    socketConnectionTry();
  }, [userToken]);

  useEffect(() => {
    if (socketConnection && stompClientState) {
      if (messageToSend && messageToSend.topic) {
        stompClientState.send(messageToSend.topic, {}, JSON.stringify(messageToSend));
      }
    }
  }, [messageToSend, socketConnection, stompClientState]);

  const checkIfPressedKeyIsEnter = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      document.querySelectorAll("input").forEach(el => el.blur());
    }
  };

  const checkIfKeyboardOpened = () => {
    // If the current active element is a text input, we can assume the soft keyboard is visible.
    if (document.activeElement) {
      document.activeElement.tagName === 'INPUT' ? setKeyboard(true) : setKeyboard(false);
      document.activeElement.tagName === 'TEXTAREA' ? setIsFeedbackPressed(true) : setIsFeedbackPressed(false);
    }
  };

  return (
    <Switch>
      <Fragment>
        <GeneralStyles theme={{keyboard, isFeedbackPressed}}>
          {userToken && <MenuAppBar/>}
          <Route exact path={ROUTES.HOME} component={LoggedHomepage}/>
          <Route path={ROUTES.LOGIN} component={MainPageLogin}/>
          <Route path={ROUTES.ADD_CLIENT_DETAILS} component={EnterClientDetailsStep}/>
          <Route path={`${ROUTES.EDIT_CLIENT_NAME}/:id`} render={() => <EditClientPage/>}/>
          <Route path={`${ROUTES.EDIT_SITE_DETAILS}/:id`} render={() => <EditSitePage/>}/>
          <Route path={ROUTES.ADD_CLIENT_SUMMARY} component={DisplayClientDetailsStep}/>
          <Route path={ROUTES.ALL_CLIENTS_SITES} component={AllClientsSites}/>
          <Route path={ROUTES.SETUP_ALL_CLIENTS_SITES} component={TechnicianAllClientsSites}/>
          <Route path={`${ROUTES.TEHNICIAN_SITE_PAGE}/:id`} render={() => <TechnicianSiteSummary/>}/>
          <Route path={`${ROUTES.TEHNICIAN_CLIENT_PAGE}/:id`} render={() => <TehnicianClientPage/>}/>
          <Route path={`${ROUTES.SITE_PAGE}/:id`} render={() => <SitePage/>}/>
          <Route path={`${ROUTES.CLIENT_PAGE}/:id`} render={() => <ClientPage/>}/>
          <Route path={`${ROUTES.CLIENT_SITES_LIST_PAGE}/:id`} render={() => <ClientSitesListPage/>} />
          <Route path={ROUTES.MACHINE_DETAILS} component={MachineDetailsPage}/>
          <Route path={ROUTES.MACHINE_SUMMARY} component={MachineSummaryPage}/>
          <Route path={ROUTES.EXTERNAL_NFC_TAG} component={ExternalNFCStep}/>
          <Route path={ROUTES.MACHINE_TEST} component={MachineTestPage}/>
          <Route path={ROUTES.MACHINE_TEST_RESULTS} component={MachineTestResultsPage}/>
          <Route path={ROUTES.MACHINE_CONNECTION_TYPE} component={VendingConnectionStep}/>
          <Route path={ROUTES.MACHINE_BUTTONS} component={VendingMachineButtons}/>
          <Route path={ROUTES.MACHINE_BUTTONS_CONFIRMATION} component={VendingMachineButtonsConfirmation}/>
          <Route path={ROUTES.MACHINE_LAYOUT_SELECTION} component={VendingLayoutSelection}/>
          <Route path={ROUTES.MACHINE_OPTIONS} component={CollectorMachineOptions}/>
          <Route path={ROUTES.MACHINE_STOCK_DETAILS} component={MachineStockDetails}/>
          <Route path={`${ROUTES.ADD_NEW_STOCK}/:id`} render={() => <AddNewStock/>}/>
          <Route path={ROUTES.GROUPS} component={ListingCompanyGroups}/>
          <Route path={`${ROUTES.GROUP_DETAILS}/:id`} component={GroupDetails}/>
          <Route path={ROUTES.SITE_COLLECTION} component={SiteCollection}/>
          <Route path={ROUTES.MACHINE_COLLECTION} component={MachineCollectionDetails}/>
          <Route path={ROUTES.COLLECTION_RECEIPT} component={CollectionReceipt}/>
          <Route path={ROUTES.COLLECTION_HISTORY} component={CollectionHistory}/>
          <Route path={ROUTES.MACHINE_DEACTIVATION} component={MachineDeactivation}/>
          <Route path={ROUTES.MACHINE_DEACTIVATION_CONFIRMATION} component={MachineDeactivationConfirmation}/>
          <Route path={ROUTES.SCAN_QR_CODE} component={ScanQRCodeStep}/>
          <Route path={ROUTES.SCAN_QR_CODE_ERROR} component={ScanQRCodeError}/>
          <Route path={ROUTES.MACHINE_SITE_CHANGE} component={MachineSiteChange}/>
          <Route path={ROUTES.CHANGE_MACHINE_POSITION} component={ChangeMachinePosition}/>
          <Route path={ROUTES.TEAM} component={TeamList}/>
          <Route path={ROUTES.TEAM_ACTIVITY_LOG} component={TeamActivityLogList}/>
          <Route path={ROUTES.NEW_TEAM_MEMBER} component={NewTeamMember}/>
          <Route path={ROUTES.EDIT_TEAM_MEMBER} component={EditTeamMember}/>
          <Route path={ROUTES.MEMBER_PROFILE} component={MemberProfile}/>
          <Route path={ROUTES.COMPANY_PROFILE} component={CompanyProfile}/>
          <Route path={ROUTES.ACTIVATE_ACCOUNT} component={ActivateMemberAccount}/>
          <Route path={ROUTES.ADD_USER_EMAIL} component={AddUserEmail}/>
          <Route path={ROUTES.UNIT_DETAILS_SETUP} component={UnitSummarySetup}/>
          <Route path={ROUTES.UNIT_DETAILS_PAGE} component={UnitDetailsPage}/>
          <Route path={ROUTES.CREATE_PASSWORD} component={CreateNewPassword}/>
          {/*<Footer/>*/}
          {(environmentTypeName === "test" || environmentTypeName === "dev")
          && <p className="env env-left">{environmentTypeName}</p>}
          {(environmentTypeName === "test" || environmentTypeName === "dev")
          && <p className="env env-right">{environmentTypeName}</p>}
        </GeneralStyles>
      </Fragment>
    </Switch>
  );
};

export default Main;
