import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";




const StyledGroupDetails = styled.div `  
  .actions-wrapper .back-button {
    margin: 0 auto;
  }
   .MuiSvgIcon-root {
      fill: ${paymeTheme.floteColor};
   }
  .group-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: ${paymeTheme.paddingRem};
    margin-right: 0.875rem;
    border-bottom: 1px solid;
    position: relative;
    
    .group-name,
    .edit-group-name-form {
       color: ${paymeTheme.floteColor};
       justify-self: center;
       font-size: ${paymeTheme.fontRemSizeH5};
       position: relative;
       left: 0;
       right: 0;
       margin: auto;
    }
    
    .MuiSvgIcon-root {
      width: 30px;
      height: 30px;
    }
  }
  .site-card {
    margin-left: ${paymeTheme.paddingRemXs};
    margin-right: ${paymeTheme.paddingRemXs};
  }

  .MuiTextField-root, .MuiFormControl-root {
    margin: 0 auto;
    margin-bottom: ${paymeTheme.paddingRemMd};
  }
 
  ${media.desktop} {
    .group-header {
      padding-top: 1.5rem;
    }
  }

  ${media.desktopMedium} {
    .group-header {
      padding-left: ${paymeTheme.listItemPadding};
    }
  }

`;

export { StyledGroupDetails } ;
