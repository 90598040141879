import React, {FC, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import { REGISTER_URL } from "./constants";
import {StyledLogin} from "./styles";
import {LoginForm} from "../../components/Login";
import {ButtonLink} from "../../components/Buttons";
import LoadingComponent from "../../components/Loading";
import * as ROUTES from "../../components/Containers/Main/constants";

const MainPageLogin: FC = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(true);

  const onClickForgottenDetailsHandler = () => {
    history.push(ROUTES.ADD_USER_EMAIL);
  };

  useEffect(() => {
    if (!!localStorage.getItem("token")) {
      history.push(ROUTES.HOME);
    }
    setLoading(false);
  }, []);

  return (
    <StyledLogin>
      {loading && <LoadingComponent />}
      {!loading && (<div className="login">
        <div className="login-header">
          <div className="login-title"><span className="flote-word">flote</span>.works</div>
          <div className="login-subtitle">Simplifying Contactless Payments</div>
        </div>
        <div className="login-register-wrapper">
          <LoginForm/>
          <div className="register-content">
            <ButtonLink buttonContent="Forgotten sign in details?" linkHandler={onClickForgottenDetailsHandler}/>
            <div>Don't have an account? <a href={`${REGISTER_URL}`} target="_blank" className="register-btn">Register</a></div>
          </div>
        </div>
      </div>)}
    </StyledLogin>
  );
};

export default MainPageLogin;
