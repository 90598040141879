import React, {FC} from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {StyledAccordionWrapper} from "./styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }),
);

interface Props {
  sites: any;
}

const SitesAccordion: FC<Props> = ({sites}) => {
  const classes = useStyles();

  return (
    <StyledAccordionWrapper>
    <div className={classes.root}>
      {
        (sites && sites.length === 1) && (<div>
          <div>{sites[0].name}</div>
        </div>)
      }

      {
        (sites && sites.length > 1) && (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon/>}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="accordion-summary-wrapper"
            >
              <div className={classes.heading}>{sites.length} total sites</div>
            </AccordionSummary>
            <AccordionDetails className="accordion-details-wrapper">
              {sites && sites.length && sites.map((itemSite: any, indexSite: any) => (
                <div key={`site-${indexSite}`} className="item-site-wrapper">
                  <div className="site-name">{itemSite.name}</div>
                  <div className="site-town">{itemSite.town}</div>
                </div>
              ))}
            </AccordionDetails>
          </Accordion>
        )
      }

    </div>
    </StyledAccordionWrapper>
  );
}

export default SitesAccordion;
