import { put, takeLatest } from "redux-saga/effects";
import {
  HISTORY_COLLECTION_LIST_FAILURE,
  HISTORY_COLLECTION_LIST_REQUEST,
  HISTORY_COLLECTION_LIST_SUCCESS
} from "./constants";
import {asyncRequest} from "../../api";

export function* fetchHistoryCollectionList(action: any) {
  try {
    const getHistoryCollectionList = yield asyncRequest({
      config: {
        method: "GET",
      },
      endpoint: 'api/collection/history',
    });


    yield put({ type: HISTORY_COLLECTION_LIST_SUCCESS, payload: getHistoryCollectionList });
  } catch (error) {
    console.log(error);
    yield put({ type: HISTORY_COLLECTION_LIST_FAILURE,
      payload: error && error.response && error.response.data &&
      error.response.data.title ? error.message : 'Unknown error' });
  }
}

export default  [
  takeLatest(HISTORY_COLLECTION_LIST_REQUEST, fetchHistoryCollectionList),
]
