import React, {FC, useEffect, useState} from "react";
import {StyledMachineSummaryPage} from "./styles";
import Header from "../../components/Header";
import {OutlinedButton, PrimaryButton} from "../../components/Buttons";
import {useHistory} from "react-router-dom";
import SummaryHeader from "../../components/SummaryHeader";
import MachineDetails from "../../components/MachineDetails";
import {asyncRequest} from "../../api";
import {useDispatch, useSelector} from "react-redux";
import {displayMachineDetails, getMachineDetailsError} from "../MachineDetailsPage/selectors";
import {getSiteDetailsData} from "../AllClientsSitesPage/selectors";
import {NotificationError} from "../../components/NotificationMessages";
import * as ROUTES from "../../components/Containers/Main/constants";
import {socketReceivedMessage} from "../../components/SocketCommunication/actions";
import PricePlayInput from "../../components/PricePlayInput/index";
import { getFlowType } from "../../components/Containers/Main/selectors";
import { setFlowType } from "../../components/Containers/Main/actions";

interface Props {
  clientsList: any;
}
const MachineSummaryPage: FC<Props> = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const flowType = useSelector(getFlowType);
  const clientPageId = flowType.clientPageId;
  const isChangeSiteFlow = flowType.isChangeSiteFlow;
  const isChangePositionFlow = flowType.isChangePositionFlow;
  const isChangePricePlayFlow = flowType.isChangePricePlayFlow;
  const CONTINUE_BTN_VALUE = (isChangeSiteFlow || isChangePositionFlow) ? 'Finish' : 'Continue';
  const machineDetails = useSelector(displayMachineDetails);
  const unitId = machineDetails.id;
  const isHostSingleValue = machineDetails.creditType.code === "HOST_SING_VAL";
  const newMachinePosition = machineDetails.position;
  const siteDetails = useSelector(getSiteDetailsData);
  const siteId = siteDetails.id;

  const [changeSiteError, setChangeSiteError] = useState(false);
  const [changePositionError, setChangePositionError] = useState(false);
  const [isSaveChangesBtnClicked, setIsSaveChangesBtnClicked] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const changePosition = () => {
    asyncRequest({
      config: {
        method: "PATCH",
        data: `${newMachinePosition}`,
      },
      endpoint: `api/unit/${unitId}/position`,
    })
      .then((response) => {
        dispatch(setFlowType({
          ...flowType,
          isChangePositionFlow: false
        }));
      })
      .catch((err) => {
        console.log(err);
        setChangePositionError(true);
      });
  };

  const changeSite = () => {
    asyncRequest({
      config: {
        method: "PATCH",
      },
      endpoint: `api/unit/${unitId}/site/${siteId}`,
    })
      .then((response) => {
        dispatch(setFlowType({
          ...flowType,
          isChangeSiteFlow: false,
        }));
      })
      .catch((err) => {
        console.log(err);
        setChangeSiteError(true);
      });
  };

  useEffect(() => {
    if ((isSaveChangesBtnClicked && isChangeSiteFlow && !changeSiteError && !changePositionError) ||
      (isSaveChangesBtnClicked && isChangePositionFlow && !isChangeSiteFlow && !changePositionError)
    ) {
      history.push(ROUTES.HOME);
    }
  }, [isSaveChangesBtnClicked, changePositionError, changeSiteError]);

  const continueToNextPageHandler = () => {
    setIsSaveChangesBtnClicked(true);
    if (isChangeSiteFlow && !isChangePositionFlow) {
      changeSite();
      changePosition();
      return;
    }
    if (!isChangeSiteFlow && isChangePositionFlow) {
      changePosition();
      return;
    }
    history.push(ROUTES.EXTERNAL_NFC_TAG);
  };

  const onGoBack = () => {
    dispatch(setFlowType({
      ...flowType,
      isChangePricePlayFlow: false,
    }));

    if (!!clientPageId) {
      dispatch(setFlowType({
        ...flowType,
        clientPageId: "",
      }));
      history.push(`${ROUTES.CLIENT_PAGE}/${clientPageId}`);
    } else {
      history.push(`${ROUTES.SITE_PAGE}/${siteId}`);
    }
  };

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  return (
    <StyledMachineSummaryPage className="height-controller-wrapper" theme={{isHostSingleValue}}>
      <Header title="Machine summary"/>
      <SummaryHeader isMenuDisplayed={false} isArrowDisplayed={false}/>
      <MachineDetails/>
      {(isChangeSiteFlow || isChangePositionFlow) &&
      <div className="test-section">
        <div className="title">Test recommended</div>
      </div>
      }
      {isChangePricePlayFlow && <PricePlayInput machineDetails={machineDetails} siteDetails={siteDetails} openModal={openModal} setOpenModal={setOpenModal}/>}
      {!isChangePricePlayFlow && <div className="actions-wrapper">
        <OutlinedButton className="back-button"
                        onClick={() => {dispatch(socketReceivedMessage(null)); history.goBack();}}>
          Back
        </OutlinedButton>
        <PrimaryButton className="continue-button" onClick={continueToNextPageHandler}>
          {CONTINUE_BTN_VALUE}
        </PrimaryButton>
      </div>}
      {isChangePricePlayFlow && <div className="actions-wrapper">
        <OutlinedButton className="back-button"
                        onClick={() => {dispatch(socketReceivedMessage(null)); onGoBack();}}>
          Back
        </OutlinedButton>
        {isHostSingleValue && <PrimaryButton className="continue-button" onClick={handleModalOpen}>
          Confirm
        </PrimaryButton>}
      </div>}
      {changeSiteError && <NotificationError message="Saving new site failed" messageClass="error"/>}
      {changePositionError && <NotificationError message="Saving new position failed" messageClass="error"/>}
    </StyledMachineSummaryPage>
  );
};

export default MachineSummaryPage;
