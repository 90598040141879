import styled from 'styled-components';
import paymeTheme from "../../../assets/styling/payme-theme";


const StyledCollectorDetails = styled.div `
  text-align: center;
  margin: 50px auto 100px auto;
  
  .collector-box {
    width: fit-content;
    margin: auto;
    font-weight: ${paymeTheme.fontBold};
    background-color: ${paymeTheme.colorWhite};
    border-radius: 15px;
  }
  
  .collector-name {
    font-size: ${paymeTheme.fontRemSizeH1};
    color: ${paymeTheme.colorPurple};
  }
  
  .collector-description {
    color: ${paymeTheme.floteColor};
    font-size: ${paymeTheme.fontRemSizeH4};
  }

`;

export { StyledCollectorDetails } ;
