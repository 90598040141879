import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";


const StyledClientPage = styled.div `
  .actions-wrapper .back-button {
    margin-right: auto;
  }
 
  ${media.desktopMedium} {
    .actions-wrapper {
      padding-left: ${paymeTheme.sideBarWidth};
    }
  }

  .site-name {
    color: ${paymeTheme.colorPurple};
  }

  .disabled-site {
    margin: 0;
    padding: ${paymeTheme.paddingRem};
    padding-right: 0.625rem;
    border-bottom: 1px solid;

    p {
      margin: 0.3rem 0;
      font-size: ${paymeTheme.fontRemSizeH5};
    }

    .site-header {
      display: flex;

      p {
        margin-bottom: 0;
      }
    }

    svg {
      fill: ${paymeTheme.customColor};
    }

    .client-name {
      font-weight: ${paymeTheme.fontBold};
      color: ${paymeTheme.customColor};
      padding-right: ${paymeTheme.paddingRem};
    }
  }

  .is-selected-wrapper div:nth-of-type(${props => props.theme.id + 2}) {
    .site-name, .off-site-name, .client-name {
      color: ${paymeTheme.errorColor};
    }
  }

  .none {
    display: none;
  }
  

  .client-name {
    color: ${paymeTheme.colorBlack};
    font-weight: ${paymeTheme.fontBold};
  }

  ${media.tablet} {
    .disabled-site {
      justify-content: center;
      width: 30%;
      margin: 0 auto;
      padding-top: 2.5rem;
    }
  }
`;

export { StyledClientPage } ;
