import {combineReducers} from "redux";
import {connectRouter} from "connected-react-router";
import {History} from "history";
import {authReducer} from "../pages/LoginPage/reducer";
import {clientDetailsReducer, clientNameReducer, getSiteTypeReducer} from "../pages/AddNewClientPage/reducer";
import {
  getClientsListReducer,
  getOffSiteDetailsReducer,
  getSiteDetailsReducer,
  getSitesListReducer
} from "../pages/AllClientsSitesPage/reducer";
import {
  addMachineDetailsReducer,
  getUnitBatteryPoweredDetailsReducer, getUnitCreditTypeDetailsReducer,
  getUnitHostTypeReducer,
  getUnitManufacturerDetailsReducer, machineDetails
} from "../pages/MachineDetailsPage/reducer";
import {vendingButtonsReducer} from "../pages/VendingMachineButtons/reducer";
import {stockSelectionDetailsReducer} from "../pages/MachineStockDetails/reducer";
import {receivedSocketMessagesReducer, sentSocketMessagesReducer} from "../components/SocketCommunication/reducer";
import {addSelectedGroupDetailsReducer} from "../pages/ListingCompanyGroups/reducer";
import {cashCollectedValuesReducer, getHistoryCollectionListReducer} from "../pages/CollectionReceipt/reducer";
import {machineCollectionDetailsReducer} from "../pages/MachineCollectionDetails/reducer";
import {siteCollectionDetailsReducer, totalCashCollectedReducer} from "../pages/SiteCollection/reducer";
import {getInitialSiteDetailsReducer} from "../pages/MachineSiteChange/reducer";
import {addNewMemberReducer, userDetailsReducer} from "../pages/TeamMemberPages/reducer";
import {userResetPasswordReducer} from "../pages/ResetPassword/reducer";
import {flowTypeReducer} from "../components/Containers/Main/reducer";
import {getCompanyDetailsReducer} from "../pages/CompanyProfile/reducer";

const createRootReducer = (history: History) => {
  return combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    client: clientNameReducer,
    clientDetails: clientDetailsReducer,
    clientsList: getClientsListReducer,
    sitesList: getSitesListReducer,
    machineDetails: machineDetails,
    unitHostTypeList: getUnitHostTypeReducer,
    unitManufacturerList: getUnitManufacturerDetailsReducer,
    unitBatteryPoweredList: getUnitBatteryPoweredDetailsReducer,
    unitCreditTypeList: getUnitCreditTypeDetailsReducer,
    siteDetails: getSiteDetailsReducer,
    initialSiteDetails: getInitialSiteDetailsReducer,
    offSiteDetails: getOffSiteDetailsReducer,
    addMachineDetails: addMachineDetailsReducer,
    vendingButtons: vendingButtonsReducer,
    stockSelectionDetails: stockSelectionDetailsReducer,
    sentSocketMessageDetails: sentSocketMessagesReducer,
    receivedSocketMessageDetails: receivedSocketMessagesReducer,
    selectedGroupDetails: addSelectedGroupDetailsReducer,
    cashCollectedValues: cashCollectedValuesReducer,
    machineCollectionDetails: machineCollectionDetailsReducer,
    siteCollectionDetails: siteCollectionDetailsReducer,
    totalCashCollected: totalCashCollectedReducer,
    userDetails: userDetailsReducer,
    addNewMember: addNewMemberReducer,
    resetUserPassword: userResetPasswordReducer,
    siteTypeList: getSiteTypeReducer,
    flowType: flowTypeReducer,
    companyDetails: getCompanyDetailsReducer,
    historyCollectionList: getHistoryCollectionListReducer,

  });
};

export default createRootReducer;
