import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";


export const StyledErrorMessage = styled.div ` 
  .error {
    background-color: #FAFAFA;
    color: ${paymeTheme.errorColor};
    padding: ${paymeTheme.paddingRemMd};
    border: 1px solid;
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 3;
    top: 0;
    right: 0;
  }
  .message-content {
    padding-left: ${paymeTheme.paddingRemSm};
  }
  
  ${media.desktopMedium} {
    margin: 0;
  }

  ${media.desktop} {
    padding-top: 2.5rem;
  }
`;


export const StyledImportantNotification = styled.div `   
  .MuiAccordion-root {
    background-color: yellow;
    font-weight: ${paymeTheme.fontRegular};
    font-size: ${paymeTheme.fontRemSizeH6};
    text-align: center;
    position: fixed;
    top: 37px;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column-reverse;
  }

  .MuiPaper-elevation1 {
    box-shadow: 0;
  }

  .MuiAccordionSummary-root {
    align-self: center;
    svg {
      fill: ${paymeTheme.floteColor};
      width: 3.75rem;
      height: 3.75rem;
      position: absolute;
    }

    min-height: 1.25rem;
    max-height: 1.25rem;
    &.Mui-expanded {
      min-height: 1.25rem;
      max-height: 1.25rem;
    }
  }

  .MuiAccordionDetails-root {
    justify-content: center;
  }

  ${media.desktop} {
    .MuiAccordion-root {
      margin: 0;
      top: 57px;
    }
  }
`;

export const StyledFilledBackgroundNotification = styled.div ` 
    font-weight: ${paymeTheme.fontRegular};
    font-size: ${paymeTheme.fontRemSizeNormal};
    color: ${paymeTheme.colorWhite};
    text-align: center;
    width: ${paymeTheme.widthXLg};
    margin: auto;
    
    .green {
      background-color: ${paymeTheme.greenStatusColor};
    }
    
    .red {
      background-color: ${paymeTheme.errorColor};
    }
    
    .yellow {
      background-color: yellow;
    }
    
    .green,
    .red,
    .yellow {
      padding: ${paymeTheme.paddingRemSm};
      margin: 3px auto;
    }
  
  ${media.desktopMedium} {
    margin: 0;
    width: 300px;
  }

  ${media.desktop} {
    padding-top: 2.5rem;
  }
`;


export const StyledLoginError = styled.div ` 
    width: 250px;
    height: 3vh;
    max-height: 40px;
    min-height: ${paymeTheme.padding};
    color: ${paymeTheme.errorColor};
    background-color: ${paymeTheme.colorWhite};
    padding: ${paymeTheme.paddingRemXs} 0;
    border-top: 2px solid ${paymeTheme.errorColor};
    font-size: ${paymeTheme.fontRemSizeBase};
    text-align: center;
    border-radius: 5px;
    margin-top: -3px;
`;

