import {IState} from "../../store/models";



export const getClientsListData = (state: IState) => state.clientsList.data;
export const getClientsListLoading = (state: IState) => state.clientsList.loading;
export const getClientsListError = (state: IState) => state.clientsList.error;

export const getSitesListData = (state: IState) => state.sitesList.data;
export const getSitesListLoading = (state: IState) => state.sitesList.loading;
export const getSitesListError = (state: IState) => state.sitesList.error;

export const getSiteDetailsData = (state: IState) => state.siteDetails.data;
export const getSiteDetailsLoading = (state: IState) => state.siteDetails.loading;
export const getSiteDetailsError = (state: IState) => state.siteDetails.error;

export const getOffSiteDetailsData = (state: IState) => state.offSiteDetails.data;
export const getOffSiteDetailsLoading = (state: IState) => state.offSiteDetails.loading;
export const getOffSiteDetailsError = (state: IState) => state.offSiteDetails.error;
