import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";



const StyledTechnicianAllClientsSites = styled.div `
  
  .height-controller-wrapper {
    padding-bottom: 0;
    min-height: auto;
  }
  
  ${media.desktop} {

  }

`;

export { StyledTechnicianAllClientsSites } ;
