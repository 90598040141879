import { IArgsApi } from "./@types";
import axios from 'axios'
import {history} from "./store/configureStore";
import * as ROUTES from "./components/Containers/Main/constants";

export const asyncRequest = async ({ config, endpoint }: IArgsApi) => {
  const url = `${process.env.REACT_APP_BASE_API_URL}/${endpoint}`;
  const UNAUTHORIZED = 401;
  const reqConfig = {
    url,
    headers: {
      "Content-Type": "application/json",
      "Authorization":  localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
    },
    mode: "no-cors",
    ...config
  };

  axios.interceptors.response.use(
    response =>  response,
    error => {
      const {status} = error.response;
      if (status === UNAUTHORIZED) {
        localStorage.clear();
        history.push(ROUTES.LOGIN);
      }
      return Promise.reject(error);
    }
  );

  const response = await axios(reqConfig)
  console.log('response axios', response);
  if (response) {
    try {
      return response.data
    } catch (err) {
      throw err.message;
    }
  } else {
    throw Error(response);
  }
};
