import React, {FC} from "react";
import {StyledEnterClientDetailsStep} from "./styles";
import Header from "../../../components/Header";
import {StyledAddNewClientPage} from "../styles";
import ClientForm from "../ClientForm";

const EnterClientDetailsStep: FC = () => {
  return (
    <StyledAddNewClientPage className="height-controller-wrapper">
        <Header title="Enter details"/>
        <ClientForm actionType="add"/>
    </StyledAddNewClientPage>
  );
};

export default EnterClientDetailsStep;
