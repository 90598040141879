import React, { FC, useEffect, useState } from "react";
import {StyledBoxSection, StyledMachineTestPage} from "./styles";
import Header from "../../components/Header";
import {OutlinedButton, PrimaryButton} from "../../components/Buttons";
import { useHistory } from "react-router-dom";
import SummaryHeader from "../../components/SummaryHeader";
import MachineDetails from "../../components/MachineDetails";
import { useDispatch, useSelector } from "react-redux";
import { IMachineDetailsState } from "../MachineDetailsPage/model";
import { displayMachineDetails } from "../MachineDetailsPage/selectors";
import { addMachineDetails } from "../MachineDetailsPage/actions";
import { NotificationError } from "../../components/NotificationMessages";
import LoadingComponent from "../../components/Loading";
import { isIOS, isAndroid } from "react-device-detect";
import { getReceivedSocketMessageDetails } from "../../components/SocketCommunication/selectors";
import {socketReceivedMessage, socketSentMessage} from "../../components/SocketCommunication/actions";
import { getVendingButtons } from "../VendingMachineButtons/selectors";
import * as ROUTES from "../../components/Containers/Main/constants";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import {MIN_CREDIT_VALUE} from "../MachineDetailsPage/constants";
import { getFlowType } from "../../components/Containers/Main/selectors";
import { setFlowType } from "../../components/Containers/Main/actions";
import {useModalStyles} from "../../components/Modal/styles";
import ModalComponent from "../../components/Modal";
import {ADAPTOR_NOT_AVAILABLE, HARDWARE_CONNECTION_MESSAGE, HOST_NOT_AVAILABLE} from "./constants";


const MachineTestPage: FC = () => {
  const machineSummaryDetails: IMachineDetailsState = useSelector(
    displayMachineDetails
  );
  const receivedSocketMessage = useSelector(getReceivedSocketMessageDetails);
  const machineDetails = useSelector(displayMachineDetails);
  const extraButtons =
    useSelector(getVendingButtons).vendingButtons.extraButtons;
  const CoinMecAdptProg = useSelector(displayMachineDetails).CoinMecAdptProg;

  const [creditTestValue, setCreditTestValue] = useState(
    machineSummaryDetails.defaultTransactionAmount
  );

  const [hubTagId, setHubTagId] = useState(machineDetails.hubTagId);
  const [redirectTo, setRedirectTo] = useState('');
  const [externalTagId, setExternalTagId] = useState(
    machineDetails.externalTagId
  );
  const [hubTagPassword, setHubTagPassword] = useState(
    machineDetails.hubTagPassword
  );
  const [replaceExternalTagId, setReplaceExternalTagId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [MT8Content, setMT8Content] = useState(null);
  const [openHardwareErrorModal, setOpenHardwareErrorModal] = useState(false);
  const [hardwareModalMessage, setHardwareModalMessage] = useState('');

  const history = useHistory();
  const dispatch = useDispatch();
  const flowType = useSelector(getFlowType);
  const isReplaceTagFlow = flowType.isReplaceTagFlow;
  const isOnlyTestFlow = flowType.isOnlyTestFlow;
  const classes = useModalStyles();

  const handleNativeUnitId = (event: any) => {
    if(!isIOS && !isAndroid) {
      return;
    }
    try {
      const unitDetailsFromRN = JSON.parse(event.data);
      const unitPassword = unitDetailsFromRN.unitPassword;
      const nativeExternalTagId = unitDetailsFromRN.externalTagId;
      const nativeReplaceExternalTagId = unitDetailsFromRN.replaceExternalTagId;
      const redirectTo = unitDetailsFromRN.redirectTo;

      redirectTo && setRedirectTo(redirectTo);
      nativeReplaceExternalTagId &&
      setReplaceExternalTagId(nativeReplaceExternalTagId);
      nativeExternalTagId && setExternalTagId(nativeExternalTagId);
      unitPassword && setHubTagPassword(unitPassword);
    } catch (e) {
      console.warn('Error parsing unitDetailsFromRN, just turn on native app!');
    }

  };

  useEffect(() => {
    redirectTo === 'HOME' && history.push(ROUTES.HOME);
  }, [redirectTo]);

  useEffect(() => {
    setHubTagId(machineDetails.hubTagId);
    if (
      machineDetails.hostType.hostCategory.name !== "Vending" ||
      isReplaceTagFlow
    ) {
      if (isIOS) {
        window.addEventListener("message", handleNativeUnitId);
        return () => window.removeEventListener("message", handleNativeUnitId);
      }
      if (isAndroid) {
        document.addEventListener("message", handleNativeUnitId);
        return () =>
          document.removeEventListener("message", handleNativeUnitId);
      }
      window.addEventListener("message", handleNativeUnitId);
      return () => window.removeEventListener("message", handleNativeUnitId);
    }
  }, []);

  useEffect(() => {
    if (machineDetails.defaultTransactionAmount) {
      setCreditTestValue(machineDetails.defaultTransactionAmount);
    } else {
      setCreditTestValue(MIN_CREDIT_VALUE);
    }
  }, [machineDetails.defaultTransactionAmount]);

  useEffect(() => {
    if (machineDetails.hostType.hostCategory.name !== "Vending" && externalTagId && externalTagId !== "") {
      dispatch(
        addMachineDetails({
          ...machineDetails,
          externalTagId,
          hubTagPassword,
        })
      );
    }
  }, [externalTagId]);

  useEffect(() => {
    if (receivedSocketMessage && receivedSocketMessage.MT) {
      setIsLoading(false);
    }
    if (
      receivedSocketMessage &&
      receivedSocketMessage.MT &&
      receivedSocketMessage.MT === 8
    ) {
      setMT8Content(receivedSocketMessage);
      dispatch(
        addMachineDetails({
          ...machineDetails,
          MT8MessageContent: receivedSocketMessage,
        })
      );
      return;
    }

    if (
      receivedSocketMessage &&
      receivedSocketMessage.MT &&
      receivedSocketMessage.MT === 2 &&
      [1, 2].indexOf(receivedSocketMessage.Err) === -1
    ) {
      dispatch(
        addMachineDetails({
          ...machineDetails,
          hubTagId,
          hubTagPassword,
          creditTestValue,
        })
      );
      history.push(ROUTES.MACHINE_TEST_RESULTS);
    }
    if (
      receivedSocketMessage &&
      receivedSocketMessage.MT &&
      receivedSocketMessage.MT === 2 &&
      [1, 2].indexOf(receivedSocketMessage.Err) !== -1
    ) {
      receivedSocketMessage.Err === 1 && setHardwareModalMessage(ADAPTOR_NOT_AVAILABLE);
      receivedSocketMessage.Err === 2 && setHardwareModalMessage(HOST_NOT_AVAILABLE);
      setOpenHardwareErrorModal(true);
    }
  }, [receivedSocketMessage]);

  const continueToTestResultsHandler = () => {
    setOpenHardwareErrorModal(false);
    const socketMsgWithHubTagVending = {
      MT: "1",
      Amt: creditTestValue,
      Ccy: "GBP",
      HubTagId: hubTagId,
      SelRefs: machineDetails.VendingRefs,
      SpecButts: extraButtons,
      topic: "/server/client/unitConfiguration",
    };
    setIsLoading(true);
    if (isReplaceTagFlow) {
      dispatch(setFlowType({
        ...flowType,
        replaceExternalTagId: JSON.stringify(replaceExternalTagId),
      }));
    }

    if (isReplaceTagFlow || isOnlyTestFlow) {
      if (machineDetails.hostType.hostCategory.name === "Vending") {
        dispatch(socketSentMessage(socketMsgWithHubTagVending));
      } else {
        dispatch(socketSentMessage({
          MT: "1",
          Amt: creditTestValue,
          Ccy: "GBP",
          HubTagId: hubTagId,
          topic: "/server/client/unitConfiguration",
        }));
      }
      return;
    }
    if (machineDetails.hostType.hostCategory.name === "Vending") {
      dispatch(
        socketSentMessage({
          MT: "1",
          Amt: creditTestValue,
          Ccy: "GBP",
          HubTagId: hubTagId,
          SelRefs: machineDetails.VendingRefs,
          SpecButts: extraButtons,
          topic: "/server/client/unitConfiguration",
        })
      );
    } else {
      dispatch(
        socketSentMessage({
          MT: "1",
          Amt: creditTestValue,
          HubTagId: hubTagId,
          Ccy: "GBP",
          topic: "/server/client/unitConfiguration",
        })
      );
    }
  };


  const handleModalClose = () => {
    dispatch(socketReceivedMessage(null));
    setOpenHardwareErrorModal(false);
    history.push(ROUTES.HOME);
  };

  const increaseCreditValue = () => {
    setCreditTestValue(prevState => (parseFloat(prevState) + 0.10).toFixed(2));
  };

  const decreaseCreditValue = () => {
    creditTestValue >= (parseFloat(MIN_CREDIT_VALUE) + 0.10).toFixed(2) &&
    setCreditTestValue(prevState => (parseFloat(prevState) - 0.10).toFixed(2));
  };

  const retryModalBody = (
    <div className="modal-wrapper">
      <div className={classes.body}>
        {hardwareModalMessage}
      </div>
      <div className="modal-footer">
        <div className={classes.footer}>
          <OutlinedButton className="back-button" onClick={handleModalClose}>Abort</OutlinedButton>
          <PrimaryButton className="continue-button" onClick={continueToTestResultsHandler}>Try Again</PrimaryButton>
        </div>
      </div>
    </div>
  );

  return (
    <StyledMachineTestPage className="height-controller-wrapper">
      <Header title="Test" />
      <SummaryHeader isMenuDisplayed={false} isArrowDisplayed={false}/>
      <MachineDetails />
      <div className="warning-section">
        <div className="warning-title">Warning!</div>
        <div className="warning-content">
          Make sure the machine is in test or it will be credited for real.
        </div>
      </div>
      <StyledBoxSection>
        <div className="credit-box-section">
          <div className="credit-value-wrapper">
            {!!machineSummaryDetails &&
            machineSummaryDetails.creditType.code === 'USER_MON_VAL' &&
            <RemoveIcon onClick={decreaseCreditValue}/>}
            <div className="credit-value">
              <span>£</span>
              <span>{parseFloat(creditTestValue).toFixed(2)}</span>
            </div>
            {!!machineSummaryDetails &&
            machineSummaryDetails.creditType.code === 'USER_MON_VAL' && <AddIcon onClick={increaseCreditValue}/>}
          </div>
        </div>
    </StyledBoxSection>
      <div className="actions-wrapper">
        <PrimaryButton
          className="continue-button"
          onClick={continueToTestResultsHandler}
        >
          Test payment
        </PrimaryButton>
      </div>
      {isLoading && <LoadingComponent />}
      <ModalComponent open={openHardwareErrorModal} handleClose={handleModalClose} body={retryModalBody}/>
    </StyledMachineTestPage>
  );
};

export default MachineTestPage;
