import React, {FC, useEffect, useState} from 'react';
import {StyledNewTeamMember} from "./styles";
import Header from "../../../components/Header";
import {TeamMemberForm} from "../TeamMemberForm";
import {asyncRequest} from "../../../api";
import {INITIAL_MEMBER_DETAILS} from "../constants";
import {setAllAuthoritiesRetriever} from "../hooks/setAllAuthorities";


export const NewTeamMember: FC = () => {

  const [allAuthorities, setAllAuthorities] = useState();
  const [initialUserDetailsState, setInitialUserDetailsState] = useState();

  useEffect(() => {
    asyncRequest({
      config: {
        method: "GET",
      },
      endpoint: 'api/users/authorities',
    })
      .then((response) => {
        setAllAuthorities(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const extendedAuthorities = allAuthorities && allAuthorities.length > 0 && allAuthorities.map(item => {
    return {
      label: item.description,
      value: item.name,
      id: item.authority_id,
      checked: false,
      checkBoxName: item.description,
    };
  });

  useEffect(() => {
    setInitialUserDetailsState({
      ...INITIAL_MEMBER_DETAILS,
      authorities: extendedAuthorities
    })
  }, [allAuthorities]);

  return (
    <StyledNewTeamMember className="height-controller-wrapper">
      <Header title="New team member"/>
      <TeamMemberForm actionType="add" initialUserDetailsState={initialUserDetailsState}/>
    </StyledNewTeamMember>
  );
};
