import React, {FC} from "react";
import {StyledEnterClientDetailsStep} from "./styles";
import Header from "../../../components/Header";
import {StyledAddNewClientPage} from "../styles";
import ClientForm from "../ClientForm";

const EditClientPage: FC = () => {

  return (
    <StyledAddNewClientPage className="height-controller-wrapper">
        <Header title="Update client name"/>
        <ClientForm actionType="edit-client"/>
    </StyledAddNewClientPage>
  );
};

export default EditClientPage;
