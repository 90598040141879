import styled from "styled-components";
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";

const StyledScanQRCodeStep = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: "Exo 2" !important;

  .icon-wrapper {
    min-height: 60vh;
    align-items: center;
    svg {
      width: 120px;
    }
  }

  ${media.desktop} {
  }
`;

export { StyledScanQRCodeStep };
