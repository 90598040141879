import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";


const StyledUnitDetailsPage = styled.div `
  .unit-details-container {
    padding: ${paymeTheme.paddingRemLg};
    font-size: ${paymeTheme.fontRemSizeLg};
  }

  .key {
    font-weight: ${paymeTheme.fontBold};
  }

  .key-value-wrapper {
    word-break: break-word;
    padding-bottom: ${paymeTheme.paddingRemMd};

    &.nested {
      padding-left: ${paymeTheme.paddingRemMd};
    }
  }

  .unit-details-container-nested {
      padding-left: ${paymeTheme.paddingRemLg};
  }

  .back-button {
      margin-right: auto!important;
      margin-left: ${paymeTheme.paddingRemLg};
  }
  
`;

export { StyledUnitDetailsPage } ;
