import React, {FC, useEffect, useState} from "react";
import {StyledMachineStockDetails} from "./styles";
import {OutlinedButton, PrimaryButton} from "../../components/Buttons";
import Header from "../../components/Header";
import {useHistory} from "react-router-dom";
import {stockColor} from "../../utils/extractStockColor";
import {extractVendingRefsByRow} from "../../utils/extractVendingRefsByRow";
import {useDispatch, useSelector} from "react-redux";
import {stockSelectionDetails} from "./actions";
import {getStockSelectionDetails} from "./selectors";
import {getReceivedSocketMessageDetails} from "../../components/SocketCommunication/selectors";
import {socketSentMessage} from "../../components/SocketCommunication/actions";
import LoadingComponent from "../../components/Loading";
import {displayMachineDetails} from "../MachineDetailsPage/selectors";
import * as ROUTES from "../../components/Containers/Main/constants";

const MachineStockDetails: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const rowsMap = new Map();

  const [vendingRefsGrid, setVendingRefsGrid] = useState([]);
  const [vendingRefsState, setVendingRefsState] = useState([]);
  const [columnRefsState, setColumnRefsState] = useState([]);
  const [rowRefsState, setRowRefsState] = useState([]);
  const [vAmtsState, setVAmtsState] = useState([]);
  const [vQtysState, setVQtysState] = useState([]);
  const [vLowMaxsState, setVLowMaxsState] = useState([]);
  const [vMedMaxsState, setVMedMaxsState] = useState([]);
  const [vNamesState, setVNamesState] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const stockSelectionDetailsItem = useSelector(getStockSelectionDetails);
  const receivedSocketMessage = useSelector(getReceivedSocketMessageDetails);
  const machineDetails = useSelector(displayMachineDetails);

  useEffect(() => {
    if (receivedSocketMessage && receivedSocketMessage.MT && receivedSocketMessage.MT === 14) {
      setVAmtsState(receivedSocketMessage.VAmts);
      setColumnRefsState(receivedSocketMessage.VCol);
      setRowRefsState(receivedSocketMessage.VRow);
      setVQtysState(receivedSocketMessage.VQtys);
      setVLowMaxsState(receivedSocketMessage.VLowMaxs);
      setVMedMaxsState(receivedSocketMessage.VMidMaxs);
      setVNamesState(receivedSocketMessage.VNames);
      setVendingRefsState(receivedSocketMessage.VRefs);
      setIsLoading(false);
    }

    if (receivedSocketMessage && receivedSocketMessage.MT && receivedSocketMessage.MT === 19) {
      if (receivedSocketMessage.Sts === 1) {
        setIsLoading(false);
        history.push(ROUTES.MACHINE_OPTIONS);
      }
    }
  }, [receivedSocketMessage]);

  useEffect(() => {
    if (vendingRefsState.length > 0) {
      const elemIndex = vendingRefsState.indexOf(stockSelectionDetailsItem.selectionName);
      if (elemIndex !== -1) {
        vQtysState[elemIndex] = stockSelectionDetailsItem.currentQty;
        vLowMaxsState[elemIndex] = stockSelectionDetailsItem.vLowQty;
        vMedMaxsState[elemIndex] = stockSelectionDetailsItem.vMedQty;
        vNamesState[elemIndex] = stockSelectionDetailsItem.vName;
      }
      extractVendingRefsByRow(rowsMap, vendingRefsState, vLowMaxsState, vMedMaxsState, vQtysState, rowRefsState, columnRefsState, vAmtsState, elemIndex);
      rowsMap.forEach(value => setVendingRefsGrid(prevState => [...prevState, value]));
    }
  }, [vendingRefsState]);

  const onRefClickHandler = (item) => {
    const id = item.selectionName;
    history.push(`${ROUTES.ADD_NEW_STOCK}/${id}`);
    dispatch(stockSelectionDetails(item));
  };


  const gridDisplaying = vendingRefsGrid.map((arrItem, idx) =>
    <div key={`row-${idx}`} className="row-wrapper">
      {arrItem.map((item, idx) =>
        <div key={`item-${idx}`} className="item-stock-wrapper"
             style={{width: `${100 / arrItem.length}%`, backgroundColor: stockColor(item.currentQty, item.vLowQty, item.vMedQty)}}
             onClick={() => onRefClickHandler(item)}
        >
          <div className="item-name">{item.selectionName}</div>
          <div className="stock-wrapper"
               style={{backgroundColor: 'black'}}>
            {item.currentQty}
          </div>
        </div>
      )}
    </div>
  );

  const confirmAddingStock = () => {
    setIsLoading(true);
    const socketMessageToSend = {
      MT: '20',
      VRefs: vendingRefsState,
      VAmts: vAmtsState,
      VQtys: vQtysState,
      VNames: vNamesState,
      VLowMaxs: vLowMaxsState,
      VMidMaxs: vMedMaxsState,
      VRow: rowRefsState,
      VCol: columnRefsState,
      topic: '/server/client/unitStocksConfiguration',
    };
    dispatch(socketSentMessage(socketMessageToSend));
  };


  return (
      <StyledMachineStockDetails className="height-controller-wrapper">
        <Header title="Stock Details" />
        <div className="machine-details-header">
          <div className="machine-name">{machineDetails.hostType.name}</div>
          <div>{machineDetails.position}</div>
        </div>
        <div className="stock-grid">{gridDisplaying}</div>
        <div className="actions-wrapper">
          <OutlinedButton className="back-button" onClick={() => {history.goBack()}}>Back</OutlinedButton>
          <PrimaryButton className="continue-button"
                         onClick={confirmAddingStock}>
            Finish
          </PrimaryButton>
        </div>
        {isLoading && <LoadingComponent/>}
      </StyledMachineStockDetails>
  );
};

export default MachineStockDetails;
