import styled from 'styled-components';
import paymeTheme from "../../assets/styling/payme-theme";




const StyledUploadImage = styled.div`
  
  .image-section-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: ${paymeTheme.paddingRem};
  }
  .user-picture {
    border-radius: 50%;
    margin-right: ${paymeTheme.paddingRem}; 
    width: 70px;
    height: 70px;
    svg {
      width: 70px;
      height: 70px;
    }
  }
`;

export { StyledUploadImage };
