import styled from 'styled-components';
import media from "../../../assets/styling/payme-media-queries";
import paymeTheme from "../../../assets/styling/payme-theme";


const StyledGroupCardComponent = styled.div `
  color: ${paymeTheme.floteColor};
  padding: ${paymeTheme.paddingRem};
  border-bottom: 1px solid ${paymeTheme.borderGrey};
  margin: 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
    
  .site-name {
    width: auto;
    flex-wrap: wrap;
    font-size: ${paymeTheme.fontRemSizeH6};
  }
  
  ${media.desktopMedium} {
    margin: 0;
    padding-left: ${paymeTheme.listItemPadding};
  }
  `;

export { StyledGroupCardComponent } ;
