import React, { FC, useEffect, useState } from "react";
import { StyledScanQRCodeStep } from "./styles";
import { OutlinedButton } from "../../components/Buttons";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { displayMachineDetails } from "../MachineDetailsPage/selectors";
import QRCodeSection from "../../components/QRCodeSection";
import { useWindowSize } from "../../utils/detectWindowSize";
import { addMachineDetails } from "../MachineDetailsPage/actions";
import { isIOS, isAndroid } from "react-device-detect";
import * as ROUTES from "../../components/Containers/Main/constants";
import LoadingComponent from "../../components/Loading";
import { getFlowType } from "../../components/Containers/Main/selectors";

const ScanQRCodeStep: FC = () => {
  const machineDetails = useSelector(displayMachineDetails);

  const [externalNfcValue, setExternalNfcValue] = useState();
  const [qrCodeId, setQrCodeId] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const windowSize = useWindowSize();
  const isDesktop = windowSize.width && windowSize.width > 1170;
  const flowType = useSelector(getFlowType);
  const isReplaceTagFlow = flowType.isReplaceTagFlow;

  useEffect(() => {
    if (machineDetails.unitPowerSource.code === "MAINS") {
      setExternalNfcValue("true");
    } else {
      setExternalNfcValue("false");
    }
  }, []);

  const handleNativeQRCodeId = (event: any) => {
    setIsLoading(false);

    if(!isIOS && !isAndroid) {
      return;
    }
    try {
      const unitDetailsFromRN = JSON.parse(event.data);
      const qrCodeId = unitDetailsFromRN.qrCodeId;
      qrCodeId && setQrCodeId(qrCodeId);
    } catch (e) {
      console.warn('Error parsing unitDetailsFromRN, just turn on native app!');
    }
  };

  useEffect(() => {
    // TODO metalCabinetOption removed might break this functionality
    if (!!qrCodeId) {
      if (
        (qrCodeId.substring(0, 2) == "NM" &&
          !!machineDetails.metalCabinetOption) ||
        (qrCodeId.substring(0, 1) == "M" && !machineDetails.metalCabinetOption)
      ) {
        history.push(ROUTES.SCAN_QR_CODE_ERROR);
      } else {
        dispatch(
          addMachineDetails({
            ...machineDetails,
            QrCodeId: qrCodeId,
          })
        );
        if (isReplaceTagFlow) {
          window.ReactNativeWebView &&
            window.ReactNativeWebView.postMessage(
              JSON.stringify({ isReplaceTagFlow: isReplaceTagFlow })
            );
        } else {
          window.ReactNativeWebView &&
            window.ReactNativeWebView.postMessage(
              JSON.stringify({ hasExternalNFC: externalNfcValue })
            );
        }
        if (
          machineDetails.hostType.hostCategory.name === "Vending" &&
          !isReplaceTagFlow
        ) {
          history.push(ROUTES.MACHINE_BUTTONS);
          return;
        }
        if (
          machineDetails.hostType.hostCategory.name !== "Vending" &&
          !isReplaceTagFlow
        ) {
          history.push(ROUTES.MACHINE_TEST);
          return;
        }
        if (isReplaceTagFlow) {
          history.push(ROUTES.MACHINE_TEST);
        }
      }
    }
  }, [qrCodeId]);

  useEffect(() => {
    if (isIOS) {
      window.addEventListener("message", handleNativeQRCodeId);
      return () => window.removeEventListener("message", handleNativeQRCodeId);
    }
    if (isAndroid) {
      document.addEventListener("message", handleNativeQRCodeId);
      return () =>
        document.removeEventListener("message", handleNativeQRCodeId);
    }
    window.addEventListener("message", handleNativeQRCodeId);
    return () => window.removeEventListener("message", handleNativeQRCodeId);
  }, []);

  const qRCodeHandler = (qrData: string | null) => {
    setIsLoading(true);
    window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ showQrCode: "true" })
      );
  };

  return (
    <StyledScanQRCodeStep className="height-controller-wrapper">
      <Header title="Scan QR Code" />
      <QRCodeSection onClickHandler={qRCodeHandler} isDesktop={isDesktop}/>
      <div className="actions-wrapper">
        <OutlinedButton
          onClick={() => {
            history.goBack();
          }}
        >
          Back
        </OutlinedButton>
      </div>
      {isLoading && <LoadingComponent/>}
    </StyledScanQRCodeStep>
  );
};

export default ScanQRCodeStep;
