import { put, takeLatest } from "redux-saga/effects";
import {GET_COMPANY_DETAILS_FAILURE, GET_COMPANY_DETAILS_REQUEST, GET_COMPANY_DETAILS_SUCCESS} from "./constants";
import {asyncRequest} from "../../api";


export function* fetchCompanyDetailsSaga(action: any) {
  try {
    const getCompanyDetails = yield asyncRequest({
      config: {
        method: "GET",
      },
      endpoint: 'api/company/owner',
    });


    yield put({ type: GET_COMPANY_DETAILS_SUCCESS, payload: getCompanyDetails });
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_COMPANY_DETAILS_FAILURE,
      payload: error && error.response && error.response.data && error.response.data.title
        ? error.response.data.title : 'Unknown error'
    });
  }
}


export default  [
  takeLatest(GET_COMPANY_DETAILS_REQUEST, fetchCompanyDetailsSaga),
]

