import {IUnitCollectionState} from "./model";
import {GET_MACHINE_COLLECTION} from "./constants";


const machineCollectionInitialState: IUnitCollectionState = {
  data: {
    title: '',
    manufacturerCode: '',
    collectionPeriod: {startTime: '', endTime: ''},
    collectionMeters: {firstMeter: 0, lastMeter: 0},
    estimatedCashAmount: 0,
    actualCashCollected: 0,
    grossContactless: 0,
    grossCashCollected: 0,
    unitId: -1,
    iconUrl: '',
  },
  loading: false,
  error: null
};


export const machineCollectionDetailsReducer = (state = machineCollectionInitialState , action: any) => {
  switch (action.type) {
    case GET_MACHINE_COLLECTION:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    default:
      return state;
  }
};
