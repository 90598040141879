import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";



const StyledSitesClientsWrapper = styled.div `
  &.height-controller-wrapper {
    overflow-y: unset;
  }

  .site-name {
    text-align: center;
  }

  .header-list {
      border-bottom: 1px solid;
   }
   
  .MuiTab-textColorPrimary.Mui-selected {
    color: ${paymeTheme.floteColor};
  }
  .PrivateTabIndicator-colorPrimary-12 {
      background-color: ${paymeTheme.floteColor};
  }
    
  .header-list,
  .item-wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: ${paymeTheme.paddingRemXs} 0;
      .item-name,
      .item-town {
        width: 30%;
        flex-wrap: wrap;
      }

      .item-town {
        position: relative;
        right: 0.2rem;
      }
        
      .item-sites {
        width: 100%;
        flex-wrap: wrap;
      }
  }
  .header-list {
      justify-content: flex-start;
      div {
        width: 30%;
      }
  }
  
  .item-wrapper {
    padding:  ${paymeTheme.paddingRem} 0;
    border-bottom: 1px solid ${paymeTheme.borderGrey};
    flex-wrap: nowrap!important;
  }
  
  .item-town {
    text-align: right;
  }
    
  .client-name {
    color: ${paymeTheme.colorBlack};
    font-weight: ${paymeTheme.fontBold};
  }
  
  .site-name,
  .sites-wrapper {
    color: ${paymeTheme.colorPurple};
  }
  
  .MuiSvgIcon-root {
    fill: ${paymeTheme.floteColor};
  }

  .search-section {
    position: relative;
    .MuiInputBase-root {
      width: 100%;
      padding: 8px;
    }
    
    .MuiInputBase-root::-webkit-input-placeholder {
      text-align: center;
    }
    .MuiFormControl-root.MuiTextField-root {
      margin-bottom: 0;
    }
    .MuiSvgIcon-root {
      position: absolute;
      right: ${paymeTheme.paddingRemSm};
    }
  }

  .add-new-client,
  .search-section {
    display: flex;
    align-items: center;
    width: 50vw;
  }
  
  .search-section {
    justify-content: flex-end;
    margin-right: 0.5rem;
  }

  .add-search-wrapper {
    position: absolute;
    top: 0.15rem;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content:  ${props => !!props.theme.isTechnician ? 'flex-end' : 'space-between'};;
    .MuiSvgIcon-root {
      fill: ${paymeTheme.floteColor};
      width: ${paymeTheme.paddingRemLg};
      height: ${paymeTheme.paddingRemLg};
    }
  }

  .MuiTab-fullWidth {
    font-size: ${paymeTheme.fontRemSizeNormal};
  }

  .MuiAppBar-positionSticky {
    top: 37px;
  }

  .MuiBox-root {
    padding-top: 0;
  }

  @media (max-width: 500px) {
    .search-section {
      .MuiInputBase-root {
        min-width: 48vw;
      }
    } 
  }

  ${media.desktop} {
    .MuiAppBar-positionSticky {
      top: 57px;
    }

    .add-search-wrapper {
      top: 2.188rem;
    }
  }

`;

export { StyledSitesClientsWrapper } ;
