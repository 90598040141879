import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import PaymeTheme from "../../assets/styling/payme-theme";
import loginBackground from "../../assets/images/man-company.jpg";
import paymeTheme from "../../assets/styling/payme-theme";


const StyledLogin = styled.div `
  .login {
    text-align: center;
    background-image: url(${loginBackground});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    position: relative;
  }
  .login-header {
    padding-top: ${PaymeTheme.paddingRemSm};
    text-align: center;
    color: ${PaymeTheme.colorWhite};
    font-size: 40px;
    .flote-word {
      color: ${PaymeTheme.floteColor};
    }
    .login-subtitle {
      font-size: ${PaymeTheme.fontRemSizeSmall};
    }
  }
  
  .register-content {
    color: ${paymeTheme.colorWhite};
    .MuiTypography-colorPrimary {
      color: ${paymeTheme.colorWhite};
    }
    div, button {
      width: fit-content;
      background-color: rgba(128,128,128, 0.5);
      margin: ${PaymeTheme.paddingRemSm} auto;
      padding: 0 5px;
    }
    .register-btn {
      font-weight: ${PaymeTheme.fontBold};
    }
  }

  a {
    text-decoration: none;
    color: ${paymeTheme.colorWhite};

    &:hover {
      text-decoration: underline;
    }
  }
  
  
  ${media.desktop} {
    .login-header {
      text-align: left;
      padding-left: 50px;
    }
    .login-register-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: auto;
      width: 25%;
      background-color: #212123;
    }
    .register-content {
      padding-top: 50px;
    }
  }
`;

export { StyledLogin } ;
