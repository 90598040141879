import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar/AppBar";
import {createStyles, makeStyles, Theme} from "@material-ui/core";
import paymeTheme from "../../assets/styling/payme-theme";
import styled from "styled-components";
import Drawer from "@material-ui/core/Drawer";


export const AppBarStyles = withStyles({
  root: {
    backgroundColor: '#0B2D5A',
    bottom: 0,
    top: 'auto',
    height: 'auto',

    '& .MuiToolbar-root': {
      justifyContent: 'space-around',
    },

    ['@media (min-width: 1170px)']: { // eslint-disable-line no-useless-computed-key
      width: paymeTheme.sideBarWidth,
      position: 'fixed',
      left: 0,
      top: 0,

      '& .MuiToolbar-root': {
        flexDirection: 'column-reverse',
        justifyContent: 'flex-start'
      },
    }

  },
})(AppBar);

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      flexGrow: 1,
    },
    menuButton: {
      margin: 0,
    },
    title: {
      flexGrow: 1,
    },

  }),
);

export const DrawerStyles = withStyles({
  root: {
    bottom: '56px!important',
    height: 'auto',
    maxHeight: 'calc(100vh - 56px)',
    overflowY: 'scroll',

    '& .MuiBackdrop-root': {
      bottom: 56,
    },

    '&& .MuiDrawer-paperAnchorBottom': {
      bottom: 56,
      maxHeight: 'calc(100vh - 56px)',
      overflowY: 'scroll',
    },

    '&& .MuiCollapse-wrapper .MuiButtonBase-root': {
      fontSize: paymeTheme.fontRemSizeSmall,
      textAlign: 'center',
      paddingBottom: 0,
    },

    '&&& .MuiCollapse-root.MuiCollapse-entered': {
      minHeight: 'auto!important',
    },

    ['@media (min-width: 1170px)']: { // eslint-disable-line no-useless-computed-key
      '& .MuiBackdrop-root': {
        bottom: 0,
      },

      '&& .MuiDrawer-paperAnchorLeft': {
        left: 56,
        minWidth: '25vh',
        maxWidth: 240,
      },
    }

  },
})(Drawer);

const StyledMenuWrapper = styled.div `
  .MuiSvgIcon-root {
     fill: ${props => !!props.theme.disableBurgerMenu ? paymeTheme.colorPurple : 'white'};
  }

  display: flex;
  justify-content: center;
`;

export { StyledMenuWrapper } ;

