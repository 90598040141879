import React, {FC, useEffect, useState} from 'react';
import {StyledUploadImage} from "./styles";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {OutlinedButton} from "../Buttons";


interface Props {
  onChangeImageHandler: (file: any) => void;
  userDetails: any;
  dataUri: any;
  actionType: string;
}


export const UploadImage: FC<Props> = ({onChangeImageHandler, userDetails, dataUri, actionType}) => {

  const hiddenFileInput = React.useRef(null);
  const [srcImage, setSrcImage] = useState();


  useEffect(() => {
    if (!!dataUri) {
      setSrcImage(dataUri);
      return;
    }
    if (!!userDetails && !!userDetails.imageUrl) {
      setSrcImage(userDetails.imageUrl);
      return;
    }
  }, [dataUri, userDetails]);

  const handleUploadPhotoClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <StyledUploadImage theme={{status: userDetails ? userDetails.status : undefined}}>
      <div className="image-section-wrapper">
        <div className="user-picture">
          {
            srcImage ? <img className="user-picture"
                           src={srcImage}
                           alt="user picture"/> : <AccountCircleIcon/>
          }
        </div>
          <div>
            <OutlinedButton onClick={handleUploadPhotoClick}>Upload picture</OutlinedButton>
            <input type="file"
                   style={{display:'none'}}
                   ref={hiddenFileInput}
                   onChange={(event) => onChangeImageHandler(event.target.files[0] || null)}/>
          </div>
      </div>
    </StyledUploadImage>
  );
};
