import {
  USER_EMAIL_FAILURE,
  USER_EMAIL_REQUEST,
  USER_EMAIL_SUCCESS, USER_RESET_PASSWORD_FAILURE, USER_RESET_PASSWORD_REQUEST, USER_RESET_PASSWORD_SUCCESS
} from "./constants";


export const userEmailAttempt = (email: string) => ({
  type: USER_EMAIL_REQUEST,
  payload: email
});

export const userEmailSuccess = (data: any) => ({
  type: USER_EMAIL_SUCCESS,
  payload: data,
});

export const userEmailFailure = (error: any) => ({
  type: USER_EMAIL_FAILURE,
  payload: error
});

export const userResetPasswordAttempt = (data: any) => ({
  type: USER_RESET_PASSWORD_REQUEST,
  payload: {...data}
});

export const userResetPasswordSuccess = (data: any) => ({
  type: USER_RESET_PASSWORD_SUCCESS,
  payload: data,
});

export const userResetPasswordFailure = (error: any) => ({
  type: USER_RESET_PASSWORD_FAILURE,
  payload: error
});

