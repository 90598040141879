export const extractVendingRefsByFirstLetter = (vendingRefs: string[], dictionaryMap: any) => {
  vendingRefs.sort();
  for (const elem of vendingRefs ) {
    const firstLetter = elem[0];
    if (dictionaryMap.has(firstLetter)) {
      const currentValue = dictionaryMap.get(firstLetter);
      currentValue.push({id: elem, content: elem});
      dictionaryMap.set(firstLetter, currentValue) ;
    } else {
      dictionaryMap.set(firstLetter, [{id: elem, content: elem}]);
    }
  }
}
