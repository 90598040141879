import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";


const StyledMachineStockDetails = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: "Exo 2"!important;
  
  .machine-details-header {
    display: flex;
    margin: ${paymeTheme.paddingLg} 0;
    width: 100%;
    
    .machine-name {
      color: ${paymeTheme.floteColor};
      font-size: ${paymeTheme.fontRemSizeH6};
      padding-right: 50px;
      padding-left: ${paymeTheme.padding};
    }
  }
    
  .stock-grid {
    width: 95vw;
    overflow-y: scroll;
    
    .row-wrapper {
      display: flex;
    }
    
    .item-stock-wrapper {
      background-color: ${paymeTheme.colorDarkGray};
      color: ${paymeTheme.colorWhite};
      margin: 2px;
      padding: ${paymeTheme.paddingRemXs};
      height: auto;
      border-radius: 4px;
      text-align: center;
      cursor: pointer;
    }
    
    .item-name {
      font-size: ${paymeTheme.fontRemSizeH5};
      padding-bottom: ${paymeTheme.paddingRemXs};
    }
    
    .stock-wrapper {
      border-radius: 50%;
      width: fit-content;
      margin: auto;
      padding: ${paymeTheme.paddingRemSm};
    }
  }
  
`;

export { StyledMachineStockDetails } ;
