import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";




const StyledMachineDeactivation = styled.div `

  .helper-screen-description {
    font-size: ${paymeTheme.fontRemSizeNormal};
    div {
      padding: ${paymeTheme.paddingRemXs} 0;
    }
  }
  
  ${media.desktop} {
    
  }

`;

export { StyledMachineDeactivation } ;
