export const ROLE_ADMIN = "ROLE_ADMIN";
export const ROLE_MASTER = "ROLE_MASTER";
export const ROLE_TECHNICIAN = "ROLE_TECHNICIAN";
export const ROLE_COLLECTOR = "ROLE_COLLECTOR";
export const ROLE_OPERATIVE = "ROLE_OPERATIVE";
export const ROLE_CLIENT = "ROLE_CLIENT";

export const GOOGLE_PLAY_URL = "https://install.appcenter.ms/orgs/tremend/apps/payme-android/distribution_groups/client%20feedback";
export const APPLE_STORE_URL = "https://install.appcenter.ms/orgs/tremend/apps/payme-ios/distribution_groups/client%20feedback";


export const REPLACE_TAG_CLICKED = "REPLACE_TAG_CLICKED";

export enum StorageKeys {
  replaceExternalTagId = 'replaceExternalTagId',
  userLatLng = 'userLatLng',
  token = 'token',
  isReplaceTagFlow = 'isReplaceTagFlow',
  isChangeSiteFlow = 'isChangeSiteFlow',
  isChangePositionFlow = 'isChangePositionFlow',
  isOnlyTestFlow = 'isOnlyTestFlow'
}
