import { createStyles, Theme, withStyles } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import styled from 'styled-components';
import media from "../../../assets/styling/payme-media-queries";
import paymeTheme from "../../../assets/styling/payme-theme";


const AntSwitch = withStyles((theme: Theme) =>
    createStyles({
      root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
      },
      switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        '&$checked': {
          transform: 'translateX(12px)',
          color: theme.palette.common.white,
          '& + $track': {
            opacity: 1,
            backgroundColor: paymeTheme.successColor,
            borderColor: paymeTheme.successColor,
          },
        },
      },
      thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
      },
      track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
      },
      checked: {},
    }),
  )(Switch);

const StyledTeamMemberForm = styled.div`
  margin: auto;

  .new-team-member-form {
    padding-top: ${paymeTheme.paddingRem};
  }

  .label-textfield-wrapper {
    display: flex;
    align-items: center;
  }

  .label {
    position: absolute;
    left: 10vw;
  }

  .status-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    right: 3%;

    span {
      &.MuiSwitch-root {
        justify-content: flex-end;
      }
    }

    p {
      position: relative;
      right: 20%;
    }
  }

  .MuiSwitch-track {
    height: unset;
  }
  
  .image-section-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: ${paymeTheme.paddingRem};
  }
  .user-picture {
    border-radius: 50%;
    margin-right: ${paymeTheme.paddingRem}; 
    width: 70px;
    height: 70px;
    svg {
      width: 70px;
      height: 70px;
    }
  }
  .roles-wrapper {
    display: flex;
    justify-content: center;
  }
  
  .MuiCheckbox-colorPrimary,
   .roles-title {
    padding: 5px ${paymeTheme.paddingRemXs};
  }
  
  .MuiCheckbox-colorPrimary.Mui-checked {
    color: ${paymeTheme.floteColor};
  }
  .MuiCheckbox-colorPrimary.Mui-disabled {
    background-color: transparent!important;
  }
  .MuiFormHelperText-root.MuiFormHelperText-filled {
    color: ${paymeTheme.errorColor};
  }
  .helper-screen-description {
    max-width: 50%;
    padding: 0;
  }

  .MuiFormHelperText-contained {
    margin-left: 0;
    margin-right: 0;
    right: 0;
    left: 0;
    margin: auto;
  }
 
  ${media.desktop} {
    .new-team-member-form {
      padding-top: 2.5rem;
    }
  }

  label {
    padding-right: 0.625rem;
    position: relative;
  }

  .label-active {
    color: ${paymeTheme.successColor};
    top: 1vh;
  }

  .label-access-denied {
    color: ${paymeTheme.errorColor};
    top: 1vh;
  }

  .field {
    text-align: right;

    .error-text-date-picker {
      display: flex;
      justify-content: center;
      color: ${paymeTheme.errorColor};
      margin: auto;
      font-size: 0.75rem;
      font-family: "Exo 2";
      letter-spacing: 0.03rem;

      div {
        flex-grow: 1;
        width: 0;
      }
    }
  }

  .status {
    color: ${props => props.theme.status === "Active" ? paymeTheme.successColor : props.theme.status === "Unverified" ? paymeTheme.customColor : paymeTheme.errorColor};
  }

  .react-datepicker-wrapper {
    display: inline-flex;
    height: 5vh;
    margin-bottom: 3px!important;
    border: 0;
    margin: 0;
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
  }

  .react-datepicker__input-container {
    color: ${paymeTheme.colorBlack};
    height: 100%;
    max-height: 40px;
    min-height: 25px;
    overflow: hidden;
    background-color: ${paymeTheme.colorWhite};
    font-family: "Exo 2"!important;
    font-size: ${paymeTheme.fontRemSizeNormal}!important;
    min-width: 250px;
    box-sizing: border-box;

    input {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      padding: ${paymeTheme.paddingRemXs};
      border: 1px solid ${paymeTheme.borderGrey};
      border-radius: 4px;
      font: inherit;
      width: 100%;
      margin: 0;
      min-width: 0;
      transition: background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      &:focus {
        background-color: ${paymeTheme.colorWhite}!important;
        border: 1px solid ${paymeTheme.floteColor};
        outline: none;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.13);
      }
      &:disabled {
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.12);
        color: rgba(0, 0, 0, 0.58);
        cursor: default;
      }
    }
  }
  
  .date-error-wrapper {
    display: inline-block;
  }

  .date-picker-field {
    display: flex;
    justify-content: flex-end;
  }
  
  .status-wrapper {
    display: flex;
  }
  
  .status-wrapper div {
    flex-grow: 1;
    width: 0;
  }
  
  .new-team-member-form {
    display: inline-block;
  }

`;

export { StyledTeamMemberForm, AntSwitch };
