import styled from 'styled-components';
import paymeTheme from "../../../assets/styling/payme-theme";


const StyledMerchantHome = styled.div `


`;

export { StyledMerchantHome } ;
