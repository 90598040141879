import styled from "styled-components";
import paymeTheme from "../../assets/styling/payme-theme";

export const StyledSection = styled.div`
  text-align: center;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: ${paymeTheme.widthXLg};
    height: ${paymeTheme.heightLg};
    max-width: 130px;
  }

  .nfc-section-wrapper {
    svg {
      width: ${paymeTheme.widthMd};
      height: ${paymeTheme.heightMd};
    }
  }

  .nfc-title {
    min-height: 108px;
  }
  .dark-overlay {
    position: absolute;
    height: 100vh;
    width: 101vw;
    background: rgb(0 0 0 / 91%);
    top: 0;
    left: 0;
    z-index: 2;
  }
`;

export const QRReaderWrapper = styled.div`
  position: absolute;
  width: 100vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
`;
