import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";




const StyledActivateMemberAccount = styled.div `
  position: relative;
  &.height-controller-wrapper {
    padding-top: 30vh;
  }
  .error-message,
  .success-message {
    font-size: ${paymeTheme.fontRemSizeH4};
    margin: auto ${paymeTheme.paddingRemLg};
    text-align: center;
  }
  .error-message {
    color: ${paymeTheme.errorColor};
  }
  .success-message {
    color: ${paymeTheme.successColor};
  }
  .actions-wrapper a {
    color: ${paymeTheme.colorWhite};
    text-decoration: none;
  }
  .MuiFormHelperText-contained {
    margin-right: 0;
  }
  
   ${media.desktop} {
    &.height-controller-wrapper {
      padding-top: calc(30vh + 1.25rem);
    }
    .error-message,
    .success-message {
      margin: auto;
    }
  }

`;

export { StyledActivateMemberAccount } ;
