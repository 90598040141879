import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar/AppBar";
import {createStyles, makeStyles, Theme} from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import styled from 'styled-components';
import Menu from '@material-ui/core/Menu';
import paymeTheme from "../../../assets/styling/payme-theme";



export const StyledMenu = styled(Menu)<{isGroupedClicked: boolean}>`
  & {
    .MuiList-root.MuiMenu-list {
      padding: 0;
    }
  }
`

export const StyledMenuItem = withStyles({
  root: {
    fontFamily: `"Exo 2"`,
    color: '#0090DF',
    minHeight: '40px'
  },
})(MenuItem);

export const StyledSubMenuItem = withStyles({
  root: {
    fontFamily: `"Exo 2"`,
    color: '#0090DF',
    textAlign: 'center',
    fontSize: paymeTheme.fontRemSizeNormal,
    justifyContent: 'center',
    minHeight: '40px',
    '&:nth-child(2)': {
      borderTop: '1px solid lightgray'
    },
    '&:last-child': {
      borderBottom: '1px solid lightgray'
    }
  },
})(MenuItem);


export const SiteAppBarStyles = withStyles({
  root: {
    position: 'static',
    backgroundColor: 'transparent',
    boxShadow: 'none',

    '& .MuiToolbar-root': {
      justifyContent: 'space-around',
    },

    '& .MuiIconButton-root': {
      padding: '0'
    },

    ['@media (min-width: 1170px)']: { // eslint-disable-line no-useless-computed-key
      // width: '64px',
      // position: 'fixed',
      // left: 0,
      // top: 0,

      '.MuiList-root': {
        // flexDirection: 'column-reverse',
        // justifyContent: 'flex-start'
        padding: 0,
      },
    }

  },
})(AppBar);

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,

    },
    menuButton: {
      margin: 0,
    },
    title: {
      flexGrow: 1,
    },
  }),
);


