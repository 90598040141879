import React, { FC } from "react";
import { StyledSiteCardComponent } from "./styles";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useSelector } from "react-redux";
import { getFlowType } from "../Containers/Main/selectors";

interface Props {
  item: any;
}

const SiteCardComponent: FC <Props>= ({item}) => {
  const flowType = useSelector(getFlowType);
  const isChangeSiteFlow = flowType.isChangeSiteFlow;

  return (
    <StyledSiteCardComponent className={item.isSelected ? "site-card isSelected" : "site-card"}>
      <div className="client-name">{item.clientName}</div>
      <div className="site-name item-name">{item.name}</div>
      <div className="item-town">{item.town}</div>
      {!isChangeSiteFlow && <ArrowForwardIosIcon/>}
    </StyledSiteCardComponent>
  );
};

export default SiteCardComponent;
