
import {GET_INITIAL_SITE_DETAILS} from "./constants";
import {ISIteDetailsState} from "../AllClientsSitesPage/model";


const siteDetailsInitialState: ISIteDetailsState = {
  data: {
    clientName: '',
    id: -1,
    name: '',
    postcode: '',
    siteType: {id: -1, code: '', name:''},
    town: '',
    units: [{id: -1,
      hostType: {
        name: '',
        hostTypeId: 0,
        code: '',
        iconUrl: '',
        maxTransactionAmount: -1,
        hostCategory: {id: 0, name: '', code: ''}
      },
      lastSeen: '',
      position: '',
      manufacturer: {id: -1, name:  ''},
      unitPowerSource: {id: -1, code: '', name: ''},
      unitStatus: {id: -1, code: '', name: ''}
    }],
    what3Words: '',
  },
  loading: false,
  error: null,
};

export const getInitialSiteDetailsReducer = (state = siteDetailsInitialState, action: any) => {
  switch (action.type) {
    case GET_INITIAL_SITE_DETAILS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    default:
      return state;
  }
};
