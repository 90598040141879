import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";


const StyledVendingMachineButtonsConfirmation = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: "Exo 2"!important;
  
  .message-content {
    color: ${paymeTheme.colorBlack};
    font-size: ${paymeTheme.fontRemSizeH5};
  }
  
   .green {
    background-color: ${paymeTheme.successColor};
  }

  .keyboard {
    margin: ${paymeTheme.paddingRemLg} auto;
  }

  
  ${media.desktop} {
      
  }
  
`;

export { StyledVendingMachineButtonsConfirmation } ;


export const StyledVendingKeyboard = styled.div `
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  
  .MuiButtonBase-root {
    color: ${paymeTheme.colorWhite}!important;
    font-size: ${paymeTheme.fontRemSizeH3};
    min-width: 1px;
    width: 17vw;
    height: 45px;
    margin: 5px;
    background-color: ${paymeTheme.customColor};
    border: 1px solid black;
    
    &.smaller-font {
      font-size: ${paymeTheme.fontRemSizeSmall};
    }
    
     &.green-color {
      background-color: ${paymeTheme.successColor};
      &.blue-color {
        background-color: ${paymeTheme.floteColor};
      }
    }
  }

  
  ${media.desktop} {
      
  }
  
`;



