import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";




const StyledListingCompanyGroups = styled.div `
  .actions-wrapper .back-button {
    margin: 0 auto;
  }

  .add-new-group-form {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: ${paymeTheme.paddingRemMd};
    
    .MuiInputBase-root {
      min-width: 200px;
    }
    .MuiButtonBase-root {
      max-width: 100px;
      min-width: 100px;
      margin-left: ${paymeTheme.paddingRemXs};
      height: ${paymeTheme.heightXs};
    }
    .MuiButtonBase-root,
    .MuiFormControl-root.MuiTextField-root {
      margin: 0;
    }
  }
 
  ${media.desktop} {
    .add-new-group-form {
      padding-top: 2.5rem;
    }
    .height-controller-wrapper {
      padding-left: ${paymeTheme.sideBarWidth}!important;
    }
  }

  ${media.desktopMedium} {
    .height-controller-wrapper {
      padding-left: ${paymeTheme.sideBarWidth};
    }
  }

`;

export { StyledListingCompanyGroups } ;
