import {SELECTED_GROUP_DETAILS} from "./constants";
import {ISelectedGroupDetailsState} from "./model";

const initialSelectedGroupState: ISelectedGroupDetailsState = {
  data: {
    id: -1,
    name: '',
    groupType: '',
    partyId: [],
    sites: [
      {
        clientName: 'Client 1',
        id: -1,
        name: 'Test Site - Pete',
        postcode: '',
        siteType: {id: -1, code: '', name:''},
        town: 'Reading',
        units: [{
          id: -1,
          hostType: {hostTypeId: -1, name: ''},
          lastSeen: '',
          position: '',
          manufacturer: {id: -1, name:  ''},
          unitPowerSource: {id: -1, code: '', name: ''},
          unitStatus: {id: -1, code: '', name: ''}
        }],
        what3Words: '',
      },
      {
        clientName: 'Reading University',
        id: -1,
        name: 'Park Eat',
        postcode: '',
        siteType: {id: -1, code: '', name:''},
        town: 'Reading',
        units: [{id: -1,
          hostType: {hostTypeId: -1, name: ''},
          lastSeen: '',
          position: '',
          manufacturer: {id: -1, name:  ''},
          unitPowerSource: {id: -1, code: '', name: ''},
          unitStatus: {id: -1, code: '', name: ''}
        }],
        what3Words: '',
      }
    ]
  },
  loading: false,
  error: null
};

export const addSelectedGroupDetailsReducer = (state = initialSelectedGroupState, action: any) => {
  switch (action.type) {
    case SELECTED_GROUP_DETAILS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

