import styled from 'styled-components';
import paymeTheme from "../../assets/styling/payme-theme";
import media from "../../assets/styling/payme-media-queries";

const StyledTeamLog = styled.div `
  &.height-controller-wrapper {
    overflow-y: unset;
  }

  .header-list {
    border-bottom: 1px solid;
    div {
      padding: ${paymeTheme.paddingRemXs} 0;
    }
    .name-section {
      width: 65%;
    }
    .date-section {
      justify-self: flex-end;
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  svg {
    align-self: center;
  }

  .add-search-wrapper {
    display: flex;
    justify-content: space-between;
    .MuiSvgIcon-root {
      fill: ${paymeTheme.floteColor};
      width: ${paymeTheme.paddingRemLg};
      height: ${paymeTheme.paddingRemLg};
    }
  }
   
  .add-trigger,
  .search-section {
    display: flex;
    align-items: center;
    width: ${paymeTheme.widthLg};
  }

  .search-section {
    justify-content: flex-end;
    margin-right: ${paymeTheme.paddingRemXs};
  }
   
  .add-search-wrapper {
    position: absolute;
    top: 0.15rem;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: space-between;
    .MuiSvgIcon-root {
      fill: ${paymeTheme.floteColor};
      width: ${paymeTheme.paddingRemLg};
      height: ${paymeTheme.paddingRemLg};
    }
  }

  .search-section {
    position: relative;
    .MuiInputBase-root {
      width: 100%;
      padding: ${paymeTheme.paddingRemXs};
    }
    
    .MuiInputBase-root::-webkit-input-placeholder {
      text-align: center;
    }
    .MuiFormControl-root.MuiTextField-root {
      margin-bottom: 0;
    }
    .MuiSvgIcon-root {
      position: absolute;
      right: ${paymeTheme.paddingRemSm};
    }
  }

  .MuiTab-fullWidth {
    font-size: ${paymeTheme.fontRemSizeNormal};
  }

  .MuiAppBar-positionSticky {
    top: 37px;
  }

  .MuiBox-root {
    padding-top: 0;
  }

  @media (max-width: 500px) {
    .search-section {
      .MuiInputBase-root {
        min-width: ${paymeTheme.widthLg};
      }
    } 
  }

  .header-list {
    display: flex;
    padding: 0 ${paymeTheme.paddingRemSm};
  }

  ${media.desktop} {
    .user-cards-list {
      top: ${paymeTheme.topLg};
      max-width: ${paymeTheme.widthXLg};
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    .MuiAppBar-positionSticky {
      top: 57px;
    }

    .add-search-wrapper {
      top: 2.188rem;
    }
  }

`;

export { StyledTeamLog } ;
