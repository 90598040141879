
export const stockColor = (currentQty, vLowQty, vMedQty) => {
  if (currentQty < vLowQty) {
    return '#E50000';
  } else if (currentQty < vMedQty) {
    return '#0090DF';
  } else {
    return '#178900';
  }
}
