import React, {FC, useEffect, useState} from "react";
import {StyledCollectionCard} from "./styles";
import {convertDateFullMonthLetters} from "../../utils/getCurrentDate";
import {getCashCollectedValues} from "../../pages/CollectionReceipt/selectors";
import {useSelector} from "react-redux";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

interface Props {
  isCollectionHistory?: boolean;
  totalCashCollected?: any;
  isIdenticalStartData: boolean;
  collectionCardStartDate: string;
}

const CollectionCard:FC<Props> = ({isCollectionHistory = false, totalCashCollected, isIdenticalStartData, collectionCardStartDate}) => {
  const cashCollectedValuesData = useSelector(getCashCollectedValues);
  const cashCollectedValues = cashCollectedValuesData.cashCollectedValues;
  const [actualCollectedTotal, setActualCollectedTotal] = useState(0);
  const [startDateValue, setStartDateValue] = useState('');
  let sum = 0;

  useEffect(() => {
    cashCollectedValues.length > 0 && cashCollectedValues.forEach(item => {
      sum += parseFloat(item.value);
    });
    setActualCollectedTotal(sum);
  }, [cashCollectedValuesData]);

  useEffect(() => {
    if (isIdenticalStartData) {
      setStartDateValue('Up to ');
    } else {
      setStartDateValue(convertDateFullMonthLetters('.', collectionCardStartDate, 'short'));
    }
  }, [isIdenticalStartData, collectionCardStartDate]);

  return (
    <StyledCollectionCard>
      <div className="collection-card-header">
        <div className="period">Collection period - {startDateValue} - {convertDateFullMonthLetters('.', new Date(), 'short')}</div>
        <div className="revenue">All machines revenue</div>
      </div>
      <div className="collection-card-body">
        <div className="column-label-wrapper">
          {!isCollectionHistory && 
            <div className="row-label">Estimate cash amount</div>
          }
          <div className="row-label">Actual cash collected</div>
          <div className="row-label">Contactless money gross</div>
          <div className="row-label">Gross machine Income</div>
        </div>
        <div className="full-price-wrapper">
          <div className="icon-wrapper">
            <FiberManualRecord />
            <FiberManualRecord />
            <FiberManualRecord />
            <FiberManualRecord />
          </div>
          <div className="icon-wrapper pound-sign">
            <span>(£</span>
            <span>£</span>
            <span>£</span>
            <span>£</span>
          </div>
          <div>
            {!isCollectionHistory && 
              <div className="row-value">{totalCashCollected.totalEstimatedCashAmount ? totalCashCollected.totalEstimatedCashAmount.toFixed(2) : 0.00})</div>
            }
            <div className="row-value total-cash-collected">
              {totalCashCollected.actualCashCollected.toFixed(2)}
            </div>
            <div className="row-value">
              {totalCashCollected.totalContactlessMoneyGross.toFixed(2)}
            </div>
            <div className="row-value">
              {totalCashCollected.totalGrossMachineIncome.toFixed(2)}
            </div>
          </div>
        </div>
      </div>
    </StyledCollectionCard>
  );
};

export default CollectionCard;
