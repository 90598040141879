import styled from 'styled-components';


const StyledLoginForm = styled.div `
  max-width: 250px;
  margin: 20px auto 0 auto;
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    input:-internal-autofill-selected {
      background-color: white!important;
    
    }
  }
`;

export { StyledLoginForm } ;
