import {IUserDetailsStateData} from "../model";

export const extractAuthoritiesIdsFromUserDetails = (userDetails: IUserDetailsStateData) => {
  let authoritiesIds: string[] = [];
  userDetails.authorities.map(elem => {
    if (elem.checked) {
      authoritiesIds.push(elem.id);
    }
  });
  return authoritiesIds;
};
