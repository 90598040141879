import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";




const StyledMachineSiteChange = styled.div `
  .new-site-details,
  .previous-site-details {
    .title {
      text-align: center;
      padding: ${paymeTheme.paddingRemMd} 0 ${paymeTheme.paddingRemSm} 0;
      margin: auto;
      font-weight: 600;
      font-size: ${paymeTheme.fontRemSizeH6};
      color: ${paymeTheme.colorBlack};

      &.previous-title {
        font-size: ${paymeTheme.fontRemSizeLg};
      }
    }
  }

  .continue-instructions {
    text-align: center;
    color: ${paymeTheme.errorColor};
    font-size: ${paymeTheme.fontRemSizeNormal};
    padding: 0 ${paymeTheme.paddingRemXLg};
    padding-top: ${paymeTheme.paddingRemMd};
  }
  
  .new-site-details .site-header {
    color: ${paymeTheme.colorPurple};
  }
  
  .shuffle-icon {
    text-align: center;
    padding-top: ${paymeTheme.paddingRemXs};
    .MuiSvgIcon-root {
      color: ${paymeTheme.errorColor};
      font-size: 40px;
      height: auto;
    }
  }

  @media (min-width: 450px) {
    .new-site-details,
    .previous-site-details {
      margin: auto;
    }
  }
  
  ${media.tablet} {
    .new-site-details,
    .previous-site-details {
      max-width: 50%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  ${media.desktop} {
    .title {
      &.new-title {
        padding-top: 2.15rem;
      }
    }
  }

`;

export { StyledMachineSiteChange } ;
