
import React, {FC, useEffect, useState} from "react";
import {StyledSitesList} from "./styles";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {GeneralTextField} from "../../../components/Inputs";
import {useHistory} from "react-router-dom";
import {asyncRequest} from "../../../api";
import {IClientsData, ISitesItem} from "../model";
import {NotificationError} from "../../../components/NotificationMessages";
import {useDispatch, useSelector} from "react-redux";
import {displaySiteDetailsFailure, displaySiteDetailsSuccess} from "../actions";
import {displayMachineDetails} from "../../MachineDetailsPage/selectors";
import {addMachineDetails} from "../../MachineDetailsPage/actions";
import SiteCardComponent from "../../../components/SiteCard";
import {OutlinedButton, PrimaryButton} from "../../../components/Buttons";
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import * as ROUTES from "../../../components/Containers/Main/constants";
import LoadingComponent from "../../../components/Loading";
import { setFlowType } from "../../../components/Containers/Main/actions";
import { getFlowType } from "../../../components/Containers/Main/selectors";

interface Props {
  sitesList: ISitesItem[];
  isTechnician?: boolean;
  addNewEntity: () => void;
}
const SitesList: FC<Props> = ({sitesList, isTechnician, addNewEntity}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const machineDetails = useSelector(displayMachineDetails);
  const siteDetails = useSelector(displaySiteDetailsSuccess);
  const flowType = useSelector(getFlowType);
  const isChangeSiteFlow = flowType.isChangeSiteFlow;

  const [sitesListSearch, setSitesListSearch] = useState([]);
  const [totalResults, setTotalResults] = useState(-1);
  const [searchSitesError, setSearchSitesError] = useState('');
  const [isContinueBtnDisabled, setIsContinueBtnDisabled] = useState(true);
  const [initialSiteDetails, setInitialSiteDetails] = useState(null);
  const [sitesListState, setSitesListState] = useState(sitesList);
  const [loadingSite, setLoadingSite] = useState(false);

  const sortByName = (sites) => {
    const sorted = [...sites].sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    return sorted;
  };

  useEffect (() => {
    if (sitesList) {
      setSitesListState(sortByName(sitesList));
    }
  }, [sitesList]);

  useEffect(() => {
    setInitialSiteDetails(siteDetails);
  }, []);

  const updateSelectedElement = (id, sitesList, sitesListArr) => {
    sitesList.map((item, index) => {
      if (id === item.id) {
        sitesListArr.push({
          ...item,
          isSelected: true,
        })
      } else {
        sitesListArr.push({
          ...item,
          isSelected: false,
        })
      }
    });
  };

  const onClickListItemHandler = (id: number) => {
    if (isChangeSiteFlow) {
      let sitesListArr = [];
      let sitesListSearchArr = [];

      updateSelectedElement(id, sitesListState, sitesListArr);
      updateSelectedElement(id, sitesListSearch, sitesListSearchArr);
      setSitesListSearch(sitesListSearchArr);
      setSitesListState(sitesListArr);
      setIsContinueBtnDisabled(false);
    } 

    asyncRequest({
      config:{
        method: "GET",
      },
      endpoint: `api/site/${id}`,
    }).then(response => {
      setLoadingSite(true);
      dispatch(displaySiteDetailsSuccess(response));
    })
    .then(() => {
      dispatch(addMachineDetails({
        ...machineDetails,
        siteId: id,
      }));
    })
    .then(() => {
      setLoadingSite(false);
      if (!isChangeSiteFlow) {
        isTechnician ? history.push(`${ROUTES.TEHNICIAN_SITE_PAGE}/${id}`) :  history.push(`${ROUTES.SITE_PAGE}/${id}`);
      }
    })
      .catch(err => {
        console.log(err);
        dispatch(displaySiteDetailsFailure(err));
      });
  };

  const buildSiteItem = (item: ISitesItem, index: number) => (
    <div key={index} onClick={() => onClickListItemHandler(item.id)}>
      <SiteCardComponent item={item}/>
    </div>
  );

  const onSearchHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    asyncRequest({
      config:{
        method: "GET",
        params: {
          search: event.target.value
        }
      },
      endpoint: `api/site`,
    }).then(response => {
      setSitesListSearch(sortByName(response.items));
      setTotalResults(response.total);
    })
      .catch(err => {
        console.log(err);
        setSearchSitesError(err.message);
      });
  };

  const machineSiteChangeHandler = () => {
    history.push(ROUTES.MACHINE_SITE_CHANGE);
  };

  return (
    loadingSite ? <LoadingComponent /> : (
        <StyledSitesList className="height-controller-wrapper">
        <div className="add-search-wrapper">
          {!isTechnician && <div className="add-new-client"  onClick={addNewEntity}><AddIcon/><span>Add new</span></div>}
            <div className="search-section">
            <GeneralTextField id="sites-search"
                              placeholder="Search site"
                              onChange={onSearchHandler}
            />
              <SearchIcon />
            </div>
          </div>
          {totalResults === -1 && sitesList.length &&
          sitesListState.map((item: ISitesItem, index: number) => (buildSiteItem(item, index)))}
          {totalResults > 0 && sitesListSearch.length &&
          sitesListSearch.map((item: ISitesItem, index: number) => (buildSiteItem(item, index)))}
          {totalResults === 0 && <div>No results found</div>}
          {searchSitesError && <NotificationError messageClass="error" message={searchSitesError}/>}
          {isChangeSiteFlow &&
            <div className="actions-wrapper">
              <OutlinedButton className="back-button" onClick={() => {history.goBack()}}>Back</OutlinedButton>
              <PrimaryButton className="continue-button"
                            disabled={isContinueBtnDisabled}
                            onClick={machineSiteChangeHandler}>Continue</PrimaryButton>
            </div>
          }
        </StyledSitesList>
      )
  );
};

export default SitesList;
