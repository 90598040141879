import React, {FC, useEffect} from 'react';
import {StyledCompanyProfile} from "./styles";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Header from "../../components/Header";
import LoadingComponent from "../../components/Loading";
import {NotificationError} from "../../components/NotificationMessages";
import {displayCompanyDetailsAttempt} from "./actions";
import {getCompanyData, getCompanyError, getCompanyLoading} from "./selectors";
import {IProduct} from "./model";

export const CompanyProfile: FC = () => {
  const dispatch = useDispatch();
  const companyDetails = useSelector(getCompanyData);
  const isLoading = useSelector(getCompanyLoading);
  const errorMessage = useSelector(getCompanyError);

  useEffect(() => {
    dispatch(displayCompanyDetailsAttempt());
  }, []);

  return (
    <StyledCompanyProfile className="height-controller-wrapper">
      <Header title="Company profile"/>
      <div className="company-details-wrapper">
        <div className="name-details detail">
          Company name: <span>{companyDetails.companyName}</span>
        </div>
        <div className="detail">Country: <span>{companyDetails.country}</span></div>
        <div className="detail">Currency: <span>{companyDetails.currency}</span></div>
        <div className="detail">Company type: <span>{companyDetails.companyType}</span></div>
        <div className="detail">Contact name:
          <span>{companyDetails.firstName}</span>
          <span>{companyDetails.lastName}</span>
        </div>
        <div className="detail">Email address: <span>{companyDetails.email}</span></div>
        <div className="detail"> Products:
          {companyDetails && companyDetails.products && companyDetails.products.length > 0 &&
            companyDetails.products.map((product: IProduct) => <span>{product.name}</span>)
        }</div>
      </div>
      {errorMessage && <NotificationError message={errorMessage} messageClass="error"/>}
      {isLoading && <LoadingComponent/>}
    </StyledCompanyProfile>
  );
};
