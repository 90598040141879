import styled from 'styled-components';
import paymeTheme from "../../assets/styling/payme-theme";
import media from "../../assets/styling/payme-media-queries";





const StyledCompanyProfile = styled.div `
  font-size: ${paymeTheme.fontRemSizeLg};
  .company-details-wrapper {
    text-align: center;
    padding-top: 10vh;
  }
  
  .name-details {
    span:first-child {
      padding-right: ${paymeTheme.paddingRemXs};
    }
  }
  
  .detail {
    padding-bottom: ${paymeTheme.paddingRemLg};
    span {
      padding-left: ${paymeTheme.paddingRemXs};
      color: ${paymeTheme.colorBlack};
    }
  }
 
  ${media.desktop} {
   
  }

`;

export { StyledCompanyProfile } ;
