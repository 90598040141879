import {IState} from "../../store/models";

export const getClientData = (state: IState) => state.client.data;

export const getClientSitesDetails = (state: IState) => state.clientDetails.data;
export const getIsAddClientDetailsLoading = (state: IState) => state.clientDetails.loading;
export const getAddClientDetailsError = (state: IState) => state.clientDetails.error;

export const getSiteTypeList = (state: IState) => state.siteTypeList.data;
export const getSiteTypeLoading = (state: IState) => state.siteTypeList.loading;
export const getSiteTypeError = (state: IState) => state.siteTypeList.error;
