import { put, takeLatest } from "redux-saga/effects";
import {
  GET_HOST_TYPE_FAILURE, GET_HOST_TYPE_REQUEST,
  GET_HOST_TYPE_SUCCESS,
  GET_UNIT_BATTERY_POWERED_FAILURE, GET_UNIT_BATTERY_POWERED_REQUEST,
  GET_UNIT_BATTERY_POWERED_SUCCESS, GET_UNIT_CREDIT_TYPE_REQUEST,
  GET_UNIT_MANUFACTURER_FAILURE, GET_UNIT_MANUFACTURER_REQUEST,
  GET_UNIT_MANUFACTURER_SUCCESS, MACHINE_DETAILS_FAILURE, MACHINE_DETAILS_REQUEST, MACHINE_DETAILS_SUCCESS
} from "./constants";
import {asyncRequest} from "../../api";
import {push} from "connected-react-router";
import * as ROUTES from "../../components/Containers/Main/constants";

export function* fetchUnitHostTypeListSaga(action: any) {
  try {
    const getUnitHostTypeList = yield asyncRequest({
      config: {
        method: "GET",
      },
      endpoint: 'api/unit/hostTypes',
    });


    yield put({ type: GET_HOST_TYPE_SUCCESS, payload: getUnitHostTypeList });
  } catch (error) {
    console.log(error);
    yield put({ type: GET_HOST_TYPE_FAILURE, payload: error && error.message ? error.message : 'Unknown error' });
  }
}

export function* fetchUnitManufacturerListSaga(action: any) {
  try {
    const getUnitManufacturerList = yield asyncRequest({
      config: {
        method: "GET",
      },
      endpoint: `api/manufacturers/hostType/${action.payload.hostTypeId}`,
    });


    yield put({ type: GET_UNIT_MANUFACTURER_SUCCESS, payload: getUnitManufacturerList });
  } catch (error) {
    console.log(error);
    yield put({ type: GET_UNIT_MANUFACTURER_FAILURE, payload: error && error.message ? error.message : 'Unknown error' });
  }
}

export function* fetchUnitBatteryPoweredSaga(action: any) {
  try {
    const getUnitBatteryPowered = yield asyncRequest({
      config: {
        method: "GET",
      },
      endpoint: 'api/unit/batteryPowered',
    });


    yield put({ type: GET_UNIT_BATTERY_POWERED_SUCCESS, payload: getUnitBatteryPowered });
  } catch (error) {
    console.log(error);
    yield put({ type: GET_UNIT_BATTERY_POWERED_FAILURE, payload: error && error.message ? error.message : 'Unknown error' });
  }
}

export function* fetchUnitCreditTypeDetailsSaga(action: any) {
  try {
    const getUnitCreditTypeDetails = yield asyncRequest({
      config: {
        method: "GET",
      },
      endpoint: 'api/unit/creditType',
    });


    yield put({ type: GET_UNIT_BATTERY_POWERED_SUCCESS, payload: getUnitCreditTypeDetails });
  } catch (error) {
    console.log(error);
    yield put({ type: GET_UNIT_BATTERY_POWERED_FAILURE, payload: error && error.message ? error.message : 'Unknown error' });
  }
}

export function* fetchUnitDetailsSaga(action: any) {
  try {
    const machineDetailsResults = yield asyncRequest({
      config: {
        method: "POST",
        data: {
          position: action.payload.position,
          hostTypeId: action.payload.hostType.hostTypeId,
          unitCategoryId: action.payload.hostType.hostCategory.id,
          manufacturerId: action.payload.manufacturer.id,
          powerSourceId: action.payload.unitPowerSource.id,
          metalCabinetOption: action.payload.metalCabinetOption,
          defaultTransactionAmount: action.payload.defaultTransactionAmount,
          creditTypeId: action.payload.creditType.id,
          qrCodeId: !!action.payload.QrCodeId ? action.payload.QrCodeId : null,
          hostTagId: !!action.payload.externalTagId ? action.payload.externalTagId : null,
          hubTagId: !!action.payload.hubTagId ? action.payload.hubTagId : null,
          hubTagPassword: action.payload.hubTagPassword,
          siteId: action.payload.siteId,
          VRow: !!action.payload.VRow ? action.payload.VRow : [],
          VCol: !!action.payload.VCol ? action.payload.VCol : [],
          VendingRefs: !!action.payload.VendingRefs ? action.payload.VendingRefs : [],
          HubHWVer: action.payload.MT8MessageContent.HubHWVer,
          HubFWVer: action.payload.MT8MessageContent.HubFWVer,
          AdptTyp: action.payload.MT8MessageContent.AdptTyp,
          AdptHWVer: action.payload.MT8MessageContent.AdptHWVer,
          AdptFWVer: action.payload.MT8MessageContent.AdptFWVer,
          IMSI: !!action.payload.MT8MessageContent.IMSI ? action.payload.MT8MessageContent.IMSI : null,
        }
      },
      endpoint: 'api/unit'
    });

    yield put({ type: MACHINE_DETAILS_SUCCESS, payload: machineDetailsResults });
    yield put(push(ROUTES.HOME));
  } catch (error) {
    console.log(error);
    yield put({
      type: MACHINE_DETAILS_FAILURE,
      payload: error && error.response && error.response.data && error.response.data.title
        ? error.response.data.title : 'Unknown error'
    });
  }
}

export default  [
  takeLatest(GET_HOST_TYPE_REQUEST, fetchUnitHostTypeListSaga),
  takeLatest(GET_UNIT_MANUFACTURER_REQUEST, fetchUnitManufacturerListSaga),
  takeLatest(GET_UNIT_BATTERY_POWERED_REQUEST, fetchUnitBatteryPoweredSaga),
  takeLatest(GET_UNIT_CREDIT_TYPE_REQUEST, fetchUnitCreditTypeDetailsSaga),
  takeLatest(MACHINE_DETAILS_REQUEST, fetchUnitDetailsSaga),
]
