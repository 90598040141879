import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";


const StyledVendingMachineButtons = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: "Exo 2"!important;
  
  .add-section {
    width: 70%;
    display: flex; 
    align-items: center;
 
    &.add-letters {
      padding: ${paymeTheme.paddingRemLg} 0;
    }
  }
  
  .MuiAccordion-root.MuiPaper-root {
    box-shadow: none;
    background-color: #FAFAFA;
    &:before {
      background-color: transparent!important;
    }
  }
  
  .MuiAccordionSummary-root {
    width: 70%;
    display: flex;
    flex-direction: row-reverse;
    color: ${paymeTheme.customColor};
    .MuiAccordionSummary-content {
      max-width: 60%;
      text-align: center;
    }
  }
  
  .add-new-button-form {
    margin: auto;
  }
  
  .MuiSvgIcon-root {
    width: ${paymeTheme.paddingRemLg};
    height: ${paymeTheme.paddingRemLg};
    fill: #212123;
  }
  
  .digits-keyboard {
    margin: ${paymeTheme.paddingRemLg} auto;
  }
  
  
  ${media.desktop} {
      
  }
  
`;

export { StyledVendingMachineButtons } ;
