import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";


const StyledVendingConnectionStep = styled.div `
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: "Exo 2"!important;
  
  .check-connection-type-wrapper {
    padding-top: ${paymeTheme.paddingRem};
    max-width: 80%;
    .MuiFormGroup-root {
      padding-top: 50px;
      flex-direction: column;
      justify-content: space-around;
    }
  
    .MuiFormLabel-root {
      text-align: center;
      color: ${paymeTheme.errorColor};
      font-size: ${paymeTheme.fontRemSizeLg};
    }
    
    .MuiFormControlLabel-root {
      display: flex;
      flex-direction: column-reverse;
      margin: 0;
      padding: ${paymeTheme.paddingRemLg} 0;
    }
    
    .MuiRadio-colorSecondary.Mui-checked {
      color: ${paymeTheme.colorBlack};
    }
    .MuiFormControlLabel-label,
    .MuiFormLabel-root {
      font-family: "Exo 2"!important;
    }
  }
  
  
  ${media.desktop} {
      
  }
  
`;

export { StyledVendingConnectionStep } ;
