import React, { FC, useEffect, useState } from "react";
import TechnicianHome from "./TehnicianHome";
import { StyledLoggedUserHome } from "./styles";
import CollectorHome from "./CollectorHome";
import OperativeHome from "./OperativeHome";
import MerchantHome from "./MerchantHome";
import { useWindowSize } from "../../utils/detectWindowSize";
import { parseJwt } from "../../utils/parseDetailsFromToken";
import {
  ROLE_ADMIN,
  ROLE_COLLECTOR,
  ROLE_MASTER,
  ROLE_OPERATIVE,
  ROLE_TECHNICIAN,
} from "../../constants";
import {useDispatch, useSelector} from "react-redux";
import { addMachineDetails } from "../MachineDetailsPage/actions";
import { useHistory } from "react-router-dom";
import * as ROUTES from "../../components/Containers/Main/constants";
import LoadingComponent from "../../components/Loading";
import {setFlowType} from "../../components/Containers/Main/actions";
import {getFlowType} from "../../components/Containers/Main/selectors";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import SendIcon from '@material-ui/icons/Send';
import {asyncRequest} from "../../api";
import {FilledBackgroundNotification, NotificationError} from "../../components/NotificationMessages";

const LoggedHomepage: FC = () => {
  const windowSize = useWindowSize();
  const dispatch = useDispatch();
  const history = useHistory();
  const isDesktop = windowSize.width && windowSize.width > 1170;
  const flowType = useSelector(getFlowType);
  const [loggedUserDetails, setLoggedUserDetails] = useState(null);
  const [userRoleTitle, setUserRoleTitle] = useState("");
  const [loading, setLoading] = useState(true);
  const [clearStateLoading, setClearStateLoading] = useState(true);
  // use token accessed from local storage not from redux store
  const userToken = localStorage.getItem("token");
  const userDetails = userToken && parseJwt(userToken);
  const userRole = userToken && parseJwt(userToken).auth.split(",")[0];
  const [wrapperClassName, setWrapperClassName] = useState('');
  const [feedbackValue, setFeedbackValue] = useState('');

  useEffect(() => {
    if (!(!!localStorage.getItem("token"))) {
      history.push(ROUTES.LOGIN);
    }
    setLoading(false);
  }, []);

  const titleRoleType = (roleType: string) => {
    switch (roleType) {
      case ROLE_ADMIN:
        setWrapperClassName('admin');
        setUserRoleTitle("Dashboard");
        return;
      case ROLE_MASTER:
        setWrapperClassName('master');
        setUserRoleTitle("Dashboard");
        return;
      case ROLE_TECHNICIAN:
        setWrapperClassName('technician');
        setUserRoleTitle("Technician");
        return;
      case ROLE_COLLECTOR:
        setWrapperClassName('collector');
        setUserRoleTitle("Collector");
        return;
      case ROLE_OPERATIVE:
        setWrapperClassName('operative');
        setUserRoleTitle("Operative");
        return;
      default:
        setWrapperClassName('another-role');
        setUserRoleTitle("Another Role");
        return;
    }
  };

  useEffect(() => {
    setLoggedUserDetails(userDetails);
    titleRoleType(userRole);
    try {
      dispatch(
        addMachineDetails({
          siteId: 0,
          QrCodeId: "",
          externalTagId: "",
          hubTagId: "",
          hubTagPassword: "",
          position: "",
          hostType: {
            name: "",
            hostTypeId: 0,
            hostCategory: { id: 0, name: "", code: "" },
          },
          manufacturer: { name: "", id: 0 },
          unitPowerSource: { name: "", code: "", id: 0 },
          metalCabinetOption: false,
          creditType: { name: "", code: "", id: 0 },
          defaultTransactionAmount: "",
          creditTestValue: "",
          CoinMecAdptProg: null,
          VRow: [],
          VCol: [],
          VendingRefs: [],
          MT8MessageContent: null,
        })
     );
      dispatch(setFlowType({
        ...flowType,
        isSetupFlow: false,
        isReplaceTagFlow: false,
        isChangeSiteFlow: false,
        isOnlyTestFlow: false,
        clientIdFromAddSiteToClientFlow: -1,
        replaceExternalTagId: "",
        isChangePositionFlow: false,
        isChangePricePlayFlow: false,
        clientPageId: ""
      }));
    } catch (err) {
      console.log(err);
    } finally {
      setClearStateLoading(false);
    }
  }, []);

  const [successFeedbackMessage, setSuccessFeedbackMessage] = useState('');
  const [failureFeedbackMessage, setFailureFeedbackMessage] = useState('');

  const onClickFeedbackHandler = () => {
    asyncRequest({
      config: {
        method: "POST",
        data: feedbackValue,
      },
      endpoint: 'api/feedback'
    })
      .then((response) => {
        setSuccessFeedbackMessage('Your feedback was successfully sent on email');
        setFeedbackValue('');
      })
      .catch((err) => {
        setFailureFeedbackMessage(err.response.data.title);
        console.log(err);
      });
  };

  const onChangeFeedbackHandler: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    setFeedbackValue(event.target.value);
  };


  return (
    clearStateLoading ? <LoadingComponent /> : (<StyledLoggedUserHome className={`${wrapperClassName} height-controller-wrapper`}>
      <div className="home-header">
        <div className="home-title">
          <span className="flote-word">flote</span>
          .works - {userRoleTitle}
        </div>
      </div>
      {loading && <LoadingComponent />}
      {!loading && userRole && (userRole === ROLE_ADMIN || userRole === ROLE_MASTER) && (
        <MerchantHome isDesktop={!!isDesktop} userRole={userRole}/>
      )}
      {!loading && userRole && userRole === ROLE_TECHNICIAN && (
        <TechnicianHome isDesktop={!!isDesktop} userRole={userRole} />
      )}
      {!loading && userRole && userRole === ROLE_COLLECTOR && (
        <CollectorHome isDesktop={!!isDesktop} userRole={userRole} />
      )}
      {!loading && userRole && userRole === ROLE_OPERATIVE && (
        <OperativeHome isDesktop={!!isDesktop} />
      )}

      <div className="feedback-wrapper">
        {failureFeedbackMessage && <NotificationError message={failureFeedbackMessage} messageClass="error"/>}
        {successFeedbackMessage &&
        <FilledBackgroundNotification backgroundColorClass="green" message={successFeedbackMessage}/>}
        <div className="feedback-form-wrapper">
        <TextareaAutosize aria-label="textarea"
                          placeholder="Feedback"
                          value={feedbackValue}
                          onChange={onChangeFeedbackHandler} />
        <SendIcon onClick={onClickFeedbackHandler}/>
        </div>
      </div>
    </StyledLoggedUserHome>)
  );
};

export default LoggedHomepage;
