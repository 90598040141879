import React, { FC, useEffect, useState } from "react";
import { StyledExternalNFCStep } from "./styles";
import { OutlinedButton, PrimaryButton } from "../../components/Buttons";
import Header from "../../components/Header";
import {useDispatch, useSelector} from "react-redux";
import { useHistory } from "react-router-dom";
import { displayMachineDetails } from "../MachineDetailsPage/selectors";
import { useWindowSize } from "../../utils/detectWindowSize";
import { BatteryIcon, VendingIcon, MainsSquareIcon } from "./QRIcons";
import * as ROUTES from "../../components/Containers/Main/constants";
import {isAndroid, isIOS} from "react-device-detect";
import {addMachineDetails} from "../MachineDetailsPage/actions";
import { getFlowType } from "../../components/Containers/Main/selectors";

const ExternalNFCStep: FC = () => {
  const dispatch = useDispatch();
  const machineDetails = useSelector(displayMachineDetails);
  const history = useHistory();
  const windowSize = useWindowSize();
  const [externalNfcValue, setExternalNfcValue] = useState();
  const [isQRCodeDisplayed, setIsQRCodeDisplayed] = useState(false);
  const [externalHostType, setExternalHostType] = useState('');
  const [qrCodeId, setQrCodeId] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const flowType = useSelector(getFlowType);
  const isReplaceTagFlow = flowType.isReplaceTagFlow;
  const isDesktop = windowSize.width && windowSize.width > 1170;

  useEffect(() => {
    setExternalTagVariations();
    if (machineDetails.unitPowerSource.code === "MAINS") {
      setExternalNfcValue("true");
    } else {
      setExternalNfcValue("false");
    }
  }, []);


  const handleNativeQRCodeId = (event: any) => {
    setIsLoading(false);

    if(!isIOS && !isAndroid) {
      return;
    }
    try {
      const unitDetailsFromRN = JSON.parse(event.data);
      const qrCodeId = unitDetailsFromRN.qrCodeId;
      qrCodeId && setQrCodeId(qrCodeId);
    } catch (e) {
      console.warn('Error parsing unitDetailsFromRN, just turn on native app!');
    }
  };

  useEffect(() => {
    if (!!qrCodeId) {
      if (
        (qrCodeId.substring(0, 2) == "NM" &&
          !!machineDetails.metalCabinetOption) ||
        (qrCodeId.substring(0, 1) == "M" && !machineDetails.metalCabinetOption)
      ) {
        history.push(ROUTES.SCAN_QR_CODE_ERROR);
      } else {
        dispatch(
          addMachineDetails({
            ...machineDetails,
            QrCodeId: qrCodeId,
          })
        );
        if (isReplaceTagFlow) {
          window.ReactNativeWebView &&
          window.ReactNativeWebView.postMessage(
            JSON.stringify({ isReplaceTagFlow: isReplaceTagFlow })
          );
        } else {
          window.ReactNativeWebView &&
          window.ReactNativeWebView.postMessage(
            JSON.stringify({ hasExternalNFC: externalNfcValue })
          );
        }
        if (
          machineDetails.hostType.hostCategory.name === "Vending" &&
          !isReplaceTagFlow
        ) {
          history.push(ROUTES.MACHINE_BUTTONS);
          return;
        }
        if (
          machineDetails.hostType.hostCategory.name !== "Vending" &&
          !isReplaceTagFlow
        ) {
          history.push(ROUTES.MACHINE_TEST);
          return;
        }
        if (isReplaceTagFlow) {
          history.push(ROUTES.MACHINE_TEST);
        }
      }
    }
  }, [qrCodeId]);

  useEffect(() => {
    if (isIOS) {
      window.addEventListener("message", handleNativeQRCodeId);
      return () => window.removeEventListener("message", handleNativeQRCodeId);
    }
    if (isAndroid) {
      document.addEventListener("message", handleNativeQRCodeId);
      return () =>
        document.removeEventListener("message", handleNativeQRCodeId);
    }
    window.addEventListener("message", handleNativeQRCodeId);
    return () => window.removeEventListener("message", handleNativeQRCodeId);
  }, []);


  const setExternalTagVariations = () => {
    if (machineDetails.hostType.hostCategory.name === "Vending") {
      setIsQRCodeDisplayed(true);
      setExternalHostType('V');
    } else {
      if (machineDetails.unitPowerSource.code === "BATTERY") {
        setIsQRCodeDisplayed(false);
        setExternalHostType('NV_B');
        return;
      }
      if (
        machineDetails.unitPowerSource.code === "MAINS" &&
        !machineDetails.metalCabinetOption
      ) {
        setIsQRCodeDisplayed(true);
        setExternalHostType('NV_M');
        return;
      }
      if (
        machineDetails.unitPowerSource.code === "MAINS" &&
        machineDetails.metalCabinetOption
      ) {
        setExternalHostType('NV_M');
        setIsQRCodeDisplayed(true);
        return;
      }
    }
  };

  const confirmConfigureUnit = () => {
    if (externalHostType === 'NV_B') {
      history.push(ROUTES.MACHINE_TEST);
      return;
    }
    if (!!isQRCodeDisplayed) {
      window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ showQrCode: "true" })
      );
    } else {
      window.ReactNativeWebView &&
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ hasExternalNFC: externalNfcValue })
        );
    }
  };

  return (
    <StyledExternalNFCStep className="height-controller-wrapper">
      <Header title="External host information" />
      <div className="nfc-description">This is the information sticker required</div>
      <div className="cards-wrapper">
        {externalHostType === 'V' && 
            <div className="vending-icons-wrapper">
              <VendingIcon />
              <MainsSquareIcon />
            </div>
        }
        {externalHostType !== 'V' && <div className="scanning-icons-wrapper">
          {externalHostType === 'NV_B' && <BatteryIcon />}
          {externalHostType === 'NV_M' && <MainsSquareIcon />}
        </div>}
      </div>
      <div className="actions-wrapper">
        <OutlinedButton
          className="back-button"
          onClick={() => {
            history.goBack();
          }}
        >
          Back
        </OutlinedButton>
        <PrimaryButton
          className="continue-button"
          onClick={confirmConfigureUnit}
        >
          Continue
        </PrimaryButton>
      </div>
    </StyledExternalNFCStep>
  );
};

export default ExternalNFCStep;
