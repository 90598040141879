import {
  GET_CLIENTS_FAILURE,
  GET_CLIENTS_REQUEST,
  GET_CLIENTS_SUCCESS, GET_OFF_SITE_DETAILS_FAILURE, GET_OFF_SITE_DETAILS_SUCCESS,
  GET_SITE_DETAILS_FAILURE,
  GET_SITE_DETAILS_SUCCESS,
  GET_SITES_FAILURE,
  GET_SITES_REQUEST,
  GET_SITES_SUCCESS
} from "./constants";


// clients list actions

export const displayClientsListAttempt = () => ({
  type: GET_CLIENTS_REQUEST,
});

export const displayClientsListSuccess = (data: any) => ({
  type: GET_CLIENTS_SUCCESS,
  payload: { ...data},
});

export const displayClientsListFailure = (error: any) => ({
  type: GET_CLIENTS_FAILURE,
  payload: error
});


// sites list actions

export const displaySitesListAttempt = () => ({
  type: GET_SITES_REQUEST,
});

export const displaySitesListSuccess = (data: any) => ({
  type: GET_SITES_SUCCESS,
  payload: { ...data},
});

export const displaySitesListFailure = (error: any) => ({
  type: GET_SITES_FAILURE,
  payload: error
});


// site details actions

export const displaySiteDetailsSuccess = (data: any) => ({
  type: GET_SITE_DETAILS_SUCCESS,
  payload: { ...data},
});

export const displaySiteDetailsFailure = (error: any) => ({
  type: GET_SITE_DETAILS_FAILURE,
  payload: error
});


// off site details actions

export const displayOffSiteDetailsSuccess = (data: any) => ({
  type: GET_OFF_SITE_DETAILS_SUCCESS,
  payload: { ...data},
});

export const displayOffSiteDetailsFailure = (error: any) => ({
  type: GET_OFF_SITE_DETAILS_FAILURE,
  payload: error
});
