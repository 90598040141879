import styled from 'styled-components';
import media from "../../../assets/styling/payme-media-queries";
import paymeTheme from "../../../assets/styling/payme-theme";




const StyledNewTeamMember = styled.div `
  
 
  ${media.desktop} {
   
  }

`;

export { StyledNewTeamMember } ;
