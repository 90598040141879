import {GET_MACHINE_COLLECTION,} from "./constants";
import {IUnitCollectionState} from "./model";


// add machine collection action

export const addMachineCollectionDetails = (data: IUnitCollectionState) => ({
  type: GET_MACHINE_COLLECTION,
  payload: {...data}
});
