import React, {FC, useEffect, useState} from "react";
import {StyledVendingLayoutSelection} from "./styles";
import {OutlinedButton, PrimaryButton} from "../../components/Buttons";
import Header from "../../components/Header";
import {useHistory} from "react-router-dom";
import {DroppableList} from "./DNDList";
import {useDispatch, useSelector} from "react-redux";
import {getReceivedSocketMessageDetails} from "../../components/SocketCommunication/selectors";
import {displayMachineDetails} from "../MachineDetailsPage/selectors";
import {socketSentMessage} from "../../components/SocketCommunication/actions";
import LoadingComponent from "../../components/Loading";
import * as ROUTES from "../../components/Containers/Main/constants";

const VendingLayoutSelection: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const receivedSocketMessage = useSelector(getReceivedSocketMessageDetails);
  const machineDetails = useSelector(displayMachineDetails);
  const [vendingRefsState, setVendingRefsState] = useState([]);
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    if (receivedSocketMessage && receivedSocketMessage.VRefs && receivedSocketMessage.VRefs.length > 0) {
      setVendingRefsState(receivedSocketMessage.VRefs);
      setShowLoading(false);
    }
  }, [receivedSocketMessage])

  const confirmLayoutSelection = () => {
    history.push(ROUTES.MACHINE_TEST);
  };


  return (
      <StyledVendingLayoutSelection className="height-controller-wrapper">
        <Header title="Selection layout" />
        <div className="helper-screen-description">
          <div>Match to machine selection</div>
          <div>Press and hi-light to move</div>
        </div>
        <DroppableList vendingRefs={vendingRefsState}/>
        <div className="actions-wrapper">
          <OutlinedButton className="back-button" onClick={() => {history.goBack()}}>Back</OutlinedButton>
          <PrimaryButton className="continue-button"
                         onClick={confirmLayoutSelection}>
            Continue
          </PrimaryButton>
        </div>
        {showLoading && <LoadingComponent/>}
      </StyledVendingLayoutSelection>
  );
};

export default VendingLayoutSelection;
