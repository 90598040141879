import {RECEIVE_SOCKET_MESSAGE, SEND_SOCKET_MESSAGE} from "./constants";


export const socketReceivedMessage = (data: any) => ({
  type: RECEIVE_SOCKET_MESSAGE,
  payload: {...data}
});


export const socketSentMessage = (data: any) => ({
  type: SEND_SOCKET_MESSAGE,
  payload: {...data}
});
