import { put, takeLatest } from "redux-saga/effects";
import {
  GET_CLIENTS_FAILURE,
  GET_CLIENTS_REQUEST,
  GET_CLIENTS_SUCCESS, GET_SITES_FAILURE, GET_SITES_REQUEST, GET_SITES_SUCCESS
} from "./constants";
import {asyncRequest} from "../../api";

export function* fetchGetClientsListSaga(action: any) {
  try {
    const getClientsList = yield asyncRequest({
      config: {
        method: "GET",
      },
      endpoint: 'api/client',
    });


    yield put({ type: GET_CLIENTS_SUCCESS, payload: getClientsList });
  } catch (error) {
    console.log(error);
    yield put({ type: GET_CLIENTS_FAILURE, payload: error && error.message ? error.message : 'Unknown error' });
  }
}

export function* fetchGetSitesListSaga(action: any) {
  try {
    const getSitesList = yield asyncRequest({
      config: {
        method: "GET",
      },
      endpoint: 'api/site',
    });


    yield put({ type: GET_SITES_SUCCESS, payload: getSitesList });
  } catch (error) {
    console.log(error);
    yield put({ type: GET_SITES_FAILURE, payload: error && error.message ? error.message : 'Unknown error' });
  }
}

export default  [
  takeLatest(GET_CLIENTS_REQUEST, fetchGetClientsListSaga),
  takeLatest(GET_SITES_REQUEST, fetchGetSitesListSaga)
]
